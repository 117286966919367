import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';

import { PermitDoc } from '../../../config';
import { AlertService } from '../../../shared/alert/alert.service';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-space-cert-edit',
  templateUrl: './edit.component.html'
})
export class PermitSpaceLeaseCertificateEditComponent implements OnInit {
  @Input()
  permit_id:number;

  @Output()
  saved: EventEmitter<PermitDoc> = new EventEmitter<PermitDoc>();

  doc_caption:string = "Space Lease Certificate";
  doc_hint:string = "In case of Public Customs Bond, please upload the attested copy of the Lease Certificate signed between the bonded warehouse and your supplier.";

  @ViewChild('licFile', { static: false }) public licFile:ElementRef;
  showPanel = false;
  customer_id:number;
  slc_doc:PermitDoc;

  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  private file: any;
  private fileName: string;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    private dataService:DataService
  ) { }
    
  ngOnInit() {
    if (localStorage.getItem('currentUser').length > 0)
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.customer_id = data.customer_id;
    }
    this.slc_doc = new PermitDoc(this.permit_id, this.customer_id, 'SLC');
    this.buildForm();
  }

  private buildForm(): void {
    let config:any = {};

    config['lic_doc'] = [
      this.fileName,
      [
        Validators.required
      ]
    ];
    this.errors['lic_doc'] = [];
    this.messages['lic_doc'] = {
      'required': 'Space Lease Certificate is required.'
    };
  
    this.fg = this.fb.group(config);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.file = fileInput.target.files[0];
      this.fg.controls['lic_doc'].setValue("File Set");
    }
    else
      this.fg.controls['lic_doc'].setValue(null);
  }
  
  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  // show() {
  //   this.reset();
  //   this.showPanel = true;
  // }

  // reset() {
  //   // this.license.customer_id = this.customer_id;
  //   this.submitted = false;
  //   this.fileName = null;
  //   this.file = null;
  //   this.fg.reset({
  //     'lic_doc': this.fileName
  //   });
  //   this.fg.controls['lic_doc'].setValue(null);
  //   if (this.licFile)
  //     this.licFile.nativeElement.value = null;
  //   // this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  //   this.showPanel = false;
  //   return true;
  // }

  save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      let sValue = this.fg.value;
      // let save_data:any = {
      //   'customer_id': this.customer_id,
      //   'permit_id': this.permit_id
      // };
      this.loading = true;
      this.dataService.saveSpaceLeaseCertificate(this.slc_doc, this.file).subscribe((data:any) => {
        if (data.message == "Space Lease Certificate saved successfully.")
        {
          if (data.id)
            this.slc_doc.id = data.id;
          if (data.doc_id)
            this.slc_doc.doc_id = data.doc_id;
          if (data.storage_name)
            this.slc_doc.storage_name = data.storage_name;

          this.saved.emit(this.slc_doc);
          // this.showPanel = false;
        }
        else
        if (data.errorMessage)
          this.alertService.error(data.errorMessage);
        
        this.loading = false;
      });
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}