import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { DataService } from '../../data.service';

@Component({
  templateUrl: './req.component.html'
})
export class PermitRequestedComponent {
  permit_requested:any;
  trustedUrl:SafeResourceUrl;

  constructor(private route:ActivatedRoute,
    private dataService:DataService,
    private sanitizer:DomSanitizer) { 
    this.route.data.subscribe((pdata:any) => {
      // Data from resolver
      this.permit_requested = pdata.permit_submitted;
      if (this.permit_requested.permit_id)
      {
        this.dataService.printPermitRequest({ 'id': this.permit_requested.permit_id }).subscribe((blob:Blob) => {
          // let blob = new Blob([response.blob()], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(blob);
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        });
      }
    });
  }
}
