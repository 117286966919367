import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../data.service';
import { State } from '../../config';

@Component({
  templateUrl: './view.component.html'
})
export class StatesComponent implements OnInit {
  filters:any = {};
  states:Array<State> = [];

  constructor(
  private dataService:DataService,
  private router: Router) {
  }

  ngOnInit() {
    this.dataService.getStates(this.filters).subscribe((data:State[]) => {
      this.states = data;
    });
  }

  onEdit(state?: State) {
    if (!state) state = new State();
    this.dataService.filters.state = state;
    this.router.navigate(['/state']);
  }
}