import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ReqItem } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './req-item-view.component.html',
})
export class SupplierReqItemViewComponent implements OnInit {
  filter: { supplier_id: number, id: number };
  rItem: ReqItem;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {
  }

  ngOnInit() {
    let supplier_id = 0;
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.supplier_id)
        supplier_id = data.supplier_id;
    }

    this.route.params.subscribe((params: Params) => {
      if (+params.id > 0) {
        this.filter = { supplier_id: supplier_id, id: +params.id };
        this.getReqItem();
      } else {
        this.goBack();
      }

    });
  }

  getReqItem() {
    this.dataService.getReqItem(this.filter).subscribe((data: ReqItem) => {
      this.rItem = data;
      if (this.rItem.action_date)
        this.rItem.action_date = new Date(this.rItem.action_date);
    });
  }

  goBack() {
    this.router.navigate(["/sup-req-items"]);
  }
}
