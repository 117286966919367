import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { PermitDoc } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-space-cert-view',
  templateUrl: './view.component.html'
})
export class PermitSpaceLeaseCertificateViewComponent implements OnChanges, OnInit {
  @Input()
  slc_doc:PermitDoc;
  
  @Input()
  showDelete:boolean = true;
  
  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  trustedUrl:SafeResourceUrl;

  constructor(private dataService:DataService, private sanitizer: DomSanitizer) {  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.slc_doc && (changes.slc_doc as SimpleChange).currentValue && (changes.slc_doc as SimpleChange).currentValue.storage_name) {
      const storage_name = (changes.slc_doc as SimpleChange).currentValue.storage_name;
      this.dataService.getUploadedPdf(storage_name).subscribe((blob:Blob) => {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      });
    }
  }

  delete() {
    if (confirm("Are you sure to delete?"))
    {
      this.dataService.deleteSpaceLeaseCertificate(this.slc_doc.id).subscribe((data:any) => {
        if (data.message == "Space Lease Certificate deleted successfully.")
        {
          this.deleted.emit(this.slc_doc);
        }
      });
    }
  }
  @ViewChild('lgModal0', { static: false }) 
  public lgModal0:ModalDirective;
  
  show() {
    this.lgModal0.show();
  }
}