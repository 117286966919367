import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable()
export class AuthenticationService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});
  
  constructor(
    private http: HttpClient
  ) { }

  allowRegistration(data:any) {
    return this.http.post(
      environment.apiUrl + 'login.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('allowRegistration', 'true') }
    );
  }
 
  login(email: string, password: string) {
    let data = { "email": email, "password": password };
    return this.http.post(
      environment.apiUrl + 'login.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('login', 'true') }
    );
  }

  sendResetPasswordEmail(data:any) {
    return this.http.post(
      environment.apiUrl + 'login.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('sendResetPasswordEmail', 'true') }
    );
  }
 
  resetPassword(data:any) {
    return this.http.post(
      environment.apiUrl + 'login.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('resetPassword', 'true') }
    );
  }

  check_permit_no(data:any) {
    return this.http.post(
      environment.apiUrl + 'login.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('check_permit_no', 'true') }
    );
  }

  getImportPermitDoc(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'login.ajax.php', 
      data,
      { headers: cusHeaders, params: new HttpParams().set('getImportPermitDoc', 'true'), responseType: 'blob' }
    );
  }

  saveFeedback(data:any) {
    return this.http.post(
      environment.apiUrl + 'login.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveFeedback', 'true') }
    );
  }

  // private makePdfRequestOptions() {
  //   let cusHeaders = new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/pdf' });
  //   let options = new RequestOptions({ headers: cusHeaders });
  //   options.responseType = ResponseContentType.Blob;
  //   return options;
  // }

  // private handleOError(error:any): Observable<any> {
  //   return Observable.throw(error.json().error || 'Server error');
  // }
  // // private handleError(error: any): Promise<any> {
	// //   console.error('An error occurred', error); // for demo purposes only
	// //   return Promise.reject(error.message || error);
	// // }
}