import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'my-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showMenu: boolean = false;
  user:any = {};

  showEnquiry = false;

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false })
  public lgModal0:ModalDirective;

  // myMenu = {
  //   "CUS": [
  //     {}
  //   ],
  //   "TSEC": [
  //     { name: "Reports", url: "reports_home" }
  //   ],
  //   "TACC": [
  //     { name: "Reports", url: "reports_home" }
  //   ],
  //   "TMD": [
  //     { name: "Reports", url: "reports_home" }
  //   ],
  //   "PSEC": [
  //     { name: "Reports", url: "reports_home" }
  //   ],
  //   "PCOM": [
  //     { name: "Reports", url: "reports_home" }
  //   ],
  //   "TDPT": [] as any[]
  // };

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }
  
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          this.router.url.startsWith('/login') ||
          this.router.url.startsWith('/forgot_pwd') ||
          this.router.url.startsWith('/reset_pwd') ||
          this.router.url.startsWith('/licensee-signup') ||
          this.router.url.startsWith('/licensee-signup-success') ||
          this.router.url.startsWith('/ipview') ||
          this.router.url.startsWith('/public-view-permit')
        ) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
          if (localStorage.getItem('currentUser')) {
            let data = JSON.parse(localStorage.getItem('currentUser'));
            this.user = data;
          }
        }
      }
    });
  }

  isUsrType(ut:string|string[]):boolean {
    if (this.user && this.user.category_code) {
      if (typeof ut == 'string')
        return (this.user.category_code == ut);
      else {
        const regExp:RegExp = new RegExp((ut as string[]).join("|"));
        const match = this.user.category_code.match(regExp);
        return match && match.length > 0;
      }
    }
    else
      return false;
  }

  showManual() {
    if (this.user.category_code === 'CUS')
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl('assets/docs/Online Import Permit Generation for Licensee.pdf');
    if (this.isUsrType(['TSEC', 'TSEH', 'TACC', 'TGM', 'TMD']))
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl('assets/docs/Online Import Permit Generation for TASMAC.pdf');
    if (this.isUsrType(['PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM']))
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl('assets/docs/Online Import Permit Generation Manual for CPE.pdf');
    
    if (this.trustedUrl)
      this.lgModal0.show();
  }

  toggleEnquiry() {
    this.showEnquiry = true;
  }

  onSaved() {
    this.showEnquiry = false;
  }
}

// switch(data.category_code)
// {
//   case "CUS":
//     this.user.menu = [];
//     break;
//   case "TSEC":
//     this.user.menu = this.myMenu["TSEC"];
//     break;
//   case "TACC":
//     this.user.menu = this.myMenu["TACC"];
//     break;
//   case "TMD":
//     this.user.menu = this.myMenu["TMD"];
//     break;
//   case "PSEC":
//     this.user.menu = this.myMenu["PSEC"];
//     break;
//   case "PCOM":
//     this.user.menu = this.myMenu["PCOM"];
//     break;
//   case "TDPT":
//     this.user.menu = [];
//     break;
//   default:
//     this.user.menu = null;
// }
