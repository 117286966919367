import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { User } from '../../config';
import { PwdValidators } from '../../shared/validators/password.directive';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './user.component.html',
})
export class SupplierUserComponent implements OnInit {
  showPwd = true;
  submitted = false;
  loading = false;
  fg: FormGroup;
  user: User;
  formErrors: any = { };
  formMessages: any = { };
    
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    if (this.dataService.filters.sup_user) {
      // this.user = new User();
      this.user = this.dataService.filters.sup_user;

      if (!this.user.category_code) {
        this.user.category_code = 'SUP';
      }

      this.showPwd = true;
      this.buildForm();

    } else {
      this.goBack();
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['name'] = [
      this.user.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      'maxlength':     'Name should not exceed 100 characters.'
    };

    controlsConfig['mobile'] = [
      this.user.mobile, 
      [
        Validators.maxLength(10)
      ]
    ];
    this.formErrors['mobile'] = [];
    this.formMessages['mobile'] = {
      'maxlength':     'Mobile Number should not exceed 10 characters.'
    };

    controlsConfig['email'] = [
      this.user.email, 
      [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"),
        Validators.maxLength(50)
      ]
    ];
    this.formErrors['email'] = [];
    this.formMessages['email'] = {
      'required':      "eMail ID is required.",
      'pattern':       "eMail ID should include valid domain name.",
      'maxlength':     "eMail ID cannot be more than 50 characters long."
    };

    if (this.user.id && this.user.id > 0)
      this.showPwd = false;
    
    if (this.showPwd)
    {
      controlsConfig['pwd'] = [
        this.user.pwd,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(24)
        ]
      ];
      this.formErrors['pwd'] = [];
      this.formMessages['pwd'] = {
        'required':      'Password is required.',
        'minlength':     'Password must be at least 8 characters long.',
        'maxlength':     'Password cannot be more than 24 characters long.'
      };

      controlsConfig['repwd'] = [
        this.user.repwd,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(24),
          PwdValidators.pwdEqual('pwd')
        ]
      ];
      this.formErrors['repwd'] = [];
      this.formMessages['repwd'] = {
        'required':      "Re-Type Password is required.",
        'minlength':     "Re-Type Password must be at least 8 characters long.",
        'maxlength':     "Re-Type Password cannot be more than 24 characters long.",
        'pwdEqual':      "Re-Type Password should be same as Password."
      };
    }

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(() => this.onValueChanged());
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      let sValue = this.fg.value;
      this.user.name = sValue.name;
      this.user.mobile = sValue.mobile;
      this.user.email = sValue.email;
      if (this.showPwd)
      {
        this.user.pwd = sValue.pwd;
        this.user.repwd = sValue.repwd;
      }

      this.loading = true;
      this.dataService.saveUser(this.user).subscribe((data:any) => {
        if (data.message == "User saved successfully.")
        {
          if (data.user_id) {
            this.user.id = data.user_id;
          }
          this.router.navigate(["/sup-users"]);
        }
        this.loading = false;
      });
    }
  };

  changeStatus() {
    this.loading = true;
    if (confirm('Are you sure to toggle user status?'))
    {
      let reqData = { id: this.user.id, status: (this.user.status)?0:1 };
      this.dataService.updateUserStatus(reqData).subscribe((data:any) => {
        if (data.message == "User status changed successfully.") {
          this.user.status = (this.user.status)?0:1;
          this.goBack();
        }
      });
    }
    this.loading = false;
  }

  goBack() {
    this.router.navigate(['/sup-users']);
  }

  isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}
