import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';

import { Warehouse } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'warehouse-star-list',
  templateUrl: './star-list.component.html'
})
export class WarehouseStarListComponent implements OnChanges, OnInit {
  @Input("supplier_id")
  supplier_id:number;

  @Output()
  selected: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();

  warehouses:Warehouse[] = [];
  @ViewChild('lgModal', { static: false }) public lgModal:ModalDirective;

  constructor(
    private dataService:DataService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges|any) {
    let filter:any = {
      status: ['REQ', 'APP'],
      allPublic: true
    };
    if (changes.supplier_id.currentValue && changes.supplier_id.currentValue > 0)
      filter.supplier_id = this.supplier_id
    
    this.dataService.getWarehouses(filter).subscribe((data:Warehouse[]) => { this.warehouses = data; });
  }

  show() {
    this.lgModal.show();
  }

  makeAddress(war:Warehouse) {
    let ret = "";
    if (war.add1)
      ret += war.add1;
    if (war.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add2;
    if (war.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add3;
    if (war.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + war.pin;
    return ret;
  }

  select(w:Warehouse) {
    this.selected.emit(w);
    this.lgModal.hide();
  }
}
