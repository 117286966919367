import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { Warehouse } from '../../config';

interface WarehousesActions {
  allowAdd: boolean;
  allowEdit: boolean;
  allowDelete: boolean;
  showActions: boolean;
  showLicCnt: boolean;
};

@Component({
  templateUrl: 'warehouses.component.html'
})
export class WarehousesComponent implements OnInit {
  cusBondGroups: { code: string, name: string, ng_class: any }[] = [{code: 'PUBLIC', name: 'Public Bonded Warehouses', ng_class: 'label-success' }, {code: 'PRIVATE', name: 'Private Bonded Warehouses', ng_class: 'label-danger'}];
  currentCusBondCode: string = 'PUBLIC';
  filter: any = {};
  actions: WarehousesActions = {
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    showActions: false,
    showLicCnt: false
  };
  warehouses: Warehouse[] = [];

  constructor(
    private dataService: DataService
  ) {  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filter.customer_id = data.customer_id;
      this.markAllowedActions(data);
    }
    this.getWarehouses();
  }

  markAllowedActions(data: any) {
    this.actions.allowAdd = (data.category_code === 'PSEC');
    this.actions.allowEdit = (data.category_code === 'PSEC');
    this.actions.allowDelete = (data.category_code === 'PSEC');
    if (this.actions.allowEdit || this.actions.allowDelete)
      this.actions.showActions = true;
    this.actions.showLicCnt = (data.category_code === 'PSEC');
  }

  getWarehouses() {
    this.dataService.getWarehouses(this.filter).subscribe((data:Warehouse[]) => {
      this.warehouses = data;
    });
  }

  filterWarehouses(owned_type: string) {
    return this.warehouses.filter(war => war.owned_type === owned_type);
  }
  
  makeAddress(war: Warehouse) {
    let ret = "";
    if (war.add1)
      ret += war.add1;
    if (war.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add2;
    if (war.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add3;
    if (war.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + war.pin;
    if (war.state_name)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.state_name;
    return ret;
  }

  deleteWarehouse(war: Warehouse) {
    if (!confirm("Are you sure to delete warehouse?"))
      return;

    let del_data = { id: war.id };
    this.dataService.deleteWarehouse(del_data).subscribe((data:any) => {
      if (data.message == "Warehouse deleted successfully.")
        this.getWarehouses();
    });
  }
}
