import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { Customer } from '../../config';

@Component({
  templateUrl: 'customers.component.html'
})
export class PeSectionCustomersComponent implements OnInit {
  typeGroups: { code: string, name: string}[] = [{code: 'FL3', name: 'FL-3'}, {code: 'FL2', name: 'FL-2'}];
  currentTypeName: string = 'FL-3';
  filters:any = {};
  customers: Customer[] = [];

  constructor(
    private dataService:DataService,
  ) { }

  ngOnInit() {
    this.dataService.getCustomers(this.filters).subscribe(data => {
      this.customers = data;
    });
  }

  getCustomersByType(type: string) {
    return this.customers.filter(cus => cus.type === type);
  }

  makeAddress(customer:any) {
    let ret = "";
    if (customer.add1)
      ret += customer.add1;
    if (customer.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + customer.add2;
    if (customer.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + customer.add3;
    if (customer.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + customer.pin;
    return ret;
  }
}
