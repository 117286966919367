import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ReqItem } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './req-item-full-view.component.html',
})
export class ReqItemFullViewComponent implements OnInit {
  
  rItem: ReqItem;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (+params.id > 0) {
        this.getReqItem(+params.id);
      } else {
        this.goBack();
      }
    });
  }

  getReqItem(id: number) {
    this.dataService.getReqItem({ id: id }).subscribe((data: ReqItem) => {
      this.rItem = data;
      if (this.rItem.action_date) {
        this.rItem.action_date = new Date(this.rItem.action_date);
      }
    });
  }

  goBack() {
    this.router.navigate(['/req_items', 'OTH']);
  }
}
