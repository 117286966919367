import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { IndentVData, IndentVerf } from '../../config';

@Component({
  selector: 'indent-verf-msg',
  templateUrl: './indent-verf-msg.component.html'
})
export class SupplierIndentVerfMsgComponent implements OnChanges, OnInit {
  @Input()
  vdata: IndentVData;

  show_all = false;
  constructor() {  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges|any) {
  }

  toggle(show_state:boolean) {
    this.show_all = show_state;
  }

  getLvf() {
    if (this.show_all)
      return this.vdata.getAllVerfs();
    else if (this.vdata.getAllVerfs().length > 0) {
      return [this.vdata.getAllVerfs()[this.vdata.getAllVerfs().length -1]];
    } else
      return [];
  }

  getStatusText(lvf: IndentVerf) {
    let text:string = '';
    switch (lvf.status) {
      case "YET":
        text = "Yet to be verified";
        break;
      case "CLR":
        text = "Clarification Requested";
        break;
      case "CLD":
        text = "Clarified";
        break;
      case "VER":
        text = "Verified";
        break;
    }
    return text;
  }

  getStyle(lvf: IndentVerf) {
    let cls: any = { };
    switch (lvf.status) {
      case "YET":
        cls['bg-danger'] = true;
        break;
      case "CLR":
        cls['bg-warning'] = true;
        break;
      case "CLD":
        cls['bg-info'] = true;
        break;
      case "VER":
        cls['bg-success'] = true;
        break;
    }
    return cls;
  }
}