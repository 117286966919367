import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { DataService } from '../../data.service';
import { Item, ReqItem } from '../../config';


@Injectable()
export class ItemResolver implements Resolve<Item> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Item> {
    return Observable.create((ob:any) => {
      let id = +route.params['id'];
      if (id == 0)
      {
        ob.next(new Item());
        ob.complete();
        return;
      }

      this.dataService.getItem({ id: id }).subscribe((data:any) => {
        if (data.id == id)
        {
          ob.next(data as Item);
          ob.complete();
        }
        else
        {
          ob.next(new Item());
          ob.complete();
          this.router.navigate(['/items']);
        }
      });
    });
  }
}
