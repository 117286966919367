import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { PermitBase } from './permit.base.component';
import { DateValidators } from '../../shared/validators/date.directive';
import { NumberValidator } from '../../shared/validators/number.directive';
import { Config, PermitItem } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './label.component.html',
})
export class PermitLabelComponent extends PermitBase implements OnInit {
  filter: any;
  user_id: number;
  permit: any;

  submitted = false;
  loading = false;
  fg: FormGroup;
  messages: any = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService
  ) {
    super();
  }

  ngOnInit() {
    this.filter = {};
    if (localStorage.getItem('currentUser'))
    {
      const data = JSON.parse(localStorage.getItem('currentUser'));
      this.user_id = data.id;
    }
    if (this.dataService.filters.permit_label) {
      // this.for = this.dataService.filters.permit_label.for;
      this.filter.id = this.dataService.filters.permit_label.id;
      this.getPermit();
    } else {
      this.router.navigate(['/home']);
    }
    // this.route.data.subscribe((data:any) => {
    // });
    
  }

  getPermit() {
    this.dataService.getPermitToView(this.filter).subscribe((data:any) => {
      this.permit = data;
      this.permit.req_date = new Date(this.permit.req_date);
      if (this.permit.permit_date)
        this.permit.permit_date = new Date(this.permit.permit_date);
      if (this.permit.valid_date)
        this.permit.valid_date = new Date(this.permit.valid_date);
      if (this.permit.reval_date)
        this.permit.reval_date = new Date(this.permit.reval_date);
      if (this.permit.cancel_date)
        this.permit.cancel_date = new Date(this.permit.cancel_date);

      if (this.permit.inward_date)
        this.permit.inward_date = new Date(this.permit.inward_date);
      if (this.permit.outward_date)
        this.permit.outward_date = new Date(this.permit.outward_date);

      // this.permit.items = this.permit.items.map((l:PermitItem) => {
      //   l.iss_date = new Date(l.iss_date);
      //   l.val_date = new Date(l.val_date);
      //   return l;
      // });
      // this.dist_licenses(this.permit.lics);
      // this.dist_fees(this.permit.fees);
      // this.dist_pay(this.permit.pay);
      this.buildForm();
    });
  }

  private buildForm(): void {
    let config:any = {};

    let itms:any[] = [];
    this.permit.items.forEach((pi:PermitItem) => {
      let con:any = {};

      con['id'] = [
        pi.id,
        [
          Validators.required
        ]
      ];
      
      con['received_qty'] = [
        pi.received_qty,
        [
          Validators.required,
          NumberValidator.min(0),
          NumberValidator.max(pi.qty_btl)
        ]
      ];

      con['label_nos'] = [
        pi.label_nos,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(2000)
        ]
      ];

      itms.push(this.fb.group(con));
    });

    config['items'] = this.fb.array(itms);

    this.messages['received_qty'] = {
      'required':  'Received Quantity is required.',
      'min':       'Received Quantity should not be less than 0.',
      'max':       'Received Quantity cannot be greater than permited quantity.'
    };

    this.messages['label_nos'] = {
      'required':  'Label numbers is required.',
      'minLength': 'Label numbers must be at least 1 character long.',
      'maxLength': 'Label numbers cannot be more than 2000 characters long.'
    };

    let dtStr:string = null;
    if (this.permit.inward_date)
      dtStr = (this.permit.inward_date as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    config['inward_date'] = [
      dtStr,
      [
        Validators.required,
        DateValidators.isDate,
        DateValidators.minDate('01/01/1900'),
        DateValidators.maxDate(Config.now())
      ]
    ];
    this.messages['inward_date'] = {
      'required':      'Inward Date is required.',
      'isDate':        'Inward Date should be in DD/MM/YYYY format.',
      'minDate':       'Inward Date should not be less than 01/01/1900.',
      'maxDate':       'Inward Date cannot be greater than today.'
    };
    
    config['inward_vno'] = [
      this.permit.inward_vno,
      [
        Validators.required, 
        Validators.minLength(5), 
        Validators.maxLength(30)
      ]
    ];
    this.messages['inward_vno'] = {
      'required':      'Inward Vehicle No. is required.',
      'minlength':     'Inward Vehicle No. must be at least 5 characters long.',
      'maxlength':     'Inward Vehicle No. cannot be more than 30 characters long.'
    };
  
    dtStr = null;
    if (this.permit.outward_date)
      dtStr = (this.permit.outward_date as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    config['outward_date'] = [
      dtStr,
      [
        Validators.required,
        DateValidators.isDate,
        DateValidators.minDate('inward_date'),
        DateValidators.maxDate(Config.now())
      ]
    ];
    this.messages['outward_date'] = {
      'required':      'Outward Date is required.',
      'isDate':        'Outward Date should be in DD/MM/YYYY format.',
      'minDate':       'Outward Date should not be less than Inward Date.',
      'maxDate':       'Outward Date cannot be greater than today.'
    };
    
    config['outward_vno'] = [
      this.permit.outward_vno,
      [
        Validators.required, 
        Validators.minLength(5), 
        Validators.maxLength(30)
      ]
    ];
    this.messages['outward_vno'] = {
      'required':      'Outward Vehicle No. is required.',
      'minlength':     'Outward Vehicle No. must be at least 5 characters long.',
      'maxlength':     'Outward Vehicle No. cannot be more than 30 characters long.'
    };
    
    config['despatch_comment'] = [
      this.permit.despatch_comment,
      [
        Validators.maxLength(2000)
      ]
    ];
    this.messages['despatch_comment'] = {
      'maxlength':     'Despatch Comments cannot be more than 2000 characters long.'
    };

    this.fg = this.fb.group(config);
    // this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  getControlErrors(fieldName:string|string[], formGroup:FormGroup = null):string[] {
    if (!formGroup) formGroup = this.fg;

    let fNames:string[] = [];
    if (typeof fieldName == "string")
      fNames.push(fieldName);
    else
      fNames = fieldName;

    let errs:string[] = [];
    fNames.forEach(fn => {
      if (formGroup.controls[fn].errors)
      {
        Object.keys(formGroup.controls[fn].errors).forEach(eName => {
          if (this.messages[fn][eName]) errs.push(this.messages[fn][eName]);
        });
      }
    });
    return errs;
  }
  
  isControlHasError(fieldName:string, formGroup:FormGroup = null):boolean {
    if (!formGroup) formGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }

  save(is_verified = false) {
    let sValue = this.fg.value;
    sValue.id = this.permit.id;

    this.loading = true;
    this.dataService.savePermitLabel(sValue).subscribe((data:any) => {
      if (data.message == "Label Details saved successfully.")
      {
        if (is_verified)
        {
          let sData = { id: this.permit.id, user_id: this.user_id, status: "CMP" };
          this.dataService.updatePermitStatus(sData).subscribe((data:any) => {
            if (data.message == "Labelled & Despatched successfully.")
            {
              this.goBack();
            }
          });
        }
      }
      this.loading = false;
    });
  }

  verify_save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid)
    {
      let checkRQty = false;
      const rItems = this.fg.value.items;
      this.permit.items.forEach((i:PermitItem, idx:number) => {
        if (!checkRQty && rItems[idx].received_qty < i.qty_btl)
          checkRQty = true;
      });
      if (checkRQty && !confirm("Received Quantity is less than permit quantity. Are you sure to despatch?\n\nNote: You will not be able to change the received quantity later."))
        return;

      this.save(true);
    }
  }

  goBack() {
    this.router.navigate(["/permits_to_be_labelled"]);
    // if (this.user_category_code === "CUS")
    //   this.router.navigate(["/permit_list", this.for]);
    // else
    // this.router.navigate(["/permit_list", this.for]);
    // [routerLink]="['/permit_list']"
  }
}
