import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';
import { IAddress, BrandOwner } from '../../config';

@Component({
  templateUrl: './view.component.html'
})
export class BrandOwnersComponent implements OnInit {
  filters:any = {};
  brandOwners:BrandOwner[] = [];
  paginationData: { pageSize: number, page: number} = { pageSize: 100, page: 1 };

  constructor(
    private dataService:DataService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.getBrandOwners();
  }
  
  getBrandOwners() {
    this.dataService.getBrandOwners(this.filters).subscribe((data:BrandOwner[]) => {
      this.brandOwners = data.map((bo, index) => { bo.slno = index+1; return bo; });
    });
  }

  getTableData() {
    let ret = this.brandOwners;
    if (this.paginationData && this.paginationData.pageSize) 
      ret = this.brandOwners.slice((this.paginationData.page - 1) * this.paginationData.pageSize, this.paginationData.page * this.paginationData.pageSize);
    // else
    //   return this.brandOwners;
    return ret;
  }

  onPagination(changeData:{pageSize: number, page: number}) {
    this.paginationData = changeData;
  }

  makeAddress(address:IAddress|any) {
    let ret = "";
    if (address.add1) ret += address.add1;
    if (address.add2) ret = (ret ? (ret.replace(/,\s*$/, '') + ',<br>') : '') + address.add2;
    if (address.add3) ret = (ret ? (ret.replace(/,\s*$/, '') + ',<br>') : '') + address.add3;
    if (address.country_name) ret = (ret ? (ret.replace(/,\s*$/, '') + '<br>') : '') + address.country_name;
    return ret;
    // let ret = "";
    // if (address.add1)
    //   ret += address.add1;
    // if (address.add2)
    //   ret = (ret ? (ret.replace(/,\s*$/, '') + ', ') : '') + address.add2;
    // if (address.add3)
    //   ret = (ret? (ret.replace(/,\s*$/, '') + ', ') : '') + address.add3;
    // if (address.pin)
    //   ret = (ret? (ret.replace(/,\s*$/, '') + ' - ') : '') + address.pin;
    // return ret;
  }

  makeContactInfo(b:BrandOwner) {
    let ret = "";
    if (b.contact_person) ret += b.contact_person;
    if (b.contact_mobile_no) ret += (ret.length > 0) ? ('<br>Ph: <b>' + b.contact_mobile_no.toString() + '</b>') : '';
    if (b.contact_email) ret += (ret.length > 0) ? ('<br>eMail ID: <b>' + b.contact_email.toString() + '</b>') : '';
    return ret;
  }

  delete(brandOwner:BrandOwner) {
    if (!confirm("Are you sure to delete?")) return;
    this.alertService.clearAlert();
    this.dataService.deleteBrandOwner(brandOwner).subscribe((data:any) => {
      if (data.message == "Brand Owner deleted successfully.")
      {
        this.alertService.success(data.message);
        this.getBrandOwners();
      }
    });
  }
}