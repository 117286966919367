import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { Permit, UserCategory, Config, User } from '../../config';

@Component({
  templateUrl: './permits.component.html'
})
export class PermitsComponent implements OnInit {
  currentUser: User;
  userCategories: UserCategory[] = Config.userCategories.filter(uc => uc.isInvolvedInPermitApproval);
  filters:any = {};
  permits:Permit[] = [];

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.route.data.subscribe((data:any) => {
      this.filters = {};
      const filterBy = data.filterBy;

      this.filters['for'] = (filterBy['for'])?filterBy['for']:null;
      this.filters.status = (filterBy.status)?filterBy.status:null;
      this.filters.is_fl_permit = 'ALL';

      if (this.currentUser)
      {
        // localStorage.getItem('currentUser')
        const data = this.currentUser;
        //JSON.parse(localStorage.getItem('currentUser'));
        if (data.customer_id)
          this.filters.customer_id = data.customer_id;
        if (data.depot_id)
          this.filters.depot_id = data.depot_id;
        if (data.level && !(this.filters.for === 'N' || this.filters.for === 'R' || this.filters.for === 'C'))
          this.filters.level = data.level;
        if (data.category_code)
          this.filters.usr_type_code = data.category_code;
      }

      // // Purge is_expired property
      // if (this.filters.hasOwnProperty('is_expired'))
      //   delete this.filters.is_expired;
      // if (filterBy.hasOwnProperty('is_expired'))
      //   this.filters.is_expired = filterBy.is_expired;

      this.getPermits();
    });
  }

  getPermits() {
    // var filters = { ...this.filters };
    
    // if (filters.level && filters.level === 5)
    //   filters.level = [5, 6];

    this.dataService.getPermits(this.filters).subscribe((data:Permit[]) => {
      this.permits = data;
      this.permits = this.permits.map(p => {
        p.req_date = new Date(p.req_date);
        if (p.permit_date)
          p.permit_date = new Date(p.permit_date);
        if (p.extend_period) // && p.status == 'APP-REV'
          p.period += p.extend_period;
        if (p.ext_app_period) //  && p.status == 'APP-EXT'
          p.period += p.ext_app_period;

        if (p.valid_date)
          p.valid_date = new Date(p.valid_date);

        if (p.reval_date)
        {
          p.reval_date = new Date(p.reval_date);
          p.valid_date = new Date(p.reval_date);
        }

        if (p.ext_valid_date)
        {
          p.ext_valid_date = new Date(p.ext_valid_date);
          p.valid_date = new Date(p.ext_valid_date);
        }
        
        const today:Date = new Date();
        today.setHours(0, 0, 0, 0);
        p.is_expired = (p.valid_date && p.valid_date < today);

        if (p.level < this.currentUser.level && p.status === 'REQ') {
          p.valid_date = null;
          p.status = 'PEV';
          p.action = 'View'
        }

        if (p.level === this.currentUser.level && p.status === 'REQ') {
          p.action = 'Verify';
        }

        if (p.level > this.currentUser.level && p.status === 'REQ') {
          p.valid_date = null;
          p.status = 'VER';
          p.action = 'View';
        }

        if (p.level < this.currentUser.level && p.status == 'REV') {
          p.status = 'VER-REV';
          p.action = 'View'
        }

        if (p.level === this.currentUser.level && p.status == 'REV') {
          p.status = 'VER-REV';
          p.action = 'Verify';
        }

        if (p.level > this.currentUser.level && p.status == 'REV') {
          p.status = 'VER-REV';
          p.action = 'View';
        }

        if (p.level < this.currentUser.level && p.status == 'CAN') {
          p.status = 'VER-CAN';
          p.action = 'View';
        }

        if (p.level === this.currentUser.level && p.status == 'CAN') {
          p.status = 'VER-CAN';
          p.action = 'Verify';
        }

        if (p.level > this.currentUser.level && p.status == 'CAN') {
          p.status = 'VER-CAN';
          p.action = 'View';
        }

        if (p.status == 'REV-INT')
          p.status = 'APP';
        
        if (p.status == 'CAN-INT')
          p.status = 'APP';
        
        p.approvalOvertakeCodes = Config.userCategories.filter(uc => uc.level === p.level)[0].approvalOvertakeCodes;
        p.approvalOvertake = (p.approvalOvertakeCodes.indexOf(this.currentUser.category_code) >= 0);

        return p;
      });
    });
  }

  getColSpan() {
    return 13; // (this.filters['for'] === 'N')?10:13;
  }

  filterPermits(level: number) {
    return this.permits.filter(p => p.level === level);
  }

  onAction(p: Permit, action: string) {

    if (this.filters['for'])
    {
      const fFor: string = this.filters['for'];
      if (action === 'Verify') {
        this.dataService.filters.permit = { id: p.id, for: fFor, back_url: '/permits/' + fFor };
        this.router.navigate(['/permit']);
      } else {
        this.dataService.filters.permit_view = { id: p.id, back_url: '/permits/' + fFor };
        this.router.navigate(['/permit_view']);
      }
    }
  }
}

// if (this.currentUser.level === p.level) {
//   switch(fFor)
//   {
//     case 'N':
//     case 'R':
//     case 'C':
//     // case 'X':
      
//       // , fFor, p.id
//       break;
//     case 'A':
//     case 'LE':
//       this.dataService.filters.permit_view = {id: p.id, back_url: '/permits/' + fFor };
//       this.router.navigate(['/permit_view']);
//       // , fFor, p.id]
//       break;
//     // case 'L':
//     //   this.router.navigate(['/permit_label', 'L', p.id]);
//     //   break;
//     // case 'E':
//     //   this.dataService.filters.permit_ext = { id: p.id };
//     //   this.router.navigate(['/permit_ext']);
//       // , 'E', p.id
//       // break;
//     // case 'P':
//     //   this.router.navigate(['/permit_print', p.id]);
//     //   break;
//   }
// } else {
  
//   // , fFor, p.id
// }