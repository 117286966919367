import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from '../../../data.service';

@Component({
  templateUrl: './tm-brand-summary.component.html'
})
export class TMBrandSummaryComponent implements OnInit {
  minDate = new Date(2018, 0, 1);
  maxDate = new Date();
  filters: { supplier_id?: number, fromDate?: Date|string, toDate?: Date|string } = {};
  loading = false;
  suppliers: string[] = [];
  rptData: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.maxDate.setHours(0, 0, 0, 0);
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      // if (data.customer_id)
      //   this.filters.customer_id = data.customer_id;
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
    }

    this.filters.fromDate = new Date();
    this.filters.toDate = new Date();
    this.filters.fromDate.setDate(this.filters.fromDate.getDate() - 90);
    this.filters.fromDate.setHours(0, 0, 0, 0);
    this.filters.toDate.setHours(0, 0, 0, 0);

    // this.route.params.subscribe(params => {
      // this.filters.trnx_to = (params.trnx_to)?params.trnx_to:'UNKNOWN';
    // });
    this.getBrandSummaryforTM();

  }

  getBrandSummaryforTM() {
    this.suppliers = [];
    const filter = Object.assign({}, this.filters);
    if (this.filters.fromDate && typeof(this.filters.fromDate) !== 'string')
      filter.fromDate = this.filters.fromDate.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    if (this.filters.toDate && typeof(this.filters.toDate) !== 'string')
      filter.toDate = this.filters.toDate.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    this.dataService.getBrandSummaryforTM(filter).subscribe((data:any[]) => {
      this.rptData = data.map(p => {

        if (p.supplier_name && this.suppliers.indexOf(p.supplier_name) === -1)
          this.suppliers.push(p.supplier_name);

        return p;
      });
      this.suppliers.sort();
    });
  }

  getRptDataBySupplier(sup: string) {
    // console.log(sup);
    return this.rptData.filter(r => r.supplier_name === sup);
  }

  // getGrandTotal(fName: string) {
  //   let tot = 0;
  //   this.rptData.forEach(p => {
  //       tot += p[fName];
  //   });
  //   return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  // }
}
