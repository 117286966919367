import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';

import { AlertService } from '../../../shared/alert/alert.service';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-proforma-invoice-edit',
  templateUrl: './edit.component.html'
})
export class PermitProformaInvoiceEditComponent implements OnInit {
  @Input('permit_id')
  permit_id:number;

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  doc_caption:string = "Proforma Invoice";
  doc_hint:string = "Upload the copy of proforma invoice received from supplier.";

  // showPanel = false;
  customer_id:number;
  supplier_id:number;
  @ViewChild('licFile', { static: false }) public licFile:ElementRef;
  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  private file: any;
  private fileName: string;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    private dataService:DataService) {
    if (localStorage.getItem('currentUser').length > 0)
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.customer_id = data.customer_id;
      if (data.supplier_id)
        this.supplier_id = data.supplier_id;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    let config:any = {};

    config['lic_doc'] = [
      this.fileName,
      [
        Validators.required
      ]
    ];
    this.errors['lic_doc'] = [];
    this.messages['lic_doc'] = {
      'required': this.doc_caption + ' is required.'
    };
  
    this.fg = this.fb.group(config);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.file = fileInput.target.files[0];
      this.fg.controls['lic_doc'].setValue("File Set");
    }
    else
      this.fg.controls['lic_doc'].setValue(null);
  }
  
  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  // show() {
  //   this.reset();
  //   // this.showPanel = true;
  // }

  // reset() {
  //   // this.license.customer_id = this.customer_id;
  //   this.submitted = false;
  //   this.fileName = null;
  //   this.file = null;
  //   this.fg.reset({
  //     'lic_doc': this.fileName
  //   });
  //   this.fg.controls['lic_doc'].setValue(null);
  //   if (this.licFile)
  //     this.licFile.nativeElement.value = null;
  //   // this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  //   // this.showPanel = false;
  //   return true;
  // }

  save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      // let sValue = this.fg.value;
      let save_data:any = {
        customer_id: this.customer_id,
        supplier_id: this.supplier_id,
        permit_id: this.permit_id
      };
      this.loading = true;
      this.dataService.saveProformaInvoice(save_data, this.file).subscribe((data:any) => {
        if (data.message == "Proforma Invoice saved successfully.")
        {
          if (data.proforma_doc_id)
            save_data.proforma_doc_id = data.proforma_doc_id;
          if (data.proforma_storage_name)
            save_data.proforma_storage_name = data.proforma_storage_name;
          this.saved.emit(save_data);
          // this.showPanel = false;
        }
        else
        if (data.errorMessage)
          this.alertService.error(data.errorMessage);

        this.loading = false;
      });
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}
