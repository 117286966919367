import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { DataService } from '../../../data.service';
import { Indent, TasmacPermit, UserCategory, Config, User } from '../../../config';

@Component({
  templateUrl: './indent-to-request-list.component.html'
})
export class SupplierIndentToRequestListComponent implements OnInit {
  currentUser: User;
  filters:any = {};
  indents:Indent[] = [];
  showIndentColumn = false;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
    this.filters = {
      supplier_id: this.currentUser.supplier_id,
      status: ['APP'],
      action: 'To Create Request'
    };
    this.showIndentColumn = false;
    this.getIndents();
  }

  getIndents() {
    this.dataService.getIndents(this.filters).subscribe((data: Indent[]) => {
      this.indents = data;
      this.indents = this.indents.map(p => {
        p.indent_date = new Date(p.indent_date);

        if (p.permit_id && p.permit_id > 0)
          p.action = 'Edit / Submit Permit Request';
        else
          p.action = 'Create Request';

        return p;
      });
    });
  }

  onAction(p: Indent) {
    switch (p.action) {
      case 'Create Request':
        this.dataService.createRequestFromIndent({ indent_id: p.id }).subscribe((data: any) => {
          if (data.message === 'Request created for given approved indent.') {
            this.dataService.filters.sup_pmt_tm_req = { id : data.permit_id };
            this.router.navigate(["/sup-pmt-tm-req"]);
          }
        });
        break;
      case 'Edit / Submit Permit Request':
        this.dataService.filters.sup_pmt_tm_req = { id: p.permit_id };
        this.router.navigate(['/sup-pmt-tm-req']);
        break;
    }
  }
}
