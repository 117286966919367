import { Component, Input } from '@angular/core';

@Component({
  selector: 'v-state',
  templateUrl: './v-state.component.html'
})
export class PermitVStateComponent {
  @Input()
  status:boolean;

  constructor() {  }
}
