import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { PermitBase } from '../../components/permit/permit.base.component';
import { IndentItem, IndentRemark, Indent, Depot, User, Config, IndentVS, IndentVData, IndentVerf } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './indent.component.html',
})
export class SupplierIndentComponent extends PermitBase implements OnInit {
  user: User;
  private supplier_id: number;
  depots: Depot[] = [];
  indent: Indent;
  new_pi: IndentItem;
  edit_pi: IndentItem;
  vStatus:{ [index:string]: boolean }; //any;
  vs = IndentVS;
  loading = false;
  back_url: string = '/home';
  isVerfMode: boolean = false;

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;

  constructor(
    private router: Router,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    let id: number = 0;
    this.dataService.getDepots({ }).subscribe((data: Depot[]) => this.depots = data);

    if (localStorage.getItem('currentUser'))
    {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      if (this.user.supplier_id) {
        this.supplier_id = this.user.supplier_id;
      }
    }

    if (this.dataService.filters.indent && this.dataService.filters.indent.id) {
      id = this.dataService.filters.indent.id;
      this.supplier_id = this.dataService.filters.indent.supplier_id;
      this.back_url = this.dataService.filters.indent.back_url;
    }

    if (this.supplier_id) {
      this.dataService.getIndent({ indent_id: id, supplier_id: this.supplier_id }).subscribe((data: any) => {
        this.populateIndentData(data);
      });
    } else {
      this.router.navigate(['/home']);
    }
  }

  private update_vStatus() {
    this.vStatus = {
      depot: this.indent && this.indent.depot_id && this.indent.depot_id > 0,
      supplier: this.indent.supplier && this.indent.supplier_id > 0,
      items: this.indent.items && this.indent.items.length > 0
    };
  }

  private populateIndentData(data: Indent) {
    this.indent =  data; // Object.assign({}, new Indent(this.supplier_id), data);
    if (this.indent.indent_date) this.indent.indent_date = new Date(this.indent.indent_date);
    this.isVerfMode = (this.indent.status !== 'IN');
    
    if (data.items && data.items.length > 0)
    {
      this.indent.items = data.items.map((i: IndentItem) => {
        let pi = new IndentItem(i.indent_id);
        pi.id = i.id;
        pi.item_id = i.item_id;
        pi.vol = i.vol;
        pi.qty_case = i.qty_case;
        pi.btl_per_case = i.btl_per_case;
        pi.qty_btl = i.qty_btl;
        pi.orig_qty_case = i.orig_qty_case;
        pi.orig_qty_btl = i.orig_qty_btl;
        pi.is_deleted = i.is_deleted;

        // Extended
        pi.item_cat_id = i.item_cat_id;
        pi.item_cat_name = i.item_cat_name;
        pi.item_name = i.item_name;
        pi.origin_country_id = i.origin_country_id;
        pi.con_name = i.con_name;
        pi.is_edit_mode = false;
        return pi;
      });
      this.new_pi = new IndentItem(this.indent.id);
    } else {
      this.new_pi = new IndentItem(this.indent.id);
    }
    
    if (data.remarks && data.remarks.length > 0)
    {
      this.indent.remarks = data.remarks.map((r: IndentRemark) => {
        r.rem_date = new Date(r.rem_date);
        return r;
      });
    } else {
      this.indent.remarks = [];
    }

    this.addNewRemark();
    this.update_vStatus();

    this.makeVerfData(this.indent.id);
    if (this.indent.verfs)
    {
      // this.verfs = data.verfs;
      this.indent.verfs = this.indent.verfs.map(v => {
        v.verf_date = new Date(v.verf_date);
        return v;
      });
      Object.values(this.vs).forEach((vd: IndentVData) => {
        let vs = this.indent.verfs.filter((v: IndentVerf) => { return v.verf_code == vd.verf_code; });
        vd.setVerfs(vs);
      });
    }
  }

  makeVerfData(indent_id: number) {
    let isClarify = false;
    const v_act = ['SUP'];
    if (this.indent.status === 'CLR-SUP') isClarify = true;

    this.vs.depot = new IndentVData(this.user, indent_id, "DEPOT", [...v_act, 'TSEH'], isClarify);
    this.vs.supplier = new IndentVData(this.user, indent_id, "SUPPLIER", [...v_act, 'TSEH'], isClarify);
    this.vs.items = new IndentVData(this.user, indent_id, "ITEMS", [...v_act, 'TSEH'], isClarify);
  }

  get_over_all_status() {
    let tot = 0;
    let ver_cnt = 0;
    let clr_cnt = 0;
    let cld_cnt = 0;
    Object.values(this.vs).forEach(vd => {
      if (vd && (vd.allow_state_change || vd.showChangeState))
      {
        tot++;
        if (vd.lvf_state.status == 'VER')
          ver_cnt++;
        if (vd.lvf_state.status == 'CLR')
          clr_cnt++;
        if (vd.lvf_state.status == 'CLD')
          cld_cnt++;
      }
    });
    let over_all_status = "YET";
    switch (true)
    {
      case (clr_cnt > 0):
        over_all_status = "CLR";
        break;
      case (cld_cnt > 0 && clr_cnt == 0):
        over_all_status = "CLD";
        break;
      case (tot == ver_cnt):
        over_all_status = "VER";
        break;
    }
    return over_all_status;
  }

  saveIndent(depot_id: number) {
    this.loading = true;
    const reqData = { id: this.indent.id, depot_id: depot_id, supplier_id: this.indent.supplier_id, user_category_code: this.user.category_code, user_id: this.user.id };
    this.dataService.saveIndent(reqData).subscribe((data: Indent) => {
      if (data.message == "Indent saved successfully.")
      {
        this.populateIndentData(data);
        this.update_vStatus();
      }
      this.loading = false;
    });
  }

  isItemEditable():boolean {
    return this.user && this.user.category_code && this.user.category_code === 'SUP' && this.indent && this.indent.id && this.indent.id > 0 && this.indent.supplier_id && this.indent.supplier_id > 0 && !!this.new_pi;
  }

  showIndentItemDeleteButton() {
    return this.indent.items.filter(i => !i.is_deleted).length > 1;
  }

  onIndentItemSaved(data: IndentItem, index: number) {
    data.is_edit_mode = false;
    if (index == -1)
    {
      this.indent.items.push(data);
      this.new_pi = new IndentItem(this.indent.id);
    }
    else
      this.indent.items[index] = data;
    this.edit_pi = null;
    this.update_vStatus();
  }

  onIndentItemCancelled(value: boolean, index: number) {
    this.indent.items[index].is_edit_mode = !value;
    this.edit_pi = null;
  }

  onIndentItemDeleted(pi: IndentItem, index: number) {
    if (!confirm("Are you sure to " + (pi.is_deleted?"undelete":"delete") + "?")) return;

    this.loading = true;
    let del_data = { id: pi.id, user_category_code: this.user.category_code, user_id: this.user.id };
    this.dataService.deleteIndentItem(del_data).subscribe((data:any) => {
      if (data.message == "Brand deleted successfully from Indent.")
      {
        if (data.hasOwnProperty('is_deleted')) {
          this.indent.items[index].is_deleted = data.is_deleted;
        } else {
          this.indent.items.splice(index, 1);
        }
        this.update_vStatus();
        this.loading = false;
      }
    });
  }

  change_edit_mode(pi: IndentItem) {
    this.edit_pi = Object.assign(new IndentItem(this.indent.id), pi);
    pi.is_edit_mode = true;
  }

  addNewRemark() {
    let nr = new IndentRemark(this.indent.id, this.user.id);
    nr.isEdit = true;
    this.indent.remarks.push(nr);
  }

  onRemarksSaved(rem: IndentRemark) {
    rem.isEdit = false;
    this.addNewRemark();
  }

  goBack() {
    this.dataService.filters.indent = null;
    this.router.navigate([this.back_url]);
  }

  print() {
    this.loading = true;
    this.dataService.printIndent({ 'id': this.indent.id, 'is_preview': true }).subscribe((blob:Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }

  // Verification related logic
  isModeVerifyOn() {
    return (!this.isCategoryCodeOf(['TGM', 'TMD']));
  }

  isCategoryCodeOf(ut:string|string[]):boolean {
    if (typeof ut == 'string')
      return (this.user.category_code == ut);
    else
    {
      const regExp:RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.user.category_code.match(regExp);
      return match && match.length > 0;
    }
  }

  isVStatusChecked() {
    this.update_vStatus();
    let result = true;
    Object.keys(this.vStatus).forEach(key => {
      if (result && !this.vStatus[key])
        result = false;
    });
    return result;
  }

  isSubmitClarificationBySUP() {
    return ( this.isCategoryCodeOf('SUP') && this.get_over_all_status() == 'CLD' );
  }

  submitClarificationBySUP() {
    if (!this.isSubmitClarificationBySUP()) return;
    if (this.isVStatusChecked() && confirm("Are you sure to submit clarification?"))
    {
      this.loading = true;
      let level = 0;
      if (localStorage.getItem('currentUser'))
      {
        let data = JSON.parse(localStorage.getItem('currentUser'));
        level = data.level + 1;
      }
      let status_data = { 'id': this.indent.id, user_id: this.user.id, 'status': 'CLD-TSEH', 'level': level };
      this.dataService.updateIndentStatus(status_data).subscribe((data:any) => {
        if (data.message == "Indent clarification submitted successfully.")
          this.goBack();
        this.loading = false;
      });
    }
  }

  verify() {
    if (this.isVStatusChecked() && confirm("Are you sure to submit this indent?"))
    {
      this.loading = true;
      let level = 0;
      if (localStorage.getItem('currentUser'))
      {
        let data = JSON.parse(localStorage.getItem('currentUser'));
        level = data.level + 1;
      }
      let status_data = { 'id': this.indent.id, user_id: this.user.id, 'status': 'REQ', 'level': level };
      this.dataService.updateIndentStatus(status_data).subscribe((data:any) => {
        if (data.message == "Indent submitted successfully.")
        {
          this.indent.indent_no = data.indent_no;
          this.indent.indent_date = new Date(data.indent_date);

          this.dataService.filters.supplier_indent_submitted = this.indent;
          this.router.navigate(["/sup-indent-submitted"]);
        }
        this.loading = false;
      });
    }
  }

  isSendBackToSUP() {
    return ( this.isCategoryCodeOf('TSEH') && this.get_over_all_status() == 'CLR' );
  }

  sendBackToSUP() {
    if (!this.isSendBackToSUP()) return;
    if (!confirm('Are you sure to send back to supplier for clarification?')) return;
    
    this.loading = true;
    const supUser = Config.userCategories.filter(ug => ug.code === 'SUP')[0];
    const reqData = { id: this.indent.id, user_id: this.user.id, status: 'CLR-SUP', level: supUser.level };
    this.dataService.updateIndentStatus(reqData).subscribe((data:any) => {
      if (data.message == "Indent sent back to supplier for clarification.")
        this.goBack();
      this.loading = false;
    });
  }

  isForward(to_category_code: string) {
    return ( this.isCategoryCodeOf(to_category_code) && this.get_over_all_status() == 'VER' );
  }

  forward(to_category_code: string) {
    this.update_vStatus();
    let result = true;
    Object.keys(this.vStatus).forEach(key => {
      if (result && !this.vStatus[key])
        result = false;
    });
    const nextUser = Config.userCategories.filter(ug => ug.code === to_category_code)[0];
    if (result && confirm("Are you sure to forward this indent to " + nextUser.name + "?"))
    {
      this.loading = true;
      const level = nextUser.level;
      let status_data = { id: this.indent.id, user_id: this.user.id, status: 'VER', level: level };
      this.dataService.updateIndentStatus(status_data).subscribe((data:any) => {
        if (data.message == "Indent verified successfully.")
        {
          this.goBack();
        }
        this.loading = false;
      });
    }
  }

  sendBacktoTSEH() {
    if (!confirm('Are you sure to send back to TASMAC (H - Section) for clarification?')) return;
    
    this.loading = true;
    const tsehUser = Config.userCategories.filter(ug => ug.code === 'TSEH')[0];
    const reqData = { id: this.indent.id, user_id: this.user.id, status: 'CLR-TSEH', level: tsehUser.level };
    this.dataService.updateIndentStatus(reqData).subscribe((data:any) => {
      if (data.message == "Indent sent back to TASMAC (H - Section) for clarification.")
        this.goBack();
      this.loading = false;
    });
  }

  approve() {
    this.update_vStatus();
    let result = true;
    Object.keys(this.vStatus).forEach(key => {
      if (result && !this.vStatus[key])
        result = false;
    });
    const nextUser = Config.userCategories.filter(ug => ug.code === 'TMD')[0];
    if (result && confirm("Are you sure to approve this indent?"))
    {
      this.loading = true;
      const level = nextUser.level;
      let status_data = { 'id': this.indent.id, user_id: this.user.id, 'status': 'APP', 'level': level };
      this.dataService.updateIndentStatus(status_data).subscribe((data:any) => {
        if (data.message == "Indent approved successfully.")
        {
          this.goBack();
        }
        this.loading = false;
      });
    }
  }
}
