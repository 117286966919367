import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, SimpleChanges, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';

import { Item, User } from '../../config';
import { EmailValidator } from '../../shared/validators/email.directive';
import { AuthenticationService } from '../../login.service';

@Component({
  selector: 'import-feedback',
  templateUrl: './new.component.html'
})
export class ImportFeedbackComponent implements OnChanges, OnInit {
  feedback = { from: '', subject: '', msg: '' };
  user: User;
  submitted = false;
  loading = false;
  fg: FormGroup;
  formErrors:any = { };
  formMessages:any = { };

  @Input()
  showEnquiry = false;

  @Output()
  saved:EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('lgModal', { static: false })
  public lgModal:ModalDirective;

  constructor(
    private fb:FormBuilder,
    private authenticationService:AuthenticationService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.showEnquiry && changes.showEnquiry.currentValue)
    {
      this.show();
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    let from = (this.user && this.user.email)?{ value: this.user.email, disabled: true }:null;
    
    controlsConfig['from'] = [
      from,
      [
        Validators.required,
        Validators.maxLength(50),
        EmailValidator.isEmail
      ]
    ];
    this.formErrors['from'] = [];
    this.formMessages['from'] = {
      'required': "eMail ID is required.",
      'maxlength': "eMail ID should not be more than 50 characters long.",
      'isemail': "Please provide valid eMail ID."
    };

    controlsConfig['subject'] = [
      this.feedback.subject,
      [
        Validators.required
      ]
    ];
    this.formErrors['subject'] = [];
    this.formMessages['subject'] = {
      'required': 'Subject is required.'
    };

    controlsConfig['msg'] = [
      this.feedback.msg,
      [
        Validators.required,
        Validators.maxLength(2000)
      ]
    ];
    this.formErrors['msg'] = [];
    this.formMessages['msg'] = {
      'required':  'Message is required.',
      'maxlength': 'Message should not be more than 2000 characters long.'
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  show() {
    if (localStorage.getItem('currentUser')) {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    } else {
      this.user = null;
    }
    this.buildForm();
    this.lgModal.show();
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      let sValue = this.fg.value;
      this.feedback.from = sValue.from;
      this.feedback.subject = sValue.subject;
      this.feedback.msg = sValue.msg;

      this.loading = true;
      this.authenticationService.saveFeedback(this.feedback).subscribe ((data:any) => {
        if (data.message == "Feedback saved successfully.")
        {
          this.hide();
        }

        this.loading = false;
      });
    }
  }

  hide() {
    this.lgModal.hide();
    this.feedback.subject = null;
    this.feedback.msg = null;
    this.feedback.from = null;
    this.saved.emit();
  }

  public isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}
