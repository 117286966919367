import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PermitBase } from './permit.base.component';
import { PermitRemark, License } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './view.component.html',
})
export class PermitViewComponent extends PermitBase implements OnInit {
  permit:any;
  filter: any;
  for:string;
  user_category_code = "";

  constructor(
    private router:Router,
    private dataService:DataService
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.user_category_code = data.category_code;
    }
    this.filter = {};
    if (this.dataService.filters.permit_view) {
      this.filter.id = this.dataService.filters.permit_view.id;
      this.getPermit();
    } else {
      this.router.navigate(['/home']);
    }
  }

  getPermit() {
    this.dataService.getPermitToView(this.filter).subscribe((data:any) => {
      this.permit = data;
      this.for = this.permit.for;
      this.permit.req_date = new Date(this.permit.req_date);
      if (this.permit.permit_date)
        this.permit.permit_date = new Date(this.permit.permit_date);
      if (this.permit.valid_date)
        this.permit.valid_date = new Date(this.permit.valid_date);
      if (this.permit.reval_date)
        this.permit.reval_date = new Date(this.permit.reval_date);
      if (this.permit.cancel_date)
        this.permit.cancel_date = new Date(this.permit.cancel_date);

      if (this.permit.ext_req_date)
        this.permit.ext_req_date = new Date(this.permit.ext_req_date);
      if (this.permit.bdr_cross_date)
        this.permit.bdr_cross_date = new Date(this.permit.bdr_cross_date);
      if (this.permit.rpt_date)
        this.permit.rpt_date = new Date(this.permit.rpt_date);
      if (this.permit.ext_valid_date)
        this.permit.ext_valid_date = new Date(this.permit.ext_valid_date);
      if (this.permit.ext_app_date)
        this.permit.ext_app_date = new Date(this.permit.ext_app_date);

      if (this.permit.inward_date)
        this.permit.inward_date = new Date(this.permit.inward_date);
      if (this.permit.outward_date)
        this.permit.outward_date = new Date(this.permit.outward_date);

      this.permit.lics = this.permit.lics.map((l:License) => {
        l.iss_date = new Date(l.iss_date);
        l.val_date = new Date(l.val_date);
        return l;
      });
      this.pDocs = this.dist_docs(this.permit.docs);
      this.dist_licenses(this.permit.lics);
      this.dist_fees(this.permit.fees);
      this.dist_pay(this.permit.pay);

      this.permit.remarks = this.permit.remarks.map((r:PermitRemark) => {
        r.rem_date = new Date(r.rem_date);
        return r;
      });
    });
  }

  goBack() {
    this.router.navigate([this.dataService.filters.permit_view.back_url]);
  }
}

// this.route.data.subscribe((data:any) => {
// });

// if (this.permit.remarks && this.permit.remarks.length > 0)
// {
// }

// if (this.user_category_code === "CUS")
//   this.router.navigate(["/permit_list", this.for]);
// else
//   this.router.navigate(["/permits", this.for]);

// this.router.navigate(["/permit_list", this.for]);
// [routerLink]="['/permit_list']"
