import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';

import { Supplier } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'supplier-star-list',
  templateUrl: './star-list.component.html'
})
export class SupplierStarListComponent implements OnInit {
  @Output()
  selected: EventEmitter<Supplier> = new EventEmitter<Supplier>();

  suppliers:Supplier[] = [];
  @ViewChild('lgModal', { static: false }) public lgModal:ModalDirective;

  constructor(
    private dataService:DataService
  ) { }

  ngOnInit() {
    let filter = {
      'status': ['REQ', 'APP']
    };
    this.dataService.getSuppliers(filter).subscribe((data:Supplier[]) => { this.suppliers = data; });
  }

  show() {
    this.lgModal.show();
  }

  makeAddress(supplier:Supplier) {
    let ret = "";
    if (supplier.add1)
      ret += supplier.add1;
    if (supplier.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + supplier.add2;
    if (supplier.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + supplier.add3;
    if (supplier.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + supplier.pin;
    return ret;
  }

  select(s:Supplier) {
    this.selected.emit(s);
    this.lgModal.hide();
  }
}
