import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { DataService } from '../../../data.service';
import { TaxInvoice } from '../../../config';

@Component({
  templateUrl: './tax-invoices.component.html'
})
export class TaxInvoicesComponent implements OnInit {
  filters:any = {};
  loading = false;
  taxInvoices: TaxInvoice[] = [];
  // customers:string[] = [];

  trustedUrl: SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0: ModalDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dataService: DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filters.customer_id = data.customer_id;
    }

    this.getTaxInvoices();
  }

  getTaxInvoices() {
    this.dataService.getTaxInvoices(this.filters).subscribe((data:any[]) => {
      this.taxInvoices = data;
      this.taxInvoices = this.taxInvoices.map(p => {
        p.inv_date = new Date(p.inv_date);

        return p;
      });
    });
  }

  getGrandTotal(fName: string) {
    let tot = 0;
    this.taxInvoices.forEach(p => {
        tot += p[fName];
    });
    return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  printCInvoice(ti: TaxInvoice) {
    this.loading = true;
    const data = { 'id': ti.permit_id, permit_payment_id: ti.permit_payment_id };
    this.dataService.printCInvoice(data).subscribe((blob:Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }
}
