import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { ReqItem } from '../../config';

@Component({
  templateUrl: './req-items.component.html'
})
export class SupplierReqItemsComponent implements OnInit {
  filters:any = {};
  items:ReqItem[] = [];

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
    }
    this.getReqItems();
  }

  getReqItems() {
    this.dataService.getReqItems(this.filters).subscribe((data:ReqItem[]) => {
      this.items = data;
    });
  }

  onActionClick(r:ReqItem) {
    switch(r.status)
    {
      case "REQ":
        this.router.navigate(["/sup-req-item", r.id]);
        break;
      case "APP":
      case "REJ":
        this.router.navigate(["/sup-req-item-view", r.id]);
        break;
    }
  }
}
