import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { EmailValidator } from '../../shared/validators/email.directive';
import { AlertService } from '../../shared/alert/alert.service';
import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: './forgot_pwd.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  showForm = true;
  submitted = false;
  loading = false;
  fg: FormGroup;
  formErrors:any = { };
  formMessages:any = { };
    
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private authenticationService:AuthenticationService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['email'] = [
      null,
      [
        Validators.required,
        Validators.maxLength(50),
        EmailValidator.isEmail
      ]
    ];
    this.formErrors['email'] = [];
    this.formMessages['email'] = {
      'required': 'eMail ID is required.',
      'maxlength': "eMail ID should not be more than 50 characters long.",
      'isemail': "Please provide valid eMail ID."
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(() => this.onValueChanged());
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      let sValue = this.fg.value;
      let fg_data = { 'email': sValue.email };

      this.loading = true;
      this.authenticationService.sendResetPasswordEmail(fg_data).subscribe((data:any) => {
        if (data.message == "Password reset email successfully sent.")
        {
          let message = data.message + ' Please click the reset button in your eMail and reset your password.';
          this.alertService.success(message, true);
          this.showForm = false;
        }
        else
          this.alertService.error(data.message);
        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.fg.controls[fieldName].touched || 
  }

  public goBack() {
    this.alertService.clearAlert();
    this.router.navigate(["/login"]);
  }
}
