import { Component, OnInit } from '@angular/core';

import { DataService } from '../../../data.service';
import { Depot, Permit, TasmacPermit, PermitItem, Supplier } from '../../../config';

@Component({
  templateUrl: './tm-approved-permits.component.html'
})
export class TMApprovedPermitsComponent implements OnInit {
  minDate = new Date(2018, 0, 1);
  maxDate = new Date();
  filters: { supplier_id?: number|'ALL', depot_id?: number|'ALL', fromDate?: Date|string, toDate?: Date|string } = { supplier_id: 'ALL', depot_id: 'ALL' };
  loading = false;
  depots: Depot[] = [];
  showDepots = false;
  depot_list: Depot[] = [];
  showSuppliers = false;
  supplier_list: Supplier[] = [];
  rptData: any[] = [];

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.maxDate.setHours(0, 0, 0, 0);
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.depot_id)
        this.filters.depot_id = data.depot_id;
      else {
        this.dataService.getDepots({}).subscribe(data => this.depot_list = data);
        this.showDepots = true;
      }
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
      else {
        this.dataService.getSuppliers({}).subscribe(data => this.supplier_list = data);
        this.showSuppliers = true;
      }
    }

    this.filters.fromDate = new Date();
    this.filters.toDate = new Date();
    this.filters.fromDate.setDate(this.filters.fromDate.getDate() - 90);
    this.filters.fromDate.setHours(0, 0, 0, 0);
    this.filters.toDate.setHours(0, 0, 0, 0);

    this.getApprovedPermitsforTM();

  }

  getApprovedPermitsforTM() {
    this.depots = [];
    const filter = Object.assign({}, this.filters);
    if (typeof(filter.depot_id) === 'string' && filter.depot_id === 'ALL') {
      delete filter.depot_id;
    }
    if (typeof(filter.supplier_id) === 'string' && filter.supplier_id === 'ALL') {
      delete filter.supplier_id;
    }
    if (this.filters.fromDate && typeof(this.filters.fromDate) !== 'string')
      filter.fromDate = this.filters.fromDate.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    if (this.filters.toDate && typeof(this.filters.toDate) !== 'string')
      filter.toDate = this.filters.toDate.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    this.dataService.getApprovedPermitsforTM(filter).subscribe((data:any[]) => {
      this.rptData = data.map(p => {
        
        let depot: Depot;
        const filDep = this.depots.filter(d => d.id === p.depot_id);
        if (filDep.length === 0) {
          depot = new Depot();
          depot.id = p.depot_id;
          depot.name = p.depot_name;
          depot.permits = [];
          this.depots.push(depot);
        } else {
          depot = filDep[0];
        }

        let permit: TasmacPermit;
        const filPer = depot.permits.filter(pr => pr.id === p.permit_id);
        if (filPer.length === 0) {
          permit = new TasmacPermit(p.supplier_id);
          permit.id = p.permit_id;
          permit.req_no = p.req_no;
          permit.req_date = new Date(p.req_date);
          permit.permit_no = p.permit_no;
          permit.permit_date = new Date(p.permit_date);
          permit.indent_no = p.indent_no;
          permit.indent_date = new Date(p.indent_date);
          permit.supplier_id = p.supplier_id;
          permit.sup_name = p.supplier_name;
          permit.items = [];
          depot.permits.push(permit);
        } else {
          permit = filPer[0];
        }

        let pItem: PermitItem;
        const filItm = permit.items.filter(it => it.id === p.permit_item_id);
        if (filItm.length === 0) {
          pItem = new PermitItem(p.permit_id);
          pItem.id = p.permit_item_id;
          pItem.item_cat_id = p.item_cat_id;
          pItem.item_cat_name = p.item_cat_name;
          pItem.item_id = p.item_id;
          pItem.item_name = p.item_name;
          pItem.vol = p.vol;
          pItem.qty_case = p.qty_case;
          pItem.btl_per_case = p.btl_per_case;
          pItem.qty_btl = p.qty_btl;
          pItem.received_qty = p.received_qty;
          permit.items.push(pItem);
        }

        return p;
      });
      // console.log(this.depots);
      // this.depots.sort();
    });
  }

  // getRptDataBySupplier(sup: string) {
  //   return this.rptData.filter(r => r.supplier_name === sup);
  // }

  // getGrandTotal(fName: string) {
  //   let tot = 0;
  //   this.rptData.forEach(p => {
  //       tot += p[fName];
  //   });
  //   return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  // }
}
