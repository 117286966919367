import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

import { DataService } from '../../data.service';
import { Permit } from '../../config';
import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: './permit-eval-print.component.html'
})
export class PermitEvalPrintComponent implements OnInit {
  filters: { status: string[], is_eval_prn: number, is_fl_permit: boolean|string } = { status: ['APP', 'REV-INT', 'CAN-INT', 'CMP'], is_eval_prn: 0, is_fl_permit: 'ALL' };
  permits: Permit[] = [];
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;
  trustedUrl:SafeResourceUrl;
  loading = false;

  constructor(
    private router: Router,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getPermits();
  }

  getPermits() {
    var filters = { ...this.filters };

    this.loading = true;
    this.dataService.getPermits(filters).subscribe((data:Permit[]) => {
      this.loading = false;
      this.permits = data;
      this.permits = this.permits.map(p => {
        p.req_date = new Date(p.req_date);
        if (p.permit_date)
          p.permit_date = new Date(p.permit_date);
        if (p.extend_period) // && p.status == "APP-REV"
          p.period += p.extend_period;
        if (p.ext_app_period) //  && p.status == "APP-EXT"
          p.period += p.ext_app_period;

        if (p.valid_date)
          p.valid_date = new Date(p.valid_date);

        if (p.reval_date)
        {
          p.reval_date = new Date(p.reval_date);
          p.valid_date = new Date(p.reval_date);
        }

        if (p.ext_valid_date)
        {
          p.ext_valid_date = new Date(p.ext_valid_date);
          p.valid_date = new Date(p.ext_valid_date);
        }
        
        const today:Date = new Date();
        today.setHours(0, 0, 0, 0);
        p.is_expired = (p.valid_date && p.valid_date < today);

        if (p.status == "REV-INT")
          p.status = "APP";
        
        if (p.status == "CAN-INT")
          p.status = "APP";
        
        return p;
      });
    });
  }

  printApplication(p: Permit) {
    this.loading = true;
    this.dataService.printPermitRequest({ 'id': p.id }).subscribe((blob: Blob) => {
      this.loading = false;
      if (blob.size > 0)
      {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        this.lgModal0.show();
      }
    });
  }

  printPermit(p: Permit) {
    this.loading = true;
    this.authenticationService.getImportPermitDoc({ 'id': p.id }).subscribe((blob: Blob) => {
      this.loading = false;
      if (blob.size > 0)
      {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        this.lgModal0.show();
      }
    });
  }

  printEval(p: Permit) {
    this.loading = true;
    this.dataService.printPermitEvalDetails({ 'id': p.id }).subscribe((blob: Blob) => {
      this.loading = false;
      if (blob.size > 0)
      {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        this.lgModal0.show();
      }
    });
  }

  markAsEvalPrinted(p: Permit) {
    if (!confirm('Are you sure to mark this Permit Request as Printed?')) return;

    this.loading = true;
    this.dataService.markAsEvalPrinted({id: p.id}).subscribe((data:any) => {
      this.loading = false;
      if (data.message === 'Permit marked as printed successfully.') {
        this.getPermits();
      }
    });
  }
}
