import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { PermitBase } from '../../components/permit/permit.base.component';
import { PermitRemark, Permit, User } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './permit-cancel.component.html',
})
export class TasmacPermitCancelComponent extends PermitBase implements OnInit {
  permit: Permit;
  user: User;

  submitted = false;
  loading = false;
  fg: FormGroup;
  errors: any = { };
  messages: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    }
    let permit_id = 0;
    if (this.dataService.filters.tasmac_pmt_can && this.dataService.filters.tasmac_pmt_can.id) {
      permit_id = this.dataService.filters.tasmac_pmt_can.id;
    } else {
      this.router.navigate(['/home']);
    }

    this.dataService.getPermitToCancel({ id: permit_id }).subscribe((data: Permit) => {
      this.permit = data;
      this.permit.req_date = new Date(this.permit.req_date);
      this.permit.permit_date = new Date(this.permit.permit_date);
      this.permit.valid_date = new Date(this.permit.valid_date);
      if (this.permit.reval_date)
        this.permit.reval_date = new Date(this.permit.reval_date);
      if (this.permit.indent_date)
        this.permit.indent_date = new Date(this.permit.indent_date);
      this.dist_fees(this.permit.fees);
      this.permit.remarks = this.permit.remarks.map((r:PermitRemark) => {
        r.rem_date = new Date(r.rem_date);
        return r;
      });
      this.addNewRemark();

      this.buildForm();
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['cancel_reason'] = [
      this.permit.cancel_reason,
      [
        Validators.required
      ]
    ];
    this.errors['cancel_reason'] = [];
    this.messages['cancel_reason'] = {
      'required': 'Reason is required.',
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }
  
  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }

  addNewRemark() {
    let nr = new PermitRemark(this.permit.id, this.user.id);
    nr.isEdit = true;
    this.permit.remarks.push(nr);
  }

  onRemarksSaved(rem:PermitRemark) {
    rem.isEdit = false;
    this.addNewRemark();
  }

  // initiate_cancel() {
  //   this.submitted = true;
  //   this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  //   let sValue = this.fg.value;
  //   if (this.fg.valid) {
  //     let rev_data = { id: this.permit.id, is_cancelled: 1, cancel_reason: sValue.cancel_reason };
  //     this.loading = true;
  //     this.dataService.initiate_permit_cancel(rev_data).subscribe((data:any) => {
  //       if (data.message == "Permit Cancellation initiated successfully.")
  //       {
  //         this.permit.is_cancelled = 1;
  //         this.permit.cancel_reason = sValue.cancel_reason;
  //         this.dist_fees(this.permit.fees);
  //       }
  //       this.loading = false;
  //     });
  //   }
  // }

  goBack() {
    this.dataService.filters.tasmac_pmt_can = null;
    this.router.navigate(["/tasmac-pmts/tm-approved"]);
  }

  verify() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    let sValue = this.fg.value;
    if (this.fg.valid && confirm('Submitted cancellation request cannot be withdrawn. Please double verify before submittion.\n\nAre you sure to submit cancellacion request for this permit?')) {
      let rev_data = {
        id: this.permit.id,
        user_id: this.user.id,
        is_cancelled: 1,
        cancel_reason: sValue.cancel_reason,
        'status': 'CAN',
        'level': 1
      };
      this.loading = true;
      this.dataService.updatePermitStatus(rev_data).subscribe((data:any) => {
        if (data.message == "Permit Request submitted for Cancellation successfully.")
        {
          this.goBack();
        }
        this.loading = false;
      });
    }
  }
}
