import { Component, Input, OnInit } from '@angular/core';

import { Verf } from '../../config';

@Component({
  selector: 'verf-det',
  templateUrl: './verf-det.component.html'
})
export class VerfDetComponent implements OnInit {
  @Input('verf')
  lvf:Verf;

  constructor() {  }

  ngOnInit() {
  }

  // reset() {
  //   return true;
  // }

  // show() {
  //   this.lgModal.show();
  // }
}