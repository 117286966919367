import { Directive, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

import { Util, ValidationResult } from './util';

const PWD_EQUAL_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PwdEqualValidator),
  multi: true
};

@Directive({
  selector: '[pwdEqual][formControlName],[pwdEqual][formControl],[pwdEqual][ngModel]',
  providers: [PWD_EQUAL_VALIDATOR]
})
class PwdEqualValidator implements Validator, OnInit {
  @Input() pwdEqual: string;
  private validator: ValidatorFn;

  ngOnInit() {
    this.validator = PwdValidators.pwdEqual(this.pwdEqual);
  }

  validate(c: AbstractControl):ValidationResult {
    return this.validator(c);
  }
}

export class PwdValidators {

  static pwdEqual(fieldName: string): ValidatorFn {
    let subscribe: boolean = false;
    return (control: FormControl):ValidationResult => {
      if (control.root instanceof FormGroup && control.root.controls[fieldName] instanceof FormControl)
      {
        let pwdEqual: AbstractControl = (control.root as FormGroup).controls[fieldName];
        if (!subscribe) {
          subscribe = true;
          pwdEqual.valueChanges.subscribe(() => {
            control.updateValueAndValidity();
          });
        }
        
        if (Util.isPresent(Validators.required(control))) return null;

        return pwdEqual.value == control.value ? null : {pwdEqual: true};
      }
      else
        return null;
    };
  }
}
  // private static isPresent(obj: any): boolean {
  //   return obj !== undefined && obj !== null;
  // }

//  {[key: string]: any}
//  {[key: string]: any}

        // if (control.hasError("minlength") || control.hasError("maxlength")) return null;
