import { Component, OnInit } from '@angular/core';

import { License } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './lic-fs.component.html'
})
export class SupplierLicenseFSComponent implements OnInit {
  filters:any = {
    'supplier_id': null,
    'name': 'FSSAI'
  };
  fssai_lics:Array<any> = [];
  is_fssai_original_available = false;
  fsAlerts:Array<any> = [];

  constructor(
    private dataService:DataService
  ) {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
    }
  }

  ngOnInit() {
    this.dataService.getLicenses(this.filters).subscribe((data:any[]) => {
      this.fssai_lics = data;
      this.fssai_lics = this.fssai_lics.map(f => {
        f.iss_date = new Date(f.iss_date);
        f.val_date = new Date(f.val_date); 
        return f; 
      });
      this.check_for_fssai_original();
    });
  }

  private check_for_fssai_original(){
    this.is_fssai_original_available = false;
    this.fssai_lics.map(f => {
      if (!this.is_fssai_original_available && f.type == 'O')
        this.is_fssai_original_available = true;
    });
  }

  delete(lic:License) {
    if (confirm("Are to sure to delete?"))
    {
      this.dataService.delLicense(lic.id).subscribe((data:any) => {
        if (data.message == "License details deleted successfully.")
        {
          let idx = -1;
          this.fssai_lics.map((l, key) => { if (lic.id == l.id) idx = key; return; });
          if (idx >= 0)
            this.fssai_lics.splice(idx, 1);
          this.check_for_fssai_original();
          this.fsAlerts.push({ type: 'danger', dismissible: true, msg: data.message, timeout: 5000 });
        }
      });
    }
  }

  licenseSaved(license:License) {
    let idx = -1;
    this.fssai_lics.map((l, key) => { if (license.id == l.id) idx = key; return; });
    if (idx == -1)
    {
      license.is_del = true;
      this.fssai_lics.push(license);
    }
    else
      this.fssai_lics[idx] = license;
    this.check_for_fssai_original();
    this.fsAlerts.push({ type: 'success', dismissible: true, msg: 'License details saved successfully.', timeout: 5000 });
  }
}
