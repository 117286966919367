import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { DataService } from '../../data.service';

@Component({
  selector: 'view-doc',
  templateUrl: './view-doc.component.html'
})
export class ViewDocComponent implements OnInit {
  @Input('doc_id')
  doc_id:number = 0;
  
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;
  trustedUrl:SafeResourceUrl;

  constructor(private dataService:DataService, private sanitizer: DomSanitizer) {  }

  ngOnInit() {  }

  show() {
    this.dataService.getDocDetails(this.doc_id).subscribe((data:any) => {
      if (data.storage_name) {
        this.dataService.getUploadedPdf(data.storage_name).subscribe((blob:Blob) => {
          var fileURL = URL.createObjectURL(blob);
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          this.lgModal0.show();
        });
      } else {
        alert("Document not found in the server.");
      }
    });
  }
}
