import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from '../../../data.service';

@Component({
  templateUrl: './daily-payments.component.html'
})
export class DailyPaymentsComponent implements OnInit {
  minDate = new Date(2018, 0, 1);
  maxDate = new Date();
  filters: { trnx_to?: string, customer_id?: number, supplier_id?: number, fromDate?: Date|string, toDate?: Date|string } = {};
  loading = false;
  payments: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.maxDate.setHours(0, 0, 0, 0);
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filters.customer_id = data.customer_id;
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
    }

    this.filters.fromDate = new Date();
    this.filters.toDate = new Date();
    this.filters.fromDate.setDate(this.filters.fromDate.getDate() - 30);
    this.filters.fromDate.setHours(0, 0, 0, 0);
    this.filters.toDate.setHours(0, 0, 0, 0);

    this.route.params.subscribe(params => {
      this.filters.trnx_to = (params.trnx_to)?params.trnx_to:'UNKNOWN';
      this.getDailyPayments();
    });

  }

  getDailyPayments() {
    const filter = Object.assign({}, this.filters);
    if (this.filters.fromDate && typeof(this.filters.fromDate) !== 'string')
      filter.fromDate = this.filters.fromDate.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    if (this.filters.toDate && typeof(this.filters.toDate) !== 'string')
      filter.toDate = this.filters.toDate.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    this.dataService.getDailyPayments(filter).subscribe((data: any[]) => {
      this.payments = data.map(p => {
        p.trnx_date = new Date(p.trnx_date);
        if (p.req_date) p.req_date = new Date(p.req_date);
        if (p.permit_date) p.permit_date = new Date(p.permit_date);
        let hod = p.hod as string;
        p.hod = hod.substr(0, 4) + '-' + hod.substr(4, 2) + '-' + hod.substr(6, 3) + '-' + hod.substr(9, 2) + '-' + hod.substr(11, 4);
        return p;
      });
    });
  }

  getGrandTotal(fName: string) {
    let tot = 0;
    this.payments.forEach(p => {
        tot += p[fName];
    });
    return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

}
