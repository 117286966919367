import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Depot } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './new.component.html',
})
export class DepotComponent implements OnInit {
  submitted = false;
  loading = false;
  dForm: FormGroup;
  private depot:Depot;
  formErrors:any = { };
  formMessages:any = { };

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.depot = new Depot();
    this.buildForm();
    
    this.route.data.subscribe((data:{depot:Depot}) => {
      this.depot = data.depot;
      let frmFields = {
        'name': this.depot.name
      }
      this.dForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['name'] = [
      this.depot.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      'maxlength':     'Name should not exceed 100 characters.'
    };

    this.dForm = this.fb.group(controlsConfig);
    this.dForm.valueChanges.subscribe(data => this.onValueChanged());
    this.dForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.dForm) { return; }
    
    const form = this.dForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.dForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.dForm.valid) {
      // this.usrForm.dirty && 
      let sValue = this.dForm.value;
      this.depot.name = sValue.name;

      this.loading = true;
      this.dataService.saveDepot(this.depot).subscribe((data:any) => {
        if (data.message == "Depot saved successfully.")
        {
          if (data.id)
            this.depot.id = data.id;
          this.router.navigate(["/depots"]);
        }
        this.loading = false;
      });
    }
  };

  // changeStatus() {
  //   this.loading = true;
  //   if (confirm('Are you sure to disable user?'))
  //     this.user.status = (this.user.status)?0:1;
  //   this.loading = false;
  // }

  public isControlHasError(fieldName:string):boolean {
    if (!this.dForm) return true;
    let formGroup: FormGroup = this.dForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.dForm.controls[fieldName].touched || 
  }
}
