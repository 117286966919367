import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: './ipview.component.html'
})
export class ImportPermitViewComponent {
  loading = true;
  showError = false;
  id:number;
  trustedUrl:SafeResourceUrl;

  constructor(private route:ActivatedRoute,
    private authenticationService:AuthenticationService,
    private sanitizer:DomSanitizer) {
    this.route.data.subscribe((pdata:any) => {
      this.id = pdata.permit.id;
      if (this.id)
      {
        this.loading = true;
        this.authenticationService.getImportPermitDoc({ 'id': this.id }).subscribe((blob:Blob) => {
          // let blob = new Blob([res.blob()], { type: 'application/pdf' });
          if (blob.size > 0)
          {
            var fileURL = URL.createObjectURL(blob);
            this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
            this.showError = false;
          }
          else
          {
            this.trustedUrl = null;
            this.showError = true;
          }
          this.loading = false;
        });
      }
    });
  }
}
