import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, SimpleChanges, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';

import { Item } from '../../config';

@Component({
  selector: 'brand-registration-select-list',
  templateUrl: './select-list.component.html'
})
export class BrandRegistrationSelectListComponent implements OnChanges, OnInit {
  @Output()
  selected: EventEmitter<Item[]> = new EventEmitter<Item[]>();

  @Input()
  items:Item[] = [];

  @ViewChild('lgModal', { static: false }) public lgModal:ModalDirective;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.items && (changes.items as SimpleChange).currentValue) {
      this.items = ((changes.items as SimpleChange).currentValue as Item[]).map(v => { v.selected = true; return v; });
    }
  }

  show() {
    this.lgModal.show();
  }

  onChangeChecked(u: any) {
    u.selected = !u.selected;
  }

  select() {
    const il = this.items.filter(i => {
      return i.selected;
    });
    this.selected.emit(il);
    this.lgModal.hide();
  }
}
