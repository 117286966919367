import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Config, Country, BrandOwner } from '../../config';
import { DataService } from '../../data.service';
import { NumberValidator } from '../../shared/validators/number.directive';
import { EmailValidator } from '../../shared/validators/email.directive';

@Component({
  templateUrl: './new.component.html',
})
export class BrandOwnerComponent implements OnInit {
  submitted = false;
  loading = false;
  fg: FormGroup;
  brandOwner: BrandOwner;
  countries:Country[] = [];
  formErrors:any = { };
  formMessages:any = { };
    
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {
  }

  ngOnInit() {
    this.brandOwner = new BrandOwner();
    this.dataService.getCountries({}).subscribe( (data:Country[]) => { this.countries = data; });
    this.buildForm();
    
    this.route.params.subscribe((params:Params) => {
      if (params['id'] && +params['id'] > 0)
      {
        let id = +params['id'];
        this.dataService.getBrandOwner({ id: id }).subscribe((data:BrandOwner) => {
          this.brandOwner = data;
          this.fg.patchValue(this.brandOwner);
        });
      }
    });
    // this.route.data.subscribe((data:any) => {
    // });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['name'] = [
      this.brandOwner.name,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      'maxlength':     'Name should not exceed 200 characters.'
    };

    controlsConfig['add1'] = [
      this.brandOwner.add1, 
      [
        Validators.required
      ]
    ];
    this.formErrors['add1'] = [];
    this.formMessages['add1'] = {
      'required': 'Address is required.'
    };

    controlsConfig['add2'] = [
      this.brandOwner.add2, 
      [
      ]
    ];
    this.formErrors['add2'] = [];
    this.formMessages['add2'] = {
    };

    controlsConfig['add3'] = [
      this.brandOwner.add3, 
      [
      ]
    ];
    this.formErrors['add3'] = [];
    this.formMessages['add3'] = {
    };

    controlsConfig['country_id'] = [
      this.brandOwner.country_id, 
      [
        Validators.required
      ]
    ];
    this.formErrors['country_id'] = [];
    this.formMessages['country_id'] = {
      'required': 'Country is required.'
    };

    controlsConfig['contact_person'] = [
      this.brandOwner.contact_person, 
      [
      ]
    ];
    this.formErrors['contact_person'] = [];
    this.formMessages['contact_person'] = {
    };

    controlsConfig['contact_mobile_no'] = [
      this.brandOwner.contact_mobile_no, 
      [
        NumberValidator.isNumber,
        // Validators.minLength(15),
        Validators.maxLength(15)
      ]
    ];
    this.formErrors['contact_mobile_no'] = [];
    this.formMessages['contact_mobile_no'] = {
      'isnumber': 'Contact Mobile No. should be a valid number',
      // 'minlength': 'Contact Mobile No. should be 15 characters long.',
      'maxlength': 'Contact Mobile No. should not exceed 15 characters.'
    };

    controlsConfig['contact_email'] = [
      this.brandOwner.contact_email, 
      [
        EmailValidator.isEmail,
        Validators.maxLength(50)
      ]
    ];
    this.formErrors['contact_email'] = [];
    this.formMessages['contact_email'] = {
      'isemail': 'Please provide valid Contact eMail ID.',
      'maxlength': 'Contact eMail ID. should not exceed 50 characters.'
    };
  
    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      // this.fg.dirty && 
      let sValue = this.fg.value;
      this.brandOwner.name = sValue.name;
      this.brandOwner.add1 = sValue.add1;
      this.brandOwner.add2 = sValue.add2;
      this.brandOwner.add3 = sValue.add3;
      this.brandOwner.country_id = sValue.country_id;
      this.brandOwner.contact_person = sValue.contact_person;
      this.brandOwner.contact_mobile_no = sValue.contact_mobile_no;
      this.brandOwner.contact_email = sValue.contact_email;

      this.loading = true;
      this.dataService.saveBrandOwner(this.brandOwner).subscribe((data:any) => {
        if (data.message == "Brand Owner saved successfully.")
        {
          if (data.id)
            this.brandOwner.id = data.id;
          this.goBack();
        }
        this.loading = false;
      });
    }
  };

  goBack() {
    this.router.navigate(["/brand_owners"]);
  }

  public isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}