import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';

import { IndentVData, IndentVerf } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'indent-verf-row',
  templateUrl: './indent-verf-row.component.html'
})
export class SupplierIndentVerfRowComponent implements OnChanges {
  @Input()
  vdata: IndentVData;

  isVerifyActionAllowed = true;
  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  lvf: IndentVerf;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vdata && changes.vdata.currentValue) {
    }
  }

  addNewVerf() {
    this.isVerifyActionAllowed = false;
    this.lvf = this.vdata.initAddChangeState();
    this.fg = this.fb.group({ remarks: this.lvf.remarks });
    this.errors['remarks'] = [];
    this.messages['remarks'] = { };
  }

  getBtnStyle() {
    let btn = 'btn-default';
    switch(true) {
      case (this.vdata.lvf_state.status == 'YET' && !this.vdata.isClarify):
        btn = 'btn-danger';
        break;
      case (this.vdata.lvf_state.status == 'CLD' || (this.vdata.lvf_state.status === 'CLR' && this.vdata.isClarify)):
        btn = 'btn-info';
        break;
      case (this.vdata.lvf_state.status == 'CLR' && !this.vdata.isClarify):
        btn = 'btn-warning';
        break;
      case (this.vdata.lvf_state.status == 'VER'):
        btn = 'btn-success';
        break;
      case (this.vdata.lvf_state.status == 'YET' && this.vdata.isClarify):
        btn = 'btn-default';
        break;
      }
    return btn;
  }

  // getBgColor() {
  //   let color = 'purple';
  //   switch(true) {
  //     case (this.vdata.lvf_state.status == 'YET' && !this.vdata.isClarify):
  //       color = 'darkred';
  //       break;
  //     case (this.vdata.lvf_state.status == 'CLD' || (this.vdata.lvf_state.status === 'CLR' && this.vdata.isClarify)):
  //       color = 'blue';
  //       break;
  //     case (this.vdata.lvf_state.status == 'CLR' && !this.vdata.isClarify):
  //       color = 'orangered';
  //       break;
  //     case (this.vdata.lvf_state.status == 'VER'):
  //       color = 'green';
  //       break;
  //     case (this.vdata.lvf_state.status == 'YET' && this.vdata.isClarify):
  //       color = 'purple';
  //       break;
  //     }
  //   return color;
  //     // 'btn-info': ,
  //     // 'btn-warning': ,
  //     // 'btn-success': ,
  //     // 'btn-default': 
  //     // 'btn-danger': ,
  // }

  save(status: string) {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    let sValue = this.fg.value;
    if (status == 'CLD' && !sValue.remarks)
    {
      alert("Comment should not be empty");
      return;
    }
    if (status == 'CLR' && !sValue.remarks)
    {
      alert("Clarification Request should not be empty");
      return;
    }
    if (this.fg.valid) {
      this.lvf.remarks = sValue.remarks;
      this.lvf.status = status;
      this.loading = true;
      this.dataService.saveIndentVerf(this.lvf).subscribe((data:any) => {
        if (data.message == "Verification details saved successfully.")
        {
          if (data.id)
          {
            this.lvf.id = data.id;
            this.lvf.verf_date = new Date();
            this.vdata.addedVerf(this.lvf);

            this.lvf = this.vdata.makeNewVerf();
            this.fg.reset({'remarks': this.lvf.remarks});
          }
        }
        this.loading = false;
        this.isVerifyActionAllowed = true;
      });
    }
  }

  cancel() {
    this.isVerifyActionAllowed = true;
    this.vdata.cancelAddStateChange();
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  isControlHasError(fieldName: string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}
