import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { DataService } from '../../data.service';
import { Permit, User } from '../../config';

@Component({
  templateUrl: './permits.component.html'
})
export class TasmacPermitsComponent implements OnInit {
  currentUser: User;
  lType: string;
  filters:any = {};
  permits:Permit[] = [];
  showPermitColumn = false;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    // clear routes
    this.dataService.filters.permit_view = null;
    this.dataService.filters.tasmac_pmt_can = null;

    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.route.params.subscribe((params:Params) => {
      // this.filters = { supplier_id: this.currentUser.supplier_id };
      this.lType = params['lType'];
      switch(this.lType) {
        // case 'fl-requests':
        //   this.filters.status = ['REQ', 'CLR'];
        //   this.filters.is_fl_permit = true;
        //   this.showPermitColumn = false;
        //   break;
        // case 'fl-approved':
        //   this.filters.status = ['APP', 'REV-INT', 'CAN-INT', 'APP-REV', 'EXT', 'APP-EXT'];
        //   this.filters.is_fl_permit = true;
        //   this.showPermitColumn = true;
        //   break;
        // case 'fl-despatched':
        //   this.filters.status = ['CMP'];
        //   this.filters.is_fl_permit = true;
        //   this.showPermitColumn = true;
        //   break;
        // case 'fl-canceled':
        //   this.filters.status = ['CAND', 'REJ', 'REJ-EXT'];
        //   this.filters.is_fl_permit = true;
        //   this.showPermitColumn = true;
        //   break;
        // case 'tm-clarify':
        //   this.filters.status = ['CLR'];
        //   this.filters.is_fl_permit = false;
        //   this.showPermitColumn = false;
        //   break;
        // case 'tm-requests':
        //   this.filters.status = ['REQ'];
        //   this.filters.is_fl_permit = false;
        //   this.showPermitColumn = false;
        //   break;
        case 'tm-approved':
          this.filters.status = ['APP'];
          this.filters.is_fl_permit = false;
          this.showPermitColumn = true;
          break;
        case 'tm-despatched':
          this.filters.status = ['CMP'];
          this.filters.is_fl_permit = false;
          this.showPermitColumn = true;
          break;
        case 'tm-canceled':
          this.filters.status = ['CAND', 'REJ', 'REJ-EXT'];
          this.filters.is_fl_permit = false;
          this.showPermitColumn = true;
          break;
        default:
          this.filters.status = ['UNKNOWN'];
          this.filters.is_fl_permit = false;
          this.showPermitColumn = false;
      }
      this.getPermits();
      // if (!params['lType'] || !this.currentUser.supplier_id) {
      //   this.router.navigate(['/home']);
      // } else {
      // }
    });
  }

  getPermits() {
    this.dataService.getPermits(this.filters).subscribe((data: Permit[]) => {
      this.permits = data;
      this.permits = this.permits.map(p => {
        p.req_date = new Date(p.req_date);
        if (p.permit_date)
          p.permit_date = new Date(p.permit_date);
        if (p.extend_period)
          p.period += p.extend_period;
        if (p.ext_app_period)
          p.period += p.ext_app_period;

        if (p.valid_date)
          p.valid_date = new Date(p.valid_date);

        if (p.reval_date)
        {
          p.reval_date = new Date(p.reval_date);
          p.valid_date = new Date(p.reval_date);
        }

        if (p.ext_valid_date)
        {
          p.ext_valid_date = new Date(p.ext_valid_date);
          p.valid_date = new Date(p.ext_valid_date);
        }
        
        const today:Date = new Date();
        today.setHours(0, 0, 0, 0);
        p.is_expired = (p.valid_date && p.valid_date < today);

        if (p.indent_date)
          p.indent_date = new Date(p.indent_date);

        if (p.status === 'CLR')
          p.action = 'Clarify';
        else
          p.action = 'View';

        return p;
      });
    });
  }

  onAction(p: Permit, action: string) {
    switch (action) {
      // case 'Clarify':
      //   this.dataService.filters.permit = { id: p.id, for: 'L', back_url: '/tasmac-pmts/tm-clarify' };
      //   this.router.navigate(["/permit"]);
      //   break;
      case 'View':
        this.dataService.filters.permit_view = { id: p.id, back_url: '/tasmac-pmts/' + this.lType };
        this.router.navigate(['/permit_view']);
        break;
      case 'Revalidate':
        break;
      case 'Cancel':
        this.dataService.filters.tasmac_pmt_can = { id: p.id };
        this.router.navigate(['/tasmac-pmt-can']);
        break;
    }
  }
}
