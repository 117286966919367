import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AppRequestInterceptor implements HttpInterceptor {
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq;
    let authorizationData = localStorage.getItem('authorizationData');

    if (authorizationData)
    {
      authorizationData = 'Bearer ' + JSON.parse(authorizationData);
      authReq = req.clone({
        headers: req.headers.set('Authorization', authorizationData)
      });
    }
    else
      authReq = req;
    
    return next.handle(authReq);
  }
}

export const requestOptionsProvider = { 
  provide: HTTP_INTERCEPTORS,
  useClass: AppRequestInterceptor,
  multi: true
};
// import { Injectable } from '@angular/core';
// import { BaseRequestOptions, Headers, RequestOptions, RequestOptionsArgs } from '@angular/http';

// @Injectable()
// export class AppRequestOptions extends BaseRequestOptions {
//   constructor() {
//     super();
//   }

//   merge(options?:RequestOptionsArgs):RequestOptions {
//     let header = new Headers();
//     let authorizationData = localStorage.getItem('authorizationData');
//     if (authorizationData)
//     {
//       // let user = JSON.parse(authorizationData);
//       // let authData = user.token;
//       authorizationData = JSON.parse(authorizationData);
//       header.append('Authorization', `Bearer ${authorizationData}`);
//     }
//     options.headers = header;
//     var result = super.merge(options);
//     result.merge = this.merge;
//     return result;
//   }

// }

// export const requestOptionsProvider = { provide: RequestOptions, useClass: AppRequestOptions };