import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { Supplier, Warehouse } from '../../config';

interface WarehousesActions {
  allowAdd:boolean;
  allowEdit:boolean;
  allowDelete:boolean;
  showActions:boolean;
};

@Component({
  templateUrl: './warehouses.component.html'
})
export class SupplierWarehousesComponent implements OnInit {
  filter:any = {};
  actions:WarehousesActions = {
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    showActions: false
  };
  warehouses:Warehouse[] = [];
  currentTypeName: string = 'Public';

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {  }

  ngOnInit() {
    this.dataService.filters.sup_war_edit = null;
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.supplier_id)
        this.filter.supplier_id = data.supplier_id;
      this.markAllowedActions(data);
    }

    this.getWarehouses();
  }

  markAllowedActions(data:any) {
    this.actions.allowAdd = (data.category_code === 'SUP');
    this.actions.allowEdit = (data.category_code === 'SUP');
    this.actions.allowDelete = (data.category_code === 'SUP');
    if (this.actions.allowEdit || this.actions.allowDelete)
      this.actions.showActions = true;
  }

  getWarehouses() {
    this.filter.allPublic = true;
    this.dataService.getWarehouses(this.filter).subscribe((data:Warehouse[]) => {
      this.warehouses = data;
    });
  }

  filterWarehouses(type: string) {
    return this.warehouses.filter(w => w.owned_type === type);
  }
  
  makeAddress(war:Warehouse) {
    let ret = "";
    if (war.add1)
      ret += war.add1;
    if (war.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add2;
    if (war.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add3;
    if (war.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + war.pin;
    if (war.state_name)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.state_name;
    return ret;
  }

  deleteWarehouse(war:Warehouse) {
    if (!confirm("Are you sure to delete warehouse?"))
      return;

    let del_data = { id: war.id };
    this.dataService.deleteWarehouse(del_data).subscribe((data:any) => {
      if (data.message == "Warehouse deleted successfully.")
        this.getWarehouses();
    });
  }

  editWarehouse(id: number) {
    this.dataService.filters.sup_war_edit = { id: id };
    this.router.navigate(["/sup-war-edit"]);
  }
}
