import { Component, Input } from '@angular/core';

import { Customer } from '../../config';

@Component({
  selector: 'view-cus',
  templateUrl: './view.component.html'
})
export class CustomerViewComponent {
  @Input('cus')
  cus:Customer;

  constructor() {  }
  
  makeAddress(cus: Customer) {
    let ret = "";
    if (cus.add1)
      ret += cus.add1;
    if (cus.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + cus.add2;
    if (cus.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + cus.add3;
    if (cus.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + cus.pin;
    // if (cus.state_name)
    ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + 'Tamil Nadu';
    return ret;
  }

}