import { Component, Input } from '@angular/core';

import { Supplier } from '../../config';

@Component({
  selector: 'view-sup',
  templateUrl: './view.component.html'
})
export class SupplierViewComponent {
  @Input()
  sup: Supplier;

  constructor() {  }
  
  makeAddress(sup: Supplier) {
    let ret = "";
    if (sup.add1)
      ret += sup.add1;
    if (sup.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.add2;
    if (sup.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.add3;
    if (sup.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + sup.pin;
    if (sup.state_name)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.state_name;
    return ret;
  }

}