import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { DataService } from '../../data.service';
import { Indent, UserCategory, Config, User } from '../../config';

@Component({
  templateUrl: './indent-list.component.html'
})
export class SupplierIndentListComponent implements OnInit {
  currentUser: User;
  lType: string;
  filters:any = {};
  indents:Indent[] = [];
  showIndentColumn = false;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.route.params.subscribe((params:Params) => {
      if (!params['lType'] || !this.currentUser.supplier_id) {
        this.router.navigate(['/home']);
      } else {
        this.filters = { supplier_id: this.currentUser.supplier_id };
        this.lType = params['lType'];
        switch(this.lType) {
          case 'pending-clarification':
            this.filters.status = ['CLR-SUP'];
            this.showIndentColumn = false;
            break;
          case 'under-verification':
            this.filters.status = ['REQ', 'CLD-TSEH'];
            this.showIndentColumn = false;
            break;
          case 'approved':
            this.filters.status = ['APP', 'REJ', 'CAND'];
            this.showIndentColumn = false;
            break;
          // case 'fl-approved':
          //   this.filters.status = ['APP', 'REV-INT', 'CAN-INT', 'APP-REV', 'EXT', 'APP-EXT'];
          //   this.filters.is_fl_permit = true;
          //   this.showIndentColumn = true;
          //   break;
          // case 'fl-despatched':
          //   this.filters.status = ['CMP'];
          //   this.filters.is_fl_permit = true;
          //   this.showIndentColumn = true;
          //   break;
          // case 'fl-canceled':
          //   this.filters.status = ['CAND', 'REJ', 'REJ-EXT'];
          //   this.filters.is_fl_permit = true;
          //   this.showIndentColumn = true;
          //   break;
          // case 'tm-clarify':
          //   this.filters.status = ['CLR'];
          //   this.filters.is_fl_permit = false;
          //   this.showIndentColumn = false;
          //   break;
          // case 'tm-requests':
          //   this.filters.status = ['REQ'];
          //   this.filters.is_fl_permit = false;
          //   this.showIndentColumn = false;
          //   break;
          // case 'tm-approved':
          //   this.filters.status = ['APP'];
          //   this.filters.is_fl_permit = false;
          //   this.showIndentColumn = true;
          //   break;
          // case 'tm-despatched':
          //   this.filters.status = ['CMP'];
          //   this.filters.is_fl_permit = false;
          //   this.showIndentColumn = true;
          //   break;
          // case 'tm-canceled':
          //   this.filters.status = ['CAND', 'REJ', 'REJ-EXT'];
          //   this.filters.is_fl_permit = false;
          //   this.showIndentColumn = true;
          //   break;
          // default:
          //   this.filters.status = ['UNKNOWN'];
          //   this.filters.is_fl_permit = false;
          //   this.showIndentColumn = false;
        }
        this.getIndents();
      }
    });
  }

  getIndents() {
    this.dataService.getIndents(this.filters).subscribe((data:Indent[]) => {
      this.indents = data;
      this.indents = this.indents.map(p => {
        p.indent_date = new Date(p.indent_date);

        if (p.status === 'CLR-SUP')
          p.action = 'Clarify';
        else
          p.action = 'View';

        return p;
      });
    });
  }

  onAction(p: Indent) {
    switch (p.action) {
      case 'Clarify':
        this.dataService.filters.indent =  { id: p.id, supplier_id: p.supplier_id, back_url: '/sup-indents/' + this.lType };
        this.router.navigate(["/sup-indent"]);
        break;
      case 'View':
        this.dataService.filters.sup_indent_view = { id: p.id, supplier_id: p.supplier_id, back_url: '/sup-indents/' + this.lType };
        this.router.navigate(['/sup-indent-view']);
        break;
    }
  }
}
