import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlertService } from './alert.service';
 
@Component({
    selector: 'my-alert',
    templateUrl: 'alert.component.html'
})
 
export class AlertComponent implements OnInit, OnDestroy {
  message:any;
  sub:Subscription;
 
  constructor(private alertService: AlertService) { }
 
  ngOnInit() {
    this.sub = this.alertService.getMessage().subscribe(message => { this.message = message; });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}