import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Config, Customer, Payment, Permit, PermitItem, Supplier, Warehouse, PermitRoute, Item, State, User, TasmacPermit, Indent, Depot, StarGradation } from './config';

class tmpCache { storage_name: string; blob: Blob };

@Injectable()
export class DataService {
  private currentUser: User;
  filters: {
    permit?: { id: number, for: string, back_url: string },
    permit_view?: { id: number, back_url: string },
    permit_print?: { id: number },
    permit_label?: { id: number },
    permit_ext?: { id: number },
    add_payment?: Payment,
    permit_approved?: { permit: Permit, back_url: string },
    state?: State,
    sup_user?: User,
    sup_war_edit?: { id: number },
    supplier_indent_submitted?: Indent,
    sup_indent_view?: { id: number, supplier_id: number, back_url: string },
    indent?: { id: number, supplier_id: number, back_url: string },
    sup_pmt_tm_req?: { id: number },
    tasmac_brand_authorization?: { supplier_id: number, fin_year: string },
    tasmac_pmt_can?: { id: number }
  } = {};
  loginMessage:string;
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  // private makePdfRequestOptions() {
  //   let cusHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/pdf' });
  //   let options = new RequestOptions({ headers: cusHeaders });
  //   options.responseType = ResponseContentType.Blob;
  //   return options;
  // }

  // Stock Demo

  demoBrand = [
    { apf_rate: 0,
      brand_owner_id: 102,
      brand_owner_name: "Autres Rivages",
      btl_per_case: 12,
      cat_name: "Beer",
      con_name: "France",
      el_rate: 0.392,
      id: 217,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 3,
      name: "Dominus Estate 'Dominus'",
      origin_country_id: 2,
      pl_pc: 0,
      spf_bl_rate: 29.89,
      spf_pl_rate: 0,
      supplier_names: null,
      vol: 750,
      pur_price:250,
      sale_price:250,
      edd:'2019-10-10',
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,

    },
    { apf_rate: 0,
      brand_owner_id: 102,
      brand_owner_name: "Autres Rivages",
      btl_per_case: 12,
      cat_name: "Beer",
      con_name: "Australia",
      el_rate: 0.392,
      id: 240,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 3,
      name: "Special Import",
      origin_country_id: 22,
      pl_pc: 0,
      spf_bl_rate: 29.89,
      spf_pl_rate: 0,
      supplier_names: "EFG",
      vol: 330,
      pur_price:150,
      sale_price:250,
      edd:'2019-10-10',
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    { apf_rate: 0,
      brand_owner_id: 44,
      brand_owner_name: "Azienda Vinicola Umani Ronchi Spa",
      btl_per_case: 12,
      cat_name: "Beer",
      con_name: "Italy1",
      el_rate: 0.392,
      id: 107,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 3,
      name: "Umani Ronchi Villa Blanchi Verdicchio del Castelli Di Jesi DOC Classico",
      origin_country_id: 10,
      pl_pc: 0,
      spf_bl_rate: 29.89,
      spf_pl_rate: 0,
      supplier_names: null,
      vol: 750,
      pur_price:70,
      sale_price:250,
      edd:'2019-10-10',
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    { apf_rate: 0,
      brand_owner_id: 3,
      brand_owner_name: "DIAGEO - USL",
      btl_per_case: 12,
      cat_name: "IFL",
      con_name: "Spain1",
      el_rate: 0.375,
      id: 35,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 2,
      name: "Ciroc Vodka",
      origin_country_id: 12,
      pl_pc: 75,
      spf_bl_rate: 0,
      spf_pl_rate: 417.68,
      supplier_names: null,
      vol: 750,
      pur_price:0,
      sale_price:0,
      edd:null,
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    { apf_rate: 0,
      brand_owner_id: 89,
      brand_owner_name: "S.P.I. SPIRITS (CYPRUS) LTD",
      btl_per_case: 12,
      cat_name: "IFL",
      con_name: "Latvia",
      el_rate: 0.375,
      id: 162,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 2,
      name: "ELIT BY STOLICHNAYA VODKA",
      origin_country_id: 134,
      pl_pc: 75,
      spf_bl_rate: 0,
      spf_pl_rate: 417.68,
      supplier_names: null,
      vol: 1000,
      pur_price:250,
      sale_price:650,
      edd:'2019-10-10',
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    { apf_rate: 100.27,
      brand_owner_id: 107,
      brand_owner_name: "Brouwerij Martens NV",
      btl_per_case: 24,
      cat_name: "Wine",
      con_name: "Belgium",
      el_rate: 0.375,
      id: 239,
      is_apf: 1,
      is_domestic: false,
      item_cat_id: 1,
      name: "Buho Strong 500 ml ( Can )",
      origin_country_id: 21,
      pl_pc: 28,
      spf_bl_rate: 0,
      spf_pl_rate: 450,
      supplier_names: null,
      vol: 500,
      pur_price:20,
      sale_price:50,
      edd:'2019-10-10',
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    { apf_rate: 100.27,
      brand_owner_id: 107,
      brand_owner_name: "Brouwerij Martens NV",
      btl_per_case: 24,
      cat_name: "Wine",
      con_name: "Belgium",
      el_rate: 0.375,
      id: 238,
      is_apf: 1,
      is_domestic: false,
      item_cat_id: 1,
      name: "Buho Witber 500 ml ( Can )",
      origin_country_id: 21,
      pl_pc: 28,
      spf_bl_rate: 0,
      spf_pl_rate: 450,
      supplier_names: null,
      vol: 500,
      pur_price:250,
      sale_price:250,
      edd:'2019-10-10',
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    { apf_rate: 100.27,
      brand_owner_id: 82,
      brand_owner_name: "C&C GROUP PLC",
      btl_per_case: 24,
      cat_name: "Wine",
      con_name: "Belgium",
      el_rate: 0.375,
      id: 136,
      is_apf: 1,
      is_domestic: false,
      item_cat_id: 1,
      name: "HEVERLEE WITTE",
      origin_country_id: 21,
      pl_pc: 28,
      spf_bl_rate: 0,
      spf_pl_rate: 450,
      supplier_names: null,
      vol: 330,
      pur_price:0,
      sale_price:0,
      edd:null,
      recp_date:"2019-10-10",
      cur_stock:100,
      avl_stock:70,
      pur_stock:20,
    },
    
  ];

  demoBrandSupplier = [
    { apf_rate: 0,
      brand_owner_id: 102,
      brand_owner_name: "Autres Rivages",
      btl_per_case: 12,
      cat_name: "Beer",
      con_name: "France",
      el_rate: 0.392,
      id: 217,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 3,
      name: "Dominus Estate 'Dominus'",
      origin_country_id: 2,
      pl_pc: 0,
      spf_bl_rate: 29.89,
      spf_pl_rate: 0,
      supplier_names: null,
      vol: 750,
      supplier:[
              {
                supplier_names:"Brindco Sales Private Limited",
                pur_price:250,
                sale_price:270,
                edd:'2019-10-10',
                dmg:60,
                dmgshop:10,
                dchrg:190,
                recp_date_wise:[
                  {
                    recpt_date:"2019-10-10",
                    cur_stock:100,
                    avl_stock:70,
                    pur_stock:20,
                  },
                  {
                    recpt_date:"2019-10-11",
                    cur_stock:40,
                    avl_stock:40,
                    pur_stock:0,
                  },
                  {
                    recpt_date:"2019-10-15",
                    cur_stock:10,
                    avl_stock:10,
                    pur_stock:0,
                  },
                ],
              },
              {
                supplier_names:"Brindco Enterprises pvt ltd.",
                pur_price:150,
                sale_price:450,
                edd:'2019-10-10',
                dmg:20,
                dmgshop:10,
                dchrg:100,
                recp_date_wise:[
                  {
                    recpt_date:"2019-10-10",
                    cur_stock:10,
                    avl_stock:0,
                    pur_stock:10,
                  },
                  {
                    recpt_date:"2019-10-16",
                    cur_stock:100,
                    avl_stock:70,
                    pur_stock:30,
                  },
                  {
                    recpt_date:"2019-10-19",
                    cur_stock:30,
                    avl_stock:30,
                    pur_stock:0,
                  },
                ],
              }
            ],
    },
    { apf_rate: 0,
      brand_owner_id: 102,
      brand_owner_name: "Autres Rivages",
      btl_per_case: 12,
      cat_name: "Beer",
      con_name: "Australia",
      el_rate: 0.392,
      id: 240,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 3,
      name: "Special Import",
      origin_country_id: 22,
      pl_pc: 0,
      spf_bl_rate: 29.89,
      spf_pl_rate: 0,
      supplier_names: "EFG",
      vol: 330,
      supplier:[
        {
          supplier_names:"Sula wines pvt ltd.",
          pur_price:250,
          sale_price:270,
          edd:'2019-9-10',
                dmg:20,
                dmgshop:11,
                dchrg:200,
          recp_date_wise:[
            {
              recpt_date:"2019-9-10",
              cur_stock:100,
              avl_stock:30,
              pur_stock:70,
            },
            {
              recpt_date:"2019-10-11",
              cur_stock:50,
              avl_stock:20,
              pur_stock:30,
            },
          ],
        },
      ]
    },
    { apf_rate: 0,
      brand_owner_id: 44,
      brand_owner_name: "Azienda Vinicola Umani Ronchi Spa",
      btl_per_case: 12,
      cat_name: "Beer",
      con_name: "Italy1",
      el_rate: 0.392,
      id: 107,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 3,
      name: "Umani Ronchi Villa Blanchi Verdicchio del Castelli Di Jesi DOC Classico",
      origin_country_id: 10,
      pl_pc: 0,
      spf_bl_rate: 29.89,
      spf_pl_rate: 0,
      supplier_names: null,
      vol: 750,
      supplier:[
        {
          supplier_names:"Wine Well Ltd.",
          pur_price:250,
          sale_price:250,
          edd:'2019-03-10',
                dmg:34,
                dmgshop:22,
                dchrg:1672,
          recp_date_wise:[
            {
              recpt_date:"2019-10-10",
              cur_stock:100,
              avl_stock:70,
              pur_stock:30,
            },
          ],
        },
      ]
    },
    { apf_rate: 0,
      brand_owner_id: 3,
      brand_owner_name: "DIAGEO - USL",
      btl_per_case: 12,
      cat_name: "IFL",
      con_name: "Spain1",
      el_rate: 0.375,
      id: 35,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 2,
      name: "Ciroc Vodka",
      origin_country_id: 12,
      pl_pc: 75,
      spf_bl_rate: 0,
      spf_pl_rate: 417.68,
      supplier_names: null,
      vol: 750,
      supplier:[
        {
          supplier_names:"Royal Stag Pvr Ltd.",
          pur_price:100,
          sale_price:270,
          edd:'2019-10-10',
                dmg:50,
                dmgshop:44,
                dchrg:260,
          recp_date_wise:[
            {
              recpt_date:"2019-10-10",
              cur_stock:100,
              avl_stock:80,
              pur_stock:20,
            },
            {
              recpt_date:"2019-10-11",
              cur_stock:60,
              avl_stock:54,
              pur_stock:6,
            },
          ],
        },
      ]
    },
    { apf_rate: 0,
      brand_owner_id: 89,
      brand_owner_name: "S.P.I. SPIRITS (CYPRUS) LTD",
      btl_per_case: 12,
      cat_name: "IFL",
      con_name: "Latvia",
      el_rate: 0.375,
      id: 162,
      is_apf: 0,
      is_domestic: false,
      item_cat_id: 2,
      name: "ELIT BY STOLICHNAYA VODKA",
      origin_country_id: 134,
      pl_pc: 75,
      spf_bl_rate: 0,
      spf_pl_rate: 417.68,
      supplier_names: null,
      vol: 1000,
      supplier:[
        {
          supplier_names:"B Private Limited",
          pur_price:250,
          sale_price:300,
          edd:'2019-6-10',
                dmg:17,
                dmgshop:11,
                dchrg:200,
          recp_date_wise:[
            {
              recpt_date:"2019-1-10",
              cur_stock:100,
              avl_stock:100,
              pur_stock:0,
            },
            {
              recpt_date:"2019-6-11",
              cur_stock:100,
              avl_stock:100,
              pur_stock:0,
            },
            {
              recpt_date:"2019-8-15",
              cur_stock:10,
              avl_stock:10,
              pur_stock:0,
            },
          ],
        },
      ]
    },
    { apf_rate: 100.27,
      brand_owner_id: 107,
      brand_owner_name: "Brouwerij Martens NV",
      btl_per_case: 24,
      cat_name: "Wine",
      con_name: "Belgium",
      el_rate: 0.375,
      id: 239,
      is_apf: 1,
      is_domestic: false,
      item_cat_id: 1,
      name: "Buho Strong 500 ml ( Can )",
      origin_country_id: 21,
      pl_pc: 28,
      spf_bl_rate: 0,
      spf_pl_rate: 450,
      supplier_names: null,
      vol: 500,
      supplier:[
        {
          supplier_names:"Brindco Sales Private Limited",
          pur_price:220,
          sale_price:500,
          edd:'2019-4-10',
                dmg:2,
                dmgshop:2,
                dchrg:20,
          recp_date_wise:[
            {
              recpt_date:"2019-10-13",
              cur_stock:10,
              avl_stock:1,
              pur_stock:9,
            },
            {
              recpt_date:"2019-10-12",
              cur_stock:130,
              avl_stock:70,
              pur_stock:60,
            },
          ],
        },
      ]
    },
    { apf_rate: 100.27,
      brand_owner_id: 107,
      brand_owner_name: "Brouwerij Martens NV",
      btl_per_case: 24,
      cat_name: "Wine",
      con_name: "Belgium",
      el_rate: 0.375,
      id: 238,
      is_apf: 1,
      is_domestic: false,
      item_cat_id: 1,
      name: "Buho Witber 500 ml ( Can )",
      origin_country_id: 21,
      pl_pc: 28,
      spf_bl_rate: 0,
      spf_pl_rate: 450,
      supplier_names: null,
      vol: 500,
      supplier:[
        {
          supplier_names:"N Sales Limited",
          pur_price:120,
          sale_price:200,
          edd:'2019-09-10',
                dmg:0,
                dmgshop:0,
                dchrg:0,
          recp_date_wise:[
            {
              recpt_date:"2019-10-10",
              cur_stock:100,
              avl_stock:70,
              pur_stock:30,
            },
          ],
        },
      ]
    },
    { apf_rate: 100.27,
      brand_owner_id: 82,
      brand_owner_name: "C&C GROUP PLC",
      btl_per_case: 24,
      cat_name: "Wine",
      con_name: "Belgium",
      el_rate: 0.375,
      id: 136,
      is_apf: 1,
      is_domestic: false,
      item_cat_id: 1,
      name: "HEVERLEE WITTE",
      origin_country_id: 21,
      pl_pc: 28,
      spf_bl_rate: 0,
      spf_pl_rate: 450,
      supplier_names: null,
      vol: 330,
      supplier:[
        {
          supplier_names:"Thiruloga Chandran",
          pur_price:500,
          sale_price:700,
          edd:'2019-10-01',
          dmg:10,
          dmgshop:1,
          dchrg:2,
          recp_date_wise:[
            {
              recpt_date:"2019-10-10",
              cur_stock:100,
              avl_stock:70,
              pur_stock:20,
            },
          ],
        },
      ]
    },
    
  ];

  constructor(private http: HttpClient,
    private router: Router
  ) { }

  logout() {
      // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('authorizationData');
    this.currentUser = null;
  }

  storeLocalStorage(data: any) {
    // Clean received data
    delete data.message;
    
    // store jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('authorizationData', JSON.stringify(data.token));
    delete data.token;
    
    // store user details in local storage to keep user logged in between page refreshes
    if (data.category_code) {
      // Determine user level
      data.level = Config.userCategories.filter(uc => uc.code == data.category_code)[0].level;
    }
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.currentUser = data;
  }

  getCurrentUser() {
    if (this.currentUser)
      return this.currentUser;
    else {
      if (localStorage.getItem('currentUser')) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.currentUser;
      }
      this.router.navigate(['/login']);
    }
  }

  // pdf_test() {
  //   let cusHeaders = new Headers({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/pdf'
  //   });
  //   let options = new RequestOptions({ headers: cusHeaders });
  //   // Ensure you set the responseType to Blob.
  //   options.responseType = ResponseContentType.Blob;

  //   return this.http.post(environment.apiUrl + 'register.ajax.php?pdf_test=true', null, options)
  //           // .map((response:Response) => {
  //           //   let fileBlob = response.blob();
  //           //   let blob = new Blob([fileBlob], {
  //           //     type: 'application/pdf'
  //           //   });
  //           //   // response.json()
  //           //   // let fileBlob = response.blob();
  //           //   // let blob = new Blob([fileBlob], {
  //           //   //   type: 'application/pdf'
  //           //   // });
  //           // })
  //           .catch(this.handleOError);
  // };

  
  blob_cache: tmpCache[] = [];
  getUploadedPdf(storage_name: string): Observable<Blob> {
    let blobCached: tmpCache;
    this.blob_cache.forEach(a => {
      if (a.storage_name === storage_name)
      blobCached = a;
    });

    if (blobCached) {
      // return Observable.of(blobCached.blob);
      return of( blobCached.blob );
    }
    else {
      let cusHeaders = new HttpHeaders({ 'Accept': 'application/pdf' });
      return this.http.get(
        environment.apiUrl + '../' + storage_name,
        { headers: cusHeaders, responseType: 'blob' }
      ).pipe(
        map((value, index) => {
          this.blob_cache.push({ storage_name: storage_name, blob: value });
          return value;
        })
      );
    }
  }

  printPermitRequest(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('printPermitRequest', 'true'), responseType: 'blob' }
    );
  }

  printPermitEvalDetails(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('printPermitEvalDetails', 'true'), responseType: 'blob' }
    );
  }

  printPermit(data: { id: number, is_draft?: boolean }) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('printPermit', 'true'), responseType: 'blob' }
    );
  }

  printCInvoice(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('printCInvoice', 'true'), responseType: 'blob' }
    );
  }

  printAcknowledgement(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('printAcknowledgement', 'true'), responseType: 'blob' }
    );
  }

  regeneratePermit(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php', 
      data,
      { headers: cusHeaders, params: new HttpParams().set('regeneratePermit', 'true'), responseType: 'blob' }
    );
  }

  isEmailExist(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('isEmailExist', 'true') }
    );
  };

  register(reg_data:any, file:any) {
    let formData = new FormData();
    formData.append("customer", JSON.stringify(reg_data.customer));
    formData.append("license", JSON.stringify(reg_data.license));
    formData.append("user", JSON.stringify(reg_data.user));
    formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('register', 'true') }
    );
  };

  getCustomers(filter:any) {
    return this.http.post<Customer[]>(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getCustomers', 'true') }
    );
  }

  getCustomerDetails(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getCustomerDetails', 'true') }
    );
  }

  getCustomer(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getCustomer', 'true') }
    );
  }

  // getCustomerSettings1(filter:any) {
  //   return this.http
  //           .post(environment.apiUrl + 'register.ajax.php?getCustomerSettings=true', JSON.stringify(filter), { headers: this.headers })
  //           .map((res:Response) => res.json())
  //           .catch(this.handleOError);
  // }

  saveCustomer(data:Customer) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveCustomer', 'true') }
    );
  }

  isCustomerNameExist(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('isCustomerNameExist', 'true') }
    );
  };

  save_fl(flLicense:any, file:any) {
    let formData = new FormData();
    formData.append("license", JSON.stringify(flLicense));
    formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('save_fl', 'true') }
    );
  }

  getDocDetails(doc_id:number) {
    let data = { 'doc_id': doc_id };
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('getDocDetails', 'true') }
    );
  }

  getLicenses(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getLicenses', 'true') }
    );
  }

  delLicense(lic_id:number) {
    let data = { 'id': lic_id };
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('delLicense', 'true') }
    );
  }

  assoPermitWithNewLicense(data :any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('assoPermitWithNewLicense', 'true') }
    );
  }

  getUsers(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getUsers', 'true') }
    );
  }
  
  getSupplierUsers(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getSupplierUsers', 'true') }
    );
  }

  getUser(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getUser', 'true') }
    );
  }

  saveUser(user:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      user,
      { headers: this.headers, params: new HttpParams().set('saveUser', 'true') }
    );
  }
  
  updateUserStatus(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('updateUserStatus', 'true') }
    );
  }

  getCountries(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getCountries', 'true') }
    );
  }

  getCountry(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getCountry', 'true') }
    );
  }

  saveCountry(country:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      country,
      { headers: this.headers, params: new HttpParams().set('saveCountry', 'true') }
    );
  }

  getStates(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getStates', 'true') }
    );
  }

  getState(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getState', 'true') }
    );
  }

  saveState(state:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      state,
      { headers: this.headers, params: new HttpParams().set('saveState', 'true') }
    );
  }
  
  getDepots(filter:any) {
    return this.http.post<Depot[]>(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getDepots', 'true') }
    );
  }

  getDepot(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getDepot', 'true') }
    );
  }

  saveDepot(depot:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      depot,
      { headers: this.headers, params: new HttpParams().set('saveDepot', 'true') }
    );
  }

  getStarGradations(filter) {
    return this.http.post<StarGradation[]>(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getStarGradations', 'true') }
    );
  }

  getBrandOwners(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getBrandOwners', 'true') }
    );
  }

  getBrandOwner(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getBrandOwner', 'true') }
    );
  }

  saveBrandOwner(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveBrandOwner', 'true') }
    );
  }
  
  deleteBrandOwner(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deleteBrandOwner', 'true') }
    );
  }

  getItemCats(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItemCats', 'true') }
    );
  }

  getItemCat(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItemCat', 'true') }
    );
  }

  saveItemCat(itemCat:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      itemCat,
      { headers: this.headers, params: new HttpParams().set('saveItemCat', 'true') }
    );
  }
  
  getItems(filter:any) {
    return this.http.post<Item[]>(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItems', 'true') }
    );
  }

  getItem(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItem', 'true') }
    );
  }

  saveItem(item:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      item,
      { headers: this.headers, params: new HttpParams().set('saveItem', 'true') }
    );
  }

  getItemAssoList(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItemAssoList', 'true') }
    );
  }

  getItemAsso(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItemAsso', 'true') }
    );
  }

  saveItemAsso(data:any, file:any) {
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    if (file)
      formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('saveItemAsso', 'true') }
    );
  }

  deleteItemAsso(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deleteItemAsso', 'true') }
    );
  }

  getVerfStatus(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getVerfStatus', 'true') }
    );
  }

  saveVerf(verf:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      verf,
      { headers: this.headers, params: new HttpParams().set('saveVerf', 'true') }
    );
  }

  getVerfHist(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getVerfHist', 'true') }
    );
  }

  getPermits(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getPermits', 'true') }
    );
  }

  getPermit(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getPermit', 'true') }
    );
  }

  getPermitToView(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getPermitToView', 'true') }
    );
  }

  getPermitToReval(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getPermitToReval', 'true') }
    );
  }

  getPermitToCancel(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getPermitToCancel', 'true') }
    );
  }

  savePermit(permit:Permit) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      permit,
      { headers: this.headers, params: new HttpParams().set('savePermit', 'true') }
    );
  }

  // uploadSignedPermit(data:any, file:any) {
  //   let formData = new FormData();
  //   formData.append("data", JSON.stringify(data));
  //   formData.append("file", file);
  //   return this.http.post(
  //     environment.apiUrl + 'register.ajax.php',
  //     formData,
  //     { params: new HttpParams().set('uploadSignedPermit', 'true') }
  //   );
  // }

  isSupplierExist(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('isSupplierExist', 'true') }
    );
  }

  getSuppliers(filter:any) {
    return this.http.post<Supplier[]>(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getSuppliers', 'true') }
    );
  }

  deleteSupplier(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deleteSupplier', 'true') }
    );
  }

  getSupplierDetails(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getSupplierDetails', 'true') }
    );
  }

  saveSupplier(data:Supplier) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveSupplier', 'true') }
    );
  }

  getWarehouses(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getWarehouses', 'true') }
    );
  }

  deleteWarehouse(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('deleteWarehouse', 'true') }
    );
  }

  getWarehouseDetails(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getWarehouseDetails', 'true') }
    );
  }

  saveWarehouse(warehouse:Warehouse) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      warehouse,
      { headers: this.headers, params: new HttpParams().set('saveWarehouse', 'true') }
    );
  }

  saveSpaceLeaseCertificate(data:any, file:any) {
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('saveSpaceLeaseCertificate', 'true') }
    );
  }

  deleteSpaceLeaseCertificate(id:number) {
    let data = { 'id': id };
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deleteSpaceLeaseCertificate', 'true') }
    );
  }

  savePermitRoute(data:PermitRoute[]) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savePermitRoute', 'true') }
    );
  }

  savePermitItem(data:PermitItem) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savePermitItem', 'true') }
    );
  }

  deletePermitItem(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deletePermitItem', 'true') }
    );
  }

  saveProformaInvoice(data:any, file:any) {
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('saveProformaInvoice', 'true') }
    );
  }

  delProformaInvoice(reqData:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      reqData,
      { headers: this.headers, params: new HttpParams().set('delProformaInvoice', 'true') }
    );
  }

  savePayment(payment:Payment) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      payment,
      { headers: this.headers, params: new HttpParams().set('savePayment', 'true') }
    );
  }

  savePaymentAdjustment(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savePaymentAdjustment', 'true') }
    );
  }

  saveOnlinePrePayment(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveOnlinePrePayment', 'true') }
    );
  }

  doubleVerifyIFHRMSPayment(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('doubleVerifyIFHRMSPayment', 'true') }
    );
  }

  doubleVerifyPayment(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('doubleVerifyPayment', 'true') }
    );
  }

  cancelPayment(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('cancelPayment', 'true') }
    );
  }

  updatePermitStatus(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('updatePermitStatus', 'true') }
    );
  }

  initiate_permit_reval(reval_data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      reval_data,
      { headers: this.headers, params: new HttpParams().set('initiate_permit_reval', 'true') }
    );
  }

  initiate_permit_cancel(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('initiate_permit_cancel', 'true') }
    );
  }

  getReqItems(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getReqItems', 'true') }
    );
  }

  getReqItem(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getReqItem', 'true') }
    );
  }

  saveReqItem(data:any, file:any) {
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    if (file)
      formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('saveReqItem', 'true') }
    );
  }

  saveReqItemAction(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveReqItemAction', 'true') }
    );
  }

  isItemNameExist(name:string) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      name,
      { headers: this.headers, params: new HttpParams().set('isItemNameExist', 'true') }
    );
  }

  savePermitLabel(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savePermitLabel', 'true') }
    );
  }

  requestPermitExt(data:any, file:any) {
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));
    if (file)
      formData.append("file", file);
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      formData,
      { params: new HttpParams().set('requestPermitExt', 'true') }
    );
  }

  savePermitRemark(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('savePermitRemark', 'true') }
    );
  }

  sendPermitMails(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('sendPermitMails', 'true') }
    );
  }

  markAsPermitPrinted(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('markAsPermitPrinted', 'true') }
    );
  }

  markAsEvalPrinted(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('markAsEvalPrinted', 'true') }
    );
  }

  changePassword(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('changePassword', 'true') }
    );
  }

  getSettings(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getSettings', 'true') }
    );
  }

  saveSettings(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveSettings', 'true') }
    );
  }

  get_payment_summary(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('get_payment_summary', 'true') }
    );
  }

  getTaxInvoices(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getTaxInvoices', 'true') }
    );
  }

  get_request_status_summary(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('get_request_status_summary', 'true') }
    );
  }

  getPaymentSummaryByMonth(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getPaymentSummaryByMonth', 'true') }
    );
  }

  getDailyPayments(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getDailyPayments', 'true') }
    );
  }

  getBrandSummaryforTM(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getBrandSummaryforTM', 'true') }
    );
  }

  getApprovedPermitsforTM(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getApprovedPermitsforTM', 'true') }
    );
  }

  getFinYears(filter) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getFinYears', 'true') }
    );
  }

  getTasmacPermit(filter) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getTasmacPermit', 'true') }
    );
  }

  saveTasmacPermit(permit: TasmacPermit) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      permit,
      { headers: this.headers, params: new HttpParams().set('saveTasmacPermit', 'true') }
    );
  }

  getItemTasmacAssoList(filter:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItemTasmacAssoList', 'true') }
    );
  }

  getItemTasmacAsso(filter: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getItemTasmacAsso', 'true') }
    );
  }

  toggleItemTasmacAssoDetAuthorize(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('toggleItemTasmacAssoDetAuthorize', 'true') }
    );
  }

  getIndents(filter: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getIndents', 'true') }
    );
  }

  getIndent(filter: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      filter,
      { headers: this.headers, params: new HttpParams().set('getIndent', 'true') }
    );
  }

  saveIndent(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveIndent', 'true') }
    );
  }

  saveIndentItem(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveIndentItem', 'true') }
    );
  }

  deleteIndentItem(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deleteIndentItem', 'true') }
    );
  }

  saveIndentRemark(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('saveIndentRemark', 'true') }
    );
  }

  updateIndentStatus(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('updateIndentStatus', 'true') }
    );
  }

  saveIndentVerf(verf:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      verf,
      { headers: this.headers, params: new HttpParams().set('saveIndentVerf', 'true') }
    );
  }

  printIndent(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/pdf');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('printIndent', 'true'), responseType: 'blob' }
    );
  }

  createRequestFromIndent(data: any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('createRequestFromIndent', 'true') }
    );
  }

  // MySQL Backup related
  createBackup() {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      null,
      { headers: this.headers, params: new HttpParams().set('createBackup', 'true') }
    );
  }

  getFiles() {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      null,
      { headers: this.headers, params: new HttpParams().set('getFiles', 'true') }
    );
  }

  downloadMySQLBackupFile(data:any) {
    let cusHeaders = this.headers.append('Accept', 'application/zip');
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: cusHeaders, params: new HttpParams().set('downloadMySQLBackupFile', 'true'), responseType: 'blob' }
    );
  }

  deleteMySQLBackupFile(data:any) {
    return this.http.post(
      environment.apiUrl + 'register.ajax.php',
      data,
      { headers: this.headers, params: new HttpParams().set('deleteMySQLBackupFile', 'true') }
    );
  }
  // End

  // // (error:any) => Observable.throw(error.json().error || 'Server error')
  // private handleOError(error:Response): Observable<any> {
  //   if (error.statusText)
  //   {
  //     return Observable.create((ob:any) => {
  //       // ob.throw(error);
  //       ob.complete();
  //       return;
  //     });
  //   }
  // }


  getDemoItems() {
    // let data = new Item[];
    return this.demoBrand;
     
  }

  getDemoStock() {
    // let data = new Item[];
    return this.demoBrandSupplier;
     
  }
}