import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { License, Supplier, Warehouse } from '../../config';

interface WarehouseActions {
  allowAdd:boolean;
  allowEdit:boolean;
  allowDelete:boolean;
};

@Component({
  templateUrl: 'warehouse-edit.component.html'
})
export class SupplierWarehouseEditComponent implements OnInit {

  warehouse: Warehouse;
  lics:License[] = [];
  currentUser:any = {};
  actions:WarehouseActions = {
    allowAdd: false,
    allowEdit: false,
    allowDelete: false
  };
  fsAlerts:Array<any> = [];

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {  }

  ngOnInit() {
    if (!this.dataService.filters.sup_war_edit) {
      this.goBack();
    } else {

      if (localStorage.getItem('currentUser')) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (!this.currentUser.supplier_id) {
          this.goBack();
        }
      }
      this.warehouse = new Warehouse();
      this.warehouse.owned_type = 'PRIVATE';
      this.warehouse.supplier_id = this.currentUser.supplier_id;
      this.warehouse.pr_cnt = 0;

      this.markAllowedActions(this.currentUser);
      let id = this.dataService.filters.sup_war_edit.id;
      if (id)
        this.getWarehouseDetails(+id);
    }
  }
  
  markAllowedActions(data: any) {
    this.actions.allowAdd = true; // (data.category_code === 'PSEC' || (this.warehouse.hasOwnProperty('req_customer_id') && data.hasOwnProperty('customer_id') && this.warehouse.req_customer_id === data.customer_id && this.warehouse.status === 'REQ'));
    this.actions.allowEdit = true; // (data.category_code === 'PSEC' || (this.warehouse.hasOwnProperty('req_customer_id') && data.hasOwnProperty('customer_id') && this.warehouse.req_customer_id === data.customer_id && this.warehouse.status === 'REQ'));
    this.actions.allowDelete = true; // (data.category_code === 'PSEC' || (this.warehouse.hasOwnProperty('req_customer_id') && data.hasOwnProperty('customer_id') && this.warehouse.req_customer_id === data.customer_id && this.warehouse.status === 'REQ'));
  }

  getWarehouseDetails(id: number) {
    const filter = { id: id };
    this.dataService.getWarehouseDetails(filter).subscribe((data:any) => {
      this.warehouse = data.war;

      if (data.lics)
      {
        this.lics = data.lics;
        this.lics = this.lics.map(l => {
          l.iss_date = new Date(l.iss_date);
          l.val_date = new Date(l.val_date);
          return l;
        });
      }
      else
        this.lics = [];

      this.markAllowedActions(this.currentUser);
    });
  }

  get_lics(type:string|string[], name:string) {
    if (typeof type === "string")
      return this.lics.filter(l => { return l.type == type && l.name == name; });
    else
      return this.lics.filter(l => { return (type.indexOf(l.type) !== -1) && l.name == name; });
  }

  filterLics(name: string) {
    return this.lics.filter(l => l.name === name);
  }

  onWarehouseChanged(w:Warehouse) {
    this.warehouse = w;
  }

  licenseSaved(lic:License) {
    lic.cnt = 0;
    this.lics.push(lic);
  }

  licenseDeleted(lic: License) {
    let idx = -1;
    this.lics.forEach((e:License, index:number) => {
      if (e.id == lic.id)
        idx = index;
    });
    if (idx > -1)
      this.lics.splice(idx, 1);
  }

  delete(lic:License) {
    if (confirm("Are to sure to delete?"))
    {
      this.dataService.delLicense(lic.id).subscribe((data:any) => {
        if (data.message == "License details deleted successfully.")
        {
          let idx = -1;
          this.lics.map((l, key) => { if (lic.id == l.id) idx = key; return; });
          if (idx >= 0) {
            this.lics.splice(idx, 1);
            this.fsAlerts.push({ type: 'danger', dismissible: true, msg: data.message, timeout: 5000 });
          }
        }
      });
    }
  }

  goBack() {
    this.router.navigate(["/sup-wars"]);
  }
}