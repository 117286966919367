import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
// import 'rxjs/add/operator/filter';

@Injectable()
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
 
    constructor(private router: Router) {
        // clear alert message on route change
        router.events.pipe(
          filter(event => event instanceof NavigationStart)
        ).subscribe(event => {
            if (this.keepAfterNavigationChange)
              this.keepAfterNavigationChange = false; // only keep for a single location change
            else
              this.subject.next(); // clear alert
            // if (event instanceof NavigationStart) {
            // }
          });
    }
 
    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
    }
 
    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }
 
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    clearAlert() {
      this.subject.next();
    }
}