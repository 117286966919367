import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Permit } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './approved.component.html'
})
export class PermitApprovedComponent implements OnInit {
  pnlColorDanger = false;
  permit: Permit;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    if (this.dataService.filters.permit_approved) {
      this.permit = this.dataService.filters.permit_approved.permit;
      this.pnlColorDanger = (this.permit.status === 'CAND');
    } else {
      this.router.navigate(['/home']);
    }
  }

  goBack() {
    this.router.navigate([this.dataService.filters.permit_approved.back_url]);
  }
}
