import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { DataService } from '../../../data.service';

@Component({
  templateUrl: './payment-summary.component.html'
})
export class PaymentSummaryComponent implements OnInit {
  filters:any = {};
  pay_sum:any[] = [];
  customers:string[] = [];

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0: ModalDirective;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dataService:DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filters.customer_id = data.customer_id;
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
    }

    this.route.params.subscribe(params => {
      this.filters.pay_to = (params.pay_to)?params.pay_to:'CPE';
      this.get_payment_summary();
    });
  }

  get_payment_summary() {
    this.customers = [];
    this.dataService.get_payment_summary(this.filters).subscribe((data:any[]) => {
      this.pay_sum = data;
      this.pay_sum = this.pay_sum.map(p => {
        p.trnx_date = new Date(p.trnx_date);
        p.req_date = new Date(p.req_date);
        p.paydet = (p.paydet as any[]).map(pd => {
          pd.trnx_date = new Date(pd.trnx_date);
          return pd;
        });

        if (p.cus_name && this.customers.indexOf(p.cus_name) === -1)
          this.customers.push(p.cus_name);

        if (p.sup_name && this.customers.indexOf(p.sup_name) === -1)
          this.customers.push(p.sup_name);

        return p;
      });
      this.customers.sort();
    });
  }

  get_pay_sum(cus_name:string) {
    let fil = this.pay_sum.filter(p => {return ((p.cus_name && p.cus_name === cus_name) || (p.sup_name && p.sup_name === cus_name)); });
    return fil;
  }

  getTotal(cus_name:string, colName:string) {
    let tot = 0;
    this.pay_sum.forEach(p => {
      if (colName && p[colName] && ((p.cus_name && p.cus_name === cus_name) || (p.sup_name && p.sup_name === cus_name)))
        tot += p[colName];
    });
    return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  getTotalDiff(cus_name:string) {
    let tot = 0;
    this.pay_sum.forEach(p => {
      if ((p.cus_name && p.cus_name === cus_name) || (p.sup_name && p.sup_name === cus_name))
        tot += p['paid_amt'] + p['adj_in'] - p['total_fee_roff'];
    });
    return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  
  getGrandTotal(colName:string) {
    let tot = 0;
    this.pay_sum.forEach(p => {
      if (colName && p[colName])
        tot += p[colName];
    });
    return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  getGrandTotalDiff() {
    let tot = 0;
    this.pay_sum.forEach(p => {
        tot += p['paid_amt'] + p['adj_in'] - p['total_fee_roff'];
    });
    return tot.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  getColSpan() {
    return (this.filters && this.filters.pay_to && this.filters.pay_to == "CPE")?2:6;
  }

  printAcknowledgement(pd: any) {
    // this.loading = true;
    const data = { permit_payment_id: pd.id };
    this.dataService.printAcknowledgement(data).subscribe((blob:Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      // this.loading = false;
    });
  }
}
