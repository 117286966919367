import { Component, Input } from '@angular/core';

import { PermitRemark, User } from '../../../../config';

@Component({
  selector: 'remark-list',
  templateUrl: './remark-list.component.html'
})
export class PermitRemarkListComponent {
  @Input()
  remarks: PermitRemark[];

  constructor(
  ) { }

  getRemarks() {
    return this.remarks.filter(r => !r.isEdit);
  }

  getStyle(remark: PermitRemark) {
    if (remark.rem_type === 'SYS')
      return { color: 'green' };
    else
      return { color: 'purple' };
  }
}
