import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';

import { DataService } from '../../data.service';
import { User } from '../../config';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return Observable.create((ob:any) => {
      let id = +route.params['id'];
      if (id == 0)
      {
        ob.next(new User);
        ob.complete();
        return;
      }

      this.dataService.getUser({ id: id }).subscribe((data:any) => {
        if (data.id == id) {
          ob.next(data as User); 
          ob.complete();
        }
        else {
          ob.next(new User);
          ob.complete();
          this.router.navigate(['/users']);
        }
      });
    });
  }
}
