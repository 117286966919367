import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PermitRemark, User } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'remark-add',
  templateUrl: './add.component.html'
})
export class PermitRemarkAddComponent implements OnChanges, OnInit {
  @Input()
  remark:PermitRemark;

  @Output()
  saved:EventEmitter<PermitRemark> = new EventEmitter<PermitRemark>();

  cuser:User;
  
  submitted = false;
  loading = false;
  fg: FormGroup;
  // errors:any = { };
  messages:any = [];
  // user_category_code:string;

  constructor(
    private fb: FormBuilder,
    private dataService:DataService
  ) {
  }

  ngOnInit() {
    this.cuser = new User();
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.cuser.id = data.id;
      this.cuser.name = data.name;
      this.cuser.designation = data.designation;
    }
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   this.user_category_code = data.category_code;
    // }
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.remark && (changes.remark as SimpleChange).currentValue)
    {
      // this.lvf = remark.make_new_verf();
      this.buildForm();
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['remarks'] = [
      this.remark.remarks,
      [
        Validators.required,
        Validators.maxLength(2000)
      ]
    ];
    // this.errors['remarks'] = [];
    this.messages['remarks'] = {
      'required': 'Remarks should not be empty.',
      'maxLength': 'Remarks length should not be greater than 2000 characters.'
    };

    this.fg = this.fb.group(controlsConfig);
    // this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  // onValueChanged(data?: any) {
  //   if (!this.fg) { return; }
    
  //   const form = this.fg;
  //   for (const field in this.errors) {
  //     this.errors[field] = [];
  //     const control = form.get(field);
  //     if (control && control.invalid) { //  control.dirty &&
  //       const messages = this.messages[field];
  //       for (const key in control.errors) {
  //         this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
  //       }
  //     }
  //   }
  // }

  getControlErrors(fieldName:string|string[], formGroup:FormGroup = null):string[] {
    if (!formGroup) formGroup = this.fg;

    let fNames:string[] = [];
    if (typeof fieldName == "string")
      fNames.push(fieldName);
    else
      fNames = fieldName;

    let errs:string[] = [];
    fNames.forEach(fn => {
      if (formGroup.controls[fn].errors)
      {
        Object.keys(formGroup.controls[fn].errors).forEach(eName => {
          if (this.messages[fn][eName]) errs.push(this.messages[fn][eName]);
        });
      }
    });
    return errs;
  }
  
  isControlHasError(fieldName:string, formGroup:FormGroup = null):boolean {
    if (!formGroup) formGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    let sValue = this.fg.value;
    if (this.fg.valid) {
      this.remark.remarks = sValue.remarks;
      this.loading = true;
      this.dataService.savePermitRemark(this.remark).subscribe((data:any) => {
        if (data.message == "Remarks saved successfully.")
        {
          if (data.id)
          {
            this.remark.id = data.id;
            this.remark.remark_by = this.cuser.name + (this.cuser.designation?(", "+this.cuser.designation):"");
            this.remark.rem_date = new Date();

            this.saved.emit(this.remark);
          }
        }
        this.loading = false;
      });
    }
  }
}