import { Component, OnInit } from '@angular/core';

import { DataService } from '../../../data.service';

@Component({
  templateUrl: './payment-summary-by-month.component.html'
})
export class PaymentSummaryByMonthComponent implements OnInit {
  filters: any = {};
  pay_sum: any[] = [];

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.getPaymentSummaryByMonth();
  }

  getPaymentSummaryByMonth() {
    this.dataService.getPaymentSummaryByMonth(this.filters).subscribe((data:any[]) => {
      this.pay_sum = data.map(p => {
        p.mon_year = new Date(p.mon_year);
        p.trnx_date_format = this.makeMonth(p.mon_year.getMonth()) + '-' + p.mon_year.getFullYear();
        return p;
      });
    });
  }

  makeMonth(monNum: number) {
    switch (monNum) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
    }
  }

  getTotal(colName:string) {
    let tot = 0;
    this.pay_sum.forEach(p => {
        tot += p[colName]?p[colName]:0;
    });
    return tot;
  }
}
