import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { ItemAsso, Supplier } from '../../config';

@Component({
  templateUrl: './list.component.html'
})
export class BrandRegistrationsComponent implements OnInit {
  supGroups: Supplier[] = [];
  filters:any = {};
  itemAssoList:ItemAsso[] = [];
  paginationData: { pageSize: number, page: number} = { pageSize: 100, page: 1 };

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   if (data.customer_id)
    //     this.filters.customer_id = data.customer_id;
    // }
    this.getItemAssoList();
  }

  getItemAssoList() {
    this.dataService.getItemAssoList(this.filters).subscribe((data:ItemAsso[]) => {

      data = data.map((a, index) => {
        a.slno = index+1;
        a.from_date = new Date(a.from_date);
        a.to_date = new Date(a.to_date);
        return a;
      });

      // const supplierNames: any[] = [];
      // this.supGroups = [];

      // const tmpSupNames:{[index: string]: true } = { };
      // data.forEach(d => tmpSupNames[d.supplier_name] = true);
      // Object.keys(tmpSupNames).map(key => supplierNames.push(key));
      // supplierNames.sort();

      this.itemAssoList = data;
      
      // if (supplierNames.length > 0) {
      //   // this.currentSupName = supplierNames[0];
      //   supplierNames.forEach(cat_name => {
      //     const catData = this.filterItemsByCatName(cat_name);
      //     this.catGroups.push({
      //       cat_name: cat_name,
      //       brand_owner_names: this.getBrandOwnerNames(catData),
      //       data: catData,
      //       currentPage: 1,
      //       itemsPerPage: 100
      //     });
      //   });
      // }

    });
  }

  getSupplierNames(data: ItemAsso[]) {
    const supplierNames:string[] = [];
    const tmpSupNames:{[index: string]: true } = { };
    data.forEach(d => { tmpSupNames[d.supplier_name] = true });
    Object.keys(tmpSupNames).map(key => supplierNames.push(key));
    supplierNames.sort();
    return supplierNames;
  }

  getTableData() {
    let ret = this.itemAssoList;
    if (this.paginationData && this.paginationData.pageSize) {
      ret = this.itemAssoList.slice((this.paginationData.page - 1) * this.paginationData.pageSize, this.paginationData.page * this.paginationData.pageSize);
    }
    return ret;
  }
  
  filterItems(itemAssoList: ItemAsso[], supplier_name: string) {
    return itemAssoList.filter(i => i.supplier_name === supplier_name);
  }

  onPagination(changeData:{pageSize: number, page: number}) {
    this.paginationData = changeData;
  }
}