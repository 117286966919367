import { Component, Input } from '@angular/core';

import { Config, Warehouse } from '../../config';

@Component({
  selector: 'view-war',
  templateUrl: './view.component.html'
})
export class WarehouseViewComponent {
  @Input()
  war:Warehouse;

  constructor() {  }

  getName(owned_type_code: string) {
    return Config.warehouse_types.filter(t => { return t.id == owned_type_code;})[0].name;
  }
  
  makeAddress(war: Warehouse) {
    let ret = "";
    if (war.add1)
      ret += war.add1;
    if (war.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add2;
    if (war.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.add3;
    if (war.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + war.pin;
    if (war.state_name)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + war.state_name;
    return ret;
  }

  getStyle(owned_type: string) {
    return (owned_type === 'PUBLIC')?{ 'color': 'green' }:{ 'color': 'red' };
  }
}