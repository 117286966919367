import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { PermitBase } from './permit.base.component';
import { Config, License, PermitRemark, VData, Verf, Permit } from '../../config';
import { DataService } from '../../data.service';

// class VS {

//   lee_det: VData;
//   lee_o_fl: VData;
//   lee_r_fl: VData;
//   lee_o_fs: VData;
//   lee_r_fs: VData;

//   dpt_det: VData;

//   sup_det: VData;
//   sup_o_il: VData;
//   sup_o_fs: VData;
//   sup_r_fs: VData;

//   war_det: VData;
//   war_o_il: VData;
//   war_r_il: VData;
//   war_o_fs: VData;
//   war_r_fs: VData;

//   p_slc_doc: VData;
//   p_route: VData;
//   p_period: VData;

//   item_det: VData;
//   prof_inv: VData;

//   spf_det: VData;
//   apf_det: VData;
//   sc_det: VData;
//   st_det: VData;
//   coel_det: VData;

//   tas_paid: VData;
//   cpe_paid: VData;

//   //Revalidate
//   e_period: VData;
//   apf_rev: VData;
//   sc_rev: VData;
//   st_rev: VData;
//   tas_r_pd: VData;
//   rev_resn: VData;

//   //Cancellation
//   spf_can: VData;
//   can_resn: VData;
// }

@Component({
  templateUrl: './verify.component.html',
})
export class PermitVerifyComponent extends PermitBase implements OnInit {
  filter: any;
  // category_code:string;
  permit: Permit;
  verfs: Verf[] = [];
  vs:{[prop_name: string]: VData};
  showDraftPermit = false;
  trustedDPUrl: SafeResourceUrl;
  isReturnedByCPEOff: boolean = false;

  for:string = "A";
  loading = false;
  user_id:number;
  user_category_code = "";
  level = 0;
  fwd_btn_name = "";

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;

  constructor(
    private router:Router,
    private dataService:DataService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.level = data.level;
      this.user_id = data.id;
      this.user_category_code = data.category_code;
      if (this.level > 0 && this.level < 9)
      {
        let nextLevel = Config.userCategories.filter(uc => { return uc.level == this.level+1; })[0];
        this.fwd_btn_name = nextLevel.name;
      }
    }
    this.filter = {};
    if (this.dataService.filters.permit) {
      this.for = this.dataService.filters.permit.for;
      this.filter.id = this.dataService.filters.permit.id;
      this.getPermit();
    } else {
      this.router.navigate(['/home']);
    }

    // this.route.data.subscribe((data:any) => {
    //   this.for = this.permit.for;
    // });
  }

  getPermit() {
    this.dataService.getPermitToView(this.filter).subscribe((data:any) => {
      this.permit = data;
      this.permit.req_date = new Date(this.permit.req_date);
      if (this.permit.permit_date)
        this.permit.permit_date = new Date(this.permit.permit_date);
      if (this.permit.valid_date)
        this.permit.valid_date = new Date(this.permit.valid_date);
      if (this.permit.reval_date)
        this.permit.reval_date = new Date(this.permit.reval_date);
      if (this.permit.is_revalidated && !this.permit.extend_period)
      {
        this.permit.extend_period = this.permit.period;
        this.permit.reval_date = new Date(this.permit.valid_date);
        this.permit.reval_date.setDate(this.permit.reval_date.getDate() + this.permit.period);
      }
      if (this.permit.cancel_date)
        this.permit.cancel_date = new Date(this.permit.cancel_date);

      if (this.permit.ext_req_date)
        this.permit.ext_req_date = new Date(this.permit.ext_req_date);
      if (this.permit.bdr_cross_date)
        this.permit.bdr_cross_date = new Date(this.permit.bdr_cross_date);
      if (this.permit.rpt_date)
        this.permit.rpt_date = new Date(this.permit.rpt_date);
      if (this.permit.ext_valid_date)
        this.permit.ext_valid_date = new Date(this.permit.ext_valid_date);
      if (this.permit.ext_app_date)
        this.permit.ext_app_date = new Date(this.permit.ext_app_date);
        
      this.permit.lics = this.permit.lics.map((l:License) => {
        l.iss_date = new Date(l.iss_date);
        l.val_date = new Date(l.val_date);
        return l;
      });
      
      this.dist_licenses(this.permit.lics);
      this.pDocs = this.dist_docs(this.permit.docs);
      this.dist_fees(this.permit.fees);
      this.dist_pay(this.permit.pay);

      this.isReturnedByCPEOff = false;
      this.permit.remarks = this.permit.remarks.map((r:PermitRemark) => {
        r.rem_date = new Date(r.rem_date);
        if (r.remarks === 'Permit Request sent back to CPE, Section for Clarification.' && r.rem_type === 'SYS') {
          this.isReturnedByCPEOff = true;
        }
        return r;
      });
      this.addNewRemark();

      this.makeVerfData(this.isReturnedByCPEOff);
      if (this.permit.verfs)
      {
        this.verfs = this.permit.verfs;
        this.permit.verfs = (this.permit.verfs as Verf[]).map(v => {
          v.verf_date = new Date(v.verf_date);
          return v;
        });
        Object.values(this.vs).forEach((vd:VData) => {
          let vs = (this.permit.verfs as Verf[]).filter((v:Verf) => { return v.verf_code == vd.verf_code; });
          vd.set_verfs(vs);
        });
        // Object.keys(this.vs).forEach(key => {
        //   let vdata = (this.vs as any)[key] as VData;
        //   let vs = (this.permit.verfs as Verf[]).filter((v:Verf) => { return v.verf_code == vdata.verf_code; });
        //   vdata.set_verfs(vs);
        // });
      }

    });
  }

  makeVerfData(isReturnedByCPEOff?: boolean) {
    this.vs = { };
    const v_act = (this.permit.is_fl_permit)?['CUS']:['SUP'];

      if (this.permit.is_fl_permit) {
      this.vs.lee_det = new VData("LEE_DET", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
      this.vs.lee_o_fl = new VData("LEE_O_FL", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
      this.vs.lee_r_fl = new VData("LEE_R_FL", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
      this.vs.lee_o_fs = new VData("LEE_O_FS", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
      this.vs.lee_r_fs = new VData("LEE_R_FS", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    } else {
      this.vs.dpt_det = new VData("DPT_DET", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
    }

    this.vs.sup_det = new VData("SUP_DET", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.sup_o_il = new VData("SUP_O_IL", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.sup_o_fs = new VData("SUP_O_FS", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.sup_r_fs = new VData("SUP_R_FS", [...v_act, 'PSEC'], [...v_act, 'PSEC']);

    this.vs.war_det = new VData("WAR_DET", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.war_o_il = new VData("WAR_O_IL", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.war_r_il = new VData("WAR_R_IL", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.war_o_fs = new VData("WAR_O_FS", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.war_r_fs = new VData("WAR_R_FS", [...v_act, 'PSEC'], [...v_act, 'PSEC']);

    this.vs.p_slc_doc = new VData("P_SLC_DOC", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.p_route = new VData("P_ROUTE", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    this.vs.p_period = new VData("P_PERIOD", [...v_act, 'PSEC'], [...v_act, 'PSEC']);

    this.vs.item_det = new VData("ITEM_DET", [...v_act, 'TSEC', 'PSEC'], [...v_act, 'TSEC', 'PSEC']);
    this.vs.prof_inv = new VData("PROF_INV", [...v_act, 'PSEC'], [...v_act, 'PSEC']);

    this.vs.spf_det = new VData("SPF_DET", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    if (this.permit.is_fl_permit) {
      this.vs.apf_det = new VData("APF_DET", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
      this.vs.sc_det = new VData("SC_DET", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
      this.vs.st_det = new VData("ST_DET", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
      this.vs.coel_det = new VData("COEL_DET", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
    }

    this.vs.cpe_paid = new VData("CPE_PAID", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    if (this.permit.is_fl_permit) {
      this.vs.tas_paid = new VData("TAS_PAID", [...v_act, 'TACC'], [...v_act, 'TACC']);
    }

    // Revalidate
    if (this.permit.is_revalidated) {
      this.vs.e_period = new VData("E_PERIOD", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
      this.vs.apf_rev = new VData("APF_REV", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
      this.vs.sc_rev = new VData("SC_REV", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
      this.vs.st_rev = new VData("ST_REV", [...v_act, 'TSEC'], [...v_act, 'TSEC']);
      this.vs.tas_r_pd = new VData("TAS_R_PD", [...v_act, 'TACC'], [...v_act, 'TACC']);
      this.vs.rev_resn = new VData("REV_RESN", [...v_act, 'PSEC'], [...v_act, 'PSEC']);
    }

    // Cancellation
    if (this.permit.is_cancelled) {
      this.vs.spf_can = new VData("SPF_CAN", ['CUS', 'PSEC'], ['CUS', 'PSEC']);
      this.vs.can_resn = new VData("CAN_RESN", ['CUS', 'PSEC'], ['CUS', 'PSEC']);
    }

    if (this.permit.status === 'REQ' && this.level >= 5 && this.level <= 9) {
      if (isReturnedByCPEOff) {
        this.vs.pmt_ds = new VData('PMT_DS', ['PSEC', 'PSSV'], ['PSEC', 'PSSV']);
      } else {
        this.vs.pmt_ds = new VData('PMT_DS', ['PSSV'], ['PSEC', 'PSSV']);
      }
      this.showDraftPermit = true;
      this.getPermitDoc();
    }
    // console.log(this.permit.status, this.level);
  }

  addNewRemark() {
    let nr = new PermitRemark(this.permit.id, this.user_id);
    nr.isEdit = true;
    this.permit.remarks.push(nr);
  }

  onRemarksSaved(rem:PermitRemark) {
    rem.isEdit = false;
    this.addNewRemark();
  }

  isUsrType(ut:string|string[]):boolean {
    if (typeof ut == 'string')
      return (this.user_category_code == ut);
    else
    {
      const regExp:RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.user_category_code.match(regExp);
      return match && match.length > 0;
    }
  }

  get_send_to_cus_status():boolean {
    if (this.isUsrType(['TGM', 'TMD', 'PSSV', 'PAC', 'PJC', 'PCOM']))
      return false;
    else
      return (this.get_over_all_status() !== 'CLR');
  }

  goBack() {
    this.router.navigate([this.dataService.filters.permit.back_url]);
    // if (this.user_category_code === "CUS")
    //   this.router.navigate(["/permit_list", this.for]);
    // else
    //   this.router.navigate(["/permits", this.for]);
  }

  get_over_all_status() {
    let tot = 0;
    let ver_cnt = 0;
    let clr_cnt = 0;
    let cld_cnt = 0;
    Object.values(this.vs).forEach((vd:VData) => {
      if (vd && (vd.allow_state_change || vd.showChangeState))
      {
        tot++;
        if (vd.lvf_state.status == 'VER')
          ver_cnt++;
        if (vd.lvf_state.status == 'CLR')
          clr_cnt++;
        if (vd.lvf_state.status == 'CLD')
          cld_cnt++;
      }
    });
    let over_all_status = "YET";
    switch (true)
    {
      case (clr_cnt > 0):
        over_all_status = "CLR";
        break;
      case (cld_cnt > 0 && clr_cnt == 0):
        over_all_status = "CLD";
        break;
      case (tot == ver_cnt):
        over_all_status = "VER";
        break;
    }
    return over_all_status;
  }

  clarified() {
    if (this.get_over_all_status() !== 'CLD') return;
    if (!confirm('Are you sure to submit clarification?')) return;

    this.loading = true;
    let update_status = "REQ";
    if (this.permit.is_revalidated) update_status = "REV";
    if (this.permit.is_cancelled) update_status = "CAN";
    let status_data = { 'id': this.permit.id, user_id: this.user_id, 'status': 'CLD', 'level': 1, 'update_status': update_status };
    this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
      if (data.message == "Permit Request submitted with clarifications successfully.") {
        this.goBack();
      }
      this.loading = false;
    });
  }

  verify() {
    if (this.get_over_all_status() === 'VER')
    {
      this.loading = true;
      let status_data = { 'id': this.permit.id, 'user_id': this.user_id, 'status': 'VER', 'level': this.level+1 };
      this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
        if (data.message == "Permit Request verified successfully.")
        {
          this.goBack();
          // this.router.navigate(["/permits", this.for]);
        }
        this.loading = false;
      });
    }
  }

  approve(isNow = false) {
    this.loading = true;
    let status = "APP";
    if (this.permit.is_revalidated)
      status = "APP-REV";
    if (this.permit.is_cancelled)
      status = "APP-CAN";
    if (this.for == "X")
      status = "APP-EXT";
    let status_data = { 'id': this.permit.id, user_id: this.user_id, 'status': status, 'level': this.level };
    this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
      if (data.message == "Permit approved successfully.")
      {
        if (data.permit_no) this.permit.permit_no = data.permit_no;
        if (data.permit_date) this.permit.permit_date = new Date(data.permit_date);
        if (data.status) this.permit.status = data.status;

        this.dataService.filters.permit_approved = { permit: this.permit, back_url: '/permits/'+ this.for };
        if (isNow) {
          this.router.navigate(["/digital-signature"]);
        } else {
          this.router.navigate(["/permit_approved"]);
        }
        // , this.for, this.permit.id, data.permit_no, data.permit_date
      }
      this.loading = false;
    });
  }

  reject() {
    this.loading = true;
    let status = "REJ";
    if (this.for == "X")
      status = "REJ-EXT";
    let status_data = { 'id': this.permit.id, user_id: this.user_id, 'status': status, 'level': this.level+1 };
    this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
      if (data.message == "Permit Request rejected successfully.")
      {
        this.goBack();
      }
      this.loading = false;
    });
  }

  send_to_cus() {
    if (!this.get_send_to_cus_status()) // get_over_all_status() === 'CLR'
    {
      if (!confirm('Are you sure to send back for clarification?')) return;

      this.loading = true;
      let status_data = { 'id': this.permit.id, user_id: this.user_id, 'status': 'CLR', 'level': 0 };
      this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
        if (data.message == "Permit Request sent back for clarification successfully.")
          this.goBack();
        this.loading = false;
      });

    }
  }

  sendBackToCpeSection() {
    if (!confirm('Are you sure to send back for clarification?')) return;

    this.loading = true;
    let status_data = { 'id': this.permit.id, user_id: this.user_id, 'status': 'VER', 'level': 5, is_sent_back: true };
    this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
      this.loading = false;
      if (data.message == "Permit Request verified successfully.")
        this.goBack();
    });
  }

  printPermitEvalDetails() {
    this.loading = true;
    this.dataService.printPermitEvalDetails({ 'id': this.permit.id }).subscribe((blob:Blob) => {
      // let blob = new Blob([response.blob()], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }

  licenseDeleted() {
    this.getPermit();
  }
  
  licenseSaved(l:License) {
    this.loading = true;
    const data = {
      permit_id: this.permit.id,
      customer_id: l.customer_id,
      name: l.name,
      supplier_id: l.supplier_id,
      warehouse_id: l.warehouse_id
    };
    this.dataService.assoPermitWithNewLicense(data).subscribe((data: any) => {
      if (data.message === "License associated with permit")
        this.getPermit();
      this.loading = false;
    });
  }

  docSaved() {
    this.getPermit();
  }

  docDeleted() {
    this.getPermit();
  }

  onProformaInvoiceSaved(data:any) {
    this.permit.proforma_doc_id = data.proforma_doc_id;
    this.permit.proforma_storage_name = data.proforma_storage_name;
    this.getPermit();
  }

  deleteProformaInvoice() {
    let reqData = {
      'doc_id': this.permit.proforma_doc_id,
      'id': this.permit.id
    };
    this.dataService.delProformaInvoice(reqData).subscribe((data:any) => {
      if (data.message == "Proforma Invoice deleted successfully.")
      {
        this.permit.proforma_doc_id = null;
        this.permit.proforma_storage_name = null;
        this.getPermit();
      }
    });
  }

  getPermitDoc() {
    this.loading = true;
    this.dataService.printPermit({ id: this.permit.id, is_draft: true }).subscribe((blob: Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedDPUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.loading = false;
    });
  }
}
