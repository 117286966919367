import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { PermitBase } from '../../components/permit/permit.base.component';
import { IndentItem, IndentRemark, Indent } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './indent-view.component.html',
})
export class SupplierIndentViewComponent extends PermitBase implements OnInit {

  indent: Indent;
  loading = false;

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;

  constructor(
    private router: Router,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   if (data.id)
    //     this.user_id = data.id;
    //   if (data.supplier_id) {
    //     this.supplier_id = data.supplier_id;
    //   }
    // }
    // this.supplier_id && 

    if (this.dataService.filters.sup_indent_view && this.dataService.filters.sup_indent_view.id > 0) {
      this.getIndent();
    } else {
      this.router.navigate(['/home']);
    }
  }
  
  getIndent() {
    const indent_id = this.dataService.filters.sup_indent_view.id;
    const supplier_id = this.dataService.filters.sup_indent_view.supplier_id;
    this.dataService.getIndent({ indent_id: indent_id, supplier_id: supplier_id }).subscribe((data: any) => {
      this.populateIndentData(data);
    });
  }

  private populateIndentData(data: Indent) {
    this.indent =  data;
    if (this.indent.indent_date) this.indent.indent_date = new Date(this.indent.indent_date);

    if (data.items && data.items.length > 0)
    {
      this.indent.items = data.items.map((i: IndentItem) => {
        let pi = new IndentItem(i.indent_id);
        pi.id = i.id;
        pi.item_id = i.item_id;
        pi.vol = i.vol;
        pi.qty_case = i.qty_case;
        pi.btl_per_case = i.btl_per_case;
        pi.qty_btl = i.qty_btl;
        pi.orig_qty_case = i.orig_qty_case;
        pi.orig_qty_btl = i.orig_qty_btl;
        pi.is_deleted = i.is_deleted;

        // Extended
        pi.item_cat_id = i.item_cat_id;
        pi.item_cat_name = i.item_cat_name;
        pi.item_name = i.item_name;
        pi.origin_country_id = i.origin_country_id;
        pi.con_name = i.con_name;
        pi.is_edit_mode = false;
        return pi;
      });
    }
    
    if (data.remarks && data.remarks.length > 0)
    {
      this.indent.remarks = data.remarks.map((r: IndentRemark) => {
        r.rem_date = new Date(r.rem_date);
        return r;
      });
    } else {
      this.indent.remarks = [];
    }
  }

  print() {
    this.loading = true;
    this.dataService.printIndent({ 'id': this.indent.id, 'is_preview': true }).subscribe((blob:Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }

  goBack() {
    this.router.navigate([this.dataService.filters.sup_indent_view.back_url]);
  }
}
