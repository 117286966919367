import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit {
  // title = 'app';
  constructor(
    private swUpdate: SwUpdate
  ) { }
  
  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("New version available.\n\nWe recommend you to load new version.\n\nDo you want to load New Version?")) {
          window.location.reload();
        }
      });
    }        
  }
}
