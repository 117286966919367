import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Renderer, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Item, ItemCat, IndentItem, User } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'indent-item-edit',
  templateUrl: './indent-item-edit.component.html'
})
export class SupplierIndentItemEditComponent implements OnChanges, OnInit {

  @Input()
  supplier_id: number;

  @Input()
  indentitem: IndentItem;

  @Output()
  saved: EventEmitter<IndentItem> = new EventEmitter<IndentItem>();
  
  @Output()
  cancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<Item[]>;

  user: User;
  itemcats:ItemCat[] = [];
  submitted = false;
  loading = false;
  fg: FormGroup;

  @ViewChild('qty_btl', { static: false }) qty_btlElement: ElementRef;
  @ViewChild('item_name', { static: false }) item_nameElement: ElementRef;

  constructor(
    private fb: FormBuilder,
    private dataService:DataService,
    private renderer: Renderer
  ) { }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.indentitem.currentValue) {
      this.reset(this.indentitem);
    }
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.dataService.getItemCats({}).subscribe((data:ItemCat[]) => { this.itemcats = data; });
    this.buildForm();
    let frmControls:any = this.fg.controls;
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(frmControls.item_name.value);
    }).pipe(
      mergeMap((token: string) => {
        let filter:any = { async: true, name: token, limit: 25, isTasmacAuthorized: true };
        if (frmControls.item_cat_id.value) {
          filter.item_cat_id = frmControls.item_cat_id.value;
        }
        if (frmControls.origin_country_id.value) {
          filter.origin_country_id = frmControls.origin_country_id.value;
        }
        if (this.supplier_id) {
          filter.supplier_id = this.supplier_id;
        }
        return this.dataService.getItems(filter);
      })
    );
  }

  private buildForm(): void {
    let config:any = {};

    config['item_cat_id'] = [
      this.indentitem.item_cat_id,
      [
        Validators.required
      ]
    ];

    config['origin_country_id'] = [
      this.indentitem.origin_country_id,
      [
        Validators.required
      ]
    ];
  
    config['item_id'] = [
      this.indentitem.item_id,
      [
        Validators.required
      ]
    ];
  
    config['item_name'] = [
      this.indentitem.item_name,
      [
        Validators.required
      ]
    ];
  
    config['qty_btl'] = [
      this.indentitem.qty_btl,
      [
        Validators.required
      ]
    ];

    this.fg = this.fb.group(config);
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
 
  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }
 
  typeaheadOnSelect(e:TypeaheadMatch): void {
    let frmControls:any = this.fg.controls;
    if (e.item)
    {
      const item:Item = e.item as Item;
      this.indentitem.item_cat_id = item.item_cat_id;
      this.indentitem.item_cat_name = item.cat_name;
      this.indentitem.origin_country_id = item.origin_country_id;
      this.indentitem.con_name = item.con_name;
      this.indentitem.item_id = item.id;
      this.indentitem.item_name = item.name + ', ' + item.vol + ' ml';
      this.indentitem.vol = item.vol;
      this.indentitem.btl_per_case = item.btl_per_case;
      frmControls.item_cat_id.setValue(item.item_cat_id);
      frmControls.origin_country_id.setValue(item.origin_country_id);
      frmControls.item_id.setValue(item.id);

      // let ic:ItemCat = this.itemcats.filter(ic => { return ic.id == item.item_cat_id; })[0];
      // this.indentitem.pl_pc = ic.pl_pc;
      // this.indentitem.spf_bl_rate = ic.spf_bl_rate;
      // this.indentitem.spf_pl_rate = ic.spf_pl_rate;
      // this.indentitem.apf_rate = ic.apf_rate;
      // this.indentitem.el_rate = ic.el_rate;

      this.renderer.invokeElementMethod(this.qty_btlElement.nativeElement, 'focus');
    }
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid)
    {
      let sValue: IndentItem = this.fg.value;
      this.indentitem.qty_btl = sValue.qty_btl;

      if (this.indentitem.qty_btl <= 0) {
        alert("Quantity should be a positive number.");
        return;
      }

      // qty_case calc
      let qtyBtl = (this.indentitem.qty_btl?this.indentitem.qty_btl:0);
      let btlPerCase = (this.indentitem.btl_per_case?this.indentitem.btl_per_case:qtyBtl);
      this.indentitem.qty_case = (Math.floor(qtyBtl/btlPerCase)).toString() + '.' + (qtyBtl%btlPerCase).toString().padStart(2, '0');

      this.loading = true;
      let reqData = Object.assign({ user_category_code: this.user.category_code, user_id: this.user.id }, this.indentitem);
      this.dataService.saveIndentItem(reqData).subscribe((data: any) => {
        if (data.message == "Brand added to indent successfully.") {
          if (data.id) {
            this.indentitem.id = data.id;
          }
          if (data.hasOwnProperty('orig_qty_case')) this.indentitem.orig_qty_case = data.orig_qty_case;
          if (data.hasOwnProperty('orig_qty_btl')) this.indentitem.orig_qty_btl = data.orig_qty_btl;
          this.saved.emit(this.indentitem);
          this.renderer.invokeElementMethod(this.item_nameElement.nativeElement, 'focus');
        }
        this.loading = false;
      });
    }
  }

  cancel() {
    if (this.indentitem.id) {
      this.cancelled.emit(true);
    } else {
      this.reset(new IndentItem(this.indentitem.indent_id));
    }
  }

  reset(pi: IndentItem) {
    this.indentitem = pi;
    if (this.fg)
    {
      this.fg.reset({
        'item_cat_id': this.indentitem.item_cat_id,
        'origin_country_id': this.indentitem.origin_country_id,
        'item_id': this.indentitem.item_id,
        'item_name': this.indentitem.item_name,
        'qty_btl': this.indentitem.qty_btl
      });
    }
    this.submitted = false;
    this.loading = false;
    this.typeaheadLoading = false;
    this.typeaheadNoResults = false;
  }

  isControlHasError(fieldName:string):boolean {
    let formGroup:FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}
