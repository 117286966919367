import { Component, Input } from '@angular/core';

import { PermitFee, PermitItem } from '../../../config';

@Component({
  selector: 'permit-item-list',
  templateUrl: './list.component.html'
})
export class PermitItemListComponent {
  @Input()
  items:PermitItem[] = [];

  constructor() {
  }
}