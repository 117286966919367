import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PermitBase } from './permit.base.component';
import { PermitRemark } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './reval.component.html',
})
export class PermitRevalComponent extends PermitBase implements OnInit {
  permit:any;
  user_id:number;
  tasmac_bal:number = 0;
  vStatus:any;

  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];

  constructor(
    private fb: FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.user_id = data.id;
    }

    this.route.data.subscribe((pdata:any) => {
      // Data from resolver
      let data = pdata.permit;
      // Disect permit data
      // this.populatePermitData(data);
      this.permit = data;
      this.permit.req_date = new Date(this.permit.req_date);
      this.permit.permit_date = new Date(this.permit.permit_date);
      this.permit.valid_date = new Date(this.permit.valid_date);
      if (this.permit.reval_date)
        this.permit.reval_date = new Date(this.permit.reval_date);
      if (this.permit.is_revalidated && !this.permit.extend_period)
      {
        this.permit.extend_period = this.permit.period;
        this.permit.reval_date = new Date(this.permit.valid_date);
        this.permit.reval_date.setDate(this.permit.reval_date.getDate() + this.permit.period);
      }
      this.dist_fees(this.permit.fees);
      this.dist_pay(this.permit.pay);
      this.permit.remarks = this.permit.remarks.map((r:PermitRemark) => {
        r.rem_date = new Date(r.rem_date);
        return r;
      });
      this.addNewRemark();

      this.buildForm();
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['reval_reason'] = [
      this.permit.reval_reason,
      [
        Validators.required
      ]
    ];
    this.errors['reval_reason'] = [];
    this.messages['reval_reason'] = {
      'required': 'Reason is required.',
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged());
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }
  
  onValueChanged() {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }

  addNewRemark() {
    let nr = new PermitRemark(this.permit.id, this.user_id);
    nr.isEdit = true;
    this.permit.remarks.push(nr);
  }

  onRemarksSaved(rem:PermitRemark) {
    rem.isEdit = false;
    this.addNewRemark();
  }

  initiate_reval() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    let sValue = this.fg.value;
    if (this.fg.valid) {
      let rev_data = { id: this.permit.id, is_revalidated: 1, reval_reason: sValue.reval_reason };
      this.loading = true;
      this.dataService.initiate_permit_reval(rev_data).subscribe((data:any) => {
        if (data.message == "Permit Revalidation initiated successfully.")
        {
          this.permit.is_revalidated = 1;
          this.permit.reval_reason = sValue.reval_reason;
          this.permit.reval_date = new Date(data.reval_date);
          this.permit.extend_period = data.extend_period;
          // (data.fees as Array<any>).forEach(f => {
          //   (this.permit.fees as Array<any>).push(f);
          // });
          this.permit.fees = data.fees;
          this.dist_fees(this.permit.fees);

          this.permit.pay = data.pay;
          this.dist_pay(this.permit.pay);
          // this.fg.reset({'remarks': this.lvf.remarks});
        }
        this.loading = false;
      });
    }
  }

  goBack() {
    this.router.navigate(["/permit_list", "R"]);
  }

  onBalChange(pay_to:string, balance:number) {
    // 'TASMAC', $event
    if (pay_to == "TASMAC")
      this.tasmac_bal = balance?balance:0;
  }

  verify() {
    this.vStatus = {
      supplier: this.permit.sup && this.permit.sup.id > 0,
      warehouse: this.permit.war && this.permit.war.id > 0,
      wh_ro: this.permit.wh_ro && this.permit.wh_ro.length > 0,
      extend_period: this.permit && this.permit.extend_period > 0,
      items: this.permit.items && this.permit.items.length > 0,
      tasmac_bal: this.tasmac_bal <= 0, // Beware of logic
      reval_reason: this.permit.reval_reason && this.permit.reval_reason.length > 0
    };
    let result = true;
    Object.keys(this.vStatus).forEach(key => {
      if (result && !this.vStatus[key])
        result = false;
    });
    if (result)
    {
      this.submitted = true;
      this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
      let sValue = this.fg.value;
      let level = 0;
      if (localStorage.getItem('currentUser')) {
        let data = JSON.parse(localStorage.getItem('currentUser'));
        level = data.level + 1;
      }
      if (this.fg.valid && confirm('Are you sure to submit this revalidate request?')) {
        let rev_data = {
          id: this.permit.id,
          user_id: this.user_id,
          is_revalidated: 1,
          reval_reason: sValue.reval_reason,
          'status': 'REV',
          'level': level
        };
        this.loading = true;
        this.dataService.updatePermitStatus(rev_data).subscribe((data:any) => {
          if (data.message == "Permit Request submitted for Revalidation successfully.")
          {
            this.goBack();
          }
          this.loading = false;
        });
      }
    }
  }

}