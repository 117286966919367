import { Component, OnInit, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { Payment, User } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './add.component.html',
})
export class PaymentComponent implements OnInit {
  user: User;
  // General
  loading = false;
  fee_type: string;
  pay_to: string;
  head_of_account: string;
  head_of_account_det: string;
  payment: Payment;

  constructor(
    private router:Router,
    private dataService:DataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.user = this.dataService.getCurrentUser();

    if (this.dataService.filters.add_payment) {
      this.payment = this.dataService.filters.add_payment;
      if (this.payment.trnx_to === 'CPE') {
        this.fee_type = "Special Fee";
        this.pay_to = "Government of Tamil Nadu";
        this.head_of_account = "003900800AO1531";
        this.head_of_account_det = "0039-00-State Excise-800-other Receipts-AO-Special fees on liquor imported from other states and from other Countries";
      }
      else {
        this.fee_type = "TASMAC Fee";
        this.pay_to = "Tamil Nadu State Marketing Corporation Limited, Chennai.";
        this.head_of_account = null;
        this.head_of_account_det = null;
      }
    } else {
      this.router.navigate(['/home']);
    }
  }

  addElement() {

  }

  makeOnlinePayment() {
    this.loading = true;

    const href = Location.joinWithSlash(location.href.split('#', 1)[0], '/svr/payment_status.php');
    this.payment.return_url = this.sanitizer.sanitize(SecurityContext.URL, href);
    
    this.dataService.saveOnlinePrePayment(this.payment).subscribe((data:any) => {
      if (data.message == "Payment saved successfully.")
      {
        if (data.id)
          this.payment.id = data.id;
        if (data.trnx_no_own)
        this.payment.trnx_no_own = data.trnx_no_own;

        // console.log(data);
        
        if (data.is_ifhrms_gw) {
          const frmIFHRMS = document.createElement('form');
          frmIFHRMS.name = data.ifhrms_form_name;
          frmIFHRMS.action = this.sanitizer.sanitize(SecurityContext.URL, data.ifhrms_url);
          frmIFHRMS.method = "post";

          Object.keys(data.ifhrms_data).forEach(element => {
            
            if (element !== "acc_det") {
              const el = document.createElement("input");
              el.name = element;
              el.value = data.ifhrms_data[element];
              frmIFHRMS.appendChild(el);
            } else {
              data.ifhrms_data.acc_det.forEach((det: any) => {
                Object.keys(det).forEach(det_element => {
                  const el = document.createElement("input");
                  el.name = det_element;
                  el.value = det[det_element];
                  frmIFHRMS.appendChild(el);
                })
              })
            }

          });
          document.body.appendChild(frmIFHRMS);
          frmIFHRMS.submit();

        } else {
          const frmSBI = document.createElement("form");
          frmSBI.action = this.sanitizer.sanitize(SecurityContext.URL, data.sbi_payment_url);
          frmSBI.method = "post";
          
          const el = document.createElement("input");
          el.name = "encdata";
          el.value = data.encdata;
          frmSBI.appendChild(el);
          
          const el1 = document.createElement("input");
          el1.name = "merchant_code";
          el1.value = data.merchant_code;
          frmSBI.appendChild(el1);
          
          document.body.appendChild(frmSBI);
          frmSBI.submit();
        }
      }
      this.loading = false;
    });
  }

  goBack() {
    if (this.user.category_code === 'SUP') {
      this.router.navigate(['/sup-pmt-tm-req']);
    } else {
      if (this.payment.fee_stage === "O")
        this.router.navigate(["/new_permit", this.payment.permit_id, this.payment.customer_id]);
      if (this.payment.fee_stage === "R")
        this.router.navigate(["/permit_reval", this.payment.permit_id]);
    }
  }

  public cancel() {
    this.goBack();
  }
}
