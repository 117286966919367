import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { FinYear, ItemTasmacAsso, Supplier } from '../../config';

@Component({
  templateUrl: './tasmac-brands.component.html'
})
export class TasmacBrandsComponent implements OnInit {
  finYears: FinYear[] = [];
  finYear: string;
  supGroups: Supplier[] = [];
  filters: any = {};
  itemTasmacAssoList: ItemTasmacAsso[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.getFinYears({ }).subscribe((data: FinYear[]) => {
      this.finYears = data;
      this.finYear = (this.finYears.length > 0)?this.finYears[this.finYears.length -1].fin_year:'2018-2019';

      this.getItemTasmacAssoList();
    });
  }

  getItemTasmacAssoList() {
    this.filters.fin_year = this.finYear;
    this.dataService.getItemTasmacAssoList(this.filters).subscribe((data: ItemTasmacAsso[]) => {
      this.itemTasmacAssoList = data;
    });
  }

  action(ita: ItemTasmacAsso) {
    this.dataService.filters.tasmac_brand_authorization = { supplier_id: ita.supplier_id, fin_year: ita.fin_year };
    this.router.navigate(['/tasmac-brand-authorization']);
  }
}