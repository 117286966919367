import { Injectable } from '@angular/core';
import { HttpEvent, HttpHeaders, HttpInterceptor, HTTP_INTERCEPTORS, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AlertService } from './alert/alert.service';

@Injectable()
export class AppResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertService:AlertService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ret = next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.headers)
          {
            let headers:HttpHeaders = event.headers;
            if (headers.get('x-token'))
            {
              let newToken = JSON.stringify(headers.get('x-token'));
              localStorage.setItem("authorizationData", newToken);
            }
          }
  
          // TODO::integrate with angular material toster
          // if (event.headers.get('Content-Type') && event.headers.get('Content-Type') === 'application/json')
          // {
          //   let data = event.body;
          //   if (data.message)
          //     this.alertService.error(data.message, true);
          // }
        }
        return event;
      }),
      catchError(err => {
        console.log(err);
        if (err instanceof HttpErrorResponse)
        {
          if (err.status === 401)
          {
            let message = "Unknown Server Error. Please Login again.";
            
            if (typeof err.error === "string")
              message = err.error;

            this.alertService.error(message, true);
            
            this.router.navigate(["/login"], { queryParams: { errMessage: message } });
            // JWT expired, go to login
            return throwError(err); // Observable.throw(err);
          }
  
          if (err.status < 200 || err.status > 299)
          {
            console.log("Server Responded Error");
            return throwError(err); // Observable.throw(err);
          }
        }

        return throwError(err); // Observable.throw(err);
      })
    );
    // .catch(err => {
    //     if (err instanceof HttpErrorResponse)
    //     {
    //       if (err.status === 401)
    //       {
    //         let message = "Unknown Server Error. Please Login again.";
            
    //         if (typeof err.error === "string")
    //           message = err.error;

    //         this.alertService.error(message, true);
            
    //         this.router.navigate(["/login"], { queryParams: { errMessage: message } });
    //         // JWT expired, go to login
    //         return Observable.throw(err);
    //       }
  
    //       if (err.status < 200 || err.status > 299)
    //       {
    //         return Observable.throw(err);
    //       }
    //     }

    //     return Observable.throw(err);
    //   });

    return ret;
  }
}

export const responseOptionsProvider = { 
  provide: HTTP_INTERCEPTORS,
  useClass: AppResponseInterceptor,
  multi: true
};
