import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'table-pagination',
  templateUrl: 'table-pagination.component.html'
})
export class TablePaginationComponent {
  // implements OnChanges
  @Input('totalItems')
  totalItems: number = 0;
  
  // Page Size Related
  @Output()
  pagination: EventEmitter<{pageSize:number, page: number}> = new EventEmitter<{pageSize:number, page: number}>();

  itemsPerPage: number = 100;
  currentPage: number = 1;

  // // Page Size Related
  // @Output('pageSizeChange')
  // pageSizeChangeEmitter: EventEmitter<number> = new EventEmitter<number>();
  
  @Input('pageSize')
  set setPageSizeValue(value:number) {
    this.itemsPerPage = value;
  }

  onPageSizeChange(val:number) {
    if (val !== this.itemsPerPage)
    {
      this.itemsPerPage = val;
      this.pagination.emit({ pageSize: this.itemsPerPage, page: this.currentPage });
    }
  }
    
  // // current Page Related
  // @Output('pageChange')
  // pageChangeEmitter: EventEmitter<number> = new EventEmitter<number>();
  
  @Input('page')
  set setPageValue(value:number) {
    this.currentPage = value;
  }

  onPageChange(val:number) {
    if (val !== this.currentPage)
    {
      this.currentPage = val;
      this.pagination.emit({ pageSize: this.itemsPerPage, page: this.currentPage });
    }
  }

  // total items Related

  // ngOnChanges(changes:SimpleChanges|any) {
  //   if (changes.totalItems && (changes.totalItems as SimpleChange).currentValue) {
  //   }
  // }
}
