import { Component, Input, OnChanges, OnInit, SimpleChanges, SimpleChange, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-proforma-invoice-view',
  templateUrl: './view.component.html'
})
export class PermitProformaInvoiceViewComponent implements OnChanges, OnInit {
  @Input()
  proforma_storage_name:string;
  trustedUrl:SafeResourceUrl;

  constructor(private dataService:DataService, private sanitizer: DomSanitizer) {  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes.proforma_storage_name && (changes.proforma_storage_name as SimpleChange).currentValue) {
      const storage_name = (changes.proforma_storage_name as SimpleChange).currentValue;
      this.dataService.getUploadedPdf(storage_name).subscribe((blob:Blob) => {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      });
    }
  }

  @ViewChild('lgModal0', { static: false }) 
  public lgModal0:ModalDirective;
  
  show() {
    this.lgModal0.show();
  }
}