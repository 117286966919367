import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { License, Supplier } from '../../config';

interface SupplierActions {
  allowAdd:boolean;
  allowEdit:boolean;
  allowDelete:boolean;
};

@Component({
  templateUrl: 'create.component.html'
})
export class SupplierComponent implements OnInit {
  supplier:Supplier;
  lics:License[] = [];
  currentUser:any = {};
  actions:SupplierActions = {
    allowAdd: false,
    allowEdit: false,
    allowDelete: false
  };

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.supplier = new Supplier();
    this.supplier.pr_cnt = 0;
    this.markAllowedActions(this.currentUser);

    this.route.params.subscribe((params:any) => {
      if (params.id && +params.id > 0)
        this.getSupplier(+params.id);
    });
  }

  markAllowedActions(data:any) {
    this.actions.allowAdd = (data.category_code === 'PSEC' || (this.supplier.hasOwnProperty('req_customer_id') && data.hasOwnProperty('customer_id') && this.supplier.req_customer_id === data.customer_id && this.supplier.status === 'REQ'));
    this.actions.allowEdit = (data.category_code === 'PSEC' || (this.supplier.hasOwnProperty('req_customer_id') && data.hasOwnProperty('customer_id') && this.supplier.req_customer_id === data.customer_id && this.supplier.status === 'REQ'));
    this.actions.allowDelete = (data.category_code === 'PSEC' || (this.supplier.hasOwnProperty('req_customer_id') && data.hasOwnProperty('customer_id') && this.supplier.req_customer_id === data.customer_id && this.supplier.status === 'REQ'));
  }

  getSupplier(id:number) {
    const filter = { id: id };
    this.dataService.getSupplierDetails(filter).subscribe((data:any) => {
      this.supplier = data.sup;
      this.lics = data.lics;
      this.lics = this.lics.map(l => {
        l.iss_date = new Date(l.iss_date);
        l.val_date = new Date(l.val_date);
        return l;
      });
      this.markAllowedActions(this.currentUser);
    });
  }

  get_lics(type:string|string[], name:string) {
    if (typeof type === "string")
      return this.lics.filter(l => { return l.type == type && l.name == name; });
    else
      return this.lics.filter(l => { return (type.indexOf(l.type) !== -1) && l.name == name; });
  }

  onSupplierChanged(s:Supplier) {
    this.supplier = s;
  }

  licenseSaved(lic:License) {
    lic.cnt = 0;
    this.lics.push(lic);
  }

  licenseDeleted(lic:License) {
    let idx = -1;
    this.lics.forEach((e:License, index:number) => {
      if (e.id == lic.id)
        idx = index;
    });
    if (idx > -1)
      this.lics.splice(idx, 1);
  }

  goBack() {
    this.router.navigate(["/suppliers"]);
  }
}