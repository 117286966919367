import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../data.service';
import { Config, User } from '../../config';

@Component({
  templateUrl: './users.component.html'
})
export class SupplierUsersComponent implements OnInit {
  filters: any = { category_code: 'SUP' };
  users: User[] = [];

  constructor(
    private router: Router,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.dataService.getSupplierUsers(this.filters).subscribe((data: User[]) => {
      this.users = data;
    });
  }

  showSupUser(u: User) {
    this.dataService.filters.sup_user = u;
    this.router.navigate(['/sup-user']);
  }
}
