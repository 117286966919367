import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { PermitBase } from './permit.base.component';
import { License, Permit, PermitDoc, PermitFee, PermitItem, PermitRemark, Supplier, Warehouse, PermitRoute } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './new.component.html',
})
export class NewPermitComponent extends PermitBase implements OnInit {
  private user_id:number;
  lics: License[] = [];
  supplier: Supplier;
  warehouse: Warehouse;
  permitRoute: PermitRoute[];
  items: PermitItem[] = [];
  is_paid = false;
  new_pi: PermitItem;
  edit_pi: PermitItem;
  remarks: PermitRemark[] = [];
  // fees:PermitFee[] = [];
  // pay:any = [];
  cpe_bal: number;
  tasmac_bal: number;
  vStatus:{ [index:string]: boolean };//any;

  loading = false;
  permit:Permit;

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.id)
        this.user_id = data.id;
    }
    this.permit = new Permit(0);
    
    this.route.data.subscribe((pdata: any) => {
      // Data from resolver
      let data = pdata.permit;
      if (data.message) {
        this.router.navigate(['/request-creation-blocked']);
      } else {
        // Disect permit data
        this.populatePermitData(data);
      }
    });
  }

  onBalChange(type:string, bal:number) {
    if (type == 'CPE')
    {
      this.cpe_bal = bal;
      if (this.vStatus && this.vStatus.hasOwnProperty('cpe_bal'))
        (this.vStatus as { [index:string]: boolean })['cpe_bal'] = (this.cpe_bal <= 0);
    }
    else
    {
      this.tasmac_bal = bal;
      if (this.vStatus && this.vStatus.hasOwnProperty('tasmac_bal'))
        (this.vStatus as { [index:string]: boolean })['tasmac_bal'] = (this.tasmac_bal <= 0);
    }
  }

  private update_vStatus() {
    this.vStatus = {
      lic_o_fl: this.lic_o_fl && this.lic_o_fl.id > 0,
      lic_r_fl: (this.check_original_valid(this.lic_o_fl) || (this.lic_r_fl && this.lic_r_fl.id > 0)),
      lic_o_fs: this.lic_o_fs && this.lic_o_fs.id > 0,
      lic_r_fs: (this.check_original_valid(this.lic_o_fs) || (this.lic_r_fs && this.lic_r_fs.id > 0)),
      supplier: this.supplier && this.supplier.id > 0,
      sup_o_il: this.sup_o_il && this.sup_o_il.id > 0,
      // sup_r_il: this.sup_r_il && this.sup_r_il.id > 0,
      sup_o_fs: this.sup_o_fs && this.sup_o_fs.id > 0,
      sup_r_fs: (this.check_original_valid(this.sup_o_fs) || (this.sup_r_fs && this.sup_r_fs.id > 0)),
      warehouse: this.warehouse && this.warehouse.id > 0,
      war_o_il: this.war_o_il && this.war_o_il.id > 0,
      war_r_il: (this.check_original_valid(this.war_o_il) || (this.war_r_il && this.war_r_il.id > 0)),
      war_o_fs: this.war_o_fs && this.war_o_fs.id > 0,
      war_r_fs: (this.check_original_valid(this.war_o_fs) || (this.war_r_fs && this.war_r_fs.id > 0)),
      storage: this.warehouse && this.warehouse.id > 0 && ((this.warehouse.owned_type == 'PRIVATE') || (this.warehouse.owned_type == 'PUBLIC' && this.slc_doc && this.slc_doc.id > 0)),
      route: this.permitRoute && this.permitRoute.length > 0 && this.permitRoute[0].id > 0,
      period: this.permit && this.permit.period > 0,
      items: this.items && this.items.length > 0,
      proforma: this.permit && this.permit.proforma_doc_id > 0,
      cpe_bal: this.cpe_bal <= 0, // Beware of logic
      tasmac_bal: this.tasmac_bal <= 0, // Beware of logic
    };
  }

  verify() {
    this.update_vStatus();
    let result = true;
    Object.keys(this.vStatus).forEach(key => {
      if (result && !this.vStatus[key])
        result = false;
    });
    if (result && confirm("Are you sure to submit this permit request?"))
    {
      this.loading = true;
      let level = 0;
      if (localStorage.getItem('currentUser'))
      {
        let data = JSON.parse(localStorage.getItem('currentUser'));
        level = data.level + 1;
      }
      let status_data = { 'id': this.permit.id, user_id: this.user_id, 'status': 'REQ', 'level': level };
      this.dataService.updatePermitStatus(status_data).subscribe((data:any) => {
        if (data.message == "Permit Request submitted successfully.")
        {
          this.router.navigate(["/permit_submitted", this.permit.id, this.permit.customer_id, data.req_no, data.req_dt]);
        }
        this.loading = false;
      });
    }
  }

  licenseSaved(l:License) {
    // this.lics.push(l);
    // this.dist_licenses();
    // Check for existing license and validity for the license before adding it to permit
    this.savePermit();
  }

  licenseDeleted(l:License) {
    this.savePermit();
  }

  private populatePermitData(data:any) {
    this.permit = data.permit;
    this.lics = data.lics;
    this.lics = this.lics.map((l:License) => {
      l.iss_date = new Date(l.iss_date);
      l.val_date = new Date(l.val_date);
      return l;
    });
    this.dist_licenses(this.lics);

    this.supplier = (data.sup)?data.sup:null;
 
    this.warehouse = (data.war)?data.war:null;

    this.pDocs = this.dist_docs(data.docs);

    if (data.route && data.route.length > 0)
      this.permitRoute = data.route;
    else
      this.permitRoute = [new PermitRoute(this.permit.id)];
    
    if (data.items && data.items.length > 0)
    {
      this.items = data.items.map((i:PermitItem) => {
        let pi = new PermitItem(i.permit_id);
        pi.id = i.id;
        pi.item_id = i.item_id;
        pi.vol = i.vol;
        pi.qty_case = i.qty_case;
        pi.btl_per_case = i.btl_per_case;
        pi.qty_btl = i.qty_btl;
        pi.rate_btl = i.rate_btl;
        pi.bl = i.bl;
        pi.pl_pc = i.pl_pc;
        pi.pl = i.pl;
        pi.spf_bl_rate = i.spf_bl_rate;
        pi.spf_pl_rate = i.spf_pl_rate;
        pi.spf = i.spf;
        pi.apf_rate = i.apf_rate;
        pi.apf = i.apf;
        pi.el_rate = i.el_rate;
        pi.el = i.el;
        pi.value = i.value;
        // Extended
        pi.item_cat_id = i.item_cat_id;
        pi.item_cat_name = i.item_cat_name;
        pi.item_name = i.item_name;
        pi.origin_country_id = i.origin_country_id;
        pi.con_name = i.con_name;
        pi.is_edit_mode = false;
        return pi;
      });
      this.new_pi = new PermitItem(this.permit.id);
      // this.items.push(new PermitItem(this.permit.id));
    }
    else
    {
      // this.items = [];
      this.new_pi = new PermitItem(this.permit.id);
    }

    if (data.fees && data.fees.length > 0)
      this.dist_fees(data.fees);

    if (data.pay && data.pay.length > 0)
      this.is_paid = this.dist_pay(data.pay);
    else
      this.is_paid = false;
    
    if (data.remarks && data.remarks.length > 0)
    {
      this.remarks = data.remarks.map((r:PermitRemark) => {
        r.rem_date = new Date(r.rem_date);
        return r;
      });
    }
    else
      this.remarks = [];
    // let nr = new PermitRemark(this.permit.id, this.user_id);
    // nr.isEdit = true;
    // this.remarks.push(nr);
    this.addNewRemark();
  }

  savePermit() {
    this.loading = true;
    this.dataService.savePermit(this.permit).subscribe((data:any) => {
      if (data.message == "Permit Request saved successfully.")
      {
        this.populatePermitData(data);
        this.update_vStatus();
      }
      this.loading = false;
    });
  }

  onSupplierChanged(s:Supplier) {
    if (s)
    {
      this.permit.supplier_id = s.id;
      this.permit.warehouse_id = null;
      this.savePermit();
    }
    else
    {
      this.permit.supplier_id = null;
      this.permit.warehouse_id = null;
      this.savePermit();
    }
  }

  onAddNewSupplier() {
    this.supplier = new Supplier();
    this.supplier.pr_cnt = 0;
    this.supplier.req_customer_id = this.permit.customer_id;
  }

  onWarehouseChanged(w:Warehouse) {
    if (w)
    {
      this.permit.warehouse_id = w.id;
      this.permit.period = w.period;
      this.savePermit();
    }
    else
    {
      this.permit.warehouse_id = null;
      this.permit.period = null;
      this.savePermit();
    }
  }
  
  onAddNewWarehouse() {
    this.warehouse = new Warehouse();
    this.warehouse.pr_cnt = 0;
    this.warehouse.req_customer_id = this.permit.customer_id;
  }

  docSaved(pd:PermitDoc) {
    this.savePermit();
  }

  docDeleted(pd:PermitDoc) {
    this.savePermit();
  }

  // onWarehouseSpaceLeaseSaved(data:any) {
  //   this.savePermit();
  // }

  onPermitRouteSaved(data:any) {
    this.update_vStatus();
  }

  isItemEditable():boolean {
    return !this.is_paid && this.permit.id && this.permit.id > 0 && this.permit.supplier_id && this.permit.supplier_id > 0 && !!this.new_pi;
  }

  onPermitItemSaved(data:{pi:PermitItem, fees:PermitFee[], pay:any}, index:number) {
    let pi = data.pi;
    pi.is_edit_mode = false;
    if (index == -1)
    {
      this.items.push(pi);
      this.new_pi = new PermitItem(this.permit.id);
    }
    else
      this.items[index] = pi;
    this.dist_fees(data.fees);
    this.dist_pay(data.pay);
    this.edit_pi = null;
    this.update_vStatus();
  }

  onPermitItemCancelled(value:boolean, index:number) {
    this.items[index].is_edit_mode = !value;
    this.edit_pi = null;
  }

  onPermitItemDeleted(pi:PermitItem, i:number) {
    if (confirm("Are you sure to delete?"))
    {
      let del_data = { id: pi.id, permit_id: pi.permit_id };
      this.dataService.deletePermitItem(del_data).subscribe((data:any) => {
        if (data.message == "Permit Brand deleted successfully.")
        {
          this.items.splice(i, 1);
          this.dist_fees(data.fees);
          this.dist_pay(data.pay);
          this.update_vStatus();
        }
      });
    }
  }

  change_edit_mode(pi:PermitItem) {
    pi.is_edit_mode = true;
    this.edit_pi = Object.assign(new PermitItem(this.permit.id), pi);
  }

  onProformaInvoiceSaved(data:any) {
    this.permit.proforma_doc_id = data.proforma_doc_id;
    this.permit.proforma_storage_name = data.proforma_storage_name;
    this.update_vStatus();
  }

  deleteProformaInvoice() {
    let reqData = {
      'doc_id': this.permit.proforma_doc_id,
      'id': this.permit.id
    };
    this.dataService.delProformaInvoice(reqData).subscribe((data:any) => {
      if (data.message == "Proforma Invoice deleted successfully.")
      {
        this.permit.proforma_doc_id = null;
        this.permit.proforma_storage_name = null;
        this.update_vStatus();
      }
    });
  }

  addNewRemark() {
    let nr = new PermitRemark(this.permit.id, this.user_id);
    nr.isEdit = true;
    this.remarks.push(nr);
  }

  onRemarksSaved(rem:PermitRemark) {
    rem.isEdit = false;
    this.addNewRemark();
  }

  print() {
    this.loading = true;
    this.dataService.printPermitRequest({ 'id': this.permit.id, 'is_preview': true }).subscribe((blob:Blob) => {
      // let blob = new Blob([response.blob()], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }
}
