import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { DataService } from '../../data.service';

@Component({
  templateUrl: './print.component.html'
})
export class PermitPrintComponent implements OnInit {
  filter: any;
  loading = true;
  id:number;
  trustedUrl:SafeResourceUrl;

  constructor(
    private router:Router,
    private dataService:DataService,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit() {
    this.filter = {};
    if (this.dataService.filters.permit_print) {
      this.filter.id = this.dataService.filters.permit_print.id;
      this.getPermitDoc();
    } else {
      this.router.navigate(['/home']);
    }
  }

  getPermitDoc() {
    this.loading = true;
    this.dataService.printPermit(this.filter).subscribe((blob:Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.loading = false;
    });
  }

  updatePermitPrintMarked() {
    this.loading = true;
    this.dataService.markAsPermitPrinted(this.filter).subscribe((data:any) => {
      this.loading = false;
      if (data.message === 'Permit marked as printed successfully.') {
        this.goBack();
      }
    });
    // alert(data.message);
    // alert("Mark as Print is suspended for time being.");
  }

  sendPermitMails() {
    this.loading = true;
    this.dataService.sendPermitMails(this.filter).subscribe((data:any) => {
      if (data.message === 'eMails send successfully')
        alert(data.message);
      this.loading = false;
    });
  }

  regenerate() {
    this.trustedUrl = null;
    this.loading = true;
    this.dataService.regeneratePermit(this.filter).subscribe((blob:Blob) => {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        this.loading = false;
      });
  }

  goBack() {
    this.router.navigate(["/permits_to_be_printed"]);
  }
}
