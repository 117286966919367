import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Renderer, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Country, Item, ItemCat, PermitItem } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-item-edit',
  templateUrl: './edit.component.html'
})
export class PermitItemEditComponent implements OnChanges, OnInit {

  @Input()
  supplier_id: number;

  @Input()
  permititem: PermitItem;

  @Input()
  is_domestic: boolean;

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();
  
  @Output()
  cancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<Item[]>;

  itemcats:ItemCat[] = [];
  // items:Item[] = [];
  // cons:Country[] = [];
  submitted = false;
  loading = false;
  fg: FormGroup;

  @ViewChild('qty_btl', { static: false }) qty_btlElement: ElementRef;
  @ViewChild('item_name', { static: false }) item_nameElement: ElementRef;

  constructor(
    private fb: FormBuilder,
    private dataService:DataService,
    private renderer: Renderer
  ) { }

    // if (localStorage.getItem('currentUser').length > 0)
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   if (data.customer_id)
    //     this.customer_id = data.customer_id;
    // }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.permititem.currentValue)
    {
      this.reset(this.permititem);
    }
  }

  ngOnInit() {
    this.dataService.getItemCats({}).subscribe((data:ItemCat[]) => { this.itemcats = data; });
    // this.dataService.getCountries({}).subscribe((data:Country[]) => { this.cons = data; });
    // if (this.items.length == 0)
    //   this.items.push(new PermitItem());
    // if (!this.permititem)
    // {
    //   this.permititem = new PermitItem(this.permit_id);

    // }
    this.buildForm();
    let frmControls:any = this.fg.controls;
    this.dataSource = Observable.create((observer: any) => {
        // Runs on every search
        observer.next(frmControls.item_name.value);
    }).pipe(
      mergeMap((token: string) => {
        let filter:any = { 'async': true, 'name': token, 'limit': 25 };
        if (frmControls.item_cat_id.value)
          filter.item_cat_id = frmControls.item_cat_id.value;
        if (frmControls.origin_country_id.value)
          filter.origin_country_id = frmControls.origin_country_id.value;
        if (this.is_domestic)
          filter.is_domestic = this.is_domestic;
        if (this.supplier_id)
          filter.supplier_id = this.supplier_id;
        return this.dataService.getItems(filter);
      })
    );
  }
  // this.getStatesAsObservable(token)

  private buildForm(): void {
    let config:any = {};

    config['item_cat_id'] = [
      this.permititem.item_cat_id,
      [
        Validators.required
      ]
    ];

    config['origin_country_id'] = [
      this.permititem.origin_country_id,
      [
        Validators.required
      ]
    ];
  
    config['item_id'] = [
      this.permititem.item_id,
      [
        Validators.required
      ]
    ];
  
    config['item_name'] = [
      this.permititem.item_name,
      [
        Validators.required
      ]
    ];
  
    config['qty_btl'] = [
      this.permititem.qty_btl,
      [
        Validators.required
      ]
    ];
  
    // config['rate_btl'] = [
    //   this.permititem.rate_btl,
    //   [
    //     Validators.required
    //   ]
    // ];

    this.fg = this.fb.group(config);
    // {
    //   items: this.fb.array( this.items.map(pi => { return this.initRoute(pi); }) )
    // });
    // this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  public changeTypeaheadLoading(e:boolean): void {
    this.typeaheadLoading = e;
  }
 
  public changeTypeaheadNoResults(e:boolean): void {
    this.typeaheadNoResults = e;
  }
 
  public typeaheadOnSelect(e:TypeaheadMatch): void {
    let frmControls:any = this.fg.controls;
    if (e.item)
    {
      const item:Item = e.item as Item;
      this.permititem.item_cat_id = item.item_cat_id;
      this.permititem.item_cat_name = item.cat_name;
      this.permititem.origin_country_id = item.origin_country_id;
      this.permititem.con_name = item.con_name;
      this.permititem.item_id = item.id;
      this.permititem.item_name = item.name;
      this.permititem.vol = item.vol;
      this.permititem.btl_per_case = item.btl_per_case;
      frmControls.item_cat_id.setValue(item.item_cat_id);
      frmControls.origin_country_id.setValue(item.origin_country_id);
      frmControls.item_id.setValue(item.id);

      let ic:ItemCat = this.itemcats.filter(ic => { return ic.id == item.item_cat_id; })[0];
      this.permititem.pl_pc = ic.pl_pc;
      this.permititem.spf_bl_rate = ic.spf_bl_rate;
      this.permititem.spf_pl_rate = ic.spf_pl_rate;
      this.permititem.apf_rate = ic.apf_rate;
      this.permititem.el_rate = ic.el_rate;

      this.renderer.invokeElementMethod(this.qty_btlElement.nativeElement, 'focus');
      // this.permititem.calc_all();
    }
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid)
    {
      let sValue:PermitItem = this.fg.value;
      this.permititem.qty_btl = sValue.qty_btl;
      if (this.permititem.qty_btl <= 0)
      {
        alert("Quantity should be a positive number.");
        return;
      }
      // this.permititem.permit_id = this.permit_id;
      // this.permititem.rate_btl = sValue.rate_btl;
      this.permititem.calc_all();
      this.loading = true;
      this.dataService.savePermitItem(this.permititem).subscribe((data:any) => {
        if (data.message == "Permit Brand saved successfully.")
        {
          if (data.id)
            this.permititem.id = data.id;
          let fees:any = null;
          if (data.fees)
            fees = data.fees;
          let pay:any = null;
          if (data.pay)
            pay = data.pay;
          this.saved.emit({ 'pi': this.permititem, 'fees': fees, 'pay': pay });
          this.renderer.invokeElementMethod(this.item_nameElement.nativeElement, 'focus');
        }
        this.loading = false;
      });
    }
  }

  cancel() {
    if (this.permititem.id)
    {
      // this.permititem.is_edit_mode = false;
      this.cancelled.emit(true);
    }
    else
    {
      this.reset(new PermitItem(this.permititem.permit_id));
    }
  }

  reset(pi:PermitItem) {
    this.permititem = pi;
    if (this.fg)
    {
      this.fg.reset({
        'item_cat_id': this.permititem.item_cat_id,
        'origin_country_id': this.permititem.origin_country_id,
        'item_id': this.permititem.item_id,
        'item_name': this.permititem.item_name,
        'qty_btl': this.permititem.qty_btl
        // ,
        // 'rate_btl': this.permititem.rate_btl
      });
    }
    this.submitted = false;
    this.loading = false;
    this.typeaheadLoading = false;
    this.typeaheadNoResults = false;
  }

  public isControlHasError(fieldName:string):boolean {
    let formGroup:FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}
