import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { PermitBase } from './permit.base.component';
import { DateValidators } from '../../shared/validators/date.directive';
import { NumberValidator } from '../../shared/validators/number.directive';
import { Config } from '../../config';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './ext.component.html',
})
export class PermitExtComponent extends PermitBase implements OnInit {
  filter: any;
  permit: any;

  submitted = false;
  loading = false;
  fg: FormGroup;
  messages:any = [];
  trustedUrl:SafeResourceUrl;
  @ViewChild('extFile', { static: false }) public extFile:ElementRef;
  private file: any;
  private fileName: string;

  constructor(
    private router:Router,
    private fb: FormBuilder,
    private dataService:DataService,
    private alertService:AlertService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.filter = {};
    if (this.dataService.filters.permit_ext) {
      this.filter.id = this.dataService.filters.permit_ext.id;
      this.getPermit();
    } else {
      this.router.navigate(['/home']);
    }
  }

  getPermit() {
    this.dataService.getPermitToView(this.filter).subscribe((data:any) => {
      this.permit = data;
      this.permit.req_date = new Date(this.permit.req_date);
      if (this.permit.permit_date)
        this.permit.permit_date = new Date(this.permit.permit_date);
      if (this.permit.valid_date)
      {
        this.permit.valid_date = new Date(this.permit.valid_date);
        this.permit.maxValidDt = new Date(this.permit.valid_date);
      }
      if (this.permit.reval_date)
      {
        this.permit.reval_date = new Date(this.permit.reval_date);
        this.permit.maxValidDt = new Date(this.permit.reval_date);
      }
      if (this.permit.cancel_date)
        this.permit.cancel_date = new Date(this.permit.cancel_date);

      if (this.permit.bdr_cross_date)
        this.permit.bdr_cross_date = new Date(this.permit.bdr_cross_date);
      if (this.permit.rpt_date)
        this.permit.rpt_date = new Date(this.permit.rpt_date);
      if (this.permit.ext_req_date)
        this.permit.ext_req_date = new Date(this.permit.ext_req_date);

      if (this.permit.ext_storage_name)
      {
        this.fileName = this.permit.ext_storage_name;
        this.dataService.getUploadedPdf(this.permit.ext_storage_name).subscribe((blob:Blob) => {
          var fileURL = URL.createObjectURL(blob);
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        });
      }

      if (this.extFile)
        this.extFile.nativeElement.value = null;

      this.buildForm();
    });
  }

  private buildForm(): void {
    let config:any = {};

    let dtStr:string = null;
    if (this.permit.bdr_cross_date)
      dtStr = (this.permit.bdr_cross_date as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    let dtbdr_crossMax:string = null;
      dtbdr_crossMax = (this.permit.permit_date as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    config['bdr_cross_date'] = [
      dtStr,
      [
        Validators.required,
        DateValidators.isDate,
        DateValidators.minDate(dtbdr_crossMax),
        DateValidators.maxDate(Config.now())
      ]
    ];
    this.messages['bdr_cross_date'] = {
      'required':      'Border Crossed Date is required.',
      'isDate':        'Border Crossed Date should be in DD/MM/YYYY format.',
      'minDate':       'Border Crossed Date should not be less than Permit Date (' + dtbdr_crossMax + ').',
      'maxDate':       'Border Crossed Date cannot be greater than today.'
    };
  
    dtStr = null;
    if (this.permit.rpt_date)
      dtStr = (this.permit.rpt_date as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    let dtMax:string = null;
      dtMax = (this.permit.maxValidDt as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    config['rpt_date'] = [
      dtStr,
      [
        Validators.required,
        DateValidators.isDate,
        DateValidators.minDate(dtMax),
        DateValidators.maxDate(Config.now())
      ]
    ];
    this.messages['rpt_date'] = {
      'required':      'Reported Date is required.',
      'isDate':        'Reported Date should be in DD/MM/YYYY format.',
      'minDate':       'Reported Date should not be less than valid Date (' + dtMax + ').',
      'maxDate':       'Reported Date cannot be greater than today.'
    };
    
    config['rpt_vno'] = [
      this.permit.rpt_vno,
      [
        Validators.required, 
        Validators.minLength(5), 
        Validators.maxLength(30)
      ]
    ];
    this.messages['rpt_vno'] = {
      'required':      'Reported Vehicle No. is required.',
      'minlength':     'Reported Vehicle No. must be at least 5 characters long.',
      'maxlength':     'Reported Vehicle No. cannot be more than 30 characters long.'
    };
  
    // dtStr = null;
    // if (this.permit.outward_date)
    //   dtStr = (this.permit.outward_date as Date).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });

    // config['outward_date'] = [
    //   dtStr,
    //   [
    //     Validators.required,
    //     DateValidators.isDate,
    //     DateValidators.minDate('inward_date'),
    //     DateValidators.maxDate(Config.now())
    //   ]
    // ];
    // this.messages['outward_date'] = {
    //   'required':      'Outward Date is required.',
    //   'isDate':        'Outward Date should be in DD/MM/YYYY format.',
    //   'minDate':       'Outward Date should not be less than Inward Date.',
    //   'maxDate':       'Outward Date cannot be greater than today.'
    // };
    
    config['ext_req_period'] = [
      this.permit.ext_req_period,
      [
        Validators.required,
        NumberValidator.min(1), 
        NumberValidator.max(30)
      ]
    ];
    this.messages['ext_req_period'] = {
      'required':'Period is required.',
      'min':     'Period must be greater than 0.',
      'max':     'Period should not be greater than 30 days.'
    };
    
    config['ext_req_remarks'] = [
      this.permit.ext_req_remarks,
      [
        Validators.maxLength(2000)
      ]
    ];
    this.messages['ext_req_remarks'] = {
      'maxlength':     'Extension Request Comments cannot be more than 2000 characters long.'
    };

    config['ext_req_doc'] = [
      this.fileName,
      [
      ]
    ];
    this.messages['ext_req_doc'] = {
    };

    this.fg = this.fb.group(config);
    // this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.file = fileInput.target.files[0];
      
      var fileURL = URL.createObjectURL(this.file);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

      this.fg.controls['ext_req_doc'].setValue("File Set");
    }
    else
    {
      this.fg.controls['ext_req_doc'].setValue(null);
      this.trustedUrl = null;
    }
  }

  removeDoc() {
    this.alertService.clearAlert();
    if (this.permit.ext_storage_name)
    {
      this.permit.ext_storage_name = null;
      this.trustedUrl = null;
    }
    if (this.file)
    {
      this.file = null;
      this.fg.controls['ext_req_doc'].setValue(null);
      this.extFile.nativeElement.value = null;
      this.trustedUrl = null;
    }
  }

  getControlErrors(fieldName:string|string[], formGroup:FormGroup = null):string[] {
    if (!formGroup) formGroup = this.fg;

    let fNames:string[] = [];
    if (typeof fieldName == "string")
      fNames.push(fieldName);
    else
      fNames = fieldName;

    let errs:string[] = [];
    fNames.forEach(fn => {
      if (formGroup.controls[fn].errors)
      {
        Object.keys(formGroup.controls[fn].errors).forEach(eName => {
          if (this.messages[fn][eName]) errs.push(this.messages[fn][eName]);
        });
      }
    });
    return errs;
  }
  
  isControlHasError(fieldName:string, formGroup:FormGroup = null):boolean {
    if (!formGroup) formGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }

  save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid)
    {
      let sValue = this.fg.value;
      sValue.id = this.permit.id;
      sValue.customer_id = this.permit.customer_id;
      this.loading = true;
      this.dataService.requestPermitExt(sValue, this.file).subscribe((data:any) => {
        if (data.message == "Permit Extension requested successfully.")
        {
          if (data.ext_req_doc_id)
            this.permit.ext_req_doc_id = data.ext_req_doc_id;
          if (data.ext_storage_name)
            this.permit.ext_storage_name = data.ext_storage_name;
          this.goBack();
        }
        else
        if (data.errorMessage)
          this.alertService.error(data.errorMessage);
        this.loading = false;
      });
    }
  }

  goBack() {
    this.router.navigate(['/permits_request_ext']);
  }
}
