import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { Config, User } from '../../config';

@Component({
  templateUrl: './view.component.html'
})
export class UsersComponent implements OnInit {
  filters: any = { category_code: ['TSEC', 'TSEH', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC'] };
  users: User[] = [];

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.dataService.getUsers(this.filters).subscribe((data: User[]) => {
      this.users = data;
      this.users = this.users.map(u => {
        u.category_name = Config.getUserCategoryDetails(u.category_code).name; 
        return u;
      });
    });
  }
}
