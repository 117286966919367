import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';

import { Config, License } from '../../config';
import { AlertService } from '../../shared/alert/alert.service';
import { DateValidators } from '../../shared/validators/date.directive';
import { DataService } from '../../data.service';

@Component({
  selector: 'license',
  templateUrl: 'license.component.html'
})
export class LicenseComponent implements OnInit {
  @Input('color')
  pnlStyle:string = "success";

  @Input()
  type:string = "R";
  
  @Input()
  name:string = 'FL2/FL3';
  
  @Input()
  for:string = "C"; // C - Customer, S - Supplier & W - Warehouse
  
  @Input('fid')
  id:number;

  @Output()
  saved: EventEmitter<License> = new EventEmitter<License>();

  settings:any = {
    'C': {
      'FL2/FL3': {
        'O': {
          'caption': 'FL2/FL3 Original License',
          'doc_caption': 'FL2/FL3 Original License (scanned in pdf format only)',
          'doc_hint': 'Upload the FL2/FL3 Original licence document.',
          'vdt_caption': 'Valid till'
        },
        'R': {
          'caption': 'FL2/FL3 License Renewal (Latest)',
          'doc_caption': 'FL2/FL3 License Renewal (scanned in pdf format only)',
          'doc_hint': 'Upload the latest FL2/FL3 licence renewal received from the CPE.',
          'vdt_caption': 'Valid till'
        },
        'A': {
          'caption': 'Applied for Renewal',
          'doc_caption': 'Copy of Application (scanned in pdf format only)',
          'doc_hint': 'Upload the copy of Application submitted for licence renewal.',
          'vdt_caption': 'Applied Date'
        }
      },
      'FSSAI': {
        'O': {
          'caption': 'FL2/FL3 FSSAI Original License',
          'doc_caption': 'FSSAI Original License (scanned in pdf format only)',
          'doc_hint': 'Upload the FSSAI Original licence document.',
          'vdt_caption': 'Valid till'
        },
        'R': {
          'caption': 'FL2/FL3 FSSAI License Renewal (Latest)',
          'doc_caption': 'FSSAI License Renewal (scanned in pdf format only)',
          'doc_hint': 'Upload the latest FSSAI licence renewal document.',
          'vdt_caption': 'Valid till'
        },
        'A': {
          'caption': 'Applied for Renewal',
          'doc_caption': 'Copy of Application (scanned in pdf format only)',
          'doc_hint': 'Upload the copy of Application submitted for licence renewal.',
          'vdt_caption': 'Applied Date'
        }
      }
    },
    "S": {
      'IMPORT': {
        'O': {
          'caption': 'Importer Exporter Code',
          'doc_caption': 'Importer Exporter Code Document (scanned in pdf format only)',
          'doc_hint': 'Upload the Importer Exporter Code document issued by Directorate General of Foreign Trade, Ministry of Commerce and Industry, Government of India.',
          'vdt_caption': 'Valid till'
        },
        'R': {
          'caption': 'Latest Import License (Renewal)',
          'doc_caption': 'Latest Import License (Renewal) (scanned in pdf format only)',
          'doc_hint': 'Upload the latest Import licence (Renewal).',
          'vdt_caption': 'Valid till'
        },
        'A': {
          'caption': 'Applied for Renewal',
          'doc_caption': 'Copy of Application (scanned in pdf format only)',
          'doc_hint': 'Upload the copy of Application submitted for licence renewal.',
          'vdt_caption': 'Applied Date'
        }
      },
      'FSSAI': {
        'O': {
          'caption': 'Original (Supplier) FSSAI License',
          'doc_caption': 'Original (Supplier) FSSAI License (scanned in pdf format only)',
          'doc_hint': 'Upload the Original FSSAI licence document of your supplier.',
          'vdt_caption': 'Valid till'
        },
        'R': {
          'caption': 'Latest (Supplier) FSSAI License (Renewal)',
          'doc_caption': 'Latest (Supplier) FSSAI License (Renewal) (scanned in pdf format only)',
          'doc_hint': 'Upload the Latest FSSAI licence (Renewal) document of your supplier.',
          'vdt_caption': 'Valid till'
        },
        'A': {
          'caption': 'Applied for Renewal',
          'doc_caption': 'Copy of Application (scanned in pdf format only)',
          'doc_hint': 'Upload the copy of Application submitted for licence renewal.',
          'vdt_caption': 'Applied Date'
        }
      }
    },
    "W": {
      'BNDWAR': {
        'O': {
          'caption': 'Original Bonded Warehouse License',
          'doc_caption': 'Original Bonded Warehouse License (scanned in pdf format only)',
          'doc_hint': 'Upload the Original Bonded Warehouse licence document.',
          'vdt_caption': 'Valid till'
        },
        'R': {
          'caption': 'Latest Bonded Warehouse License (Renewal)',
          'doc_caption': 'Latest Bonded Warehouse License (Renewal) (scanned in pdf format only)',
          'doc_hint': 'Upload the latest Bonded Warehouse licence (Renewal).',
          'vdt_caption': 'Valid till'
        },
        'A': {
          'caption': 'Applied for Renewal',
          'doc_caption': 'Copy of Application (scanned in pdf format only)',
          'doc_hint': 'Upload the copy of Application submitted for licence renewal.',
          'vdt_caption': 'Applied Date'
        }
      },
      'FSSAI': {
        'O': {
          'caption': 'Original (Bonded Warehouse) FSSAI License',
          'doc_caption': 'Original (Bonded Warehouse) FSSAI License (scanned in pdf format only)',
          'doc_hint': 'Upload the Original FSSAI licence document of your supplier.',
          'vdt_caption': 'Valid till'
        },
        'R': {
          'caption': 'Latest (Bonded Warehouse) FSSAI License (Renewal)',
          'doc_caption': 'Latest (Bonded Warehouse) FSSAI License (Renewal) (scanned in pdf format only)',
          'doc_hint': 'Upload the Latest FSSAI licence (Renewal) document of your supplier.',
          'vdt_caption': 'Valid till'
        },
        'A': {
          'caption': 'Applied for Renewal',
          'doc_caption': 'Copy of Application (scanned in pdf format only)',
          'doc_hint': 'Upload the copy of Application submitted for licence renewal.',
          'vdt_caption': 'Applied Date'
        }
      }
    }
  };
  caption:string;
  doc_caption:string;
  doc_hint:string;
  vdt_caption:string;

  @Input('keep_open')
  showPanel = false;

  @ViewChild('licFile', { static: false }) public licFile:ElementRef;
  submitted = false;
  loading = false;
  customer_id:number;
  license:License;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  private file: any;
  private fileName: string;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    private dataService:DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser').length > 0)
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.customer_id = data.customer_id;
    }
    this.init_vals();
    this.buildForm();
  }

  init_vals() {
    this.license = new License(this.type, this.name);
    if (this.customer_id)
      this.license.customer_id = this.customer_id;

    let myFor:any = this.settings[this.for];
    switch(this.for)
    {
      case "C":
        this.license.customer_id = this.id;
        break;
      case "S":
        this.license.supplier_id = this.id;
        break;
      case "W":
        this.license.warehouse_id = this.id;
        break;
    };
    // this.license.name = this.name;
    // this.license.type = this.type;
    
    let myName:any = myFor[this.name];
    let myType:any = myName[this.type];
    this.caption = myType.caption;
    this.doc_caption = myType.doc_caption;
    this.doc_hint = myType.doc_hint;
    this.vdt_caption = myType.vdt_caption;

  }

  private buildForm(): void {
    let config:any = {};
    let vdt_cap = "";
    let doc_cap = "";
    if (this.type != "A")
    {
      config['no'] = [
        this.license.no,
        [
          Validators.required, 
          Validators.minLength(5), 
          Validators.maxLength(30)
        ]
      ];
      this.errors['no'] = [];
      this.messages['no'] = {
        'required':      'License No. is required.',
        'minlength':     'License No. must be at least 5 characters long.',
        'maxlength':     'License No. cannot be more than 30 characters long.'
      };

      config['iss_date'] = [
        this.license.iss_date,
        [
          Validators.required,
          DateValidators.isDate,
          DateValidators.minDate('01/01/1900'),
          DateValidators.maxDate(Config.now())
        ]
      ];
      this.errors['iss_date'] = [];
      this.messages['iss_date'] = {
        'required':      'License Date is required.',
        'isDate':        'License Date should be in DD/MM/YYYY format.',
        'minDate':       'License Date should not be less than 01/01/1900.',
        'maxDate':       'License Date cannot be greater than today.'
      };

      vdt_cap = "Valid till";
      doc_cap = "FL2/FL3 License Renewal Document";
    }
    else
    {
      vdt_cap = "Applied Date";
      doc_cap = "Copy of Application Document";
    }

    if (this.name !== "IMPORT")
    {
      let val_date_validators = [
        Validators.required,
        DateValidators.isDate
      ]
      switch(this.type)
      {
        case "O":
          val_date_validators.push(DateValidators.minDate('iss_date'));
          break;
        case "R":
          val_date_validators.push(DateValidators.minDate(Config.now()));
          break;
        case "A":
          val_date_validators.push(DateValidators.maxDate(Config.now()));
    }

      config['val_date'] = [
        this.license.val_date,
        [
          ...val_date_validators
          // ,
          // DateValidators.maxDate(Config.oneYearFromNow())
        ]
      ];
      this.errors['val_date'] = [];
      this.messages['val_date'] = {
        'required':      vdt_cap + ' is required.',
        'isDate':        vdt_cap + ' should be in DD/MM/YYYY format.',
        'minDate':       vdt_cap + ' should not be less than License date' + ((this.type === "R")?' and should be valid as on today.':'.'),
        'maxDate':       vdt_cap + ' cannot be greater than today.'
      };
    }

    config['lic_doc'] = [
      this.fileName,
      [
        Validators.required
      ]
    ];
    this.errors['lic_doc'] = [];
    this.messages['lic_doc'] = {
      'required':      doc_cap + ' is required.'
    };
  
    this.fg = this.fb.group(config);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.file = fileInput.target.files[0];
      this.fg.controls['lic_doc'].setValue("File Set");
    // var reader = new FileReader();
    // reader.onload = function (e : any) {
    //   $('#preview').attr('src', e.target.result);
    // }
    // reader.readAsDataURL(fileInput.target.files[0]);
    }
    else
      this.fg.controls['lic_doc'].setValue(null);
  }
  
  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  show() {
    this.reset();
    // this.lgModal.show();
    this.showPanel = true;
  }

  reset() {
    this.alertService.clearAlert();
    this.init_vals();
    this.submitted = false;
    this.fileName = null;
    this.file = null;
    this.fg.reset({
      no: this.license.no, 
      iss_date: this.license.iss_date,
      val_date: this.license.val_date,
      lic_doc: this.fileName
    });
    this.fg.controls['lic_doc'].setValue(null);
    if (this.licFile)
      this.licFile.nativeElement.value = null;
    this.showPanel = false;
    return true;
  }

  save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid)
    {
      let sValue = this.fg.value;

      if (this.license.type != 'A')
        this.license.no = sValue.no;
      else
        this.license.no = 'N/A';

      if (sValue.iss_date)
        this.license.iss_date = sValue.iss_date;
      else
        this.license.iss_date = sValue.val_date;

      if (sValue.val_date)
        this.license.val_date = sValue.val_date;
      else
        this.license.val_date = sValue.iss_date;

      if (this.customer_id)
        this.license.customer_id = this.customer_id;

      this.loading = true;
      this.dataService.save_fl(this.license, this.file).subscribe((data:any) => {
        if (data.message == "License details saved successfully.")
        {
          if (data.license_id)
            this.license.id = data.license_id;
          if (data.license_doc_id)
            this.license.license_doc_id = data.license_doc_id;
          if (data.storage_name)
            this.license.storage_name = data.storage_name;

          // Add this.flLicense into fl_lics and clear/reset fgFL
          this.license.status = "YET";
          this.license.iss_date = Config.MakeDate(this.license.iss_date);
          this.license.val_date = Config.MakeDate(this.license.val_date);

          this.saved.emit(this.license);
          this.showPanel = false;
        }
        else if (data.errorMessage)
          this.alertService.error(data.errorMessage);

        this.loading = false;
      });
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}
