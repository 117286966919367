import { Component, OnInit, SecurityContext } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';

import { Permit } from '../../config';
import { DataService } from '../../data.service';
import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: './digital-signature.component.html'
})
export class DigitalSignatureComponent implements OnInit {
  loading = false;
  permit: Permit;
  trustedUrl: SafeResourceUrl;

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.dataService.filters.permit_approved) {
      this.permit = this.dataService.filters.permit_approved.permit;
      this.getImportPermitDoc();
    } else {
      this.router.navigate(['/home']);
    }
  }

  getImportPermitDoc() {
    const filter = { id: this.permit.id };
    this.loading = true;
    this.authenticationService.getImportPermitDoc(filter).subscribe(blob => {
      this.loading = false;
      const fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    });
  }

  download() {
    this.loading = true;
    if (this.trustedUrl) {
      const fileUrl = this.sanitizer.sanitize(SecurityContext.URL, this.trustedUrl);
      FileSaver.saveAs(fileUrl, this.permit.permit_no + ".pdf");
    }
    this.loading = false;
  }
  
  // this.errMsg = null;
  // fileChangeEvent(fileInput: any) {
  //   if (fileInput.target.files && fileInput.target.files[0]) {
  //     this.file = fileInput.target.files[0];
  //     if (this.file.size >= 2097152) { // 2Mb = 2*1024*1024 = 2097152
  //       alert('File size should not be greater than 2Mb.');
  //       this.removeDoc();
  //       return;
  //     }
  //     this.setBlob(this.file);
  //   } else {
  //     this.setBlob(null);
  //   }
  // }

  // setBlob(blob?: Blob) {
  //   if (blob) {
  //     var fileURL = URL.createObjectURL(blob);
  //     this.signedTrustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
  //   } else {
  //     this.signedTrustedUrl = null;
  //   }
  // }

  // removeDoc() {
  //   this.errMsg = null;
  //   this.file = null;
  //   if (this.docFile)
  //     this.docFile.nativeElement.value = null;
  //   this.setBlob(null);
  // }

  // uploadSignedPermit() {
  //   this.errMsg = null;
  //   if (this.signedTrustedUrl) {
  //     const data = new PermitDoc(this.permit.id, this.permit.customer_id, 'IP');

  //     if (localStorage.getItem('currentUser'))
  //     {
  //       let dataUser = JSON.parse(localStorage.getItem('currentUser'));
  //       data.user_id = dataUser.id;
  //     }
      
  //     this.loading = true;
  //     this.dataService.uploadSignedPermit(data, this.file).subscribe((data: any) => {
  //       this.loading = false;
  //       if (data.message == "Digital Signed Permit uploaded successfully.") {
  //         this.goBack();
  //       } else {
  //         this.errMsg = data.errorMessage;
  //       }
  //     });
  //   }
  // }

  goBack() {
    this.router.navigate([this.dataService.filters.permit_approved.back_url]);
  }
}
