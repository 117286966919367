import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/authguard/authguard';

import { ImportPermitViewResolver } from './components/login/resolver.service';
import { UserResolver } from './components/user/resolver.service';
import { CountryResolver } from './components/country/resolver.service';
import { DepotResolver } from './components/depot/resolver.service';
import { ItemCatResolver } from './components/item_cat/resolver.service';
import { ItemResolver } from './components/item/resolver.service';
import { CustomerResolver } from './components/customers/resolver.service';
import { NewPermitResolver, PermitRequestedResolver, PermitsResolver, PermitListResolver, PermitRevalResolver, PermitCancelResolver } from './components/permit/resolver.service';

import { LoginComponent } from './general/login/login.component';
import { ImportPermitViewComponent } from './components/login/ipview.component';
import { PublicViewPermitComponent } from './components/public-view-permit/public-view-permit.component';
import { ForgotPasswordComponent } from './components/login/forgot_pwd.component';
import { ResetPasswordComponent } from './general/reset-pwd/reset-pwd.component';
import { LicenseeSignupComponent } from './licensee/signup/signup.component';
import { LicenseeSignupSuccessComponent } from './licensee/signup-success/signup-success.component';
import { HomeComponent } from './components/home/home.component';
import { ChangePasswordComponent } from './general/change-pwd/change-pwd.component';
import { FailurePaymentComponent } from './components/payments/failed-payment.component';

// CUS
import { NewPermitComponent } from './components/permit/new.component';
import { PaymentComponent } from './components/payments/add.component';
import { PermitRequestedComponent } from './components/permit/req.component';
import { PermitListComponent } from './components/permit/list.component';
import { PermitViewComponent } from './components/permit/view.component';
import { PermitRevalComponent } from './components/permit/reval.component';
import { PermitCancelComponent } from './components/permit/cancel.component';

import { CusFLLicenseComponent } from './components/license/cus-fl-lic.component';
import { CusFSLicenseComponent } from './components/license/cus-fs-lic.component';

import { CusMyselfComponent } from './components/license/myself.component';

// To ALL ('TSEC', 'TACC', 'TMD', 'PSEC', 'PCOM')
import { PermitsComponent } from './components/permit/permits.component';
import { PermitVerifyComponent } from './components/permit/verify.component';
import { PermitApprovedComponent } from './components/permit/approved.component';
// import { PendingDigitalSignaturesComponent } from './components/pending-digital-signatures/pending-digital-signatures.component';
import { DigitalSignatureComponent } from './components/digital-signature/digital-signature.component';
import { PermitsApprovedComponent } from './components/permit/permits_approved.component';
import { PermitsToBePrintedComponent } from './components/permit/permits_to_be_printed.component';
import { PermitsExtRequestedComponent } from './components/permit/permits_ext_requested.component';
import { PermitsToBeLabelledComponent } from './components/permit/permits_to_be_labelled.component';
import { PermitsRequestExtComponent } from './components/permit/permits_request_ext.component';
import { PermitsDespatchedComponent } from './components/permit/permits_despatched.component';
import { PermitsCandRejComponent } from './components/permit/permits_cand_rej.component';
import { PermitLabelComponent } from './components/permit/label.component';
import { PermitExtComponent } from './components/permit/ext.component';
import { PermitPrintComponent } from './components/permit/print.component';
import { PermitEvalPrintComponent } from './components/permit-eval-print/permit-eval-print.component';
import { RequestCreationBlockedComponent } from './components/permit/request-creation-blocked/request-creation-blocked.component';

import { PeSectionCustomersComponent } from './components/customers/customers.component';

// import { BrandRegistrationSelectListComponent } from './components/brand_registration/select-list.component';
import { CustomerComponent } from './components/customers/customer.component';
import { UsersComponent } from './components/user/view.component';
import { UserComponent } from './components/user/new.component';
import { CountriesComponent } from './components/country/view.component';
import { CountryComponent } from './components/country/new.component';
import { StatesComponent } from './components/state/view.component';
import { StateComponent } from './components/state/new.component';
import { DepotsComponent } from './components/depot/view.component';
import { DepotComponent } from './components/depot/new.component';
import { BrandOwnersComponent } from './components/brand_owners/view.component';
import { BrandOwnerComponent } from './components/brand_owners/new.component';
import { BrandRegistrationsComponent } from './components/brand_registration/list.component';
import { BrandRegistrationComponent } from './components/brand_registration/new.component';
import { ItemCatsComponent } from './components/item_cat/view.component';
import { ItemCatComponent } from './components/item_cat/new.component';
import { ItemsComponent } from './components/item/items.component';
import { ItemComponent } from './components/item/item.component';
import { SuppliersComponent } from './components/supplier/suppliers.component';
import { SupplierComponent } from './components/supplier/create.component';
import { WarehousesComponent } from './components/warehouse/warehouses.component';
import { WarehouseComponent } from './components/warehouse/create.component';
import { SettingsEditComponent } from './components/settings/edit.component';

// Supplier Module
import { SupplierUsersComponent } from './supplier/users/users.component';
import { SupplierUserComponent } from './supplier/user/user.component';
import { SupplierReqItemsComponent } from './supplier/req-items/req-items.component';
import { SupplierReqItemComponent } from './supplier/req-item/req-item.component';
import { SupplierReqItemViewComponent } from './supplier/req-item-view/req-item-view.component';
import { SupplierLicenseIECComponent } from './supplier/lic-iec/lic-iec.component';
import { SupplierLicenseFSComponent } from './supplier/lic-fs/lic-fs.component';
import { SupplierWarehousesComponent } from './supplier/warehouses/warehouses.component';
import { SupplierWarehouseEditComponent } from './supplier/warehouse-edit/warehouse-edit.component';
import { SupplierPermitListComponent } from './supplier/permit/list/list.component';
import { SupplierPermitTasmacRequestComponent } from './supplier/permit/tasmac/request/request.component';
import { SupplierIndentComponent } from './supplier/indent/indent.component';
import { SupplierIndentSubmittedComponent } from './supplier/indent-submitted/indent-submitted.component';
import { SupplierIndentListComponent } from './supplier/indent-list/indent-list.component';
import { SupplierIndentViewComponent } from './supplier/indent-view/indent-view.component';
import { IndentsComponent } from './components/indents/indents.component';
import { ApprovedIndentsComponent } from './components/approved-indents/approved-indents.component';
import { SupplierIndentToRequestListComponent } from './supplier/permit/indent-to-request-list/indent-to-request-list.component';

// TASMAC Module
import { ReqItemsComponent } from './tasmac/req-items/req-items.component';
import { ReqItemAppComponent } from './tasmac/req-item-app/req-item-app.component';
import { ReqItemFullViewComponent } from './tasmac/req-item-full-view/req-item-full-view.component';
import { TasmacBrandsComponent } from './tasmac/tasmac-brands/tasmac-brands.component';
import { TasmacBrandAuthorizationComponent } from './tasmac/tasmac-brand-authorization/tasmac-brand-authorization.component';
import { TasmacPermitsComponent } from './tasmac/permits/permits.component';
import { TasmacPermitCancelComponent } from './tasmac/permit-cancel/permit-cancel.component';

// Advanced Settings
import { MySQLBackupListComponent } from './shared/mysql-bkup/list.component';

// Reports
// import { CustomerPaymentSummaryComponent } from './components/reports/customer/payment-summary/payment-summary.component';
import { PaymentSummaryComponent } from './components/reports/payment-summary/payment-summary.component';
import { TaxInvoicesComponent } from './components/reports/tax-invoices/tax-invoices.component';
import { RequestStatusSummaryComponent } from './components/reports/request-status-summary/request-status-summary.component';
import { PaymentSummaryByMonthComponent } from './components/reports/payment-summary-by-month/payment-summary-by-month.component';
import { DailyPaymentsComponent } from './components/reports/daily-payments/daily-payments.component';
import { TMBrandSummaryComponent } from './components/reports/tm-brand-summary/tm-brand-summary.component';
import { TMApprovedPermitsComponent } from './components/reports/tm-approved-permits/tm-approved-permits.component';


// New System Stock Monitoring
import { RateRevisedComponent } from './components/rate-master/rate-revised.component';
import { RateMastersComponent } from './components/rate-master/rate-masters.component';
import { StockComponent } from './components/stock/stock.component';
// import { ItemRate } from './components/rate-master/rate.service';
import { StockNineComponent } from './components/stock/stock-nine.component';
import { TasmacStockComponent } from './components/tasmac-stock/tasmac-stock.component';
import { OrderComponent } from './components/tasmac-stock/order.component';
import { PaymentDetailsComponent } from './components/tasmac-stock/payment-details.component';
import { ShopStockNineComponent } from './components/shop/stock-nine.component';
import { ShopStockComponent } from './components/shop/stock.component';
import { ShopOrderComponent } from './components/shop/order.component';
import { SupStockNineComponent } from './components/supplier/stock-nine.component';
import { SupStockComponent } from './components/supplier/stock.component';
import { TransferComponent } from './components/supplier/transfer.component';
import { TransferSupComponent } from './components/supplier/tasmac-stock.component';
import { TransferSupShopComponent } from './components/supplier/tasmac-stock-shop.component';
import { InvoiceComponent } from './components/supplier/invoice.component';
import { TransferInComponent } from './components/shop/transferin.component';
import { TransferOutComponent } from './components/shop/transferout.component';
import { TransferInDepotComponent } from './components/depot/transferin.component';
import { TransferInSupplierComponent } from './components/depot/transferinsupplier.component';
import { TransferOutDepotComponent } from './components/depot/transferout.component';
import { TasmacStockShopComponent } from './components/shop/tasmac-stock.component';
import { TasmacSaleShopComponent } from './components/shop/tasmac-sale.component';
import { InvoiceStatusComponent } from './components/supplier/order.component';
import { InvoicePaymentDetailsComponent } from './components/supplier/payment-details.component';
import { SedimentComponent } from './components/depot/tasmac-sale.component';
// import { SedimentComponent } from './components/depot/tasmac-sale.component';
import { ShopRequestComponent } from './components/depot/shop.component';
import { FlComponent } from './components/depot/fl.component';
import { DepotStockComponent } from './components/depot/stock.component';
import { DepotStockNineComponent } from './components/depot/stock-nine.component';
import { SedimentDComponent } from './components/depot/sediment.component';

import { SupplierInvoiceComponent } from './components/tasmac-stock/invoice.component';
import { InvoiceApprovingComponent } from './components/tasmac-stock/permits.component';
import { InvoiceVerfyComponent } from './components/tasmac-stock/verfy.component';
import { InvoiceViewComponent } from './components/tasmac-stock/view.component';
import { InvoiceApproving2Component } from './components/tasmac-stock/permits2.component';
import { InvoiceApprovingGMComponent } from './components/tasmac-stock/permitsgm.component';
import { InvoiceApprovingmdComponent } from './components/tasmac-stock/permitsmd.component';
import { InvoicePaymentComponent } from './components/tasmac-stock/payment-invoice.component';
import { PermitsToBeGRAComponent } from './components/depot/permits_to_be_labelled.component';

import { SupFlStockComponent } from './components/supplier/flstock.component';



const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'ipview/:id', component: ImportPermitViewComponent, resolve: { permit: ImportPermitViewResolver } },
  { path: 'public-view-permit/:encodedPermitNo', component: PublicViewPermitComponent },
  { path: 'forgot_pwd', component: ForgotPasswordComponent },
  { path: 'reset_pwd/:q', component: ResetPasswordComponent },
  { path: 'licensee-signup', component: LicenseeSignupComponent },
  { path: 'licensee-signup-success', component: LicenseeSignupSuccessComponent },

  // ALL Users (secured)
  { path: 'home',  component: HomeComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN','TSHP'] } },
  { path: 'change_pwd', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN','TSHP'] } },
    // Except PFC  
  { path: 'permit_view', component: PermitViewComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },  // /:for/:id, resolve: { permit: ViewPermitResolver }
  { path: 'permit', component: PermitVerifyComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },
    // Except TDPT
  { path: 'items', component: ItemsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN','TSHP'] } },
  { path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'warehouses', component: WarehousesComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'item/:id', component: ItemComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] }, resolve: { item: ItemResolver } },
  { path: 'supplier/:id', component: SupplierComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'warehouse/:id', component: WarehouseComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  
  // CUS & SUP
  { path: 'payment_failed', component: FailurePaymentComponent, canActivate:[AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP'] } },
  { path: 'add_payment', component: PaymentComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP'] } },

  // CUS
  { path: 'new_permit/:pid/:cid', component: NewPermitComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] }, resolve: {permit: NewPermitResolver } },  // /:permit_id/:customer_id/:trnx_value/:trnx_to/:fee_stage, resolve: {payment: PaymentResolver } 
  { path: 'permit_submitted/:pid/:cid/:req_no/:req_dt', component: PermitRequestedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] }, resolve: { permit_submitted: PermitRequestedResolver } },
  { path: 'permit_list/:for', component: PermitListComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] }, resolve: { filterBy: PermitListResolver} },
  { path: 'permit_reval/:id', component: PermitRevalComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] }, resolve: { permit: PermitRevalResolver } },
  { path: 'permit_cancel/:id', component: PermitCancelComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] }, resolve: { permit: PermitCancelResolver } },
  { path: 'cus_fl_lic', component: CusFLLicenseComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] } },
  { path: 'cus_fs_lic', component: CusFSLicenseComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] } },
  { path: 'cus_myself', component: CusMyselfComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] } },
  { path: 'request-creation-blocked', component: RequestCreationBlockedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS'] } }, // /:id

  // To ALL ('TSEC', 'TACC', 'TMD', 'PSEC', 'PCOM')
  { path: 'permits/:for', component: PermitsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] }, resolve: { filterBy: PermitsResolver } },  // /:for/:id, resolve: {permit: VerifyPermitResolver }
  { path: 'permits_approved', component: PermitsApprovedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'permits_to_be_printed', component: PermitsToBePrintedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'permits_ext_requested', component: PermitsExtRequestedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },
  { path: 'permits_despatched', component: PermitsDespatchedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },
  { path: 'permits_cand_rej', component: PermitsCandRejComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },
  { path: 'permit_approved', component: PermitApprovedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PCOM'] } },  // /:for/:pid/:pno/:pdt, resolve: {permit_approved: PermitApprovedResolver }
  // { path: 'pending-digital-signatures', component: PendingDigitalSignaturesComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PCOM'] } },
  { path: 'digital-signature', component: DigitalSignatureComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PCOM'] } },
  { path: 'permit_print', component: PermitPrintComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'permit-eval-print', component: PermitEvalPrintComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'permits_to_be_labelled', component: PermitsToBeLabelledComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TDPT'] } },
  { path: 'permits_request_ext', component: PermitsRequestExtComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TDPT'] } },
  { path: 'permit_label', component: PermitLabelComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TDPT'] } },  // /:for/:id, resolve: { permit: PermitLabelResolver }
  { path: 'permit_ext', component: PermitExtComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TDPT'] } },  // /:for/:id, resolve: { permit: PermitExtResolver }

  { path: 'pe_section_customers', component: PeSectionCustomersComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },

  { path: 'customer/:id', component: CustomerComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] }, resolve: { cus_data: CustomerResolver } },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'user/:id', component: UserComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] }, resolve: { user: UserResolver } },
  { path: 'cons', component: CountriesComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'con/:id', component: CountryComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] }, resolve: { country: CountryResolver } },
  { path: 'states', component: StatesComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'state', component: StateComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },  // /:id, resolve: { state: StateResolver }
  { path: 'depots', component: DepotsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'depot/:id', component: DepotComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] }, resolve: { depot: DepotResolver } },
  { path: 'brand_owners', component: BrandOwnersComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'brand_owner/:id', component: BrandOwnerComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'brand_registrations', component: BrandRegistrationsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'brand_registration/:id', component: BrandRegistrationComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'item_cats', component: ItemCatsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'item_cat/:id', component: ItemCatComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] }, resolve: { itemCat: ItemCatResolver } },
  
  // Supplier Module
  { path: 'sup-users', component: SupplierUsersComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  { path: 'sup-user', component: SupplierUserComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['PSEC'] } },
  
  { path: 'sup-req-items', component: SupplierReqItemsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-req-item/:id', component: SupplierReqItemComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-req-item-view/:id', component: SupplierReqItemViewComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-lic-iec', component: SupplierLicenseIECComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-lic-fs', component: SupplierLicenseFSComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-wars', component: SupplierWarehousesComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-war-edit', component: SupplierWarehouseEditComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-pmts/:lType', component: SupplierPermitListComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-pmt-tm-req', component: SupplierPermitTasmacRequestComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-indent-submitted', component: SupplierIndentSubmittedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-indents/:lType', component: SupplierIndentListComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  { path: 'sup-indent-to-request-list', component: SupplierIndentToRequestListComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP'] } },
  
  // SUP, TSEH
  { path: 'sup-indent', component: SupplierIndentComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP', 'TSEH', 'TGM', 'TMD'] } },
  { path: 'sup-indent-view', component: SupplierIndentViewComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP', 'TSEH', 'TGM', 'TMD'] } },
  
  // TASMAC Module
  { path: 'req_items/:status', component: ReqItemsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TSEC'] } },
  { path: 'req_item/:id', component: ReqItemAppComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TSEC'] } },
  { path: 'req_item_view/:id', component: ReqItemFullViewComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TSEC'] } },
  { path: 'indents/:for', component: IndentsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TGM', 'TMD'] } },
  { path: 'approved-indents', component: ApprovedIndentsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TGM', 'TMD'] } },
  { path: 'tasmac-brands', component: TasmacBrandsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TSEC'] } },
  { path: 'tasmac-brand-authorization', component: TasmacBrandAuthorizationComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH', 'TSEC'] } },
  { path: 'tasmac-pmts/:lType', component: TasmacPermitsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH'] } },
  { path: 'tasmac-pmt-can', component: TasmacPermitCancelComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEH'] } },
  
  // Advanced Settings
  { path: 'settings', component: SettingsEditComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['ADMN'] } },
  { path: 'mysql_backup', component: MySQLBackupListComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['ADMN'] } },
  
  // Reports
  { path: 'daily-payments/:trnx_to', component: DailyPaymentsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN'] } },
  { path: 'pay_sum/:pay_to', component: PaymentSummaryComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN'] } },
  { path: 'tax-invoices', component: TaxInvoicesComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN'] } },
  { path: 'payment-summary-by-month', component: PaymentSummaryByMonthComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN'] } },
  { path: 'tm-brand-summary', component: TMBrandSummaryComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },
  { path: 'tm-approved-permits', component: TMApprovedPermitsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['SUP', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'ADMN'] } },
  { path: 'request_status_summary', component: RequestStatusSummaryComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'TDPT', 'PFC', 'ADMN'] } },

  // Advanced Settings
  { path: 'rates', component: RateMastersComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'rate', component: RateRevisedComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'stock', component: StockComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'demurrage', component: StockNineComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  
  // FL2/FL3 New
  { path: 'tasmacstock', component: TasmacStockComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },
  { path: 'return-shop', component: TasmacStockShopComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },
  { path: 'order', component: OrderComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP','TDPT'] } },
  { path: 'payment-status/:id', component: PaymentDetailsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'TDPT', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },

  // Elite Shops
  { path: 'stock-shop', component: ShopStockComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },
  { path: 'daily-sale', component: TasmacSaleShopComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },
  { path: 'demurrage-shop', component: ShopStockNineComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP','TDPT'] } },
  { path: 'sediment-depot', component: SedimentComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT','TSHP'] } },
  { path: 'order-shop', component: ShopOrderComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },
  { path: 'transfer-in-shop', component: TransferInComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },
  { path: 'transfer-out-shop', component: TransferOutComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TSHP'] } },

  { path: 'stock-supplier', component: SupStockComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP','TDPT'] } },
  { path: 'demurrage-supplier', component: SupStockNineComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },
  { path: 'transfer-supplier', component: TransferComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },
  { path: 'transfer-depot', component: TransferSupComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },
  { path: 'transfer-shop', component: TransferSupShopComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },
  
  { path: 'invoice-supplier', component: InvoiceComponent , canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },
  { path: 'supplier-fl-stock', component: SupFlStockComponent , canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },

  { path: 'invoice-status', component: InvoiceStatusComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },
  { path: 'invoice-payment', component: InvoicePaymentDetailsComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','SUP'] } },

  { path: 'transfer-in-Depot', component: TransferInDepotComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'transfer-in-supplier', component: TransferInSupplierComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'transfer-out-Depot', component: TransferOutDepotComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'shop-request', component: ShopRequestComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'fl-out', component: FlComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'stock-depot', component: DepotStockComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'demurrage-depot', component: DepotStockNineComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'invoice-tasmac', component: SupplierInvoiceComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'invoice-approving', component: InvoiceApprovingComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'invoice-verfy/:id', component: InvoiceVerfyComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'invoice-view/:id', component: InvoiceViewComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'invoice-approving2', component: InvoiceApproving2Component, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'ADMN'] } },
  { path: 'invoice-approvinggm', component: InvoiceApprovingGMComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TGM'] } },
  { path: 'invoice-approvingmd', component: InvoiceApprovingmdComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TMD'] } },
  { path: 'Sediment-entry', component: SedimentDComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS','TDPT'] } },
  { path: 'label_gra', component: PermitsToBeGRAComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['TDPT'] } },
  { path: 'invoice-payment', component: InvoicePaymentComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  { path: 'invoice-make', component: InvoicePaymentComponent, canActivate: [AuthGuard], data: { expectedUserGroups: ['CUS', 'SUP', 'TSEH', 'TSEC', 'TACC', 'TGM', 'TMD', 'PSEC', 'PSSV', 'PAC', 'PJC', 'PCOM', 'ADMN'] } },
  
  // otherwise redirect to home
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
