import { Component, OnInit } from '@angular/core';

import { License } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './lic-iec.component.html'
})
export class SupplierLicenseIECComponent implements OnInit {
  filters:any = {
    'supplier_id': null,
    'name': 'IMPORT'
  };
  lics:any[] = [];
  is_fl_original_available = false;
  flAlerts:any[] = [];

  constructor(
    private dataService:DataService
  ) {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.supplier_id)
        this.filters.supplier_id = data.supplier_id;
    }
  }

  ngOnInit() {
    this.dataService.getLicenses(this.filters).subscribe((data:any[]) => {
      this.lics = data;
      this.lics = this.lics.map(f => {
        f.iss_date = new Date(f.iss_date);
        f.val_date = new Date(f.val_date);
        return f;
      });
      this.check_for_fl_original();
    });
  }

  private check_for_fl_original() {
    this.is_fl_original_available = false;
    this.lics.map(f => {
      if (!this.is_fl_original_available && f.type == 'O')
        this.is_fl_original_available = true;
    });
  }

  delete(lic: License) {
    if (confirm("Are to sure to delete?"))
    {
      this.dataService.delLicense(lic.id).subscribe((data:any) => {
        if (data.message == "License details deleted successfully.")
        {
          let idx = -1;
          this.lics.forEach((l, key) => { if (lic.id == l.id) idx = key; });
          if (idx >= 0)
            this.lics.splice(idx, 1);
          this.check_for_fl_original();
          this.flAlerts.push({ type: 'danger', dismissible: true, msg: data.message, timeout: 5000 });
        }
      });
    }
  }

  licenseSaved(license: License) {
    let idx = -1;
    this.lics.map((l, key) => { if (license.id == l.id) idx = key; return; });
    if (idx == -1)
    {
      license.is_del = true;
      this.lics.push(license);
    }
    else
      this.lics[idx] = license;
    this.check_for_fl_original();
    this.flAlerts.push({ type: 'success', dismissible: true, msg: 'License details saved successfully.', timeout: 5000 });
  }
}
