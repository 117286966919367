import { Injectable } from '@angular/core';

export interface UserCategory {
  code: string,
  name: string,
  level: number,
  isCreateUser: boolean,
  isSupReq: boolean,
  isDepotReq: boolean,
  isInvolvedInIndentApproval: boolean,
  isInvolvedInPermitApproval: boolean,
  approvalOvertakeCodes: string[]
}

@Injectable()
export class Config {

  static userCategories: UserCategory[] = [
    { code: 'CUS',  name: 'Customer',                     level: 0,   isCreateUser: false,  isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
    { code: 'SUP',  name: 'Supplier',                     level: 0,   isCreateUser: false,  isSupReq: true,  isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
    { code: 'TSEH', name: 'TASMAC (H - Section)',         level: 1,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: true,  isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
    { code: 'TSEC', name: 'TASMAC (C - Section)',         level: 1,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: [] },
    { code: 'TACC', name: 'TASMAC (Accounts)',            level: 2,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: [] },
    { code: 'TGM',  name: 'TASMAC (GM)',                  level: 3,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: true,  isInvolvedInPermitApproval: true,    approvalOvertakeCodes: ['TMD'] },
    { code: 'TMD',  name: 'TASMAC (MD)',                  level: 4,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: true,  isInvolvedInPermitApproval: true,    approvalOvertakeCodes: [] },
    { code: 'PSEC', name: 'CPE (Section Officer)',        level: 5,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: [] },
    { code: 'PSSV', name: 'CPE (Section Superintendent)', level: 6,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: ['PAC', 'PJC', 'PCOM'] },
    { code: 'PAC',  name: 'CPE (Assistant Commissioner)', level: 7,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: ['PJC', 'PCOM'] },
    { code: 'PJC',  name: 'CPE (Joint Commissioner)',     level: 8,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: ['PCOM'] },
    { code: 'PCOM', name: 'CPE (Commissioner)',           level: 9,   isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: true,    approvalOvertakeCodes: [] },
    { code: 'TDPT', name: 'TASMAC (Depot)',               level: 10,  isCreateUser: true,   isSupReq: false, isDepotReq: true,   isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
    { code: 'PFC',  name: 'CPE (Financial Controller)',   level: 11,  isCreateUser: true,   isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
    { code: 'ADMN', name: 'System Administrator',         level: 99,  isCreateUser: false,  isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
    { code: 'TSHP', name: 'Elite Shop (TASMAC SHOP)',     level: 0,   isCreateUser: false,  isSupReq: false, isDepotReq: false,  isInvolvedInIndentApproval: false, isInvolvedInPermitApproval: false,   approvalOvertakeCodes: [] },
  ];

  // { code: 'TGM',  name: 'TASMAC (GM)',        isCreateUser: true,  isDepotReq: false },
  // { code: 'PJC',  name: 'P&E (JC)',           isCreateUser: true,  isDepotReq: false },
  // { code: 'PAC',  name: 'P&E (AC)',           isCreateUser: true,  isDepotReq: false },
  
  static defaultRegistrationUserCategory = 'CUS';
  static customerLicenseTypes = ['FL2', 'FL3'];

  static verf_codes:Array<VerfCode> = [
    { code: 'LEE_DET', name: 'Licensee verification upon signup.', allowedUserCat: ['PSEC'], allowMultiVerf: false }
  ];

  // static verf_status:Array<verfStatus> = [
  //   { code: 'VER', name: 'Verified' },
  //   { code: 'YET', name: 'Yet to be verified' },
  //   { code: 'CLR', name: 'Clarification Requested' },
  //   { code: 'CLD', name: 'Clarified' }
  // ];

  static warehouse_types = [ { id: "PRIVATE", name: "Private Customs Bond" }, { id: "PUBLIC", name: "Public Customs Bond" } ];

  constructor(
    ) { }

  public static getUserCategoryDetails(code:string) {
    return this.userCategories.find(uc => { return uc.code == code; });
  }
  
  static now() {
    // return moment().startOf('day').format('DD/MM/YYYY');
    let dt = new Date();
    dt.setHours(0, 0, 0, 0);
    return dt.toLocaleDateString('hi-IN');
  }
  static sixMonthBackFromNow() {
    // return moment().startOf('day').add(1, 'years').format('DD/MM/YYYY');
    let dt = new Date();
    dt.setHours(0, 0, 0, 0);
    dt.setMonth(dt.getMonth()-6);
    return dt.toLocaleDateString('hi-IN');
  }
  static oneYearFromNow() {
    // return moment().startOf('day').add(1, 'years').format('DD/MM/YYYY');
    let dt = new Date();
    dt.setHours(0, 0, 0, 0);
    dt.setFullYear(dt.getFullYear()+1);
    return dt.toLocaleDateString('hi-IN');
  }
  static isValidDate(obj: any): boolean {
    let dt:string;
    if (obj instanceof Date)
    {
      //  return true;
      dt = obj.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    }
    else
    {
      dt = this.MakeDate(obj).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
    }
    // return moment(obj, 'DD/MM/YYYY').isValid();
    return !/Invalid|Invalid Date|NaN/.test(dt);
  }
  static MakeDate(value:any): Date {
    // return moment(value, 'DD/MM/YYYY');
    // if (!value) {
    //   let dt = new Date();
    //   dt.setHours(0, 0, 0, 0);
    //    return dt;
    // }
    // else
    if (typeof value === 'string')
    {
      let dtObj = value.split("/");
      if (parseInt(dtObj[0]) <= 0 || parseInt(dtObj[0]) >= 32)
        return new Date("invalid date");
      if (parseInt(dtObj[1]) <= 0 || parseInt(dtObj[1]) >= 13)
        return new Date("invalid date");
      let dt = new Date(parseInt(dtObj[2]), parseInt(dtObj[1])-1, parseInt(dtObj[0]));
      return dt;
    }
    else
      return new Date(value);
  }

  // static isDate(): ValidatorFn {
  //   return (control: FormControl): {[key: string]: any} => {
  //     if (this.isPresent(Validators.required(control))) return null;

  //     if (control.value && control.value.length != 10 ) return {isDate: true};
  //     // let v = moment(control.value, 'DD/MM/YYYY');
  //     return this.isValidDate(control.value) ? null : {isDate: true};
  //   }
  // }
}

export class Indent {
  id: number;
  constructor(
    public supplier_id: number
  ) { }
  indent_no?: string = null;
  indent_date?: Date = null;
  depot_id?: number;
  status: string = "IN";
  level?: number = null;
  permit_id?: number;
  orig_depot_id?: number;

  //Extended Properties
  supplier?: Supplier;
  sup_name?: string;
  items?: IndentItem[];
  remarks?: IndentRemark[];
  verfs?: IndentVerf[];
  depot_name?: string;
  orig_depot_name?: string;
  user_id?: number;
  user_name?: string;
  message?: string;
  action?: string;
  approvalOvertakeCodes?: string[];
  approvalOvertake?: boolean;
}

export class IndentItem {
  id?: number;
  constructor(
    public indent_id: number
  ) { }
  item_id?: number = null;
  vol?: number = null;
  qty_case?: string = null;
  btl_per_case?: number = null;
  qty_btl?: number = null;
  orig_qty_case?: string;
  orig_qty_btl?: number;

  // Extended Properties
  item_cat_id?: number = null;
  item_cat_name?: string = null;
  item_name?: string = null;
  origin_country_id?: number = null;
  con_name?: string = null;
  is_edit_mode: boolean = true;
  is_deleted: boolean = false;
}

export class IndentRemark {
  id?: number = null;
  constructor(
    public indent_id: number,
    public user_id: number
  ) { }
  rem_date?: Date = null;
  rem_type?: string;
  remarks?: string = null;

  // Extended Properties
  isEdit?: boolean = false;
  remark_by?: string = null;
}

export class IndentVerf {
  id?: number;
  constructor(
    public indent_id: number,
    public verf_code: string,
    public category_code: string,
    public user_id: number
  ) {}
  verf_date?: Date = null;
  remarks?: string = null;
  status: string = "YET";

  // Extended Properties
  status_by?: string = null;
}

export class IndentVData {
  constructor(
    private current_user: User,
    public indent_id: number,
    public verf_code: string,
    public allow_uc: string[],
    public isClarify: boolean = false
  ) {
    if (this.allow_uc && this.allow_uc.indexOf(this.current_user.category_code) >= 0)
      this.allow_state_change = true;
  }
  private verfs: IndentVerf[] = [];

  lvf_state: IndentVerf;

  setVerfs(vs: IndentVerf[]) {
    this.verfs = vs;
    this.updateLastVerf();
  }

  private updateLastVerf() {
    this.lvf_state = null;

    this.lvf_state = (this.verfs.length > 0)?this.verfs[this.verfs.length - 1]:this.makeNewVerf();
    if (this.allow_uc && this.lvf_state.status !== 'CLR' && this.lvf_state.status !== 'CLD') {
      const verfs = this.verfs.filter(v => v.category_code === this.current_user.category_code);
      this.lvf_state = (verfs.length > 0)?verfs[verfs.length -1]:this.makeNewVerf();
    }
  };

  allow_state_change: boolean = false;
  allow_state_visible: boolean = true; // false;
  showChangeState = false;

  initAddChangeState() {
    this.allow_state_change = false;
    this.showChangeState = true;
    return this.makeNewVerf();
  }

  makeNewVerf(): IndentVerf {
    const verf = new IndentVerf(this.indent_id, this.verf_code, this.current_user.category_code, this.current_user.id);
    verf.status_by = this.current_user.name + (this.current_user.designation?(", "+this.current_user.designation):"");
    return verf;
  }

  cancelAddStateChange() {
    this.allow_state_change = true;
    this.showChangeState = false;
  }

  addedVerf(v: IndentVerf) {
    this.verfs.push(v);
    this.updateLastVerf();
    this.cancelAddStateChange();
  }

  getAllVerfs() {
    return this.verfs;
  }

  getLastVerfs() {
    return (this.verfs.length > 0)?this.verfs[this.verfs.length - 1]:null;
  }

  isVerified() {
    let verfStatus = false;
    if (this.getLastVerfs()) {
      const lvf = this.getLastVerfs();
      if (lvf.status === 'VER') verfStatus = true;
    }
    return verfStatus;
  }

  isClarified() {
    let verfStatus = false;
    if (this.getLastVerfs()) {
      const lvf = this.getLastVerfs();
      if (lvf.status === 'CLD') verfStatus = true;
    }
    return verfStatus;
  }
}

export const IndentVS: {
  [prop_name: string]: IndentVData
} = {
}

export class ngxAlert {
  constructor(
    public msg: string,
    public type: 'success' | 'info' | 'warning' | 'danger' = 'success',
    public dismissible = true,
    public dismissOnTimeout?: number
  ) {
    if (this.dismissible && !this.dismissOnTimeout)
      this.dismissOnTimeout = 5000;
  }
}

export class Settings {
  current_comm_name?: string = null;
  current_comm_designation?: string = null;
  st_name?: string = null;
  st_pc?: number = null;
  tasmac_gstin?: string = null;
  tasmac_pan?: string = null;
  tasmac_cin?: string = null;
  feedback_email?: string = null;
}

export class PermitRemark {
  id?: number = null;
  constructor(
    public permit_id: number,
    public user_id: number
  ) { }
  rem_date?: Date = null;
  rem_type?: string;
  remarks?: string = null;
  // Extended Properties
  isEdit?: boolean = false;
  remark_by?: string = null;
}

export class VData {
  private current_category_code:string;
  constructor(
    /**
     * Unique Verification Code
     */
    public verf_code:string,
    /**
     * User Category codes in array to whom state change is allowed
     */
    public allow_uc:string[],
    /**
     * User Category codes in array to whom state change is visible
     */
    public visible_uc:string[],
  ) {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.current_category_code = data.category_code;
    }
    if (this.allow_uc && this.allow_uc.indexOf(this.current_category_code) >= 0)
      this.allow_state_change = true;
    if (this.visible_uc && this.visible_uc.indexOf(this.current_category_code) >= 0)
      this.allow_state_visible = true;
  }
  private verfs:Verf[] = [];

  //State (Info) related
  lvf_state:Verf;
  set_verfs(vs:Verf[]) {
    this.verfs = vs;
    this.update_last_verf();
  }
  private update_last_verf() {
    if (this.verfs.length > 0)
      this.lvf_state = this.verfs[this.verfs.length - 1];
    else
      this.lvf_state = this.make_new_verf();
  };
  allow_state_change:boolean = false;
  allow_state_visible:boolean = false;
  showChangeState = false;
  init_add_changeState() {
    this.allow_state_change = false;
    this.showChangeState = true;
  }

  // Add/Edit related
  make_new_verf():Verf {
    return new Verf(this.verf_code, this.current_category_code);
  }
  cancel_add_state_change() {
    this.allow_state_change = true;
    this.showChangeState = false;
  }
  added_verf(v:Verf) {
    this.verfs.push(v);
    this.update_last_verf();
    this.cancel_add_state_change();
  }

  // Msg related
  get_all_verfs() {
    return this.verfs;
  }
}

export class Payment {
  id?:number;
  permit_id?:number;
  
  constructor(
    public customer_id:number,
    public fee_stage:string, 
    public trnx_to:string,
    public trnx_value:number
  )
  {  }
  trnx_date?:Date = null;
  trnx_no_own?:string = null;
  trnx_no_gw?:string = null;
  
  status?:string = null;
  status_desc?: string;
  // remarks?:string = null;

  //Extended properties
  return_url?:string = null;
  // encdata?:string;
}

export class TasmacPermit {
  id: number;
  constructor(
    public supplier_id: number
  ) { }
  req_no?: string = null;
  req_date?: Date = null;
  depot_id?: number = null;
  warehouse_id?: number = null;
  period?: number = null;
  status: string = "IN";
  level?: number = null;
  valid_date?: Date = null;
  is_revalidated?: number = null;
  extend_period?: number = null;
  reval_date?: Date = null;
  is_cancelled?: number = null;
  cancel_date?: Date;
  permit_no?: string = null;
  permit_date?: Date = null;
  proforma_doc_id?: number = null;
  ext_req_date?: Date;
  bdr_cross_date?: Date;
  rpt_date?: Date;
  ext_req_period?: number = null;
  ext_app_period?: number = null;
  ext_valid_date?: Date = null;
  ext_app_date?: Date;

  //Extended Properties
  sup_name?: string = null;
  war_name?: string = null;
  status_name?: string = null;
  proforma_storage_name?: string = null;
  is_expired?:boolean = null;
  user_id?: number;
  user_name?: string;
  action?: string;
  approvalOvertakeCodes?: string[];
  approvalOvertake?: boolean;
  lics?: License[];
  items?: PermitItem[];
  docs?: PermitDoc[];
  fees?: PermitFee[];
  pay?: Payment[];
  remarks?: PermitRemark[];
  verfs?: Verf[];
  message?: string;
  // For Reports
  indent_no?: string;
  indent_date?: Date;
}

export class Permit {
  id: number;
  constructor(
    public customer_id: number
  ) { }
  req_no?: string = null;
  req_date?: Date = null;
  depot_id?: number;
  supplier_id?: number = null;
  warehouse_id?: number = null;
  period?: number = null;
  status: string = "IN";
  level?: number = null;
  valid_date?: Date = null;
  is_revalidated?: number = null;
  extend_period?: number = null;
  reval_date?: Date = null;
  is_cancelled?: number = null;
  cancel_date?: Date;
  cancel_reason?: string;
  permit_no?: string = null;
  permit_date?: Date = null;
  proforma_doc_id?: number = null;
  ext_req_date?: Date;
  bdr_cross_date?: Date;
  rpt_date?: Date;
  ext_req_period?: number = null;
  ext_app_period?: number = null;
  ext_valid_date?: Date = null;
  ext_app_date?: Date;

  //Extended Properties
  depot_name?: string;
  sup_name?: string;
  war_name?: string;
  status_name?: string;
  proforma_storage_name?: string;
  is_expired?:boolean;
  user_id?: number;
  user_name?: string;
  action?: string;
  approvalOvertakeCodes?: string[];
  approvalOvertake?: boolean;
  lics?: License[];
  docs?: PermitDoc[];
  fees?: PermitFee[];
  pay?: Payment[];
  remarks?: PermitRemark[];
  is_fl_permit?: boolean;
  verfs?: Verf[];
  indent_no?: string;
  indent_date?: Date;
  licensee_type?: string;
  star_gradation_name?: string;
  is_domestic?: boolean;
  ip_storage_name?: string;
}

export class PermitDoc {
  id?:number;
  constructor(
    public permit_id:number,
    public customer_id:number,
    public doc_type:string
  ) { }
  gen_date?:Date;
  doc_id?:number;

  // Extended Properties
  cnt?:number;
  storage_name?:string;
  user_id?: number;
}

export class PermitRoute {
  id?:number;
  constructor(
    public permit_id:number
  ) { }
  odr_num?:number = null;
  tnspt_by?:string = null;
  name?:string = null;
  state_id?:number = null;

  // extended properties
  tnspt_by_name?:string = null;
  state_name?:string = null;
}

export class PermitItem {
  id?:number;
  constructor(
    public permit_id:number
  ) { }
  item_id?:number = null;
  vol?:number = null;
  qty_case?:string = null;
  btl_per_case?:number = null;
  qty_btl?:number = null;
  rate_btl?:number = null;
  bl?:number = null;
  pl_pc?:number = null;
  pl?:number = null;
  spf_bl_rate?:number = null;
  spf_pl_rate?:number = null;
  spf?:number = null;
  apf_rate?:number = null;
  apf?:number = null;
  el_rate?:number = null;
  el?:number = null;
  value?:number = null;
  calc_all() {
    // qty_case calc
    let qtyBtl = (this.qty_btl?this.qty_btl:0);
    let btlPerCase = (this.btl_per_case?this.btl_per_case:qtyBtl);
    this.qty_case = (Math.floor(qtyBtl/btlPerCase)).toString() + '.' + (qtyBtl%btlPerCase).toString();
    // this.qty_case = Math.round(t_qty_case * 10000) / 10000; //Set it to 4 decimals
    // bl calc
    this.bl = (qtyBtl * (this.vol?this.vol:0)) / 1000;
    // pl calc
    this.pl = ((this.bl?this.bl:0) * (this.pl_pc?this.pl_pc:0)) / 100;
    // spf calc
    this.spf = ( (this.bl?this.bl:0) * (this.spf_bl_rate?this.spf_bl_rate:0) ) + ( (this.pl?this.pl:0) * (this.spf_pl_rate?this.spf_pl_rate:0) );
    // apf calc
    this.apf = qtyBtl * ((this.apf_rate?this.apf_rate:0)/btlPerCase);
    // el calc
    this.el = parseFloat(qtyBtl.toString()) * (this.el_rate?this.el_rate:0);
    // value calc
    if (this.qty_btl && this.rate_btl)
      this.value = this.qty_btl * this.rate_btl;
  }
  received_qty?:number = null;
  label_nos?:string = null;

  // Extended Properties
  item_cat_id?:number = null;
  item_cat_name?:string = null;
  item_name?:string = null;
  origin_country_id?:number = null;
  con_name?:string = null;
  is_edit_mode:boolean = true;
}

export class PermitFee {
  id?:number;
  constructor(
    public permit_id:number,
    public fee_stage:string,
    public pay_to:string
  ) { }
  fee_date?:Date = null;
  is_deducted:number = 0;
  fee_code?:string = null;
  fee_name?:string = null;
  fee_pc?:number = null;
  fee_flat?:number = null;
  fee_value?:number = null;
}

export class Verf {
  id?:number;
  customer_id?:number = null;
  permit_id?:number = null;
  constructor(
    public verf_code:string,
    public category_code:string
  ) {}
  verf_date?:Date;
  user_id?:number = null;
  remarks?:string = null;
  status:string = "YET";
  // Extended Properties
  status_by?:string = null;
}

export class VerfCode {
  code:string = null;
  name:string = null;
  allowedUserCat:Array<string> = null;
  allowMultiVerf:boolean = null;
}

export class ItemCat {
  id?:number;
  name?:string = null;
  btl_per_case?:number = null;
  pl_pc?:number = null;
  spf_bl_rate?:number = null;
  spf_pl_rate?:number = null;
  is_apf?:boolean = false;
  apf_rate?:number = null;
  el_rate?:number = null;
}

export class Item {
  id?: number = null;
  item_cat_id?: number = null;
  brand_owner_id?: number = null;
  name?: string = null;
  origin_country_id?: number = null;
  con_name?: string = null;
  vol?: number = null;
  btl_per_case?: number = 12;
  is_domestic?: boolean = false;

  //Extended Properties
  cat_name?: string = null;
  brand_owner_name?: string = null;
  selected?: boolean = null;
  slno?: number = null;
  supplier_names?: string|string[];
}

export class Stock {
  id?: number = null;
  item_cat_id?: number = null;
  brand_owner_id?: number = null;
  name?: string = null;
  origin_country_id?: number = null;
  con_name?: string = null;
  vol?: number = null;
  btl_per_case?: number = 12;
  is_domestic?: boolean = false;

  //Extended Properties
  cat_name?: string = null;
  brand_owner_name?: string = null;
  selected?: boolean = null;
  slno?: number = null;
  supplier_names?: string|string[];
  
  //Price Detail
}

export class ItemAsso {
  id?:number = null;
  supplier_id?:number = null;
  brand_owner_id?:number = null;
  from_date?:Date = null;
  to_date?:Date = null;
  doc_id?:number = null;
  remarks?:string = null;
  det?:ItemAssoDet[] = null;

  // Extended Properties
  supplier_name?:string = null;
  brand_owner_name?:string = null;
  storage_name?:string = null;
  slno?:number = null;
  tot_cnt?:number = null;
}

export class ItemAssoDet {
  id?:number = null;
  item_asso_id:number;
  item_id:number;

  // Extended Properties
  name?:string = null;
  vol?:number = null;
  btl_per_case?:number = null;
  cnt?:number = null;
}

export class ItemTasmacAsso {
  fin_year: string;
  supplier_id: number;
  supplier_name?: string;
  supplier_address?: string;
  available_cnt?: number;
  app_cnt?: number;
  det?: ItemTasmacAssoDet[];
}

export class ItemTasmacAssoDet {
  id?: number;
  fin_year: string;
  item_asso_det_id: number;
  item_cat_id: number;
  item_cat_name: string;
  item_id: number;
  item_name: string;
  vol: number;
  btl_per_case: number;
  from_date: Date;
  to_date: Date;
}

export class ReqItem extends Item {
  req_date?:Date = null;
  constructor(
    public supplier_id:number
  ) {
    super();
  }
  req_comment?:string = null;
  status = "REQ";
  action_date?:Date = null;
  action_user_id?:number = null;
  action_comment?:string = null;
  item_id?:number = null;
  item_spec_doc_id?:number = null;

  // Extended Properties
  supplier_name?:string = null;
  usr_name?:string = null;
  storage_name?:string = null;
}

export class State {
  id?:number;
  code?:string;
  name?:string;
  permit_period? = 60;
  excise_comm_name?: string;
  excise_off_email?: string;
}

export class Country { 
  id?:number;
  code?:string = null;
  name?:string = null;
}

export class Depot {
  id?:number;
  name?:string = null;

  // Extended Properties
  permits?: TasmacPermit[];
}

export class StarGradation {
  id?: number;
  name?: string;
  is_permit_allowed?: boolean;
}

export class License {
  id?:number;
  constructor(
    public type:string = "O",
    public name:string = "FL2/FL3"
  ) { }
  no?:string = null;
  iss_date?:Date = null;
  val_date?:Date = null;
  customer_id?:number = null;
  supplier_id?:number = null;
  warehouse_id?:number = null;
  license_doc_id?:any = null;
  status?:any = null;
  // Extended Properties
  is_del?:boolean = true;
  cnt?:number = null;
  storage_name:string = null;
}

export interface IAddress {
  add1?:string;
  add2?:string;
  add3?:string;
  pin?:string;
}

export class Supplier implements IAddress {
  id?:number;
  name?:string = null;
  add1?:string = null;
  add2?:string = null;
  add3?:string = null;
  pin?:string = null;
  state_id?:number = null;
  contact_person?:string = null;
  contact_mobile_no?:string = null;
  contact_email?:string = null;
  status?:string = "REQ";
  req_customer_id?:number = null;
  req_date?:Date = null;

  //Extended Properties
  state_name?:string = null;
  req_cus_name?:string = null;
  wh_cnt?:number = null;
  pr_cnt?:number = null;
}

export class Warehouse implements IAddress {
  id?:number;
  supplier_id:number = null;
  name?:string = null;
  add1?:string = null;
  add2?:string = null;
  add3?:string = null;
  pin?:string = null;
  state_id?:number = null;
  owned_type?:string = "PRIVATE";
  owner_name?:string = null;
  contact_person?:string = null;
  contact_mobile_no?:string = null;
  contact_email?:string = null;
  status?:string = "REQ";
  req_customer_id?:number = null;
  req_date?:Date = null;
  
  // Extended Properties
  period?:number = null;
  sup_name?:string = null;
  state_name?:string = null;
  req_cus_name?:string = null;
  pr_cnt?:number = null;
}

export class Customer implements IAddress {
  id?: number;
  name?: string = null;
  type?: string = null;
  star_gradation_id?: number = null;
  add1?: string = null;
  add2?: string = null;
  add3?: string = null;
  pin?: string = null;
  web?: string = null;
  depot_id?: number = null;
  gstin?: string = null;

  //Extended Properties
  depot_name?: string;
  star_gradation_name?: string;
  contact_person?: string;
  contact_mobile_no?: string;
  contact_email?: string;
}

export class BrandOwner implements IAddress {
  id?:number;
  name?:string = null;
  add1?:string = null;
  add2?:string = null;
  add3?:string = null;
  country_id?:number = null;
  contact_person?:string = null;
  contact_mobile_no?:string = null;
  contact_email?:string = null;

  //Extended Properties
  country_name?:string = null;
  cnt?:number = null;
  slno?:number = null;
}

export class User {
  id?:number;
  category_code:string = null;
  email?:string = null;
  pwd?:string = null;
  repwd?:string = null;
  name?:string = null;
  designation?:string = null;
  mobile?:string = null;
  customer_id?:number = null;
  supplier_id?: number;
  depot_id?:number = null;
  status?:number = 1;

  //Extended
  oldPwd?:string = null;
  q?:string = null;
  level?: number = null;
  category_name?: string;
  supplier_name?: string;
  supplier_address?: string;
}

export class TaxInvoice {
  id?: number;
  inv_no?: string;
  inv_date?: Date;
  gstin?: string;
  cname?: string;
  cadd?: string;
  tot_value?: number;

  // Extended Properties
  permit_id?: number;
  permit_payment_id?: number;
}

export class FinYear {
  fin_year: string;
  start_date: Date;
  end_date: Date;
}
