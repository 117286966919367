import { Component, OnInit } from '@angular/core';

import { License } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './cus-fs-lic.component.html'
})
export class CusFSLicenseComponent implements OnInit {
  filters:any = {
    'customer_id': null,
    'name': 'FSSAI'
  };
  fssai_lics:Array<any> = [];
  is_fssai_original_available = false;
  fsAlerts:Array<any> = [];

  constructor(
    private dataService:DataService
  ) {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filters.customer_id = data.customer_id;
    }
  }

  ngOnInit() {
    this.dataService.getLicenses(this.filters).subscribe((data:any[]) => {
      this.fssai_lics = data;
      this.fssai_lics = this.fssai_lics.map(f => {
        f.iss_date = new Date(f.iss_date);
        f.val_date = new Date(f.val_date); 
        return f; 
      });
      this.check_for_fssai_original();
    });
  }

  private check_for_fssai_original() {
    this.is_fssai_original_available = false;
    this.fssai_lics.map(f => {
      if (!this.is_fssai_original_available && f.type == 'O')
        this.is_fssai_original_available = true;
    });
  }

  delete(lic:License) {
    if (confirm("Are to sure to delete?"))
    {
      this.dataService.delLicense(lic.id).subscribe((data:any) => {
        if (data.message == "License details deleted successfully.")
        {
          let idx = -1;
          switch(lic.name)
          {
            // case "FL2/FL3":
            //   this.fl_lics.map((l, key) => { if (lic.id == l.id) idx = key; return; });
            //   if (idx >= 0)
            //     this.fl_lics.splice(idx, 1);
            //   this.flAlerts.push({ type: 'danger', dismissible: true, msg: data.message, timeout: 5000 });
            // break;
            case "FSSAI":
              this.fssai_lics.map((l, key) => { if (lic.id == l.id) idx = key; return; });
              if (idx >= 0)
                this.fssai_lics.splice(idx, 1);
              this.check_for_fssai_original();
              this.fsAlerts.push({ type: 'danger', dismissible: true, msg: data.message, timeout: 5000 });
            break;
            default:
          }
        }
      });
    }
  }

  licenseSaved(license:License) {
    let idx = -1;
    switch(license.name)
    {
      // case "FL2/FL3":
      //   this.fl_lics.map((l, key) => { if (license.id == l.id) idx = key; return; });
      //   if (idx == -1)
      //   {
      //     license.is_del = true;
      //     this.fl_lics.push(license);
      //   }
      //   else
      //     this.fl_lics[idx] = license;
      //   this.flAlerts.push({ type: 'success', dismissible: true, msg: 'License details saved successfully.', timeout: 5000 });
      // break;
      case "FSSAI":
        this.fssai_lics.map((l, key) => { if (license.id == l.id) idx = key; return; });
        if (idx == -1)
        {  
          license.is_del = true;
          this.fssai_lics.push(license);
        }
        else
          this.fssai_lics[idx] = license;
        this.check_for_fssai_original();
        this.fsAlerts.push({ type: 'success', dismissible: true, msg: 'License details saved successfully.', timeout: 5000 });
      break;
      default:
    }
  }
}
