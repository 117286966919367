import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators  } from '@angular/forms';

import { State, PermitRoute } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-route-edit',
  templateUrl: './edit.component.html'
})
export class PermitRouteEditComponent implements OnChanges, OnInit {
  @Input('permit_id')
  permit_id:number;

  @Input()
  permitRoute:PermitRoute[];

  @Input()
  start_loc:string;

  @Input()
  start_state:string;

  @Input()
  end_loc:string;

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  states:State[] = [];
  customer_id:number;
  submitted = false;
  loading = false;
  fg: FormGroup;
  tnspt_bys = [{ id: "R", name: "Road" }, { id: "I", name: "Rail" }];

  constructor(
    private fb: FormBuilder,
    private dataService:DataService) {
  }

  ngOnInit() {
    // this.permitRoute.push(new PermitRoute(this.permit_id));
    this.dataService.getStates({}).subscribe((data:State[]) => { this.states = data; });
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.war_route && (changes.war_route as SimpleChange).currentValue && (changes.war_route as SimpleChange).currentValue.length > 0)
      this.permitRoute = (changes.permitRoute as SimpleChange).currentValue;
    // {
    // }
    // else
    // {
    //   this.permitRoute = [];
    // }
    this.buildForm();
  }

  initRoute(pRoute: PermitRoute) {
    let config:any = {};

    config['tnspt_by'] = [
      pRoute.tnspt_by,
      [
        Validators.required
      ]
    ];

    config['name'] = [
      pRoute.name,
      [
        Validators.required
      ]
    ];
  
    config['state_id'] = [
      pRoute.state_id,
      [
        Validators.required
      ]
    ];

    return this.fb.group(config);
  }

  private buildForm(): void {
    this.fg = this.fb.group({
      routes: this.fb.array( this.permitRoute.map(pR => { return this.initRoute(pR); }) )
    });
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  addRoute() {
    const control = <FormArray>this.fg.controls['routes'];
    let wR = new PermitRoute(this.permit_id);
    this.permitRoute.push(wR)
    control.push(this.initRoute(wR));
  }

  removeRoute(i:number) {
    const control = <FormArray>this.fg.controls['routes'];
    this.permitRoute.splice(i, 1);
    control.removeAt(i);
  }

  // show() {
  //   this.reset();
  // }

  // reset() {
  //   this.submitted = false;
  //   this.fg.reset({
  //   });
  //   return true;
  // }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      let sValue = this.fg.value;
      sValue.routes.forEach((r:PermitRoute, i:number) => {
        this.permitRoute[i].permit_id = this.permit_id;
        this.permitRoute[i].odr_num = i;
        this.permitRoute[i].tnspt_by = r.tnspt_by;
        this.permitRoute[i].name = r.name;
        this.permitRoute[i].state_id = r.state_id;
        this.permitRoute[i].state_name = this.states.filter(s => { return s.id == r.state_id; })[0].name;
      });

      this.loading = true;
      this.dataService.savePermitRoute(this.permitRoute).subscribe((data:any) => {
        if (data.message == "Permit Route saved successfully.")
        {
          if (data.ids)
          {
            data.ids.forEach((id:number, i:number) => {
              this.permitRoute[i].id = id;
            });
          }
          this.saved.emit(this.permitRoute);
        }
        this.loading = false;
      });
    }
  }
  
  public isControlHasError(formGroup:FormGroup, fieldName:string):boolean {
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }

  public getControls() {
    return (this.fg.get('routes') as FormArray).controls;
  }
}