import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: './public-view-permit.component.html'
})
export class PublicViewPermitComponent implements OnInit {
  loading = true;
  // encodedPermitNo: string;
  showError = false;
  permit_no: string;
  trustedUrl:SafeResourceUrl;

  constructor(
    private route:ActivatedRoute,
    private authenticationService:AuthenticationService,
    private sanitizer:DomSanitizer
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.encodedPermitNo) {
        this.permit_no = decodeURI(params.encodedPermitNo)
        this.getImportPermitDoc();
      } else {
        this.showError = true;
      }
    });
    //   this.id = pdata.permit.id;
    //   if (this.id)
    //   {
    //   }
  }

  getImportPermitDoc() {
    this.showError = false;
    this.loading = true;
    this.authenticationService.getImportPermitDoc({ permit_no: this.permit_no }).subscribe((blob:Blob) => {
      this.loading = false;
      // let blob = new Blob([res.blob()], { type: 'application/pdf' });
      if (blob.size > 0)
      {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      }
      else
      {
        this.trustedUrl = null;
        this.showError = true;
      }
    });
  }
}
