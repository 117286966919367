// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: '../svr/',
  loginAs(type: string) {
    const data = { email: '', password: '' };
    switch(type)
    {
      case "1":
        data.email = "thiru13@broadline.co.in";
        data.password = "12345678";
        break;
      case "2":
        data.email = "thiru@broadline.co.in";
        data.password = "12345678";
        break;
      case "3":
        data.email = "section@tasmac.com";
        data.password = "12345678";
        break;
      case "4":
        data.email = "accounts@tasmac.com";
        data.password = "12345678";
        break;
      case "5":
        data.email = "gm@tasmac.com";
        data.password = "12345678";
        break;
      case "6":
        data.email = "md@tasmac.com";
        data.password = "12345678";
        break;
      case "7":
        data.email = "section@pe.com";
        data.password = "12345678";
        break;
      case "8":
        data.email = "sv@pe.com";
        data.password = "12345678";
        break;
      case "9":
        data.email = "ac@pe.com";
        data.password = "12345678";
        break;
      case "10":
        data.email = "jc@pe.com";
        data.password = "12345678";
        break;
      case "11":
        data.email = "commissioner@pe.com";
        data.password = "12345678";
        break;
      case "12":
        data.email = "depot@tasmac.com";
        data.password = "12345678";
        break;
      case "13":
        data.email = "sectionh@tasmac.com";
        data.password = "12345678";
        break;
      case "14":
        data.email = "fc@pe.com";
        data.password = "12345678";
        break;
      case "15":
        data.email = "n.sathishkumar111@gmail.com";
        data.password = "12345678";
        break;

      default:
        data.email = "";
        data.password = "";
        break;
    }
    return data;
  }
};
