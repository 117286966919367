import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SimpleChange, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';

import { License, User } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'view-lic',
  templateUrl: './view.component.html'
})
export class ViewLicComponent implements OnInit { //  OnChanges,
  @Input()
  lic: License;

  @Input()
  allowDelete: boolean = false;
  
  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  trustedUrl: SafeResourceUrl;
  showSimple: boolean = true;
  loading: boolean = false;
  user: User;

  constructor(private dataService:DataService, private sanitizer: DomSanitizer) {  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      if (this.isUsrType(['TSEC', 'PSEC'])) {
        setTimeout(() => {
          this.showDocument()
        }, Math.floor(Math.random() * 3000) + 100);
      }
    }
    // if (this.lic.storage_name)
    // {
    //   this.dataService.getUploadedPdf(this.lic.storage_name).subscribe((blob:Blob) => {
    //     var fileURL = URL.createObjectURL(blob);
    //     this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
    //   });
    // }
  }

  isUsrType(ut:string|string[]): boolean {
    if (this.user && this.user.category_code) {
      if (typeof ut == 'string')
        return (this.user.category_code == ut);
      else {
        const regExp:RegExp = new RegExp((ut as string[]).join("|"));
        const match = this.user.category_code.match(regExp);
        return match && match.length > 0;
      }
    }
    else
      return false;
  }

  // ngOnChanges(changes:SimpleChanges|any) {
  //   if (changes.lic && (changes.lic as SimpleChange).currentValue && (changes.lic as SimpleChange).currentValue.storage_name) {
  //     const storage_name = (changes.lic as SimpleChange).currentValue.storage_name;
  //   }
  // }

  delLicense() {
    if (!confirm("Are to sure to delete?")) return;

    this.dataService.delLicense(this.lic.id).subscribe((data:any) => {
      if (data.message == "License details deleted successfully.")
      {
        this.deleted.emit(this.lic);
      }
    });
  }

  showDocument() {
    const storage_name = this.lic.storage_name;
    if (storage_name) {
      this.loading = true;
      this.dataService.getUploadedPdf(storage_name).subscribe((blob:Blob) => {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        this.showSimple = false;
        this.loading = false;
      }, (error: any) => {
        this.loading = false;
      });
    }
  }

  @ViewChild('lgModal0', { static: false }) 
  public lgModal0:ModalDirective;
  
  show() {
    this.lgModal0.show();
  }
}