import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './request-creation-blocked.component.html'
})
export class RequestCreationBlockedComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }
}
