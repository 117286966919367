import { Component, OnInit } from '@angular/core';

import { Config } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './myself.component.html'
})
export class CusMyselfComponent implements OnInit {
  customerLicenseTypes = Config.customerLicenseTypes;
  filters:any = {};
  customer:any = {};
  users:any = [];
  user:any = {};
  depots:any = [];

  constructor(
    private dataService:DataService
  ) {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filters.id = data.customer_id;
    }
  }

  ngOnInit() {
    this.dataService.getCustomer(this.filters).subscribe((data:any) => {
      this.customer = data.customer;
      this.users = data.users;
      this.user = this.users[0];
      this.depots = data.depots;
    });
  }
}
