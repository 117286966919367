import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { Stock, BrandOwner } from '../../config';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  templateUrl: './invoice.component.html',
})
export class InvoiceComponent implements OnInit {
  
  filters: any = { };
  items: Stock[] = [];
  Bdata: Stock[] = [];
  categoryNames: string[] = [];
  catGroups:any[] = [];
  currentCatName = '';
  chk_true = true;
  chk : any = 0;
  
  // brandOwnerNames: string[] = [];

  @ViewChild('lgModal0', { static: false })
  public lgModal0:ModalDirective;

  @ViewChild('lgModal1', { static: false })
  public lgModal1:ModalDirective;

  @ViewChild('lgModal2', { static: false })
  public lgModal2:ModalDirective;



  constructor(
    private router:Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.filters.category_code = data.category_code;
      if (data.customer_id) {
        this.filters.customer_id = data.customer_id;
      }
      if (data.supplier_id) {
        this.filters.supplier_id = data.supplier_id;
      }
    }
    this.getItems();
  }

  getItems() {
    this.Bdata = this.dataService.getDemoStock()
    // this.dataService.getItems(this.filters).subscribe((data:any[]) => {
      this.categoryNames = [];
      this.catGroups = [];

      console.log(this.Bdata);
      const tmpCatNames:{[index: string]: true } = { };
      this.Bdata = this.Bdata.map(d => {
        tmpCatNames[d.cat_name] = true
        if (d.supplier_names && (typeof d.supplier_names === 'string')) {
          d.supplier_names = d.supplier_names.split('@$@');
        }
        return d;
      });
      Object.keys(tmpCatNames).map(key => this.categoryNames.push(key));
      this.categoryNames.sort();

      this.items = this.Bdata;

      if (this.categoryNames.length > 0) {
        this.currentCatName = this.categoryNames[0];
        this.categoryNames.forEach(cat_name => {
          const catData = this.filterItemsByCatName(cat_name);
          this.catGroups.push({
            cat_name: cat_name,
            brand_owner_names: this.getBrandOwnerNames(catData),
            data: catData,
            currentPage: 1,
            itemsPerPage: 100
          });
        });
      }

      // this.filterBrandOwners(cat_name)

      // const bos:{[index:string] : string} = {};
      // data.forEach((i, idx) => {
      //   bos[i.brand_owner_id] = i.brand_owner_name;
      // });
      // this.brandOwners = Object.keys(bos).map(key => { return ({ 'id': parseInt(key), name: bos[key] } as BrandOwner) });

      // const brand_owners:BrandOwner[] = data.map(d => { return { 'id': d.brand_owner_id, name: d.brand_owner_name } });
      // this.brandOwners = brand_owners.filter( (bo, index, self) => {
      //   return self.indexOf(bo) === index;
      // });

    // });
  }

  // showActionButton() {
  //   this.router.navigate(["/rate"]);// !this.filters.customer_id;
  //   // return this.filters.category_code === 'TSEC'; 
  // }

  getBrandOwnerNames(data: Stock[]) {
    const brandOwnerNames:string[] = [];
    const tmpBONames:{[index: string]: true } = { };
    data.forEach(d => { tmpBONames[d.brand_owner_name] = true });
    Object.keys(tmpBONames).map(key => brandOwnerNames.push(key));
    brandOwnerNames.sort();
    return brandOwnerNames;
  }

  // filterBrandOwners(cat_name:string) {
  //   const brandOwnerNames:string[] = [];
  //   const tmpBONames:{[index: string]: true } = { };
  //   this.items.forEach(d => {
  //     if (d.cat_name === cat_name) {
  //       tmpBONames[d.brand_owner_name] = true
  //     }
  //   });
  //   Object.keys(tmpBONames).map(key => brandOwnerNames.push(key));
  //   brandOwnerNames.sort();
  //   return brandOwnerNames;
  // }

  filterPageData(items: Stock[], currentPage: number, itemsPerPage: number) {
    if (typeof itemsPerPage === "number" && itemsPerPage > 0)
      return items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    else
      return items;
  }

  filterItems(items: Stock[], brand_owner_name: string) {
    return items.filter(i => i.brand_owner_name === brand_owner_name);
  }

  filterItemsByCatName(cat_name: string) {
    return this.items.filter(i => i.cat_name === cat_name).map((i, idx) => { i.slno = idx + 1; return i; });
  }

  // pageChanged(event:any, group:any) {
  //   let page = event.page;
  //   let itemsPerPage = event.itemsPerPage;
  //   let data:Item[] = group.data;
  //   let newData = data.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  // }

  // getBrandCount(cat_name: string) {
  //   return this.items.filter(i => i.cat_name === cat_name).length;
  // }
  
  groupTrackByFn(index:number, item:Stock) {
    return item.id;
  }

  // getColSpan() {
  //   return (!this.filters.customer_id)?6:7;
  // }

  getCurStock(arr : any[]) {
    let sum = 0;
    arr.forEach(element => {
      sum += element.cur_stock;
    });
    // console.log(sum);
    return sum;
  }

  // getSalStockValue(arr : any[]) {
  //   let sum = 0;
  //   arr.forEach(element => {
  //     sum += element.cur_stock;
  //   });
  //   // console.log(sum);
  //   return sum;
  // }

  getSalStock(arr : any[]) {
    let sum = 0;
    arr.forEach(element => {
      sum += element.pur_stock;
    });
    // console.log(sum);
    return sum;
  }

  stockDetails(){
    this.lgModal0.show();
  }

  recptWise(){
    this.lgModal1.show();
  }

  recptWise2(){
    this.lgModal2.show();
  }

  cases(bt:number){

    let bs = Math.floor(bt/12);
    let bsr = bt%12;
    return (bs+"."+bsr);
  }

  invoieStatus(){
    this.lgModal1.hide();
    this.router.navigate(["/invoice-status"]);
  }
  

}
