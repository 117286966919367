import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { VData, Verf } from '../../config';

@Component({
  selector: 'verf-state',
  templateUrl: './verf-state.component.html'
})
export class VerfStateComponent implements OnChanges, OnInit {
  @Input()
  vdata:VData;
  @Input()
  lvf:Verf;

  user_category_code:string;
  constructor() {
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.user_category_code = data.category_code;
    }
  }

  ngOnChanges(changes:SimpleChanges|any) {
    // if (changes.vdata && (changes.vdata as SimpleChange).currentValue)
    // {
    //   let vdata:VData = (changes.vdata as SimpleChange).currentValue as VData;
    //   this.lvf = vdata.lvf_state;
    // }
  }

  isUserCategory(ut:string|string[]):boolean {
    if (typeof ut == 'string')
      return (this.user_category_code == ut);
    else
    {
      const regExp:RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.user_category_code.match(regExp);
      return match && match.length > 0;
    }
  }
}
