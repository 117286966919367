import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators  } from '@angular/forms';
import { Observable } from 'rxjs';

import { Config, Customer, License, User, Depot, StarGradation } from '../../config';
import { ValidationResult } from '../../shared/validators/util';
import { DateValidators } from '../../shared/validators/date.directive';
import { PwdValidators } from '../../shared/validators/password.directive';
import { NumberValidator } from '../../shared/validators/number.directive';
import { UrlValidator } from '../../shared/validators/url.directive';
import { EmailValidator } from '../../shared/validators/email.directive';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';
import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: 'signup.component.html'
})
export class LicenseeSignupComponent implements OnInit {
  minDate = new Date(1930, 0, 1);
  maxDate: Date = new Date();
  model: any;
  submitted = false;
  loading = false;
  customerLicenseTypes = Config.customerLicenseTypes;
  regForm: FormGroup;
  private license: License;
  private customer: Customer;
  private user: User;
  depots: Depot[] = [];
  star_gradations: StarGradation[] = [];
  private formFiles: any;
  formErrors: any = { };
  formMessages: any = { };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {
    this.customer = new Customer();
    this.license = new License('O', 'FL2/FL3');
    this.user = new User();
    this.user.category_code = Config.defaultRegistrationUserCategory;

    this.buildForm(); // this.customer
  }

  ngOnInit() {
    this.authenticationService.allowRegistration({}).subscribe((data:any) => {
      if (data.token) {
        localStorage.setItem("authorizationData", JSON.stringify(data.token));
        this.dataService.getDepots({}).subscribe(data => { this.depots = data; });
        this.dataService.getStarGradations({}).subscribe(data => { this.star_gradations = data; });
      } else {
        // Server Error
        this.router.navigate(['/login']);
      }
    });
  }

  checkUniqueName(customer_id: number): ValidatorFn {
    return (control: FormControl): Observable<ValidationResult> => {
      let searchData:any = { id: customer_id, name: control.value || ""};
      return new Observable((ob:any) => {
        this.dataService.isCustomerNameExist(searchData).subscribe(data => {
          if (data === true)
          {
            this.formErrors['name'].push(this.formMessages['name']['checkUniqueName']);
            ob.next({ 'checkUniqueName': true } as ValidationResult);
            ob.complete();
          }
          else
          {
            ob.next(null as ValidationResult);
            ob.complete();
          }
        });
      });
    }
  }

  checkUniqueEmail(user_id: number): ValidatorFn {
    return (control:FormControl):Observable<ValidationResult> => {
      let searchData:any = { id: user_id, email: control.value || ""};
      return new Observable((ob:any) => {
        this.dataService.isEmailExist(searchData).subscribe(data => {
          if (data === true)
          {
            this.formErrors['u_email'].push(this.formMessages['u_email']['checkUniqueEmail']);
            ob.next({ 'checkUniqueEmail': true } as ValidationResult);
            ob.complete();
          }
          else
          {
            ob.next(null as ValidationResult);
            ob.complete();
          }
        });
      });
    }
  }

  private buildForm(): void { //customer: Customer
    let controlsConfig:any = {};
    // let licenseConfig:any = {};

    controlsConfig['no'] = [
      this.license.no, 
      [
        Validators.required, 
        Validators.minLength(5), 
        Validators.maxLength(30)
      ]
    ];
    this.formErrors['no'] = [];
    this.formMessages['no'] = {
      'required':      'License No. is required.',
      'minlength':     'License No. must be at least 5 characters long.',
      'maxlength':     'License No. cannot be more than 30 characters long.'
    };

    controlsConfig['iss_date'] = [
      this.license.iss_date,
      [
        Validators.required,
        // DateValidators.isDate,
        // DateValidators.minDate('01/01/2000'),
        DateValidators.maxDate(Config.now())
      ]
    ];
    this.formErrors['iss_date'] = [];
    this.formMessages['iss_date'] = {
      'required':      'License dt. is required.',
      // 'isDate':        'License dt. should be in DD/MM/YYYY format.',
      // 'minDate':       'License dt. should not be less than 01/01/2000.',
      'maxDate':       'License dt. cannot be greater than today.'
    };

    controlsConfig['val_date'] = [
      this.license.val_date,
      // "01/01/2015", 
      [
        Validators.required,
        // DateValidators.isDate,
        DateValidators.minDate('iss_date')
        // DateValidators.maxDate(Config.oneYearFromNow())
      ]
    ];
    this.formErrors['val_date'] = [];
    this.formMessages['val_date'] = {
      'required':      'Valid till is required.',
      // 'isDate':        'Valid till should be in DD/MM/YYYY format.',
      'minDate':       'Valid till should not be less than License date.'
      // 'maxDate':       'Valid till cannot be greater than one year from today.'
    };

    controlsConfig['org_lic'] = [
      null,
      [
        Validators.required
      ]
    ];
    this.formErrors['org_lic'] = [];
    this.formMessages['org_lic'] = {
      'required':      'FL2/FL3 Original License is required.'
    };
    // controlsConfig['license'] = this.fb.group(licenseConfig);

    controlsConfig['type'] = [
      this.customer.type, 
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3)
      ]
    ];
    this.formErrors['type'] = [];
    this.formMessages['type'] = {
      'required':      '"Licensed as" is required.',
      'minlength':     '"Licensed as" must be at least 3 characters long.',
      'maxlength':     '"Licensed as" cannot be more than 3 characters long.'
    };

    controlsConfig['star_gradation_id'] = [
      this.customer.star_gradation_id, 
      [
      ]
    ];
    this.formErrors['star_gradation_id'] = [];
    this.formMessages['star_gradation_id'] = {
      'required':      'Star Gradation is required.'
    };

    controlsConfig['gstin'] = [
      this.customer.gstin, 
      [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15)
      ]
    ];
    this.formErrors['gstin'] = [];
    this.formMessages['gstin'] = {
      'required': 'GSTIN is required.',
      'minlength': 'GSTIN must be at least 15 characters long.',
      'maxlength': 'GSTIN cannot be more than 15 characters long.'
    };

    controlsConfig['name'] = [
      this.customer.name, 
      [
        Validators.required
      ],
      [
        // async validation
        this.checkUniqueName(null)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      checkUniqueName: 'Licensee name already exist. If you have already registered please login. If you are registering additional bar, add "main"/"additional" along with the name.'
    };

    controlsConfig['add1'] = [
      this.customer.add1, 
      [
        Validators.required
      ]
    ];
    this.formErrors['add1'] = [];
    this.formMessages['add1'] = {
      'required':      'Address is required.'
    };

    controlsConfig['add2'] = [
      this.customer.add2, 
      [
      ]
    ];
    this.formErrors['add2'] = [];
    this.formMessages['add2'] = {
    };

    controlsConfig['add3'] = [
      this.customer.add3, 
      [
      ]
    ];
    this.formErrors['add3'] = [];
    this.formMessages['add3'] = {
    };

    controlsConfig['pin'] = [
      this.customer.pin, 
      [
        NumberValidator.isNumber,
        NumberValidator.min(1),
        Validators.minLength(6),
        Validators.maxLength(6)
      ]
    ];
    this.formErrors['pin'] = [];
    this.formMessages['pin'] = {
      'isnumber': 'Pincode should be a valid 6 digit number.',
      'min': 'Pincode should be a valid 6 digit number.',
      'minlength': 'Pincode should be entered in full 6 characters.',
      'maxlength': 'Pincode should not exceed 6 characters.'
    };

    controlsConfig['web'] = [
      this.customer.web, 
      [
        UrlValidator.isUrl
      ]
    ];
    this.formErrors['web'] = [];
    this.formMessages['web'] = {
      'isurl': 'Please provide valid full Web Address. ex: http://broadline.co.in'
    };

    controlsConfig['depot_id'] = [
      this.customer.depot_id, 
      [
        Validators.required
      ]
    ];
    this.formErrors['depot_id'] = [];
    this.formMessages['depot_id'] = {
      'required':      'TASMAC Depot is required.'
    };

    controlsConfig['u_name'] = [
      this.user.name, 
      [
        Validators.required
      ]
    ];
    this.formErrors['u_name'] = [];
    this.formMessages['u_name'] = {
      'required':      'Contact Person is required.'
    };

    controlsConfig['u_mobile'] = [
      this.user.mobile, 
      [
        Validators.minLength(10),
        Validators.maxLength(10),
        NumberValidator.isNumber
      ]
    ];
    this.formErrors['u_mobile'] = [];
    this.formMessages['u_mobile'] = {
      'minlength': 'Contact No. length should be atleast 10 characters.',
      'maxlength': 'Contact No. length should not be greater than 10 characters.',
      'isnumber': 'Please provide a valid Contact No..'
    };

    controlsConfig['u_email'] = [
      this.user.email, 
      [
        Validators.required,
        Validators.maxLength(50),
        EmailValidator.isEmail
      ],
      [
        // async validation
        this.checkUniqueEmail(null)
      ]
    ];
    this.formErrors['u_email'] = [];
    this.formMessages['u_email'] = {
      'required': "eMail ID is required.",
      'maxlength': "eMail ID should not be more than 50 characters long.",
      'isemail': "Please provide valid eMail ID.",
      'checkUniqueEmail': 'eMail ID already exist. If you have already registered please login, else use someother eMail ID.'
    };

    controlsConfig['u_pwd'] = [
      this.user.pwd, 
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['u_pwd'] = [];
    this.formMessages['u_pwd'] = {
      'required':      'Password is required.',
      'minlength':     'Password must be at least 8 characters long.',
      'maxlength':     'Password cannot be more than 100 characters long.'
    };

    controlsConfig['u_repwd'] = [
      this.user.repwd, 
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
        PwdValidators.pwdEqual('u_pwd')
      ]
    ];
    this.formErrors['u_repwd'] = [];
    this.formMessages['u_repwd'] = {
      'required':      "Re-Type Password is required.",
      'minlength':     "Re-Type Password must be at least 8 characters long.",
      'maxlength':     "Re-Type Password cannot be more than 100 characters long.",
      'pwdEqual':      "Re-Type Password should be same as Password."
    };

    this.regForm = this.fb.group(controlsConfig);
    this.regForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.regForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.formFiles = fileInput.target.files[0];
      this.regForm.controls['org_lic'].setValue("File Set");
    } else {
      this.regForm.controls['org_lic'].setValue(null);
    }
  }

  onValueChanged(data?: any) {
    if (!this.regForm) { return; }
    
    const form = this.regForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
    // if (!this.formFiles)
    // {
    //   this.formErrors["org_lic"].push('required');
    //   return;
    // }

  }

  onTypeChanged(type?: string) {
    const starControl = this.regForm.get('star_gradation_id')
    if (type === 'FL2' || !type) {
      starControl.setValue(null);
      starControl.disable();
    } else {
      starControl.enable();
    }
  }

  public register() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.regForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.regForm.valid) {
      let sValue = this.regForm.value;
      this.license.no = sValue.no;
      this.license.iss_date = sValue.iss_date.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
      this.license.val_date = sValue.val_date.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
      this.customer.gstin = sValue.gstin;
      this.customer.name = sValue.name;
      this.customer.type = sValue.type;
      if (sValue.star_gradation_id)
        this.customer.star_gradation_id = sValue.star_gradation_id;
      this.customer.add1 = sValue.add1;
      this.customer.add2 = sValue.add2;
      this.customer.add3 = sValue.add3;
      this.customer.pin = sValue.pin;
      this.customer.web = sValue.web;
      this.customer.depot_id = sValue.depot_id;
      this.user.name = sValue.u_name;
      this.user.mobile = sValue.u_mobile;
      this.user.email = sValue.u_email;
      this.user.pwd = sValue.u_pwd;
      this.user.repwd = sValue.u_repwd;

      this.loading = true;
      let reg_data = { customer: this.customer, license: this.license, user: this.user };
      this.dataService.register(reg_data, this.formFiles).subscribe((data:any) => {
        if (data.message == "Registration successfully done.")
        {
          if (data.customer_id)
          {
            this.customer.id = data.customer_id;
            this.license.customer_id = data.customer_id;
            this.user.customer_id = data.customer_id;
          }
          if (data.license_id)
            this.license.id = data.license_id;
          if (data.license_doc_id)
            this.license.license_doc_id = data.license_doc_id;
          if (data.user_id)
            this.user.id = data.user_id;
          this.router.navigate(["/licensee-signup-success"]);
        }
        else
        if (data.errorMessage)
          this.alertService.error(data.errorMessage);
        
        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string, groupName:string = ''):boolean {
    let formGroup: FormGroup = (groupName == '')?this.regForm:(this.regForm.controls[groupName] as FormGroup);
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}
