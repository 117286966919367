import { Component, Input } from '@angular/core';

import { PermitFee, PermitItem } from '../../../config';

@Component({
  selector: 'apf',
  templateUrl: './apf.component.html'
})
export class PermitAPFComponent {
  @Input('permititems')
  permititems:PermitItem[] = [];
  
  @Input()
  fee:PermitFee;

  constructor() {
  }

  getwineItems() {
    let wItems = this.permititems.filter(d => { return (d.apf_rate && d.apf_rate > 0); });
    return wItems;
  }
}
