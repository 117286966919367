import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { DataService } from '../../data.service';
import { Indent, UserCategory, Config, User } from '../../config';

@Component({
  templateUrl: './indents.component.html'
})
export class IndentsComponent implements OnInit {
  currentUser: User;
  userCategories: UserCategory[] = Config.userCategories.filter(uc => { return uc.isInvolvedInIndentApproval || uc.code === 'SUP'; });
  filters: any = {};
  indents: Indent[] = [];

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.dataService.filters.indent = null;
    if (localStorage.getItem('currentUser'))
    {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.route.params.subscribe((params: Params) => {
      this.filters = {};
      const filterBy = params;

      this.filters['for'] = (filterBy['for'])?filterBy['for']:null;
      switch(this.filters['for']) {
        case "N":
          this.filters.status = ['REQ', 'CLR-SUP', 'CLD-TSEH', 'CLR-TSEH']; // (filterBy.status)?filterBy.status:null;
          break;
        case 'P':
          this.filters.status = ['APP'];
          break;
      }
      // this.filters.is_fl_permit = 'ALL';

      if (this.currentUser)
      {
        const data = this.currentUser;
        // if (data.customer_id)
        //   this.filters.customer_id = data.customer_id;
        // if (data.depot_id)
        //   this.filters.depot_id = data.depot_id;
        // if (data.level && !(this.filters.for === 'N' || this.filters.for === 'R' || this.filters.for === 'C'))
        //   this.filters.level = data.level;
        if (data.category_code)
          this.filters.usr_type_code = data.category_code;
      }

      this.getIndents();
    });
  }

  getIndents() {
    this.dataService.getIndents(this.filters).subscribe((data:Indent[]) => {
      this.indents = data;
      this.indents = this.indents.map(p => {
        p.indent_date = new Date(p.indent_date);

        if (p.level < this.currentUser.level && p.status === 'REQ') {
          p.action = 'View'
        }

        if (p.level === this.currentUser.level && p.status === 'REQ') {
          p.action = 'Verify';
        }

        if (p.level > this.currentUser.level && p.status === 'REQ') {
          p.action = 'View';
        }

        if (p.status === 'CLR-SUP') {
          p.action = 'View';
        }

        if (p.status === 'CLD-TSEH') {
          p.action = (this.currentUser.category_code === 'TSEH')?'Verify':'View';
        }

        if (p.status === 'CLR-TSEH') {
          p.action = (this.currentUser.category_code === 'TSEH')?'Clarify':'View';
        }

        // if (p.status == 'REV-INT')
        //   p.status = 'APP';
        
        // if (p.status == 'CAN-INT')
        //   p.status = 'APP';
        
        p.approvalOvertakeCodes = Config.userCategories.filter(uc => uc.level === p.level)[0].approvalOvertakeCodes;
        p.approvalOvertake = (p.approvalOvertakeCodes.indexOf(this.currentUser.category_code) >= 0);

        return p;
      });
    });
  }

  getColSpan() {
    return (this.filters['for'] === 'N')?9:12;
  }

  filterIndents(level: number) {
    return this.indents.filter(p => p.level === level);
  }

  onAction(p: Indent, action: string) {

    if (this.filters['for'])
    {
      const fFor: string = this.filters['for'];
      switch (true) {
        case (action === 'Verify' || action === 'Clarify'):
          this.dataService.filters.indent = { id: p.id, supplier_id: p.supplier_id, back_url: '/indents/' + fFor };
          this.router.navigate(['/sup-indent']);
          break;
        default:
          this.dataService.filters.sup_indent_view = { id: p.id, supplier_id: p.supplier_id, back_url: '/indents/' + fFor };
          this.router.navigate(['/sup-indent-view']);
      }

    }
  }
}
