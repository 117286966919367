import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';

import { environment } from '../../../environments/environment';
import { DataService } from '../../data.service';
import { Permit } from '../../config';

@Component({
  templateUrl: './list.component.html'
})
export class PermitListComponent implements OnInit {
  filters: any = {};
  permits: Permit[] = [];
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filters.customer_id = data.customer_id;
    }
    this.route.data.subscribe((data:any) => {
      const filterBy:any = data.filterBy;
      this.filters['for'] = (filterBy['for'])?filterBy['for']:null;
      
      if (filterBy.hasOwnProperty('status'))
        this.filters.status = filterBy.status;
      else
        delete this.filters.status;
      
      if (filterBy.hasOwnProperty('is_revalidated'))
        this.filters.is_revalidated = filterBy.is_revalidated;
      else
        delete this.filters.is_revalidated;
      
      if (filterBy.hasOwnProperty('is_cancelled'))
        this.filters.is_cancelled = filterBy.is_cancelled;
      else
        delete this.filters.is_cancelled;

      if (filterBy.hasOwnProperty('ds_alone'))
        this.filters.ds_alone = filterBy.ds_alone;
      else
        delete this.filters.ds_alone;
      
      this.filters.is_fl_permit = true;
      this.getPermits();
    });
  }

  getPermits() {
    this.dataService.getPermits(this.filters).subscribe((data:Permit[]) => {
      this.permits = data;
      this.permits = this.permits.map(p => {
        p.req_date = new Date(p.req_date);
        if (p.permit_date)
          p.permit_date = new Date(p.permit_date);
        
        if (p.extend_period) // && p.status == "APP-REV"
          p.period += p.extend_period;
        if (p.ext_app_period) //  && p.status == "APP-EXT"
          p.period += p.ext_app_period;

        if (p.valid_date)
          p.valid_date = new Date(p.valid_date);

        if (p.reval_date)
        {
          p.reval_date = new Date(p.reval_date);
          p.valid_date = new Date(p.reval_date);
        }

        if (p.ext_valid_date)
        {
          p.ext_valid_date = new Date(p.ext_valid_date);
          p.valid_date = new Date(p.ext_valid_date);
        }
        
        const today:Date = new Date();
        today.setHours(0, 0, 0, 0);
        p.is_expired = (p.valid_date && p.valid_date < today);

        if (p.status == "APP" && p.is_revalidated)
          p.status = "REV-INT";
        if (p.status == "APP" && p.is_cancelled)
          p.status = "CAN-INT";
        return p;
      });
    });
  }

  onAction(p: Permit) {
    switch (true)
    {
      case (p.status == "CLR"):
        this.dataService.filters.permit = { id: p.id, for: this.filters.for, back_url: '/permit_list/' + this.filters.for };
        this.router.navigate(["/permit"]);
        break;
      case (this.filters.for == "R"):
        this.router.navigate(["/permit_reval", p.id]);
        break;
      case (this.filters.for == "C"):
        this.router.navigate(["/permit_cancel", p.id]);
        break;
      default:
        this.dataService.filters.permit_view = { id: p.id, back_url: '/permit_list/' + this.filters.for };
        this.router.navigate(["/permit_view"]);
    }
  }

  download(p: Permit) {
    this.loading = true;
    if (p.ip_storage_name) {
      const sn = ((environment.production)?'/':'../') + p.ip_storage_name;
      const fileUrl = this.sanitizer.sanitize(SecurityContext.URL, sn);
      FileSaver.saveAs(fileUrl, p.permit_no + ".pdf");
    }
    this.loading = false;
  }
}
