import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../data.service';
import { Permit } from '../../config';

@Component({
  templateUrl: './permits_to_be_printed.component.html'
})
export class PermitsToBePrintedComponent implements OnInit {
  filters = { status: ['APP', 'REV-INT', 'CAN-INT', 'CMP'], is_p_prn: 0, is_fl_permit: 'ALL' };
  permits: Permit[] = [];
  loading = false;

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.getPermits();
  }

  getPermits() {
    var filters = { ...this.filters };
    this.loading = true;
    this.dataService.getPermits(filters).subscribe((data:Permit[]) => {
      this.loading = false;
      this.permits = data;
      this.permits = this.permits.map(p => {
        p.req_date = new Date(p.req_date);
        if (p.permit_date)
          p.permit_date = new Date(p.permit_date);
        if (p.extend_period) // && p.status == "APP-REV"
          p.period += p.extend_period;
        if (p.ext_app_period) //  && p.status == "APP-EXT"
          p.period += p.ext_app_period;

        if (p.valid_date)
          p.valid_date = new Date(p.valid_date);

        if (p.reval_date)
        {
          p.reval_date = new Date(p.reval_date);
          p.valid_date = new Date(p.reval_date);
        }

        if (p.ext_valid_date)
        {
          p.ext_valid_date = new Date(p.ext_valid_date);
          p.valid_date = new Date(p.ext_valid_date);
        }
        
        const today:Date = new Date();
        today.setHours(0, 0, 0, 0);
        p.is_expired = (p.valid_date && p.valid_date < today);

        if (p.status == "REV-INT")
          p.status = "APP";
        
        if (p.status == "CAN-INT")
          p.status = "APP";
        
        return p;
      });
    });
  }

  onAction(p:Permit) {
    this.dataService.filters.permit_print = { id: p.id };
    this.router.navigate(["/permit_print"]);
  }
}
