import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';

import { Settings } from '../../config';
import { NumberValidator } from '../../shared/validators/number.directive';
import { EmailValidator } from '../../shared/validators/email.directive';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './edit.component.html',
})
export class SettingsEditComponent implements OnInit {
  submitted = false;
  loading = false;
  dForm: FormGroup;
  private settings:Settings;
  formErrors:any = { };
  formMessages:any = { };

  constructor(
    private fb:FormBuilder,
    private dataService:DataService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    this.settings = new Settings();
    this.buildForm();
    this.dataService.getSettings({}).subscribe(data => {
      this.settings = data;
      let frmFields = {
        current_comm_name: this.settings.current_comm_name || null,
        current_comm_designation: this.settings.current_comm_designation || null,
        st_name: this.settings.st_name || null,
        st_pc: this.settings.st_pc || null,
        tasmac_gstin: this.settings.tasmac_gstin || null,
        tasmac_pan: this.settings.tasmac_pan || null,
        tasmac_cin: this.settings.tasmac_cin || null,
        feedback_email: this.settings.feedback_email || null
      };
      this.dForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['current_comm_name'] = [
      this.settings.current_comm_name,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['current_comm_name'] = [];
    this.formMessages['current_comm_name'] = {
      'required': 'Name is required.',
      'maxlength': 'Name should not exceed 200 characters.'
    };

    controlsConfig['current_comm_designation'] = [
      this.settings.current_comm_designation,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['current_comm_designation'] = [];
    this.formMessages['current_comm_designation'] = {
      'required': 'Designation is required.',
      'maxlength': 'Designation should not exceed 200 characters.'
    };

    controlsConfig['st_name'] = [
      this.settings.st_name,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['st_name'] = [];
    this.formMessages['st_name'] = {
      'required': 'Tax Name is required.',
      'maxlength': 'Tax Name should not exceed 200 characters.'
    };

    controlsConfig['st_pc'] = [
      this.settings.st_pc,
      [
        Validators.required,
        NumberValidator.isNumber,
        NumberValidator.min(0),
        NumberValidator.max(100)
      ]
    ];
    this.formErrors['st_pc'] = [];
    this.formMessages['st_pc'] = {
      'required': 'Tax Percent is required.',
      'isnumber': 'Tax Percent should be a valid number.',
      'min': 'Tax Percent should not be less than 0.',
      'max': 'Tax Percent should not be greater than 100.'
      // 'maxlength': 'Tax Percent should not exceed 200 characters.'
    };

    controlsConfig['tasmac_gstin'] = [
      this.settings.tasmac_gstin,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['tasmac_gstin'] = [];
    this.formMessages['tasmac_gstin'] = {
      'required': 'Name is required.',
      'maxlength': 'Name should not exceed 200 characters.'
    };

    controlsConfig['tasmac_pan'] = [
      this.settings.tasmac_pan,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['tasmac_pan'] = [];
    this.formMessages['tasmac_pan'] = {
      'required': 'Designation is required.',
      'maxlength': 'Designation should not exceed 200 characters.'
    };

    controlsConfig['tasmac_cin'] = [
      this.settings.tasmac_cin,
      [
        Validators.required,
        Validators.maxLength(200)
      ]
    ];
    this.formErrors['tasmac_cin'] = [];
    this.formMessages['tasmac_cin'] = {
      'required': 'Tax Name is required.',
      'maxlength': 'Tax Name should not exceed 200 characters.'
    };

    controlsConfig['feedback_email'] = [
      this.settings.feedback_email, 
      [
        Validators.required,
        Validators.maxLength(50),
        EmailValidator.isEmail
      ]
    ];
    this.formErrors['feedback_email'] = [];
    this.formMessages['feedback_email'] = {
      'required': "eMail ID is required.",
      'maxlength': "eMail ID should not be more than 50 characters long.",
      'isemail': "Please provide valid eMail ID."
    };

    this.dForm = this.fb.group(controlsConfig);
    this.dForm.valueChanges.subscribe(data => this.onValueChanged());
    this.dForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.dForm) { return; }
    
    const form = this.dForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.dForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.dForm.valid) {
      let sValue = this.dForm.value;
      this.settings.current_comm_name = sValue.current_comm_name;
      this.settings.current_comm_designation = sValue.current_comm_designation;
      this.settings.st_name = sValue.st_name;
      this.settings.st_pc = sValue.st_pc;
      this.settings.tasmac_gstin = sValue.tasmac_gstin;
      this.settings.tasmac_pan = sValue.tasmac_pan;
      this.settings.tasmac_cin = sValue.tasmac_cin;
      this.settings.feedback_email = sValue.feedback_email;

      this.loading = true;
      this.dataService.saveSettings(this.settings).subscribe((data:any) => {
        if (data.message == "Settings saved successfully.")
        {
          this.alertService.success(data.message, true);
          // this.router.navigate(["/settingss"]);
        }
        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string):boolean {
    if (!this.dForm) return true;
    let formGroup: FormGroup = this.dForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.dForm.controls[fieldName].touched || 
  }
}
