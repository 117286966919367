import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AlertService } from '../alert/alert.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './list.component.html'
})
export class MySQLBackupListComponent implements OnInit {
  loading = false;
  data: any[];
  
  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    this.getFiles();
  }

  private makeFilesList(file_list:any[]) {
    this.data = file_list.map(e => {
      return { doDownload: true, file_name: e };
    });
  }

  public getFiles() {
    this.loading = true;
    this.dataService.getFiles().subscribe((file_list:any[]) => {
      this.makeFilesList(file_list);
      this.loading = false;
    });
  }

  public downloadMySQLBackupFile(file_name:string) {
    this.loading = true;
    this.dataService.downloadMySQLBackupFile({file_name: file_name}).subscribe((blob:Blob) => {
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveOrOpenBlob(blob, file_name);
      else
      {
        let a:any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var fileURL = URL.createObjectURL(blob);
        a.href = fileURL;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      }
      this.loading = false;
    });
  }

  public deleteMySQLBackupFile(file_name:string) {
    this.loading = true;
    this.dataService.deleteMySQLBackupFile({file_name: file_name}).subscribe((res:any) => {
      if (res.message == "MySQL backup file deleted successfully.")
      {
        this.getFiles();
        this.alertService.success(res.message);
      }
      else
        this.alertService.error(res.message);
      this.loading = false;
    });
  }

  public createBackup() {
    this.alertService.clearAlert();

    this.loading = true;
    this.dataService.createBackup().subscribe((data:any) => {
      if (data.message == "MySQL Backup initiated successfully.")
      {
        this.makeFilesList(data.file_list);
        this.alertService.success(data.message);
      }
      else
        this.alertService.error(data.message);
      this.loading = false;
    });
  };

  public goBack() {
    this.alertService.clearAlert();
    this.router.navigate(["/home"]);
  }
}
