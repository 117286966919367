import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { State } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './new.component.html',
})
export class StateComponent implements OnInit {
  filter: any;
  submitted = false;
  loading = false;
  stForm: FormGroup;
  private state: State;
  formErrors:any = { };
  formMessages:any = { };

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.filter = {};
    if (this.dataService.filters.state) {
      this.state = this.dataService.filters.state;
      this.buildForm();
    } else {
      this.goBack();
    }
  }
  
  // this.getState();
    // this.state = new State();
    // { id: id }    
    // this.route.data.subscribe((data:{state:State}) => {
    //   this.state = data.state;
    //   let frmFields = {
    //     code: this.state.code,
    //     name: this.state.name,
    //     permit_period: this.state.permit_period,
    //     excise_comm_name: this.state.excise_comm_name,
    //     excise_off_email: this.state.excise_off_email
    //   }
    //   this.stForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    // });

  // getState() {
  //   this.dataService.getState(this.filter).subscribe((data: State) => {
  //     this.state = data;
  //   });
  // }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['code'] = [
      this.state.code || null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(5)
      ]
    ];
    this.formErrors['code'] = [];
    this.formMessages['code'] = {
      'required':      'Code is required.',
      'minlength':     'Code must be at least 2 characters long.',
      'maxlength':     'Code should not exceed 5 characters.'
    };

    controlsConfig['name'] = [
      this.state.name || null,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      'maxlength':     'Name should not exceed 100 characters.'
    };

    controlsConfig['permit_period'] = [
      this.state.permit_period || 60,
      [
        Validators.required
      ]
    ];
    this.formErrors['permit_period'] = [];
    this.formMessages['permit_period'] = {
      'required':      'Permit Period is required.'
    };

    controlsConfig['excise_comm_name'] = [
      this.state.excise_comm_name || null,
      [
        // Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['excise_comm_name'] = [];
    this.formMessages['excise_comm_name'] = {
      // 'required': 'Name is required.',
      'maxlength': '"State Excise Comm. Name / Addressed By" should not exceed 100 characters.'
    };

    controlsConfig['excise_off_email'] = [
      this.state.excise_off_email || null,
      [
        // Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['excise_off_email'] = [];
    this.formMessages['excise_off_email'] = {
      // 'required': 'Name is required.',
      'maxlength': 'State Excise Official Email ID should not exceed 100 characters.'
    };

    this.stForm = this.fb.group(controlsConfig);
    this.stForm.valueChanges.subscribe(() => this.onValueChanged());
    this.stForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.stForm) { return; }
    
    const form = this.stForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.stForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.stForm.valid) {
      let sValue = this.stForm.value as State;
      sValue.id = this.state.id || null;

      this.loading = true;
      this.dataService.saveState(sValue).subscribe((data:any) => {
        if (data.message == "State saved successfully.")
        {
          if (data.id)
            this.state.id = data.id;
          this.goBack();
          // this.router.navigate(["/states"]);
        }
        this.loading = false;
      });
    }
  };

      // this.state.code = sValue.code;
      // this.state.name = sValue.name;
      // this.state.permit_period = sValue.permit_period;
      // this.state.excise_comm_name
  // changeStatus() {
  //   this.loading = true;
  //   if (confirm('Are you sure to disable user?'))
  //     this.user.status = (this.user.status)?0:1;
  //   this.loading = false;
  // }

  public isControlHasError(fieldName:string):boolean {
    if (!this.stForm) return true;
    let formGroup: FormGroup = this.stForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.stForm.controls[fieldName].touched || 
  }

  goBack() {
    // TODO::Add animation for added or modified data
    this.dataService.filters.state = this.state;
    this.router.navigate(['/states']);
  }
}
