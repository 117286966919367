import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { VData, Verf } from '../../config';

@Component({
  selector: 'verf-msg',
  templateUrl: './verf-msg.component.html'
})
export class VerfMsgComponent implements OnChanges, OnInit {
  @Input()
  vdata:VData;
  @Input()
  lvf:Verf;

  show_all = false;
  constructor() {  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges|any) {
    // if (changes.vdata && (changes.vdata as SimpleChange).currentValue)
    // {
    //   let vdata:VData = (changes.vdata as SimpleChange).currentValue as VData;
    //   this.lvf = vdata.lvf_state;
    // }
  }

  toggle(show_state:boolean){
    this.show_all = show_state;
  }
}