import { Component, Input, OnChanges, OnInit, SimpleChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup  } from '@angular/forms';

import { VData, Verf, User } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'verf-add',
  templateUrl: './verf-add.component.html'
})
export class VerfAddComponent implements OnChanges, OnInit {
  @Input()
  vdata:VData;
  // @Input()
  // verf_code:string;
  @Input()
  customer_id:number;

  @Input()
  permit_id:number;

  cuser:User;
  lvf:Verf;
  
  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  user_category_code:string;

  constructor(
    private fb: FormBuilder,
    private dataService:DataService
  ) {
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    // }
  }

  ngOnInit() {
    this.cuser = new User();
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.cuser.id = data.id;
      this.cuser.name = data.name;
      this.cuser.designation = data.designation;
      // user_category
      this.user_category_code = data.category_code;
    }
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.vdata && (changes.vdata as SimpleChange).currentValue)
    {
      let vdata:VData = (changes.vdata as SimpleChange).currentValue as VData;
      this.lvf = vdata.make_new_verf();
      this.buildForm();
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['remarks'] = [
      this.lvf.remarks,
      [
      ]
    ];
    this.errors['remarks'] = [];
    this.messages['remarks'] = {
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged());
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  save(status:string) {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    let sValue = this.fg.value;
    if (status == 'CLD' && !sValue.remarks)
    {
      alert("Comment should not be empty");
      return;
    }
    if (status == 'CLR' && !sValue.remarks)
    {
      alert("Clarification Request should not be empty");
      return;
    }
    if (this.fg.valid) {
      this.lvf.remarks = sValue.remarks;
      this.lvf.customer_id = this.customer_id;
      this.lvf.permit_id = this.permit_id;
      this.lvf.status = status;
      this.lvf.user_id = this.cuser.id;
      this.loading = true;
      this.dataService.saveVerf(this.lvf).subscribe((data:any) => {
        if (data.message == "Verification details saved successfully.")
        {
          if (data.id)
          {
            this.lvf.id = data.id;
            this.lvf.status_by = this.cuser.name + (this.cuser.designation?(", "+this.cuser.designation):"");
            this.lvf.verf_date = new Date();
            this.vdata.added_verf(this.lvf);

            this.lvf = this.vdata.make_new_verf();
            this.fg.reset({'remarks': this.lvf.remarks});
          }
        }
        this.loading = false;
      });
    }
  }

  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }

  isUserCategory(ut:string|string[]):boolean {
    if (typeof ut == 'string')
      return (this.user_category_code == ut);
    else
    {
      const regExp:RegExp = new RegExp((ut as string[]).join("|"));
      const match = this.user_category_code.match(regExp);
      return match && match.length > 0;
    }
  }
}