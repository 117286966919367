import { Component, Input } from '@angular/core';

import { PermitFee } from '../../../config';

@Component({
  selector: 'st',
  templateUrl: './st.component.html'
})
export class PermitSTComponent {
  @Input('apf')
  apf:PermitFee;

  @Input('sc')
  sc:PermitFee;

  @Input('coel')
  coel:PermitFee;

  @Input('st')
  st:PermitFee;

  constructor() {
  }

}