import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { DataService } from '../../data.service';
import { Indent, UserCategory, Config, User } from '../../config';

@Component({
  templateUrl: './approved-indents.component.html'
})
export class ApprovedIndentsComponent implements OnInit {
  filters:any = {};
  indents:Indent[] = [];
  showIndentColumn = false;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.filters = { };
    this.filters.status = ['APP'];
    this.showIndentColumn = false;
    this.getIndents();
  }

  getIndents() {
    this.dataService.getIndents(this.filters).subscribe((data:Indent[]) => {
      this.indents = data;
      this.indents = this.indents.map(p => {
        p.indent_date = new Date(p.indent_date);
        p.action = 'View';
        return p;
      });
    });
  }

  onAction(p: Indent) {
    this.dataService.filters.sup_indent_view = { id: p.id, supplier_id: p.supplier_id, back_url: '/approved-indents' };
    this.router.navigate(['/sup-indent-view']);
  }
}
