import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';

import { Util, ValidationResult } from './util';

export class EmailValidator {

  static isEmail:ValidatorFn = (control: AbstractControl):ValidationResult => {
    if (Util.isNotPresent(control)) return undefined;
    if (Util.isPresent(Validators.required(control))) return null;

    let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (pattern.test(control.value)) {
      return undefined;
    }
    return { 'isemail': true };
  };

};