import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';

import { environment } from '../../../environments/environment';
import { DataService } from '../../data.service';
import { Permit, User } from '../../config';

@Component({
  templateUrl: './permits_approved.component.html'
})
export class PermitsApprovedComponent implements OnInit {
  currentUser: User;
  filters:any = {};
  permits:Permit[] = [];
  loading = false;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      this.filters = {};
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.currentUser.customer_id)
        this.filters.customer_id = this.currentUser.customer_id;
      if (this.currentUser.depot_id)
        this.filters.depot_id = this.currentUser.depot_id;
      if (this.currentUser.category_code)
        this.filters.usr_type_code = this.currentUser.category_code;
    }
    this.filters.status = ['APP', 'REV-INT', 'CAN-INT', 'APP-REV'];
    this.filters.is_fl_permit = 'ALL';

    this.getPermits();
  }

  getPermits() {
    var filters = { ...this.filters };

    this.dataService.getPermits(filters).subscribe((data:Permit[]) => {
      this.permits = data;
      this.permits = this.permits.map(p => {
        p.req_date = new Date(p.req_date);
        if (p.permit_date)
          p.permit_date = new Date(p.permit_date);
        if (p.extend_period)
          p.period += p.extend_period;
        if (p.ext_app_period)
          p.period += p.ext_app_period;

        if (p.valid_date)
          p.valid_date = new Date(p.valid_date);

        if (p.reval_date)
        {
          p.reval_date = new Date(p.reval_date);
          p.valid_date = new Date(p.reval_date);
        }

        if (p.ext_valid_date)
        {
          p.ext_valid_date = new Date(p.ext_valid_date);
          p.valid_date = new Date(p.ext_valid_date);
        }
        
        const today:Date = new Date();
        today.setHours(0, 0, 0, 0);
        p.is_expired = (p.valid_date && p.valid_date < today);

        if (p.status == 'REV-INT')
          p.status = 'APP';
        
        if (p.status == 'CAN-INT')
          p.status = 'APP';
        return p;
      });
    });
  }

  onAction(p:Permit) {
    this.dataService.filters.permit_view = { id: p.id, back_url: '/permits_approved' };
    this.router.navigate(['/permit_view']);
  }

  download(p: Permit) {
    this.loading = true;
    if (p.ip_storage_name) {
      const sn = ((environment.production)?'/':'../') + p.ip_storage_name;
      const fileUrl = this.sanitizer.sanitize(SecurityContext.URL, sn);
      FileSaver.saveAs(fileUrl, p.permit_no + ".pdf");
    }
    this.loading = false;
  }
}
