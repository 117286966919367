import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';

import { Config, Customer, Verf, VerfCode, User } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'verify',
  templateUrl: './verify.component.html'
})
export class VerifyComponent implements OnInit {
  @Input('verf_code')
  verf_code:string;
  @Input('customer')
  customer:Customer;
  @Input('permit_id')
  permit_id:number;

  lvf:Verf;
  // status:string;
  // status_by:string;
  // last_remarks:string;
  hist_count:number = 0;
  hist:Array<Verf> = [];
  
  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];

  remarks:string;
  is_vc_available = true;
  vc:VerfCode;
  currentUser:User;
  // userCategory:string;
  // user_id:number;
  @ViewChild('lgModal', { static: false }) public lgModal:ModalDirective;
  @ViewChild('lgModal1', { static: false }) public lgModal1:ModalDirective;

  constructor(private fb: FormBuilder, private dataService:DataService) {  }

  ngOnInit() {
    this.currentUser = new User();
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser.id = data.id;
      this.currentUser.category_code = data.category_code;
      this.currentUser.name = data.name;
      this.currentUser.designation = data.designation;
      // this.user_id = ;
      // this.userCategory = data.category_code;
      // this.user_name = data.name;
    }
    this.lvf = new Verf(this.verf_code, this.currentUser.category_code);
    let vcs = Config.verf_codes.filter(vcl => { return vcl.code == this.verf_code; });
    if (vcs.length > 0)
    {
      // check this is applicable to current user.
      this.vc = vcs[0];
      let allowedCats = this.vc.allowedUserCat.filter(uc => { return uc == this.currentUser.category_code; });
      if (allowedCats.length > 0)
      {
        // get current status from DB
        let filter = {
          'verf_code': this.verf_code,
          'category_code': this.currentUser.category_code,
          'customer_id': this.customer.id
        };
        this.dataService.getVerfStatus(filter).subscribe((data:any) => {
          if (data.hist_count)
            this.hist_count = data.hist_count;

          if (data.status)
          {
            if (data.verf_date)
              data.verf_date = new Date(data.verf_date);
            else
              data.verf_date = new Date();
            
            this.lvf = data as Verf;
          }
        });
      }
      else
        this.is_vc_available = false;
    }
    else
    {
      // set no btns visibles
      this.is_vc_available = false;
    }
    this.buildForm();
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['remarks'] = [
      this.remarks,
      [
      ]
    ];
    this.errors['remarks'] = [];
    this.messages['remarks'] = {
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  reset() {
    this.remarks = null;
    this.fg.reset({
      'remarks': this.remarks
    });
    return true;
  }

  show() {
    this.lgModal.show();
  }

  show_view_all() {
    let filter = {
      'verf_code': this.verf_code,
      'category_code': this.currentUser.category_code,
      'customer_id': this.customer.id
    };
    this.dataService.getVerfHist(filter).subscribe((data:Verf[]) => {
      this.hist = data;
      this.hist = this.hist.map(h => {
        h.verf_date = new Date(h.verf_date);
        return h;
      });
      this.lgModal1.show();
    });
  }

  save(status:string) {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    let sValue = this.fg.value;
    if (status == 'CLR' && !sValue.remarks)
    {
      alert("Clarification Request should not be empty");
      return;
    }
    if (this.fg.valid) {
      this.remarks = sValue.remarks;
      let verf_data:Verf = {
        'customer_id': this.customer.id,
        'verf_code': this.verf_code,
        'category_code': this.currentUser.category_code,
        'user_id': this.currentUser.id,
        'remarks': this.remarks,
        'status': status
      }
      this.loading = true;
      this.dataService.saveVerf(verf_data).subscribe((data:any) => {
        if (data.message == "Verification details saved successfully.")
        {
          if (data.id)
          {
            verf_data.id = data.id;
            if (this.hist)
              this.hist.push(verf_data);
            else
              this.hist_count++;
            this.lvf = verf_data;
            this.lvf.status = status;
            this.lvf.status_by = this.currentUser.name + (this.currentUser.designation?(", "+this.currentUser.designation):"");
          }
          // this.saved.emit();
          this.lgModal.hide();
        }
        this.loading = false;
      });
    }
  }

  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}