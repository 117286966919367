import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators  } from '@angular/forms';
import { Observable } from 'rxjs';

import { State, Supplier } from '../../config';
import { ValidationResult } from '../../shared/validators/util';
import { NumberValidator } from '../../shared/validators/number.directive';
import { EmailValidator } from '../../shared/validators/email.directive';
import { DataService } from '../../data.service';

@Component({
  selector: 'supplier-edit',
  templateUrl: './new.component.html'
})
export class SupplierEditComponent implements OnChanges, OnInit {
  @Input()
  supplier:Supplier;

  @Input()
  allowEdit:boolean = false;

  @Output()
  saved: EventEmitter<Supplier> = new EventEmitter<Supplier>();

  states:State[] = [];
  submitted = false;
  loading = false;
  isEditModeOn:boolean = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  // Extended properties
  customer_id:number;

  constructor(
    private fb: FormBuilder,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.dataService.getStates({}).subscribe((data:State[]) => { this.states = data; });
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.customer_id = data.customer_id;
    }
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.supplier && (changes.supplier as SimpleChange).currentValue)
    {
      this.supplier = (changes.supplier as SimpleChange).currentValue;
      this.buildForm();
      if (this.supplier.id)
        this.onReset();
      else
        this.onEdit();
    }
  }

  checkUniqueSupplier(): ValidatorFn {
    return (control:FormControl):Observable<ValidationResult> => {
      let searchData:any = {
        name: control.value || "",
        id: this.supplier.id
      };
      return new Observable((ob:any) => {
        this.dataService.isSupplierExist(searchData).subscribe(data => {
          if (data === true)
          {
            this.errors['name'].push(this.messages['name']['checkUniqueSupplier']);
            ob.next({ 'checkUniqueSupplier': true } as ValidationResult);
            ob.complete();
          }
          else
          {
            ob.next(null as ValidationResult);
            ob.complete();
          }
        });
      });
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['name'] = [
      this.supplier.name, 
      [
        Validators.required
      ],
      [
        this.checkUniqueSupplier()
      ]
    ];
    this.errors['name'] = [];
    this.messages['name'] = {
      'required': 'Name is required.',
      'checkUniqueSupplier': 'Supplier already exist.'
    };

    controlsConfig['add1'] = [
      this.supplier.add1, 
      [
        Validators.required
      ]
    ];
    this.errors['add1'] = [];
    this.messages['add1'] = {
      'required': 'Address is required.'
    };

    controlsConfig['add2'] = [
      this.supplier.add2, 
      [
      ]
    ];
    this.errors['add2'] = [];
    this.messages['add2'] = {
    };

    controlsConfig['add3'] = [
      this.supplier.add3, 
      [
      ]
    ];
    this.errors['add3'] = [];
    this.messages['add3'] = {
    };

    controlsConfig['pin'] = [
      this.supplier.pin, 
      [
        NumberValidator.isNumber,
        NumberValidator.min(1),
        Validators.minLength(6),
        Validators.maxLength(6)
      ]
    ];
    this.errors['pin'] = [];
    this.messages['pin'] = {
      'isnumber': 'Pincode should be a valid 6 digit number.',
      'min': 'Pincode should be a valid 6 digit number.',
      'minlength': 'Pincode should be entered in full 6 characters.',
      'maxlength': 'Pincode should not exceed 6 characters.'
    };

    controlsConfig['state_id'] = [
      this.supplier.state_id, 
      [
        Validators.required
      ]
    ];
    this.errors['state_id'] = [];
    this.messages['state_id'] = {
      'required': 'State is required.'
    };

    controlsConfig['contact_person'] = [
      this.supplier.contact_person, 
      [
      ]
    ];
    this.errors['contact_person'] = [];
    this.messages['contact_person'] = {
    };

    controlsConfig['contact_mobile_no'] = [
      this.supplier.contact_mobile_no, 
      [
        NumberValidator.isNumber,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]
    ];
    this.errors['contact_mobile_no'] = [];
    this.messages['contact_mobile_no'] = {
      'isnumber': 'Contact Mobile No. should be a valid number',
      'minlength': 'Contact Mobile No. should be 10 characters long.',
      'maxlength': 'Contact Mobile No. should not exceed 10 characters.'
    };

    controlsConfig['contact_email'] = [
      this.supplier.contact_email, 
      [
        EmailValidator.isEmail,
        Validators.maxLength(50)
      ]
    ];
    this.errors['contact_email'] = [];
    this.messages['contact_email'] = {
      'isemail': 'Please provide valid Contact eMail ID.',
      'maxlength': 'Contact eMail ID. should not exceed 50 characters.'
    };
  
    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged());
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }
  
  onValueChanged() {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  onEdit() {
    this.isEditModeOn = true;
  }

  onReset() {
    this.submitted = false;
    this.fg.reset({
      'name': this.supplier.name, 
      'add1': this.supplier.add1,
      'add2': this.supplier.add2,
      'add3': this.supplier.add3,
      'pin': this.supplier.pin,
      'state_id': this.supplier.state_id,
      'contact_person': this.supplier.contact_person,
      'contact_mobile_no': this.supplier.contact_mobile_no,
      'contact_email': this.supplier.contact_email
    });
    this.isEditModeOn = false;
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      let sValue = this.fg.value;
      sValue.id = this.supplier.id;
      if (!this.supplier.id)
        sValue.req_customer_id = this.customer_id;
      
      this.loading = true;
      this.dataService.saveSupplier(sValue).subscribe((data:any) => {
        if (data.message == "Supplier saved successfully.")
        {
          if (data.id)
            this.supplier.id = data.id;
          this.supplier.name = sValue.name;
          this.supplier.add1 = sValue.add1;
          this.supplier.add2 = sValue.add2;
          this.supplier.add3 = sValue.add3;
          this.supplier.pin = sValue.pin;
          this.supplier.state_id = sValue.state_id;
          this.supplier.contact_person = sValue.contact_person;
          this.supplier.contact_mobile_no = sValue.contact_mobile_no;
          this.supplier.contact_email = sValue.contact_email;
          this.supplier.state_name = this.states.filter(s => { return s.id == sValue.state_id; })[0].name;

          this.supplier.req_customer_id = sValue.req_customer_id;

          this.saved.emit(this.supplier);
        }
        this.onReset();
        this.loading = false;
      });
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}
