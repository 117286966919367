import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';

import { DataService } from '../../data.service';
// import { ItemCat } from '../../config';

@Injectable()
export class NewPermitResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      let pid = +route.params['pid'];
      let cid = +route.params['cid'];
      this.dataService.getPermit({ 'permit_id': pid, 'customer_id': cid }).subscribe(data => {
        ob.next(data);
        ob.complete();
      });
      // if (id == 0)
      // {
      //   ob.next(new ItemCat());
      //   ob.complete();
      //   return;
      // }

      // this.dataService.getItemCat({ id: id }).subscribe(data => {
      //   if (data.id == id)
      //   {
      //     ob.next(data as ItemCat);
      //     ob.complete();
      //   }
      //   else
      //   {
      //     ob.next(new ItemCat());
      //     ob.complete();
      //     this.router.navigate(['/home']);
      //   }
      // });
    });
  }
}

@Injectable()
export class PermitRequestedResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      let pid = +route.params['pid'];
      let cid = +route.params['cid'];
      let req_no = route.params['req_no'];
      let req_dt = new Date(route.params['req_dt']);
      let data = { permit_id: pid, customer_id: cid, req_no: req_no, req_dt: req_dt };
      ob.next(data);
      ob.complete();
      // this.dataService.getPermit({ 'permit_id': pid, 'customer_id': cid }).subscribe(data => {
        
      // });
      // if (id == 0)
      // {
      //   ob.next(new ItemCat());
      //   ob.complete();
      //   return;
      // }

      // this.dataService.getItemCat({ id: id }).subscribe(data => {
      //   if (data.id == id)
      //   {
      //     ob.next(data as ItemCat);
      //     ob.complete();
      //   }
      //   else
      //   {
      //     ob.next(new ItemCat());
      //     ob.complete();
      //     this.router.navigate(['/home']);
      //   }
      // });
    });
  }
}

// @Injectable()
// export class ViewPermitResolver implements Resolve<any> {
//   constructor(private dataService: DataService, private router: Router) {}
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return Observable.create((ob:any) => {
//       let f:string = route.params['for'];
//       let id = +route.params['id'];

//       if (id <= 0)
//       {
//         ob.next(null);
//         ob.complete();
//         alert("In-valid request");
//         this.router.navigate(['/home']);
//         return;
//       }
//       this.dataService.getPermitToView({ id: id }).subscribe((data:any) => {
//         if (data.id == id)
//         {
//           data.for = f;
//           ob.next(data);
//           ob.complete();
//         }
//         else
//         {
//           ob.next(null);
//           ob.complete();
//           alert("In-valid request");
//           this.router.navigate(['/home']);
//         }
//       });
//     });
//   }
// }

@Injectable()
export class PermitsResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      let f:string = route.params['for'] || "A";
      let data:any = { 'for': f };
      switch (f)
      {
        case "N": // Added R & C
          data.status = ["REQ", "REV", "CAN"];
          break;
        // case "R":
        //   data.status = "REV";
        //   break;
        // case "C":
        //   data.status = "CAN";
        //   break;
        // case "X":
        //   data.status = "EXT";
          // break;
        case "A":
          break;
        // case "P":
        //   data.status = "APP";
        //   break;
        // case "L":
        //   data.status = ["APP", "APP-REV", "APP-EXT"];
        //   data.is_expired = false;
        //   break;
        // case "E":
        //   data.status = ["APP", "APP-REV"];
        //   data.is_expired = true;
        //   break;
        case "LE":
          data.status = ["APP", "APP-REV", "EXT", "APP-EXT", "REJ-EXT", "CMP"];
          break;
        default:
          alert("In-valid request");
          this.router.navigate(['/home']);
          return;
      }
      ob.next(data);
      ob.complete();
    });
  }
}

// @Injectable()
// export class VerifyPermitResolver implements Resolve<any> {
//   constructor(private dataService: DataService, private router: Router) {}
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return Observable.create((ob:any) => {
//       let f:string = route.params['for'];
//       let id = +route.params['id'];
//       // this.dataService.getPermitToView({ 'permit_id': pid }).subscribe(data => {
//       //   ob.next(data);
//       //   ob.complete();
//       // });
//       if (id <= 0)
//       {
//         ob.next(null);
//         ob.complete();
//         alert("In-valid request");
//         this.router.navigate(['/home']);
//         return;
//       }
//       this.dataService.getPermitToView({ id: id }).subscribe((data:any) => {
//         if (data.id == id)
//         {
//           data.for = f;
//           ob.next(data);
//           ob.complete();
//         }
//         else
//         {
//           ob.next(null);
//           ob.complete();
//           alert("In-valid request");
//           this.router.navigate(['/home']);
//         }
//       });
//     });
//   }
// }

// @Injectable()
// export class PermitApprovedResolver implements Resolve<any> {
//   constructor(private dataService: DataService, private router: Router) {}
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return Observable.create((ob:any) => {
//       let f:string = route.params['for'];
//       let pid = +route.params['pid'];
//       let pno:string = route.params['pno'];
//       let pdt:Date = new Date(route.params['pdt']);
//       let data = { for: f, permit_id: pid, permit_no: pno, permit_date: pdt };
//       ob.next(data);
//       ob.complete();
//     });
//   }
// }

@Injectable()
export class PermitListResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      let f:string = route.params['for'] || "A";
      let data:any = { 'for': f };
      switch (f)
      {
        case "R":
          // data.is_revalidated = 0;
          data.status = ["APP", "REV-INT"];
          break;
        case "C":
          // data.is_cancelled = 0;
          data.status = ["APP", "CAN-INT"];
          break;
        case "L":
          data.status = "CLR";
          break;
        case "DS":
          data.status = "APP";
          data.ds_alone = true;
        case "A":
          break;
        default:
          alert("In-valid request");
          this.router.navigate(['/home']);
          return;
      }
      ob.next(data);
      ob.complete();
    });
  }
}

@Injectable()
export class PermitRevalResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      // let f:string = route.params['for'];
      let id = +route.params['id'];
      if (id <= 0)
      {
        ob.next(null);
        ob.complete();
        alert("In-valid request");
        this.router.navigate(['/home']);
        return;
      }
      this.dataService.getPermitToReval({ id: id }).subscribe((data:any) => {
        if (data.id == id)
        {
          // data.for = f;
          ob.next(data);
          ob.complete();
        }
        else
        {
          ob.next(null);
          ob.complete();
          alert("In-valid request");
          this.router.navigate(['/home']);
        }
      });
    });
  }
}

@Injectable()
export class PermitCancelResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      // let f:string = route.params['for'];
      let id = +route.params['id'];
      if (id <= 0)
      {
        ob.next(null);
        ob.complete();
        alert("In-valid request");
        this.router.navigate(['/home']);
        return;
      }
      this.dataService.getPermitToCancel({ id: id }).subscribe((data:any) => {
        if (data.id == id)
        {
          // data.for = f;
          ob.next(data);
          ob.complete();
        }
        else
        {
          ob.next(null);
          ob.complete();
          alert("In-valid request");
          this.router.navigate(['/home']);
        }
      });
    });
  }
}

// @Injectable()
// export class PermitLabelResolver implements Resolve<any> {
//   constructor(private dataService: DataService, private router: Router) {}
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return Observable.create((ob:any) => {
//       let f:string = route.params['for'];
//       let id = +route.params['id'];
//       if (id <= 0)
//       {
//         ob.next(null);
//         ob.complete();
//         alert("In-valid request");
//         this.router.navigate(['/home']);
//         return;
//       }
//       this.dataService.getPermitToView({ id: id }).subscribe((data:any) => {
//         if (data.id == id)
//         {
//           data.for = f;
//           ob.next(data);
//           ob.complete();
//         }
//         else
//         {
//           ob.next(null);
//           ob.complete();
//           alert("In-valid request");
//           this.router.navigate(['/home']);
//         }
//       });
//     });
//   }
// }

// @Injectable()
// export class PermitExtResolver implements Resolve<any> {
//   constructor(private dataService: DataService, private router: Router) {}
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return Observable.create((ob:any) => {
//       let f:string = route.params['for'];
//       let id = +route.params['id'];
//       if (id <= 0)
//       {
//         ob.next(null);
//         ob.complete();
//         alert("In-valid request");
//         this.router.navigate(['/home']);
//         return;
//       }
//       this.dataService.getPermitToView({ id: id }).subscribe((data:any) => {
//         if (data.id == id)
//         {
//           data.for = f;
//           ob.next(data);
//           ob.complete();
//         }
//         else
//         {
//           ob.next(null);
//           ob.complete();
//           alert("In-valid request");
//           this.router.navigate(['/home']);
//         }
//       });
//     });
//   }
// }

// @Injectable()
// export class PermitPrintResolver implements Resolve<any> {
//   constructor(private dataService: DataService, private router: Router) {}
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return Observable.create((ob:any) => {
//       let id = +route.params['id'];
//       let data = { permit_id: id };
//       ob.next(data);
//       ob.complete();
//     });
//   }
// }

@Injectable()
export class ImportPermitViewResolver implements Resolve<any> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      let id = +route.params['id'];
      let data = { 'id': id };
      ob.next(data);
      ob.complete();
    });
  }
}