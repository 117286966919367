import { Component, Input } from '@angular/core';

import { PermitFee, PermitItem } from '../../../config';

@Component({
  selector: 'spf',
  templateUrl: './spf.component.html'
})
export class PermitSPFComponent {
  @Input('permititems')
  permititems:PermitItem[] = [];
  
  @Input()
  fee:PermitFee;

  constructor() {
  }

  getCategoryNames(pItems: PermitItem[])
  {
    const catNames: string[] = [];
    const catNamesObj = {};
    pItems.forEach(pi => {
      catNamesObj[pi.item_cat_name] = true;
    });
    Object.keys(catNamesObj).map(key => catNames.push(key));
    return catNames.sort();
  }

  getCaseSubTotal(catName: string)
  {
    let sTot1 = 0;
    let sTot2 = 0;
    this.filterPermitItems(catName).forEach(pi => {
      let q = pi.qty_case.split('.');
      if (q[0] && parseInt(q[0]) > 0)
        sTot1 += parseInt(q[0]);
      if (q[1] && parseInt(q[1]) > 0)
        sTot2 += parseInt(q[1]);
    });
    return sTot1.toString() + '.' + sTot2.toString();
  }

  getSubTotal(catName: string, colName: string)
  {
    let sTot = 0;
    this.filterPermitItems(catName).forEach(pi => sTot += pi[colName]);
    return sTot;
  }

  filterPermitItems(catName: string)
  {
    return this.permititems.filter(pi => { return pi.item_cat_name === catName; });
  }
}
