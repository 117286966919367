import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

import { PermitRemark } from '../../../config';

@Component({
  selector: 'remark-view',
  templateUrl: './view.component.html'
})
export class PermitRemarkViewComponent implements OnChanges {
  @Input()
  remark: PermitRemark;

  constructor(
  ) { }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.remark && (changes.remark as SimpleChange).currentValue)
    {
      this.remark = (changes.remark as SimpleChange).currentValue as PermitRemark;
    }
  }

  getStyle() {
    if (this.remark.rem_type === 'SYS')
      return { color: 'green' };
    else
      return { color: 'purple' };
  }
}