import { Component, Input, OnInit } from '@angular/core';

import { PermitRoute } from '../../../config';

@Component({
  selector: 'permit-route-view',
  templateUrl: './view.component.html'
})
export class PermitRouteViewComponent implements OnInit {
  @Input()
  permitRoute:PermitRoute[];

  @Input()
  start_loc:string;

  @Input()
  start_state:string;

  @Input()
  end_loc:string;

  tnspt_bys = [{ id: "R", name: "Road" }, { id: "I", name: "Rail" }];

  constructor(
) {
  }

  ngOnInit() {
    this.permitRoute = this.permitRoute.map(r => {
      r.tnspt_by_name = this.tnspt_bys.filter(t => {return t.id == r.tnspt_by})[0].name;
      return r;
    });
  }
}