import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { Depot } from '../../config';

@Component({
  templateUrl: './view.component.html'
})
export class DepotsComponent implements OnInit {
  filters:any = {};
  depots:Array<Depot> = [];

  constructor(
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.dataService.getDepots(this.filters).subscribe((data:Depot[]) => {
      this.depots = data;
    });
  }
}
