import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ItemTasmacAsso, ItemTasmacAssoDet } from '../../config';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './tasmac-brand-authorization.component.html',
})
export class TasmacBrandAuthorizationComponent implements OnInit {
  loading = false;
  filter: { supplier_id: number, fin_year: string };
  ita: ItemTasmacAsso;

  constructor(
    private router:Router,
    private dataService:DataService,
    private alertService:AlertService
  ) {
  }

  ngOnInit() {
    if (!this.dataService.filters.tasmac_brand_authorization) {
      this.router.navigate(['/tasmac-brands']);
    }
    this.getItemTasmacAsso();
  }

  getItemTasmacAsso() {
    const filter = this.dataService.filters.tasmac_brand_authorization;
    this.dataService.getItemTasmacAsso(filter).subscribe((data: ItemTasmacAsso) => {
      
      if (data.det)
      {
        data.det.forEach(id => {
          if(id.from_date) id.from_date = new Date(id.from_date);
          if(id.to_date) id.to_date = new Date(id.to_date);
        });
      }
      this.ita = data;
    });
  }

  toggleAuthorization(itmDet: ItemTasmacAssoDet) {
    this.alertService.clearAlert();
    if (!confirm('Are you sure to ' + ((itmDet.id)?'Unauthorize':'Authorize') + '?')) return;

    this.loading = true;
    this.dataService.toggleItemTasmacAssoDetAuthorize(itmDet).subscribe ((data: any) => {
      if (data.message == "Brand Authorized successfully." || data.message === 'Brand Unauthorized successfully.')
      {
        if (data.hasOwnProperty('id'))
          itmDet.id = data.id;
        else
          itmDet.id = null;
        this.alertService.success(data.message);
      }

      this.loading = false;
    });
  }

  goBack() {
    this.router.navigate(["/tasmac-brands"]);
  }
}