import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Country } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './new.component.html',
})
export class CountryComponent implements OnInit {
  submitted = false;
  loading = false;
  conForm: FormGroup;
  private country:Country;
  formErrors:any = { };
  formMessages:any = { };

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {
  }

  ngOnInit() {
    this.country = new Country();
    this.buildForm();
    
    this.route.data.subscribe((data:{country:Country}) => {
      this.country = data.country;
      let frmFields = {
        'code': this.country.code,
        'name': this.country.name
      }
      this.conForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['code'] = [
      this.country.code,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(5)
      ]
    ];
    this.formErrors['code'] = [];
    this.formMessages['code'] = {
      'required':      'Code is required.',
      'minlength':     'Code must be at least 2 characters long.',
      'maxlength':     'Code should not exceed 5 characters.'
    };

    controlsConfig['name'] = [
      this.country.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      'maxlength':     'Name should not exceed 100 characters.'
    };

    this.conForm = this.fb.group(controlsConfig);
    this.conForm.valueChanges.subscribe(data => this.onValueChanged());
    this.conForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.conForm) { return; }
    
    const form = this.conForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.conForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.conForm.valid) {
      // this.usrForm.dirty && 
      let sValue = this.conForm.value;
      this.country.code = sValue.code;
      this.country.name = sValue.name;

      this.loading = true;
      this.dataService.saveCountry(this.country).subscribe((data:any) => {
        if (data.message == "Country saved successfully.")
        {
          if (data.id)
            this.country.id = data.id;
          this.router.navigate(["/cons"]);
        }
        this.loading = false;
      });
    }
  };

  // changeStatus() {
  //   this.loading = true;
  //   if (confirm('Are you sure to disable user?'))
  //     this.user.status = (this.user.status)?0:1;
  //   this.loading = false;
  // }

  public isControlHasError(fieldName:string):boolean {
    if (!this.conForm) return true;
    let formGroup: FormGroup = this.conForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.conForm.controls[fieldName].touched || 
  }
}
