import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';

@Injectable()
export class ImportPermitViewResolver implements Resolve<any> {
  constructor() {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return Observable.create((ob:any) => {
      let id = +route.params['id'];
      let data = { 'id': id };
      ob.next(data);
      ob.complete();
    });
  }
}