import { AbstractControl, FormControl, ValidatorFn  } from '@angular/forms';

import { Util, ValidationResult } from './util';

export class NumberValidator {
  static isNumber:ValidatorFn = (control:AbstractControl):ValidationResult => {
    if (Util.isNotPresent(control)) return undefined;
    if (isNaN(control.value)) {
        return { 'isnumber': true };
    }
    return undefined;
  };

  static min(num:number):ValidatorFn  {
    return (control:AbstractControl):ValidationResult => {
      if (Util.isNotPresent(control)) return undefined;
      if (control.value < num) {
        return { 'min': true };
      }
      return undefined;
    }
  };

  static max(num:number):ValidatorFn  {
    return (control:AbstractControl):ValidationResult => {
      if (Util.isNotPresent(control)) return undefined;
      if (control.value > num) {
        return { 'max': true };
      }
      return undefined;
    }
  };
}
