import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { ItemCat } from '../../config';

@Component({
  templateUrl: './view.component.html'
})
export class ItemCatsComponent implements OnInit {
  filters:any = {};
  itemCats:Array<ItemCat> = [];

  constructor(
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.dataService.getItemCats(this.filters).subscribe((data:ItemCat[]) => {
      this.itemCats = data;
      // Imported Foreign Liquor (IFL)
      // this.users = this.users.map(u => { 
      //   u.category_name = Config.getUserCategoryDetails(u.category_code).name; 
      //   return u;
      // });
    });
  }
}