import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PwdValidators } from '../../shared/validators/password.directive';
import { AlertService } from '../../shared/alert/alert.service';
import { AuthenticationService } from '../../login.service';

@Component({
  templateUrl: './reset-pwd.component.html',
})
export class ResetPasswordComponent implements OnInit {
  showForm = true;
  showForgotPassword = false;
  submitted = false;
  loading = false;
  fg: FormGroup;
  q:string;
  formErrors:any = { };
  formMessages:any = { };
    
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private authenticationService:AuthenticationService,
    private alertService:AlertService
  ) {
  }

  ngOnInit() {
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   this.user.id = data.id;
    // }
    // let frmFields = {
    //   'email': this.user.email,
    //   'pwd': this.user.pwd,
    //   'repwd': this.user.repwd
    // }
    // this.fg.setValue(frmFields, { onlySelf: false, emitEvent: true });

    this.buildForm();
    
    this.route.params.subscribe((data: any) => {
      if (data.q)
        this.q = data.q;
      else
      {
        this.q = "";
        alert("Invalid url");
        this.goBack();
      }
    });
  }

  private buildForm(): void {
    let controlsConfig: any = {};

    // controlsConfig['email'] = [
    //   null,
    //   [
    //     Validators.required,
    //     Validators.maxLength(50),
    //     EmailValidator.isEmail
    //   ]
    // ];
    // this.formErrors['email'] = [];
    // this.formMessages['email'] = {
    //   'required': 'eMail ID is required.',
    //   'maxlength': "eMail ID should not be more than 50 characters long.",
    //   'isemail': "Please provide valid eMail ID."
    // };
    const pPattern = new RegExp("^(?=.*\\W+)(?![\\n])(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).*$"); // "^(?=^.{8,}$)(?=.*\\W+)(?![\\n])(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).*$"
    controlsConfig['pwd'] = [
      null,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
        Validators.pattern(pPattern)
      ]
    ];
    this.formErrors['pwd'] = [];
    this.formMessages['pwd'] = {
      'required': 'New Password is required.',
      'minlength': 'New Password must be at least 8 characters long.',
      'maxlength': 'New Password should not exceed 100 characters.',
      'pattern': 'Password should contain atleast 1 uppercase, 1 lowercase, 1 digit and 1 special characters.'
    };

    controlsConfig['repwd'] = [
      null,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
        PwdValidators.pwdEqual('pwd')
      ]
    ];
    this.formErrors['repwd'] = [];
    this.formMessages['repwd'] = {
      'required': 'Re-type New Password is required.',
      'minlength': 'Re-type New Password must be at least 8 characters long.',
      'maxlength': 'Re-type New Password should not exceed 100 characters.',
      'pwdEqual': 'Re-type New Password should be same as New Password.'
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      // this.fg.dirty &&
        // 'email': sValue.email,
      let sValue = this.fg.value;
      let rpData = {
        'pwd': sValue.pwd,
        'q': this.q
      };

      this.loading = true;
      this.authenticationService.resetPassword(rpData).subscribe((data:any) => {
        if (data.message == "Password changed successfully.")
        {
          this.showForm = false;
          let message = data.message + ' Please Login using your new password.';
          this.alertService.success(message)
          // alert(message);
          // this.router.navigate(["/login"]);
        }
        else if (data.message == "Reset Password link Expired. Try Forgot Password again.")
        {
          this.showForgotPassword = true;
        }
        else
          this.alertService.error(data.message);
        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.fg.controls[fieldName].touched || 
  }

  public goBack() {
    this.alertService.clearAlert();
    this.router.navigate(["/login"]);
  }

  public goToForgotPassword() {
    this.alertService.clearAlert();
    this.router.navigate(["/forgot_pwd"]);
  }
}
