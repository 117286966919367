import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';

import { User } from '../../config';
import { PwdValidators } from '../../shared/validators/password.directive';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './change-pwd.component.html',
})
export class ChangePasswordComponent implements OnInit {
  submitted = false;
  loading = false;
  fg: FormGroup;
  user:User;
  formErrors:any = { };
  formMessages:any = { };
    
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private dataService:DataService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    this.user = new User();
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      this.user.id = data.id;
    }
    // let frmFields = {
    //   'oldPwd': this.user.oldPwd,
    //   'pwd': this.user.pwd,
    //   'repwd': this.user.repwd
    // }
    // this.fg.setValue(frmFields, { onlySelf: false, emitEvent: true });

    this.buildForm();
    
    // this.route.params.subscribe((data:any) => {
      // this.user = data.user as User;
    // });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['oldPwd'] = [
      this.user.oldPwd,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['oldPwd'] = [];
    this.formMessages['oldPwd'] = {
      'required': 'Current Password is required.',
      'minlength': 'Current Password must be at least 8 characters long.',
      'maxlength': 'Current Password should not exceed 100 characters.'
    };

    const pPattern = new RegExp("^(?=.*\\W+)(?![\\n])(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).*$"); // "^(?=^.{8,}$)(?=.*\\W+)(?![\\n])(?=.*[A-Z])(?=.*[a-z])(?=.*\\d).*$"
    controlsConfig['pwd'] = [
      this.user.pwd,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
        Validators.pattern(pPattern)
      ]
    ];
    this.formErrors['pwd'] = [];
    this.formMessages['pwd'] = {
      'required': 'New Password is required.',
      'minlength': 'New Password must be at least 8 characters long.',
      'maxlength': 'New Password should not exceed 100 characters.',
      'pattern': 'Password should contain atleast 1 uppercase, 1 lowercase, 1 digit and 1 special characters.'
    };

    controlsConfig['repwd'] = [
      this.user.repwd,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100),
        PwdValidators.pwdEqual('pwd')
      ]
    ];
    this.formErrors['repwd'] = [];
    this.formMessages['repwd'] = {
      'required': 'Re-type New Password is required.',
      'minlength': 'Re-type New Password must be at least 8 characters long.',
      'maxlength': 'Re-type New Password should not exceed 100 characters.',
      'pwdEqual': 'Re-type New Password should be same as New Password.'
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      // this.fg.dirty &&
      let sValue = this.fg.value;
      this.user.oldPwd = sValue.oldPwd;
      this.user.pwd = sValue.pwd;
      this.user.repwd = sValue.repwd;

      this.loading = true;
      this.dataService.changePassword(this.user).subscribe((data:any) => {
        if (data.message == "Password changed successfully.")
        {
          let message = data.message + ' Please Login using your new password.';
          alert(message);
          this.router.navigate(["/login"]);
        }
        else
          this.alertService.error(data.message);
        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.fg.controls[fieldName].touched || 
  }

  public goBack() {
    this.alertService.clearAlert();
    this.router.navigate(["/home"]);
  }
}
