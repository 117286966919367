import { Component, OnInit } from '@angular/core';

import { DataService } from '../../../data.service';

@Component({
  templateUrl: './request-status-summary.component.html'
})
export class RequestStatusSummaryComponent implements OnInit {
  filters:any = {};
  data:any[] = [];

  constructor(
    private dataService:DataService) {
  }

  ngOnInit() {
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   if (data.customer_id)
    //     this.filters.customer_id = data.customer_id;
    // }
    this.get_request_status_summary();
  }

  get_request_status_summary() {
    this.dataService.get_request_status_summary(this.filters).subscribe((data:any[]) => {
      this.data = data;
    });
  }

  getGrandTotal(colName:string) {
    let tot = 0;
    this.data.forEach(p => {
      if (colName && p[colName])
        tot += p[colName];
    });
    return tot; //.toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
}