import { Component, EventEmitter, Input, Output } from '@angular/core';

import { License } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'license-list',
  templateUrl: './license-list.component.html'
})
export class LicenseListComponent {
  @Input()
  lics: License[];

  @Input()
  allowDelete:boolean = false;
  
  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dataService:DataService) {  }

  delLicense(lic: License) {
    if (!confirm("Are to sure to delete?")) return;

    this.dataService.delLicense(lic.id).subscribe((data:any) => {
      if (data.message == "License details deleted successfully.")
      {
        this.deleted.emit(lic);
      }
    });
  }
}
