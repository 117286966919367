import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { DataService } from '../../data.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedUserGroups = route.data.expectedUserGroups as string[];
    const user = this.dataService.getCurrentUser();
    // localStorage.getItem('currentUser')
    if (user && user.category_code && expectedUserGroups && expectedUserGroups.indexOf(user.category_code) >= 0) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { errMessage: 'You tried to access unauthorized page.' } }); // returnUrl: state.url
    return false;
  }
}
