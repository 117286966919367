import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Config, User } from '../../config';
import { PwdValidators } from '../../shared/validators/password.directive';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './new.component.html',
})
export class UserComponent implements OnInit {
  showPwd = true;
  submitted = false;
  loading = false;
  userCategories = Config.userCategories.filter(uc => uc.isCreateUser ).sort((ucA, ucB) => {
    if (ucA.name < ucB.name) return -1;
    if (ucA.name > ucB.name) return 1;
    return 0;
    });
  // { return uc.code != Config.defaultRegistrationUserCategory; }
  usrForm: FormGroup;
  user: User;
  depots: any = [];
  formErrors:any = { };
  formMessages:any = { };
    
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.user = new User();
    this.dataService.getDepots({}).subscribe( data => { this.depots = data; });
    
    this.route.data.subscribe((data:any) => {
      this.user = data.user as User;
      this.showPwd = true;
      this.buildForm();
      // let frmFields = {
      //   'code': this.user.category_code,
      //   'name': this.user.name,
      //   'designation': this.user.designation,
      //   'mobile': this.user.mobile,
      //   'depot_id': this.user.depot_id,
      //   'email': this.user.email,
      //   'pwd': this.user.pwd,
      //   'repwd': this.user.pwd
      // }
      // this.usrForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });

    // let user_id = 0;
    // this.route
    //     .params
    //     .subscribe((params:Params) => {
    //       user_id = +params['id'];
    //       if (user_id > 0)
    //       {
    //         this.customersService.getUser({id: user_id}).subscribe(
    //           data => {
    //             this.user = data as User;
    //             let frmFields = {
    //               'code': this.user.category_code,
    //               'name': this.user.name,
    //               'designation': this.user.designation,
    //               'mobile': this.user.mobile,
    //               'depot_id': this.user.depot_id,
    //               'email': this.user.email,
    //               'pwd': this.user.pwd,
    //               'repwd': this.user.pwd
    //             }
    //             this.usrForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    //           }
    //         );
    //       }
    //       else
    //       {
    //         this.user = new User();
    //         let frmFields = {
    //           'code': this.user.category_code,
    //           'name': this.user.name,
    //           'designation': this.user.designation,
    //           'mobile': this.user.mobile,
    //           'depot_id': this.user.depot_id,
    //           'email': this.user.email,
    //           'pwd': this.user.pwd,
    //           'repwd': this.user.pwd
    //         }
    //         this.usrForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    //       }
    //     });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['code'] = [
      this.user.category_code,
      [
        Validators.required
      ]
    ];
    this.formErrors['code'] = [];
    this.formMessages['code'] = {
      'required':      'User Category is required.'
    };

    controlsConfig['name'] = [
      this.user.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':      'Name is required.',
      'maxlength':     'Name should not exceed 100 characters.'
    };

    controlsConfig['designation'] = [
      this.user.designation,
      [
        // Validators.required
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['designation'] = [];
    this.formMessages['designation'] = {
      // 'required':      'Designation is required.',
      'maxlength':     'Designation should not exceed 100 characters.'
    };

    controlsConfig['mobile'] = [
      this.user.mobile, 
      [
        Validators.maxLength(10)
      ]
    ];
    this.formErrors['mobile'] = [];
    this.formMessages['mobile'] = {
      'maxlength':     'Mobile Number should not exceed 10 characters.'
    };

    controlsConfig['depot_id'] = [
      this.user.depot_id,
      [
        // Validators.required
      ]
    ];
    this.formErrors['depot_id'] = [];
    this.formMessages['depot_id'] = {
      // 'required':      'TASMAC Depot is required.'
    };

    controlsConfig['email'] = [
      this.user.email, 
      [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"),
        Validators.maxLength(50)
      ]
    ];
    this.formErrors['email'] = [];
    this.formMessages['email'] = {
      'required':      "eMail ID is required.",
      'pattern':       "eMail ID should include valid domain name.",
      'maxlength':     "eMail ID cannot be more than 50 characters long."
    };

    if (this.user.id && this.user.id > 0)
      this.showPwd = false;
    
    if (this.showPwd)
    {
      controlsConfig['pwd'] = [
        this.user.pwd,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(24)
        ]
      ];
      this.formErrors['pwd'] = [];
      this.formMessages['pwd'] = {
        'required':      'Password is required.',
        'minlength':     'Password must be at least 8 characters long.',
        'maxlength':     'Password cannot be more than 24 characters long.'
      };

      controlsConfig['repwd'] = [
        this.user.repwd,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(24),
          PwdValidators.pwdEqual('pwd')
        ]
      ];
      this.formErrors['repwd'] = [];
      this.formMessages['repwd'] = {
        'required':      "Re-Type Password is required.",
        'minlength':     "Re-Type Password must be at least 8 characters long.",
        'maxlength':     "Re-Type Password cannot be more than 24 characters long.",
        'pwdEqual':      "Re-Type Password should be same as Password."
      };
    }

    this.usrForm = this.fb.group(controlsConfig);
    this.usrForm.valueChanges.subscribe(data => this.onValueChanged());
    this.usrForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.usrForm) { return; }
    
    const form = this.usrForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.usrForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.usrForm.valid) {
      // this.usrForm.dirty && 
      let sValue = this.usrForm.value;
      this.user.category_code = sValue.code;
      this.user.name = sValue.name;
      this.user.designation = sValue.designation;
      this.user.mobile = sValue.mobile;
      this.user.depot_id = sValue.depot_id;
      this.user.email = sValue.email;
      if (this.showPwd)
      {
        this.user.pwd = sValue.pwd;
        this.user.repwd = sValue.repwd;
      }

      this.loading = true;
      // let data = { customer: this.customer, license: this.license, user: this.user };
      this.dataService.saveUser(this.user).subscribe((data:any) => {
        if (data.message == "User saved successfully.")
        {
          if (data.user_id)
            this.user.id = data.user_id;
          this.router.navigate(["/users"]);
        }
        this.loading = false;
      });
    }
  };

  changeStatus() {
    this.loading = true;
    if (confirm('Are you sure to toggle user status?'))
    {
      let reqData = { id: this.user.id, status: (this.user.status)?0:1 };
      this.dataService.updateUserStatus(reqData).subscribe((data:any) => {
        if (data.message == "User status changed successfully.") {
          this.user.status = (this.user.status)?0:1;
          this.goBack();
        }
      });
    }
    this.loading = false;
  }

  goBack() {
    this.router.navigate(['/users']);
  }

  public isControlHasError(fieldName:string):boolean {
    if (!this.usrForm) return true;
    let formGroup: FormGroup = this.usrForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.usrForm.controls[fieldName].touched || 
  }
}
