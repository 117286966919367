import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { DataService } from '../../data.service';
import { Indent } from '../../config';

@Component({
  templateUrl: './indent-submitted.component.html'
})
export class SupplierIndentSubmittedComponent implements OnInit {
  indent: Indent;
  trustedUrl:SafeResourceUrl;

  constructor(
    private router: Router,
    private dataService:DataService,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit() {
    if (this.dataService.filters.supplier_indent_submitted) {
      this.indent = this.dataService.filters.supplier_indent_submitted;
      if (this.indent.id)
      {
        this.dataService.printIndent({ 'id': this.indent.id }).subscribe((blob:Blob) => {
          var fileURL = URL.createObjectURL(blob);
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        });
      }
    } else {
      this.router.navigate(['/home']);
    }
  }
}
