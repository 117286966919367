import { Config, License, PermitDoc, PermitFee } from '../../config';

export class PermitBase {
  lic_o_fl:License;
  lic_r_fl:License;
  lic_o_fs:License;
  lic_r_fs:License;

  sup_o_il:License;
  sup_o_fs:License;
  sup_r_fs:License;

  war_o_il:License;
  war_r_il:License;
  war_o_fs:License;
  war_r_fs:License;

  // All Docs
  pDocs:PermitDoc[] = [];

  slc_doc:PermitDoc;

  // All fees
  fees:PermitFee[];
  // For Original
  spf:PermitFee;
  apf:PermitFee;
  sc:PermitFee;
  st:PermitFee;
  coel:PermitFee;
  // For Reval
  spf_r:PermitFee;
  apf_r:PermitFee;
  sc_r:PermitFee;
  st_r:PermitFee;
  coel_r:PermitFee;

  // All Pay
  pay:any[];
  // For Oroginal
  pay_tasmac_o:any[];
  pay_cpe_o:any[];
  // For Reval
  pay_tasmac_r:any[];
  pay_cpe_r:any[];

  dist_licenses(lics:License[]) {
    this.lic_o_fl = null;
    this.lic_r_fl = null;
    this.lic_o_fs = null;
    this.lic_r_fs = null;

    this.sup_o_il = null;
    this.sup_o_fs = null;
    this.sup_r_fs = null;
    
    this.war_o_il = null;
    this.war_r_il = null;
    this.war_o_fs = null;
    this.war_r_fs = null;

    lics.forEach((l:License) => {
      if (!l.warehouse_id && !l.supplier_id && l.customer_id && l.name == 'FL2/FL3' && l.type == 'O')
        this.lic_o_fl = l;
      if (!l.warehouse_id && !l.supplier_id && l.customer_id && l.name == 'FL2/FL3' && (l.type == 'R' || l.type == 'A'))
        this.lic_r_fl = l;
      if (!l.warehouse_id && !l.supplier_id && l.customer_id && l.name == 'FSSAI' && l.type == 'O')
        this.lic_o_fs = l;
      if (!l.warehouse_id && !l.supplier_id && l.customer_id && l.name == 'FSSAI' && (l.type == 'R' || l.type == 'A'))
        this.lic_r_fs = l;
      if (!l.warehouse_id && l.supplier_id && l.name == 'IMPORT' && l.type == 'O')
        this.sup_o_il = l;
      // if (!l.warehouse_id && l.supplier_id && l.name == 'IMPORT' && (l.type == 'R' || l.type == 'A'))
      //   this.sup_r_il = l;
      if (!l.warehouse_id && l.supplier_id && l.name == 'FSSAI' && l.type == 'O')
        this.sup_o_fs = l;
      if (!l.warehouse_id && l.supplier_id && l.name == 'FSSAI' && (l.type == 'R' || l.type == 'A'))
        this.sup_r_fs = l;
      if (l.warehouse_id && l.name == 'BNDWAR' && l.type == 'O')
        this.war_o_il = l;
      if (l.warehouse_id && l.name == 'BNDWAR' && (l.type == 'R' || l.type == 'A'))
        this.war_r_il = l;
      if (l.warehouse_id && l.name == 'FSSAI' && l.type == 'O')
        this.war_o_fs = l;
      if (l.warehouse_id && l.name == 'FSSAI' && (l.type == 'R' || l.type == 'A'))
        this.war_r_fs = l;
    });
  }

  dist_docs(docs:PermitDoc[]) {
    this.slc_doc = null;

    docs = docs.map((d:PermitDoc) => {
      if (d.gen_date)
        d.gen_date = new Date(d.gen_date);

      switch (d.doc_type)
      {
        case 'SLC':
          this.slc_doc = d;
          break;
      }
      
      return d;
    });
    return docs;
  }

  dist_fees(fees:PermitFee[]) {
    this.spf = null;
    this.apf = null;
    this.sc = null;
    this.st = null;
    this.coel = null;

    this.spf_r = new PermitFee(0, "R", "TASMAC");
    this.spf_r.fee_value = 0;
    this.apf_r = null;
    this.sc_r = null;
    this.st_r = null;
    this.coel_r = new PermitFee(0, "R", "TASMAC");
    this.coel_r.fee_value = 0;

    this.fees = fees.map((f:PermitFee) => {
      f.fee_date = new Date(f.fee_date);
      return f;
    });

    fees.forEach((f:PermitFee) => {
      switch(true)
      {
        case (f.fee_code == "SPF" && f.fee_stage == "O"):
          this.spf = f;
          break;
        case (f.fee_code == "APF" && f.fee_stage == "O"):
          this.apf = f;
          break;
        case (f.fee_code == "SC" && f.fee_stage == "O"):
          this.sc = f;
          break;
        case (f.fee_code == "ST" && f.fee_stage == "O"):
          this.st = f;
          break;
        case (f.fee_code == "CoEL" && f.fee_stage == "O"):
          this.coel = f;
          break;
        case (f.fee_code == "APF" && f.fee_stage == "R"):
          this.apf_r = f;
          break;
        case (f.fee_code == "SC" && f.fee_stage == "R"):
          this.sc_r = f;
          break;
        case (f.fee_code == "ST" && f.fee_stage == "R"):
          this.st_r = f;
          break;
     }
    });
  }

  dist_pay(pay:any[]) {
    let is_paid = false;
    this.pay_tasmac_o = [];
    this.pay_cpe_o = [];
    this.pay_tasmac_r = [];
    this.pay_cpe_r = [];

    this.pay = pay;
    pay.forEach((p:any) => {
      if (!is_paid && p.ptype === 'Current Paid Amount' && +p.pay_amt > 0 ) is_paid = true; //&& p.is_adjusted

      switch(true)
      {
        case (p.pay_to == "TASMAC" && p.fee_stage == "O"):
          this.pay_tasmac_o.push(p);
          break;
        case (p.pay_to == "TASMAC" && p.fee_stage == "R"):
          this.pay_tasmac_r.push(p);
          break;
        case (p.pay_to == "CPE" && p.fee_stage == "O"):
          this.pay_cpe_o.push(p);
          break;
        case (p.pay_to == "CPE" && p.fee_stage == "R"):
          this.pay_cpe_r.push(p);
          break;
     }
    });

    return is_paid;
  }

  check_original_valid(lic_o:License) {
    return lic_o && lic_o.val_date > new Date();
  }
}