import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';

import { Config, State, Supplier, Warehouse } from '../../config';
import { NumberValidator } from '../../shared/validators/number.directive';
import { EmailValidator } from '../../shared/validators/email.directive';
import { DataService } from '../../data.service';

@Component({
  selector: 'warehouse-edit',
  templateUrl: './new.component.html'
})
export class WarehouseEditComponent implements OnChanges, OnInit {
  @Input()
  warehouse:Warehouse;
  
  @Input()
  supplierId:number = null;

  @Input()
  showSupplier:boolean = false;

  @Input()
  showCustomsBond: boolean = true;

  @Input()
  allowEdit:boolean = false;

  @Output()
  saved: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();
  
  owned_types = Config.warehouse_types;
  suppliers:Supplier[] = [];
  states:State[] = [];
  submitted = false;
  loading = false;
  isEditModeOn:boolean = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  // Extended properties
  customer_id:number;

  constructor(
    private fb: FormBuilder,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.dataService.getStates({}).subscribe((data:State[]) => { this.states = data; });
    
    if (this.showSupplier)
      this.dataService.getSuppliers({}).subscribe((data:Supplier[]) => { this.suppliers = data; });

    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.customer_id = data.customer_id;
    }
  }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.warehouse && (changes.warehouse as SimpleChange).currentValue)
    {
      this.warehouse = (changes.warehouse as SimpleChange).currentValue;

      if (!this.showSupplier && this.warehouse.owned_type === 'PRIVATE' && !this.warehouse.supplier_id && this.supplierId)
        this.warehouse.supplier_id = this.supplierId;

      this.buildForm();

      if (this.warehouse.id)
        this.onReset();
      else
        this.onEdit();
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['owned_type'] = [
      this.warehouse.owned_type, 
      [
        Validators.required
      ]
    ];
    this.errors['owned_type'] = [];
    this.messages['owned_type'] = {
      'required': 'Customs Bond is required'
    };

    controlsConfig['supplier_id'] = [
      this.warehouse.supplier_id, 
      [
        (this.warehouse.owned_type === 'PRIVATE')?Validators.required:Validators.nullValidator
      ]
    ];
    this.errors['supplier_id'] = [];
    this.messages['supplier_id'] = {
      'required': 'Supplier is required.'
    };

    // controlsConfig['owner_name'] = [
    //   this.warehouse.owner_name,
    //   [
    //   ]
    // ];
    // this.errors['owner_name'] = [];
    // this.messages['owner_name'] = {
    // };

    controlsConfig['name'] = [
      this.warehouse.name, 
      [
        Validators.required
      ]
    ];
    this.errors['name'] = [];
    this.messages['name'] = {
      'required': 'Name is required.'
    };

    controlsConfig['add1'] = [
      this.warehouse.add1, 
      [
        Validators.required
      ]
    ];
    this.errors['add1'] = [];
    this.messages['add1'] = {
      'required': 'Address is required.'
    };

    controlsConfig['add2'] = [
      this.warehouse.add2, 
      [
      ]
    ];
    this.errors['add2'] = [];
    this.messages['add2'] = {
    };

    controlsConfig['add3'] = [
      this.warehouse.add3, 
      [
      ]
    ];
    this.errors['add3'] = [];
    this.messages['add3'] = {
    };

    controlsConfig['pin'] = [
      this.warehouse.pin, 
      [
        NumberValidator.isNumber,
        NumberValidator.min(1),
        Validators.minLength(6),
        Validators.maxLength(6)
      ]
    ];
    this.errors['pin'] = [];
    this.messages['pin'] = {
      'isnumber': 'Pincode should be a valid 6 digit number.',
      'min': 'Pincode should be a valid 6 digit number.',
      'minlength': 'Pincode should be entered in full 6 characters.',
      'maxlength': 'Pincode should not exceed 6 characters.'
    };

    controlsConfig['state_id'] = [
      this.warehouse.state_id, 
      [
        Validators.required
      ]
    ];
    this.errors['state_id'] = [];
    this.messages['state_id'] = {
      'required': 'State is required.'
    };

    controlsConfig['contact_person'] = [
      this.warehouse.contact_person, 
      [
      ]
    ];
    this.errors['contact_person'] = [];
    this.messages['contact_person'] = {
    };

    controlsConfig['contact_mobile_no'] = [
      this.warehouse.contact_mobile_no, 
      [
        NumberValidator.isNumber,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]
    ];
    this.errors['contact_mobile_no'] = [];
    this.messages['contact_mobile_no'] = {
      'isnumber': 'Contact Mobile No. should be a valid number',
      'minlength': 'Contact Mobile No. should be 10 characters long.',
      'maxlength': 'Contact Mobile No. should not exceed 10 characters.'
    };

    controlsConfig['contact_email'] = [
      this.warehouse.contact_email, 
      [
        EmailValidator.isEmail,
        Validators.maxLength(50)
      ]
    ];
    this.errors['contact_email'] = [];
    this.messages['contact_email'] = {
      'isemail': 'Please provide valid Contact eMail ID.',
      'maxlength': 'Contact eMail ID. should not exceed 50 characters.'
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged());
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }
  
  onValueChanged() {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  makeAddress(sup:Supplier) {
    let ret = "";
    if (sup.add1)
      ret += sup.add1;
    if (sup.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.add2;
    if (sup.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.add3;
    if (sup.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + sup.pin;
    if (sup.state_name)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.state_name;
    return ret;
  }
  
  onOwnedTypeChanged(owned_type:string) {
    const supplier_idCtrl = this.fg.controls['supplier_id'];
    if (owned_type === "PUBLIC")
    {
      supplier_idCtrl.setValue(null);
      supplier_idCtrl.clearValidators();
    }
    else
    {
      if (!this.showSupplier && this.supplierId)
        supplier_idCtrl.setValue(this.supplierId);
      supplier_idCtrl.setValidators([Validators.required]);
    }
    supplier_idCtrl.updateValueAndValidity();
  }

  onEdit() {
    this.isEditModeOn = true;
  }

  onReset() {
    this.submitted = false;
    let resetData:any = {
      'owned_type': this.warehouse.owned_type,
      // 'owner_name': this.warehouse.owner_name
      'name': this.warehouse.name, 
      'add1': this.warehouse.add1,
      'add2': this.warehouse.add2,
      'add3': this.warehouse.add3,
      'pin': this.warehouse.pin,
      'state_id': this.warehouse.state_id,
      'contact_person': this.warehouse.contact_person,
      'contact_mobile_no': this.warehouse.contact_mobile_no,
      'contact_email': this.warehouse.contact_email
    }
    if (this.showSupplier)
      resetData.supplier_id = this.warehouse.supplier_id;
    this.fg.reset(resetData);
    if (this.showSupplier)
    {
      const supplier_idCtrl = this.fg.controls['supplier_id'];
      supplier_idCtrl.updateValueAndValidity();
    }
    this.isEditModeOn = false;
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      let sValue = this.fg.value;
      sValue.id = this.warehouse.id;
      if (!this.warehouse.id)
        sValue.req_customer_id = this.customer_id;
      // sValue.supplier_id = this.supplier_id;
      
      this.loading = true;
      this.dataService.saveWarehouse(sValue).subscribe((data:any) => {
        if (data.message == "Warehouse saved successfully.")
        {
          if (data.id)
            this.warehouse.id = data.id;
          this.warehouse.owned_type = sValue.owned_type;
          this.warehouse.supplier_id = sValue.supplier_id;
          // this.warehouse.owner_name = sValue.owner_name;
          this.warehouse.name = sValue.name;
          this.warehouse.add1 = sValue.add1;
          this.warehouse.add2 = sValue.add2;
          this.warehouse.add3 = sValue.add3;
          this.warehouse.pin = sValue.pin;
          this.warehouse.state_id = sValue.state_id;
          this.warehouse.contact_person = sValue.contact_person;
          this.warehouse.contact_mobile_no = sValue.contact_mobile_no;
          this.warehouse.contact_email = sValue.contact_email;
          let state = this.states.filter(s => { return s.id == sValue.state_id; })[0];
          this.warehouse.state_name = state.name;
          this.warehouse.period = state.permit_period;

          this.warehouse.req_customer_id = sValue.req_customer_id;

          this.saved.emit(this.warehouse);
        }
        this.onReset();
        this.loading = false;
      });
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}
