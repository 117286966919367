import { Component, Input } from '@angular/core';

import { ReqItem } from '../../config';

@Component({
  selector: 'req-item-action-view',
  templateUrl: './req-item-action-view.component.html'
})
export class ReqItemActionViewComponent {
  @Input('ritem')
  rItem:ReqItem;
  @Input()
  showuser:boolean;

  constructor() { }

}
