import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ItemAsso, BrandOwner, Supplier, Item, ItemAssoDet } from '../../config';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';
import { DateValidators } from '../../shared/validators/date.directive';

@Component({
  templateUrl: './new.component.html',
})
export class BrandRegistrationComponent implements OnInit {
  // selItem:number = 0;
  brands:Item[];
  // customer_id:number;
  trustedUrl:SafeResourceUrl;
  suppliers:Supplier[];
  brandOwners:BrandOwner[];

  submitted = false;
  loading = false;
  fg: FormGroup;
  itemAsso: ItemAsso;
  formErrors:any = { };
  formMessages:any = { };
  @ViewChild('specFile', { static: false }) public specFile:ElementRef;
  private file: any;
  private fileName: string;

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService,
    private alertService:AlertService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    // if (localStorage.getItem('currentUser'))
    // {
    //   let data = JSON.parse(localStorage.getItem('currentUser'));
    //   if (data.customer_id)
    //     this.customer_id = data.customer_id;
    // }
    this.itemAsso = new ItemAsso();
    this.itemAsso.det = [];
    this.buildForm();
    this.dataService.getSuppliers({}).subscribe( (data:Supplier[]) => { this.suppliers = data; });
    this.dataService.getBrandOwners({}).subscribe( (data:BrandOwner[]) => { this.brandOwners = data; });

    this.route.params.subscribe((params:Params) => {
      if (params['id'] && +params['id'] > 0)
      {
        let id = +params['id'];
        this.dataService.getItemAsso({id: id}).subscribe((data:ItemAsso) => {
          if(data.from_date)
            data.from_date = new Date(data.from_date);
          if(data.to_date)
            data.to_date = new Date(data.to_date);

          if (data.det)
          {
            let tot_cnt = 0;
            data.det.forEach(id => tot_cnt += id.cnt);
            data.tot_cnt = tot_cnt;
          }
          // this.itemAssoList = data;
          this.itemAsso = data;
          if (this.itemAsso.storage_name)
          {
            this.fileName = this.itemAsso.storage_name;
            this.dataService.getUploadedPdf(this.itemAsso.storage_name).subscribe((blob:Blob) => {
              var fileURL = URL.createObjectURL(blob);
              this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
            });
          }
    
          if (this.specFile)
            this.specFile.nativeElement.value = null;
          let frmFields = {
            'supplier_id': this.itemAsso.supplier_id,
            'brand_owner_id': this.itemAsso.brand_owner_id,
            'from_date': this.itemAsso.from_date.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }),
            'to_date': this.itemAsso.to_date.toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }),
            'doc': this.fileName || null,
            'remarks': this.itemAsso.remarks,
            'selItem': null as number
          }
    
          this.fg.setValue(frmFields, { onlySelf: false, emitEvent: true });

          this.getBrands();
        });
      }
    });
  }

  removeDoc() {
    this.alertService.clearAlert();
    if (this.itemAsso.storage_name)
    {
      this.itemAsso.storage_name = null;
      this.trustedUrl = null;
    }
    if (this.file)
    {
      this.file = null;
      this.fg.controls['doc'].setValue(null);
      this.specFile.nativeElement.value = null;
      this.trustedUrl = null;
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['supplier_id'] = [
      this.itemAsso.supplier_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['supplier_id'] = [];
    this.formMessages['supplier_id'] = {
      'required': 'Supplier is required.'
    };

    controlsConfig['brand_owner_id'] = [
      this.itemAsso.brand_owner_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['brand_owner_id'] = [];
    this.formMessages['brand_owner_id'] = {
      'required':  'Brand Owner is required.'
    };

    controlsConfig['from_date'] = [
      this.itemAsso.from_date,
      [
        Validators.required,
        DateValidators.isDate
      ]
    ];
    this.formErrors['from_date'] = [];
    this.formMessages['from_date'] = {
      'required':      'From Date is required.',
      'isDate':        'From Date should be in DD/MM/YYYY format.'
    };

    controlsConfig['to_date'] = [
      this.itemAsso.to_date,
      [
        Validators.required,
        DateValidators.isDate,
        DateValidators.minDate('from_date')
      ]
    ];
    this.formErrors['to_date'] = [];
    this.formMessages['to_date'] = {
      'required':      'To Date is required.',
      'isDate':        'To Date should be in DD/MM/YYYY format.',
      'minDate':       'To Date should not be less than From Date.'
    };

    controlsConfig['remarks'] = [
      this.itemAsso.remarks,
      [

      ]
    ];
    this.formErrors['remarks'] = [];
    this.formMessages['remarks'] = {
    };

    controlsConfig['doc'] = [
      this.fileName,
      [
        Validators.required
      ]
    ];
    this.formErrors['doc'] = [];
    this.formMessages['doc'] = {
      'required': 'Brand Authorization document is required.'
    };

    controlsConfig['selItem'] = [
      null as number,
      [

      ]
    ];
    this.formErrors['selItem'] = [];
    this.formMessages['selItem'] = {
    };

    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.file = fileInput.target.files[0];
      
      var fileURL = URL.createObjectURL(this.file);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

      this.fg.controls['doc'].setValue("File Set");
    }
    else
    {
      this.fg.controls['doc'].setValue(null);
      this.trustedUrl = null;
    }
  }

  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.fg.valid) {
      // this.fg.dirty && 
      let sValue = this.fg.value as ItemAsso;
      this.itemAsso.supplier_id = sValue.supplier_id;
      this.itemAsso.brand_owner_id = sValue.brand_owner_id;
      this.itemAsso.from_date = sValue.from_date;
      this.itemAsso.to_date = sValue.to_date;
      this.itemAsso.remarks = sValue.remarks;

      this.loading = true;
      this.dataService.saveItemAsso(this.itemAsso, this.file).subscribe ((data:any) => {
        if (data.message == "Brand Authorization details saved successfully.")
        {
          if (data.id)
            this.itemAsso.id = data.id;
          if (data.doc_id)
            this.itemAsso.doc_id = data.doc_id;
          if (data.storage_name)
            this.itemAsso.storage_name = data.storage_name;
          this.getBrands();
          this.alertService.success(data.message);
        }
        else
        if (data.errorMessage)
          this.alertService.error(data.errorMessage);

        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string):boolean {
    if (!this.fg) return true;
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }

  getBrands() {
    let brand_owner_id = this.fg.get('brand_owner_id').value;
    if (brand_owner_id)
    {
      this.dataService.getItems({brand_owner_id: brand_owner_id}).subscribe((data:Item[]) => {
        this.brands = data;
      });
    }
  }

  getFilteredBrands() {
    let fil:Item[] = [];
    if (this.brands)
    {
      fil = this.brands.filter(f => {
        let ret = true;
        this.itemAsso.det.forEach(g => {
          if (ret && g.item_id == f.id)
            ret = false;
        });
        return ret;
      });
    }
    return fil;
  }
  
  onSelected(selItems:Item[]) {
    this.alertService.clearAlert();
    selItems.forEach(i => {
      this.itemAsso.det.push({ item_asso_id: this.itemAsso.id, item_id: i.id, name: i.name, vol: i.vol });
    });
    this.save();
  }

  addItemDet() {
    this.alertService.clearAlert();
    if (this.fg.get('selItem').value)
    {
      let item_id = this.fg.get('selItem').value;
      let itf = this.brands.filter(f => { return f.id == item_id; })[0];
      
      this.itemAsso.det.push({ item_asso_id: this.itemAsso.id, item_id: this.fg.get('selItem').value, name: itf.name, vol: itf.vol });
      this.fg.get('selItem').setValue(null);
    }
  }

  deleteItemDet(iad:ItemAssoDet, index:number) {
    this.alertService.clearAlert();
    // this.itemAsso.det = 
    this.itemAsso.det.splice(index, 1);
  }

  deleteItemAsso() {
    this.alertService.clearAlert();
    if (!confirm('Are you sure to delete?')) return;

    this.loading = true;
    this.dataService.deleteItemAsso(this.itemAsso).subscribe ((data:any) => {
      if (data.message == "Brand Authorization details deleted successfully.")
      {
        this.alertService.success(data.message);
        this.goBack();      
      }
      else
      if (data.errorMessage)
        this.alertService.error(data.errorMessage);

      this.loading = false;
    });
  }

  goBack() {
    this.router.navigate(["/brand_registrations"]);
    // [routerLink]="['/brand_registrations']"
  }
}