import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
// import 'rxjs/add/operator/switchMap';

import { Config, Customer, License, Depot, StarGradation } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: 'customer.component.html'
})
export class CustomerComponent implements OnInit {
  
  customerLicenseTypes = Config.customerLicenseTypes;
  filters:any = {};
  customers:any = [];
  customer:any = {};
  users:any = [];
  user:any = {};
  fl_lics:Array<any> = [];
  fssai_lics:Array<any> = [];
  depots:Depot[] = [];
  star_gradations: StarGradation[] = [];

  constructor(
    private dataService:DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // this.route.params
    //   .switchMap((params: Params) => {
    //     this.filters.customer_id = +params['id'];
    //     return this.dataService.getCustomerSettings(this.filters);
    //   })
    //   .subscribe(data => {
        
    //   });

    this.route.data.subscribe((Ori_data: any) => {
      const data = Ori_data.cus_data;
      this.customer = data.customer;
      this.users = data.users;
      this.user = this.users[0];
      this.fl_lics = data.fl_lics;
      this.fl_lics = this.fl_lics.map(f => {
        f.iss_date = new Date(f.iss_date);
        f.val_date = new Date(f.val_date);
        return f;
      });
      this.fssai_lics = data.fssai_lics;
      this.fssai_lics = this.fssai_lics.map(f => {
        f.iss_date = new Date(f.iss_date);
        f.val_date = new Date(f.val_date); 
        return f; 
      });
      this.depots = data.depots;
      this.star_gradations = data.star_gradations;
    });

      // this.country = data.country;
      // let frmFields = {
      //   'code': this.country.code,
      //   'name': this.country.name
      // }
      // this.conForm.setValue(frmFields, { onlySelf: false, emitEvent: true });

    // this.customersService.getCustomers(this.filters).then(
    //   data => {
    //     this.customers = data;
    //   },
    //   error => {
    //   }
    // );
  }

  getDepotName(depot_id:any) {
    return (depot_id)?this.depots.filter(d => { return d.id == depot_id; })[0].name:"";
  }

  makeAddress(customer:any) {
    let ret = "";
    if (customer.add1)
      ret += customer.add1 + "\n";
    if (customer.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + customer.add2;
    if (customer.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + customer.add3;
    if (customer.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + customer.pin;
    return ret;
  }

  onSaved(customer: Customer) {
    this.customer = customer;
  }
}
