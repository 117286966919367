import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../login.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  showDevLoginShotcuts = !environment.production;
  permit_no:string;
  model: { email: string, password: string } = { email: null, password: null };
  loading = false;
  loading1 = false;
  returnUrl: string;
  showFeedback: boolean;
  showEnquiry = false;
  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false })
  public lgModal0:ModalDirective;
  
  is_locked = false;
  errMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }
  
  ngOnInit() {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl('assets/docs/Online Import Permit - Registration of Licensees.pdf');

    // reset login status
    this.dataService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    this.showFeedback = true;
    
    // Check Error Messages
    this.errMessage = this.route.snapshot.queryParams['errMessage'];

    // this.route.queryParams.subscribe(qParams => {
    //   if (qParams.errMessage)
    //     this.alerts.push(new ngxAlert(qParams.errMessage, 'danger', true, 10000));
    // });

    // Check lockout
    if (localStorage.getItem('lockout')) {
      const lockout = JSON.parse(localStorage.getItem('lockout'));
      let lon = new Date();
      if (lockout.lon) {
        lon = new Date(lockout.lon);
      }
      let lfor = 0;
      if (lockout.lfor) {
        lfor = lockout.lfor;
        lon.setMinutes(lon.getMinutes() + lfor);
      }
      let time_left = lon.getTime() - (new Date()).getTime();
      if (time_left > 0) {
        this.errMessage = 'Account Locked.';
        this.lockLoginFields(time_left);
      } else {
        localStorage.removeItem('lockout');
      }
    }
  }
   
  login() {
    this.errMessage = null;
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.password).subscribe((data:any) => {
      this.loading = false;
      if (data && data.message)
      {
        localStorage.removeItem('lockout');
        if (data.message == "User authenticated")
        {
          if (data && data.token)
          {
            this.dataService.storeLocalStorage(data);
            this.router.navigate([this.returnUrl]);
          }
        }
        else
        {
          this.errMessage = data.message;
          // this.alerts.push(new ngxAlert(data.message, 'danger'));
          if (data.lockout_time) {
            localStorage.setItem('lockout', JSON.stringify({ lon: new Date(), lfor: (1+data.lockout_time) }));
            this.lockLoginFields(data.lockout_time*60*1000);
          }
        }
      }
    });
  }

  lockLoginFields(lockout_time: number) {
    this.model = { email: null, password: null };
    this.is_locked = true;
    setTimeout(() => {
      this.is_locked = false;
      this.errMessage = null;
    }, lockout_time);
  }

  prView() {
    this.errMessage = null;
    this.loading1 = true;
    this.authenticationService.check_permit_no({ permit_no: this.permit_no }).subscribe((data:any) => {
      if (data && data.message == "Valid Permit No. found.")
      {
        let permit_id = data.id;
        this.router.navigate(['/ipview', permit_id]);
      }
      else
      {
        alert("Permit No. not found. Check your permit no and try again.");
      }
      this.loading1 = false;
    });
  }
  
  showManual() {
    this.errMessage = null;
    this.lgModal0.show();
  }

  toggleEnquiry() {
    this.showEnquiry = true;
  }

  onSaved() {
    this.showEnquiry = false;
  }

  loginAs(type: string) {
    this.errMessage = null;
    this.model = environment.loginAs(type);
    this.login();
  }
}
