import { Component, OnInit } from '@angular/core';


@Component({
  templateUrl: './rate-revised.component.html',
})
export class RateRevisedComponent implements OnInit {
  
  constructor(
   
  ) { }

  ngOnInit() {
    
  }
}
