import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ReqItem } from '../../config';
import { DataService } from '../../data.service';

@Component({
  selector: 'req-item-view',
  templateUrl: './req-item-view.component.html'
})
export class ReqItemViewComponent implements OnChanges {
  @Input('ritem')
  rItem:ReqItem;

  @Input()
  storage_name:string;

  trustedUrl:SafeResourceUrl;
  constructor (
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.storage_name && (changes.storage_name as SimpleChange).currentValue) {
      const storage_name = (changes.storage_name as SimpleChange).currentValue;
      this.dataService.getUploadedPdf(storage_name).subscribe((blob:Blob) => {
        var fileURL = URL.createObjectURL(blob);
        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      });
    }
  }
}
