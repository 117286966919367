import { Directive, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

import { Config } from '../../config';
import { Util, ValidationResult } from './util';

const IS_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IsDateValidator),
  multi: true
};
@Directive({
  selector: '[isDate][formControlName],[isDate][formControl],[isDate][ngModel]',
  providers: [IS_DATE_VALIDATOR]
})
class IsDateValidator implements Validator, OnInit {
  // @Input() minDate: string;
  private validator: ValidatorFn;

  ngOnInit() {
    this.validator = DateValidators.isDate;
    // (this.minDate);
  }

  validate(c: AbstractControl):ValidationResult {
    return this.validator(c);
  }
}
//  {[key: string]: any}

const MIN_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinDateValidator),
  multi: true
};

@Directive({
  selector: '[minDate][formControlName],[minDate][formControl],[minDate][ngModel]',
  providers: [MIN_DATE_VALIDATOR]
})
class MinDateValidator implements Validator, OnInit {
  @Input() minDate: string;
  private validator: ValidatorFn;

  ngOnInit() {
    this.validator = DateValidators.minDate(this.minDate);
  }

  validate(c: AbstractControl):ValidationResult {
    return this.validator(c);
  }
}
//  {[key: string]: any}

const MAX_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxDateValidator),
  multi: true
};

@Directive({
  selector: '[maxDate][formControlName],[maxDate][formControl],[maxDate][ngModel]',
  providers: [MAX_DATE_VALIDATOR]
})
class MaxDateValidator implements Validator, OnInit {
  @Input() maxDate: string;
  private validator: ValidatorFn;

  ngOnInit() {
    this.validator = DateValidators.maxDate(this.maxDate);
  }

  validate(c: AbstractControl):ValidationResult {
    return this.validator(c);
  }
}
//  {[key: string]: any}
export class DateValidators {
  // private static isPresent(obj: any): boolean {
  //   return obj !== undefined && obj !== null;
  // }

  static isDate(control: AbstractControl): {[key: string]: boolean} {
    let obj = Validators.required(control);
    if (obj !== undefined && obj !== null) return null;

      if (control.value && control.value.length != 10 ) return {isDate: true};
      return Config.isValidDate(control.value)?null:{isDate: true};
  }

  static minDate(fieldName: string): ValidatorFn {
    let subscribe: boolean = false;
    // String (DD/MM/YYYY)
    if (Config.isValidDate(fieldName))
      return (control: FormControl): {[key: string]: any} => {
        if (Util.isPresent(DateValidators.isDate(control))) return null;

        let m = Config.MakeDate(fieldName);
        if (!Config.isValidDate(control.value)) return null;
        let v = Config.MakeDate(control.value);
        return (m <= v)?null:{minDate: true};
      }
    // AbstractControl
    else
      return (control: FormControl): {[key: string]: any} => {
        if (Util.isPresent(DateValidators.isDate(control))) return null;

        if (control.root instanceof FormGroup && control.root.controls[fieldName] instanceof FormControl)
        {
          let minDate: AbstractControl = (control.root as FormGroup).controls[fieldName];
          if (!subscribe) {
            subscribe = true;
            minDate.valueChanges.subscribe(() => {
              control.updateValueAndValidity();
            });
          }

          if (!Config.isValidDate(control.value)) return null;
          let v = Config.MakeDate(control.value);
          if (!Config.isValidDate(minDate.value)) return null;
          let m = Config.MakeDate(minDate.value);
          return (m <= v)?null:{minDate: true};
        }
        else
          return null;
      };
  }

  static maxDate(fieldName: string): ValidatorFn {
    let subscribe: boolean = false;
    // String (DD/MM/YYYY)
    if (Config.isValidDate(fieldName))
      return (control: FormControl): {[key: string]: any} => {
        if (Util.isPresent(DateValidators.isDate(control))) return null;

        let m = Config.MakeDate(fieldName);
        if (!Config.isValidDate(control.value)) return null;
        let v = Config.MakeDate(control.value);
        return (m >= v)?null:{maxDate: true};
      }
    // AbstractControl
    else
      return (control: FormControl): {[key: string]: any} => {
        if (Util.isPresent(DateValidators.isDate(control))) return null;

        if (control.root instanceof FormGroup && control.root.controls[fieldName] instanceof FormControl)
        {
          let maxDate: AbstractControl = (control.root as FormGroup).controls[fieldName];
          if (!subscribe) {
            subscribe = true;
            maxDate.valueChanges.subscribe(() => {
              control.updateValueAndValidity();
            });
          }

          if (!Config.isValidDate(control.value)) return null;
          let v = Config.MakeDate(control.value);
          if (!Config.isValidDate(maxDate.value)) return null;
          let m = Config.MakeDate(maxDate.value);
          return (m >= v)?null:{maxDate: true};
        }
        else
          return null;
      };
  }
}