import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Country, ItemCat, ReqItem, BrandOwner } from '../../config';
import { AlertService } from '../../shared/alert/alert.service';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './req-item.component.html',
})
export class SupplierReqItemComponent implements OnInit {
  supplier_id: number;
  trustedUrl: SafeResourceUrl;
  itemCats: ItemCat[];
  countries: Country[];
  brandOwners: BrandOwner[];

  submitted = false;
  loading = false;
  iForm: FormGroup;
  rItem: ReqItem;
  formErrors: any = { };
  formMessages: any = { };
  @ViewChild('specFile', { static: false }) public specFile:ElementRef;
  private file: any;
  private fileName: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.supplier_id)
        this.supplier_id = data.supplier_id;
    }

    this.rItem = new ReqItem(this.supplier_id);
    this.buildForm();
    this.dataService.getItemCats({}).subscribe( (data: ItemCat[]) => { this.itemCats = data; });
    this.dataService.getCountries({}).subscribe( (data: Country[]) => { this.countries = data; });
    this.dataService.getBrandOwners({}).subscribe( (data: BrandOwner[]) => { this.brandOwners = data; });

    this.route.params.subscribe((params: Params) => {
      if (+params.id > 0) {
        this.getReqItem({ supplier_id: this.supplier_id, id: +params.id });
      }
    });
  }

  getReqItem(filter: { supplier_id: number, id: number }) {
    this.dataService.getReqItem(filter).subscribe((data: ReqItem) => {
      this.rItem = data;

      if (this.rItem.storage_name)
      {
        this.fileName = this.rItem.storage_name;
        this.dataService.getUploadedPdf(this.rItem.storage_name).subscribe((blob:Blob) => {
          var fileURL = URL.createObjectURL(blob);
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        });
      }

      if (this.specFile)
        this.specFile.nativeElement.value = null;

      let frmFields = {
        'item_cat_id': this.rItem.item_cat_id,
        'brand_owner_id': this.rItem.brand_owner_id,
        'name': this.rItem.name,
        'origin_country_id': this.rItem.origin_country_id,
        'vol': this.rItem.vol,
        'btl_per_case': this.rItem.btl_per_case,
        'req_comment': this.rItem.req_comment,
        'item_spec_doc': this.fileName || null
      };

      this.iForm.setValue(frmFields, { onlySelf: false, emitEvent: true });

      // if (this.rItem.action_date)
      //   this.rItem.action_date = new Date(this.rItem.action_date);
    });
  }

  removeDoc() {
    this.alertService.clearAlert();
    if (this.rItem.storage_name)
    {
      this.rItem.storage_name = null;
      this.trustedUrl = null;
    }
    if (this.file)
    {
      this.file = null;
      this.iForm.controls['item_spec_doc'].setValue(null);
      this.specFile.nativeElement.value = null;
      this.trustedUrl = null;
    }
  }

  private buildForm(): void {
    let controlsConfig: any = {};

    controlsConfig['item_cat_id'] = [
      this.rItem.item_cat_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['item_cat_id'] = [];
    this.formMessages['item_cat_id'] = {
      'required': 'Brand Category is required.'
    };

    controlsConfig['brand_owner_id'] = [
      this.rItem.brand_owner_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['brand_owner_id'] = [];
    this.formMessages['brand_owner_id'] = {
      'required': 'Brand Owner is required.'
    };

    controlsConfig['name'] = [
      this.rItem.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':  'Name is required.',
      'maxlength': 'Name should not exceed 100 characters.'
    };

    controlsConfig['origin_country_id'] = [
      this.rItem.origin_country_id, 
      [
        Validators.required
      ]
    ];
    this.formErrors['origin_country_id'] = [];
    this.formMessages['origin_country_id'] = {
      'required': 'Country of Origin is required.'
    };

    controlsConfig['vol'] = [
      this.rItem.vol,
      [
        Validators.required
      ]
    ];
    this.formErrors['vol'] = [];
    this.formMessages['vol'] = {
      'required': 'Volume is required.'
    };

    controlsConfig['btl_per_case'] = [
      this.rItem.btl_per_case,
      [
        Validators.required
      ]
    ];
    this.formErrors['btl_per_case'] = [];
    this.formMessages['btl_per_case'] = {
      'required': 'Bottles per case is required.'
    };

    controlsConfig['req_comment'] = [
      this.rItem.req_comment,
      [

      ]
    ];
    this.formErrors['req_comment'] = [];
    this.formMessages['req_comment'] = {
    };

    controlsConfig['item_spec_doc'] = [
      this.fileName,
      [
        // Validators.required
      ]
    ];
    this.formErrors['item_spec_doc'] = [];
    this.formMessages['item_spec_doc'] = {
      // 'required':      doc_cap + ' is required.'
    };

    this.iForm = this.fb.group(controlsConfig);
    this.iForm.valueChanges.subscribe(data => this.onValueChanged());
    this.iForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.file = fileInput.target.files[0];
      
      var fileURL = URL.createObjectURL(this.file);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

      this.iForm.controls['item_spec_doc'].setValue("File Set");
    }
    else
    {
      this.iForm.controls['item_spec_doc'].setValue(null);
      this.trustedUrl = null;
    }
  }

  onValueChanged() {
    if (!this.iForm) { return; }
    
    const form = this.iForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.alertService.clearAlert();
    this.submitted = true;
    this.iForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.iForm.valid) {
      let sValue = this.iForm.value as ReqItem;
      this.rItem.item_cat_id = sValue.item_cat_id;
      this.rItem.brand_owner_id = sValue.brand_owner_id;
      this.rItem.name = sValue.name;
      this.rItem.origin_country_id = sValue.origin_country_id;
      this.rItem.vol = sValue.vol;
      this.rItem.btl_per_case = sValue.btl_per_case;
      this.rItem.req_comment = sValue.req_comment;

      this.loading = true;
      this.dataService.saveReqItem(this.rItem, this.file).subscribe ((data:any) => {
        if (data.message == "Brand Request saved successfully.")
        {
          if (data.id)
            this.rItem.id = data.id;
          if (data.item_spec_doc_id)
            this.rItem.item_spec_doc_id = data.item_spec_doc_id;
          if (data.storage_name)
            this.rItem.storage_name = data.storage_name;
          this.goBack();          
        }
        else
        if (data.errorMessage)
          this.alertService.error(data.errorMessage);

        this.loading = false;
      });
    }
  }

  goBack() {
    this.router.navigate(["/sup-req-items"]);
  }

  public isControlHasError(fieldName:string): boolean {
    if (!this.iForm) return true;
    let formGroup: FormGroup = this.iForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
  }
}
