import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators  } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Country, Item, ItemCat, ReqItem, BrandOwner } from '../../config';
import { ValidationResult } from '../../shared/validators/util';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './req-item-app.component.html',
})
export class ReqItemAppComponent implements OnInit {
  user_id:number;
  trustedUrl:SafeResourceUrl;
  itemCats: ItemCat[];
  countries: Country[];
  brandOwners: BrandOwner[];
  rItem: ReqItem;

  isAllowEdit = false;
  item: Item;
  submitted = false;
  loading = false;
  iForm: FormGroup;
  formErrors: any = { };
  formMessages: any = { };

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService,
    private sanitizer: DomSanitizer
  ) { }

  checkDbName(): ValidatorFn {
    return (control:FormControl):Observable<ValidationResult> => {
      let new_name = control.value || "";
      return new Observable((ob:any) => {
        this.dataService.isItemNameExist(new_name).subscribe(data => {
          if (data === true)
          {
            this.formErrors['name'].push(this.formMessages['name']['checkDbName']);
            ob.next({ 'checkDbName': true } as ValidationResult);
            ob.complete();
          }
          else
          {
            ob.next(null as ValidationResult);
            ob.complete();
          }
        });
      });
    }
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.id)
        this.user_id = data.id;
    }
    this.item = new Item();
    this.buildForm();
    this.dataService.getItemCats({}).subscribe( (data:ItemCat[]) => { this.itemCats = data; });
    this.dataService.getCountries({}).subscribe( (data:Country[]) => { this.countries = data; });
    this.dataService.getBrandOwners({}).subscribe( (data:BrandOwner[]) => { this.brandOwners = data; });

    this.route.params.subscribe((params: Params) => {
      if (+params.id > 0) {
        this.getReqItem(+params.id);
      } else {
        this.goBack();
      }
    });
  }

  getReqItem(id: number) {
    this.dataService.getReqItem({ id: id }).subscribe((data: ReqItem) => {
      this.rItem = data;

      if (this.rItem.storage_name) {
        this.dataService.getUploadedPdf(this.rItem.storage_name).subscribe((blob:Blob) => {
          var fileURL = URL.createObjectURL(blob);
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        });
      }

      let frmFields = {
        item_cat_id: this.rItem.item_cat_id,
        brand_owner_id: this.rItem.brand_owner_id,
        name: this.rItem.name,
        vol: this.rItem.vol,
        btl_per_case: this.rItem.btl_per_case,
        origin_country_id: this.rItem.origin_country_id,
        action_comment: this.rItem.action_comment
      };

      this.iForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['item_cat_id'] = [
      this.item.item_cat_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['item_cat_id'] = [];
    this.formMessages['item_cat_id'] = {
      'required': 'Category is required.'
    };

    controlsConfig['brand_owner_id'] = [
      this.item.brand_owner_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['brand_owner_id'] = [];
    this.formMessages['brand_owner_id'] = {
      'required': 'Brand Owner is required.'
    };

    controlsConfig['name'] = [
      this.item.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ],
      [
        this.checkDbName()
        // .debounceTime(500).distinctUntilChange().first()
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':  'Name is required.',
      'maxlength': 'Name should not exceed 100 characters.',
      'checkDbName': 'Name already available in the item master.'
    };

    controlsConfig['origin_country_id'] = [
      this.item.origin_country_id, 
      [
        Validators.required
      ]
    ];
    this.formErrors['origin_country_id'] = [];
    this.formMessages['origin_country_id'] = {
      'required': 'Country of Origin is required.'
    };

    controlsConfig['vol'] = [
      this.item.vol,
      [
        Validators.required
      ]
    ];
    this.formErrors['vol'] = [];
    this.formMessages['vol'] = {
      'required': 'Volume is required.'
    };

    controlsConfig['btl_per_case'] = [
      this.item.btl_per_case,
      [
        Validators.required
      ]
    ];
    this.formErrors['btl_per_case'] = [];
    this.formMessages['btl_per_case'] = {
      'required': 'Bottles per case is required.'
    };

    controlsConfig['action_comment'] = [
      "",
      [

      ]
    ];
    this.formErrors['action_comment'] = [];
    this.formMessages['action_comment'] = {
    };

    this.iForm = this.fb.group(controlsConfig);
    // , { 'asyncValidator': this.checkItem }
    this.iForm.valueChanges.subscribe(data => this.onValueChanged());
    this.iForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  // sngOnInit() {
  //   this.user = this.fb.group({
  //     name: ['', Validators.required],
  //     account: this.fb.group({
  //       email: ['', Validators.required],
  //       confirm: ['', Validators.required]
  //     }, { validator: emailMatcher })
  //   });
  // }

  // fileChangeEvent(fileInput: any) {
  //   if (fileInput.target.files && fileInput.target.files[0]) {
  //     this.file = fileInput.target.files[0];
      
  //     var fileURL = URL.createObjectURL(this.file);
  //     this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);

  //     this.iForm.controls['item_spec_doc'].setValue("File Set");
  //   }
  //   else
  //   {
  //     this.iForm.controls['item_spec_doc'].setValue(null);
  //     this.trustedUrl = null;
  //   }
  // }

  onValueChanged() {
    if (!this.iForm) { return; }
    
    const form = this.iForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  onAllowEdit() {
    this.isAllowEdit = true;
  }

  onApprove() {
    this.submitted = true;
    this.iForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.iForm.valid) {
      // this.iForm.dirty && 
      let sValue = this.iForm.value as ReqItem;
      this.item.item_cat_id = sValue.item_cat_id;
      this.item.brand_owner_id = sValue.brand_owner_id;
      this.item.name = sValue.name;
      this.item.vol = sValue.vol;
      this.item.btl_per_case = sValue.btl_per_case;
      this.item.origin_country_id = sValue.origin_country_id;

      this.rItem.action_comment = sValue.action_comment;
      this.rItem.action_user_id = this.user_id;
      this.rItem.status = "APP";

      this.loading = true;
      let save_data = { item: this.item, ritem: this.rItem };
      this.dataService.saveReqItemAction(save_data).subscribe ((data:any) => {
        if (data.message == "Requested Brand approved successfully.")
        {
          if (data.item_id)
            this.rItem.item_id = data.item_id;
          this.goBack();
        }
        this.loading = false;
      });
    }
  };

  onReject() {
    if (!this.iForm.get('action_comment').value)
    {
      alert("Please add comment to Reject the requested item.");
      return;
    }
    this.rItem.action_comment = this.iForm.get('action_comment').value;
    this.rItem.action_user_id = this.user_id;
    this.rItem.status = "REJ";

    this.loading = true;
    let save_data = { ritem: this.rItem };
    this.dataService.saveReqItemAction(save_data).subscribe ((data:any) => {
      if (data.message == "Requested Brand rejected successfully.")
      {
        if (data.item_id)
          this.rItem.item_id = data.item_id;
        this.goBack();
      }
      this.loading = false;
    });
  }

  goBack() {
    this.router.navigate(["/req_items", 'REQ']);
  }

  isControlHasError(fieldName:string):boolean {
    if (!this.iForm) return true;
    let formGroup: FormGroup = this.iForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.iForm.controls[fieldName].touched || 
  }
}
