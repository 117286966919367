import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { ReqItem } from '../../config';

@Component({
  templateUrl: './req-items.component.html'
})
export class ReqItemsComponent implements OnInit {
  sm_caption: string;
  filters: any = {};
  items: ReqItem[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params.status && (params.status === 'REQ' || params.status === 'OTH')) {
        if (params.status === 'REQ') {
          this.filters.status = 'REQ';
          this.sm_caption = 'Yet to be take action';
        } else {
          this.filters.status = ['APP', 'REJ'];
          this.sm_caption = 'Action Taken';
        }
        this.getReqItems();
      } else {
        this.router.navigate(['/home']);
      }
    });
  }

  getReqItems() {
    this.dataService.getReqItems(this.filters).subscribe((data:ReqItem[]) => {
      this.items = data;
    });
  }

  onActionClick(r:ReqItem) {
    switch(r.status)
    {
      case "REQ":
        this.router.navigate(["/req_item", r.id]);
        break;
      case "APP":
      case "REJ":
        this.router.navigate(["/req_item_view", r.id]);
        break;
    }
  }
}
