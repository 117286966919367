import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { DataService } from '../../data.service';
import { Supplier } from '../../config';

interface SuppliersActions {
  allowAdd:boolean;
  allowEdit:boolean;
  allowDelete:boolean;
  showActions:boolean;
};

@Component({
  templateUrl: 'suppliers.component.html'
})
export class SuppliersComponent implements OnInit {
  filter:any = {};
  actions:SuppliersActions = {
    allowAdd: false,
    allowEdit: false,
    allowDelete: false,
    showActions: false
  };
  suppliers:Supplier[] = [];

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {  }

  ngOnInit() {
    if (localStorage.getItem('currentUser'))
    {
      let data = JSON.parse(localStorage.getItem('currentUser'));
      if (data.customer_id)
        this.filter.customer_id = data.customer_id;
      this.markAllowedActions(data);
    }

    // this.route.data.subscribe((data:any) => {
    //   let fdata = data.filter;
    //   if (fdata.action)
    //     this.filter.action = fdata.action;
    //   else
    //     this.filter.action = "A";
    //   this.getSuppliers();
    // });
    this.getSuppliers();
  }

  markAllowedActions(data:any) {
    this.actions.allowAdd = (data.category_code === 'PSEC');
    this.actions.allowEdit = (data.category_code === 'PSEC');
    this.actions.allowDelete = (data.category_code === 'PSEC');
    if (this.actions.allowEdit || this.actions.allowDelete)
      this.actions.showActions = true;
  }

  getSuppliers() {
    this.dataService.getSuppliers(this.filter).subscribe((data:Supplier[]) => {
      this.suppliers = data;
    });
  }
  
  makeAddress(sup:Supplier) {
    let ret = "";
    if (sup.add1)
      ret += sup.add1;
    if (sup.add2)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.add2;
    if (sup.add3)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.add3;
    if (sup.pin)
      ret = (ret?(ret.replace(/,\s*$/, '')+' - '):'') + sup.pin;
    if (sup.state_name)
      ret = (ret?(ret.replace(/,\s*$/, '')+', '):'') + sup.state_name;
    return ret;
  }

  deleteSupplier(sup:Supplier) {
    if (!confirm("Are you sure to delete supplier?")) 
      return;

    let del_data = { id: sup.id };
    this.dataService.deleteSupplier(del_data).subscribe((data:any) => {
      if (data.message == "Supplier deleted successfully.")
        this.getSuppliers();
    });
  }
}
