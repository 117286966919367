import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, Validator  } from '@angular/forms';
// import { Router } from '@angular/router';

import { PermitFee } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-pay-view',
  templateUrl: './view.component.html'
})
export class PermitPayViewComponent implements OnChanges {
  // @Input()
  // permit_id:number;
  // @Input()
  // customer_id:number;
  @Input()
  pay_to:string;
  @Input()
  pay:any;
  @Input()
  fees:PermitFee[];
  @Input()
  fee_stage:string;

  // @Output()
  // balance_changed:EventEmitter<number> = new EventEmitter<number>();

  // pre_bal_p:any;
  // pre_bal_s:any;
  cur:any;
  adj:any;
  paid:any;
  balance:number = 0;

  constructor( ) { }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.pay && changes.pay.currentValue)
      this.dist_pay();
  }

  dist_pay() {
    // this.pre_bal_p = null;
    // this.pre_bal_s = null;
    this.cur = null;
    this.adj = null;
    this.paid = null;

    if (this.pay)
    {
      this.pay.forEach((p:any) => {
        // if (p.pay_to == this.pay_to && p.ptype == "Previous Balance" && p.pay_amt >= 0)
        //   this.pre_bal_p = p;
        // if (p.pay_to == this.pay_to && p.ptype == "Previous Balance" && p.pay_amt < 0)
        //   this.pre_bal_s = p;
        if (p.pay_to == this.pay_to && p.ptype == "Current Payable Amount")
          this.cur = p;
        if (p.pay_to == this.pay_to && p.ptype == "Current Paid Amount" && p.is_adjusted)
          this.adj = p;
        if (p.pay_to == this.pay_to && p.ptype == "Current Paid Amount" && !p.is_adjusted)
        {
          this.paid = p;
          if (this.paid.pay_det) {
            this.paid.pay_det = this.paid.pay_det.map((pd:any) => {
              if (pd.trnx_date) pd.trnx_date = new Date(pd.trnx_date);
              if (pd.ifhrms_challan_date) pd.ifhrms_challan_date = new Date(pd.ifhrms_challan_date);
              if (pd.cancel_date) pd.cancel_date = new Date(pd.cancel_date);
              return pd;
            });
          }
        }
      });
    }
    this.calc_balance();
  }

  calc_balance() {
    this.balance = 
      + (this.cur?Math.ceil(this.cur.pay_amt):0)
      - (this.adj?this.adj.pay_amt:0)
      - (this.paid?this.paid.pay_amt:0);
  }

  get_pay_to_fee() {
    return this.fees.filter(f => {
      return f.pay_to == this.pay_to && f.fee_stage == this.fee_stage;
    });
  }

  get_abs(val:number) {
    return Math.abs(val);
  }

  get_rounded(val:number) {
    return Math.ceil(val);
  }

  get_total_paid_adj_amt() {
    let tot = 0;
    if (this.paid && this.paid.pay_amt)
      tot += this.paid.pay_amt;
    if (this.adj && this.adj.pay_amt)
      tot += this.adj.pay_amt;
    return tot;
  }

  // get_max_amt() {
  //   let ret_val = 0;
  //   if (this.pre_bal_s && this.pre_bal_s.pay_amt)
  //   {
  //     ret_val = Math.abs(this.pre_bal_s.pay_amt);
  //     if (this.cur && this.cur.pay_amt && this.cur.pay_amt < ret_val)
  //     ret_val = this.cur.pay_amt;
  //   }
  //   else if (this.adj && this.adj.pay_amt)
  //     ret_val = this.adj.pay_amt;
  //   return ret_val;
  // }

  // get_adj_amt() {
  //   let ret_val = 0;
  //   if (this.adj && this.adj.pay_amt)
  //     ret_val = this.adj.pay_amt;
  //   return ret_val;
  // }

}
