import { Component, Input } from '@angular/core';

import { PermitFee } from '../../../config';

@Component({
  selector: 'sc',
  templateUrl: './sc.component.html'
})
export class PermitSCComponent {
  @Input('spf')
  spf:PermitFee;

  @Input('apf')
  apf:PermitFee;
  
  @Input('sc')
  sc:PermitFee;

  constructor() {
  }

  getTotal() {
    let t = this.apf.fee_value;
    if (this.spf)
      t += this.spf.fee_value;
    return t;
  }
  // ((spf.fee_value || 0) + apf.fee_value)
}
