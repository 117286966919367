import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ItemCat } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './new.component.html',
})
export class ItemCatComponent implements OnInit {
  submitted = false;
  loading = false;
  icForm: FormGroup;
  itemCat: ItemCat;
  formErrors:any = { };
  formMessages:any = { };
    
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) {
  }

  ngOnInit() {
    this.itemCat = new ItemCat();
    this.buildForm();
    
    this.route.data.subscribe((data:any) => {
      this.itemCat = data.itemCat as ItemCat;
      let frmFields = {
        'name': this.itemCat.name,
        'btl_per_case': this.itemCat.btl_per_case,
        'pl_pc': this.itemCat.pl_pc,
        'spf_bl_rate': this.itemCat.spf_bl_rate,
        'spf_pl_rate': this.itemCat.spf_pl_rate,
        'is_apf': (this.itemCat.is_apf)?true:false,
        'apf_rate': this.itemCat.apf_rate,
        'el_rate': this.itemCat.el_rate
      }
      this.icForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['name'] = [
      this.itemCat.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':  'Name is required.',
      'maxlength': 'Name should not exceed 100 characters.'
    };

    controlsConfig['btl_per_case'] = [
      this.itemCat.btl_per_case,
      [
        Validators.required
      ]
    ];
    this.formErrors['btl_per_case'] = [];
    this.formMessages['btl_per_case'] = {
      'required': 'Bottles per case is required.'
    };

    controlsConfig['pl_pc'] = [
      this.itemCat.pl_pc,
      [
        Validators.required
      ]
    ];
    this.formErrors['pl_pc'] = [];
    this.formMessages['pl_pc'] = {
      'required': 'Proof Litre (PL) Calculation % is required.'
    };

    controlsConfig['spf_bl_rate'] = [
      this.itemCat.spf_bl_rate, 
      [
        Validators.required
      ]
    ];
    this.formErrors['spf_bl_rate'] = [];
    this.formMessages['spf_bl_rate'] = {
      'required': 'Special Fee Rate is required.'
    };

    controlsConfig['spf_pl_rate'] = [
      this.itemCat.spf_pl_rate,
      [
        Validators.required
      ]
    ];
    this.formErrors['spf_pl_rate'] = [];
    this.formMessages['spf_pl_rate'] = {
      'required': 'Special Fee Rate is required.'
    };

    controlsConfig['is_apf'] = [
      this.itemCat.is_apf, 
      [
        // Validators.required
      ]
    ];
    this.formErrors['is_apf'] = [];
    this.formMessages['is_apf'] = {
      // 'required': "Allow Addl. Processing Fee? is required."
    };

    controlsConfig['apf_rate'] = [
      this.itemCat.apf_rate,
      [
      ]
    ];
    this.formErrors['apf_rate'] = [];
    this.formMessages['apf_rate'] = {
    };

    controlsConfig['el_rate'] = [
      this.itemCat.el_rate, 
      [
        Validators.required
      ]
    ];
    this.formErrors['el_rate'] = [];
    this.formMessages['el_rate'] = {
      'required': 'Excise Label Rate is required.'
    };

    this.icForm = this.fb.group(controlsConfig);
    this.icForm.valueChanges.subscribe(data => this.onValueChanged());
    this.icForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onValueChanged() {
    if (!this.icForm) { return; }
    
    const form = this.icForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.icForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.icForm.valid) {
      // this.icForm.dirty &&
      let sValue = this.icForm.value;
      this.itemCat.name = sValue.name;
      this.itemCat.btl_per_case = sValue.btl_per_case;
      this.itemCat.pl_pc = sValue.pl_pc;
      this.itemCat.spf_bl_rate = sValue.spf_bl_rate;
      this.itemCat.spf_pl_rate = sValue.spf_pl_rate;
      this.itemCat.is_apf = sValue.is_apf;
      if (this.itemCat.is_apf)
        this.itemCat.apf_rate = sValue.apf_rate;
      else
        this.itemCat.apf_rate = null;
      this.itemCat.el_rate = sValue.el_rate;

      this.loading = true;
      this.dataService.saveItemCat(this.itemCat).subscribe((data:any) => {
        if (data.message == "Brand category saved successfully.")
        {
          if (data.id)
            this.itemCat.id = data.id;
          this.router.navigate(["/item_cats"]);
        }
        this.loading = false;
      });
    }
  };

  public is_APF_Checked():boolean {
    return (this.icForm.controls['is_apf'].value)?true:false;
  }

  public isControlHasError(fieldName:string):boolean {
    if (!this.icForm) return true;
    let formGroup: FormGroup = this.icForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.icForm.controls[fieldName].touched || 
  }
}
