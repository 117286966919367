import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Country, ItemCat, Item, BrandOwner } from '../../config';
import { DataService } from '../../data.service';

@Component({
  templateUrl: './item.component.html',
})
export class ItemComponent implements OnInit {
  submitted = false;
  loading = false;
  iForm: FormGroup;
  item: Item;
  formErrors:any = { };
  formMessages:any = { };
  itemCats:ItemCat[];
  brandOwners:ItemCat[];
  countries:Country[];

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.item = new Item();
    this.buildForm();
    this.dataService.getItemCats({}).subscribe( (data:ItemCat[]) => { this.itemCats = data; });
    this.dataService.getBrandOwners({}).subscribe( (data:BrandOwner[]) => { this.brandOwners = data; });
    this.dataService.getCountries({}).subscribe( (data:Country[]) => { this.countries = data; });

    this.route.data.subscribe((data:any) => {
      this.item = data.item as Item;
      let frmFields = {
        item_cat_id: this.item.item_cat_id,
        brand_owner_id: this.item.brand_owner_id,
        name: this.item.name,
        origin_country_id: this.item.origin_country_id,
        is_domestic: this.item.is_domestic,
        vol: this.item.vol,
        btl_per_case: this.item.btl_per_case
      }
      this.iForm.setValue(frmFields, { onlySelf: false, emitEvent: true });
    });
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['item_cat_id'] = [
      this.item.item_cat_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['item_cat_id'] = [];
    this.formMessages['item_cat_id'] = {
      'required': 'Brand Category is required.'
    };

    controlsConfig['brand_owner_id'] = [
      this.item.brand_owner_id,
      [
        Validators.required
      ]
    ];
    this.formErrors['brand_owner_id'] = [];
    this.formMessages['brand_owner_id'] = {
      'required': 'Brand Owner is required.'
    };

    controlsConfig['name'] = [
      this.item.name,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
    ];
    this.formErrors['name'] = [];
    this.formMessages['name'] = {
      'required':  'Name is required.',
      'maxlength': 'Name should not exceed 100 characters.'
    };

    controlsConfig['origin_country_id'] = [
      this.item.origin_country_id, 
      [
        Validators.required
      ]
    ];
    this.formErrors['origin_country_id'] = [];
    this.formMessages['origin_country_id'] = {
      'required': 'Country of Origin is required.'
    };

    controlsConfig['is_domestic'] = [
      this.item.is_domestic, 
      [
        Validators.required
      ]
    ];
    this.formErrors['is_domestic'] = [];
    this.formMessages['is_domestic'] = {
      'required': '"Is Domestric Brand?" is required.'
    };

    controlsConfig['vol'] = [
      this.item.vol,
      [
        Validators.required
      ]
    ];
    this.formErrors['vol'] = [];
    this.formMessages['vol'] = {
      'required': 'Volume is required.'
    };

    controlsConfig['btl_per_case'] = [
      this.item.btl_per_case,
      [
        Validators.required
      ]
    ];
    this.formErrors['btl_per_case'] = [];
    this.formMessages['btl_per_case'] = {
      'required': 'Bottles per case is required.'
    };

    this.iForm = this.fb.group(controlsConfig);
    this.iForm.valueChanges.subscribe(data => this.onValueChanged());
    this.iForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }

  onIsDomesticChange($event) {
    this.item.is_domestic = $event.target.checked;
    this.iForm.get('is_domestic').setValue(this.item.is_domestic);
  }

  onValueChanged() {
    if (!this.iForm) { return; }
    
    const form = this.iForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) {
        //  control.dirty &&
        const messages = this.formMessages[field];
        for (const key in control.errors) {
          this.formErrors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  public save() {
    this.submitted = true;
    this.iForm.updateValueAndValidity({ onlySelf: false, emitEvent: true});

    if (this.iForm.valid) {
      // this.iForm.dirty && 
      let sValue = this.iForm.value;
      this.item.item_cat_id = sValue.item_cat_id;
      this.item.brand_owner_id = sValue.brand_owner_id;
      this.item.name = sValue.name;
      this.item.origin_country_id = sValue.origin_country_id;
      this.item.vol = sValue.vol;
      this.item.btl_per_case = sValue.btl_per_case;
      // this.item.is_domestic = sValue.is_domestic;

      this.loading = true;
      this.dataService.saveItem(this.item).subscribe((data:any) => {
        if (data.message == "Brand saved successfully.")
        {
          if (data.id)
            this.item.id = data.id;
          this.router.navigate(["/items"]);
        }
        this.loading = false;
      });
    }
  };

  public isControlHasError(fieldName:string):boolean {
    if (!this.iForm) return true;
    let formGroup: FormGroup = this.iForm;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.iForm.controls[fieldName].touched || 
  }
}
