import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, Validator  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';

import { Config, Customer, Depot, StarGradation } from '../../config';
import { NumberValidator } from '../../shared/validators/number.directive';
import { DataService } from '../../data.service';

@Component({
  selector: 'licensee-edit',
  templateUrl: './edit.component.html'
})
export class LicenseeEditComponent implements OnInit {
  @Input('customer')
  customer: Customer;

  @Input('depots')
  depots: Depot[] = [];

  @Input()
  star_gradations: StarGradation[] = [];

  @Output()
  saved: EventEmitter<Customer> = new EventEmitter<Customer>();

  @ViewChild('lgModal', { static: false }) public lgModal:ModalDirective;

  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];
  customerLicenseTypes = Config.customerLicenseTypes;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService
  ) { }

  ngOnInit() {
    // this.dataService.getDepots({}).subscribe(data => { this.depots = data; });
    this.buildForm();
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    controlsConfig['type'] = [
      this.customer.type, 
      [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3)
      ]
    ];
    this.errors['type'] = [];
    this.messages['type'] = {
      'required': '"Licensed as" is required.',
      'minlength': '"Licensed as" must be at least 3 characters long.',
      'maxlength': '"Licensed as" cannot be more than 3 characters long.'
    };

    controlsConfig['star_gradation_id'] = [
      this.customer.star_gradation_id,
      [
        // Validators.required
      ]
    ];
    this.errors['star_gradation_id'] = [];
    this.messages['star_gradation_id'] = {
      'required': 'Star Gradation is required.',
    };

    controlsConfig['gstin'] = [
      this.customer.gstin, 
      [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15)
      ]
    ];
    this.errors['gstin'] = [];
    this.messages['gstin'] = {
      'required': 'GSTIN is required.',
      'minlength': 'GSTIN must be at least 15 characters long.',
      'maxlength': 'GSTIN cannot be more than 15 characters long.'
    };

    controlsConfig['name'] = [
      this.customer.name, 
      [
        Validators.required
      ]
    ];
    this.errors['name'] = [];
    this.messages['name'] = {
      'required': 'Name is required.'
    };

    controlsConfig['add1'] = [
      this.customer.add1, 
      [
        Validators.required
      ]
    ];
    this.errors['add1'] = [];
    this.messages['add1'] = {
      'required': 'Address is required.'
    };

    controlsConfig['add2'] = [
      this.customer.add2, 
      [
      ]
    ];
    this.errors['add2'] = [];
    this.messages['add2'] = {
    };

    controlsConfig['add3'] = [
      this.customer.add3, 
      [
      ]
    ];
    this.errors['add3'] = [];
    this.messages['add3'] = {
    };

    controlsConfig['pin'] = [
      this.customer.pin, 
      [
        NumberValidator.isNumber,
        NumberValidator.min(1),
        Validators.minLength(6),
        Validators.maxLength(6)
      ]
    ];
    this.errors['pin'] = [];
    this.messages['pin'] = {
      'isnumber': 'Pincode should be a valid 6 digit number.',
      'min': 'Pincode should be a valid 6 digit number.',
      'minlength': 'Pincode should be entered in full 6 characters.',
      'maxlength': 'Pincode should not exceed 6 characters.'
    };

    controlsConfig['web'] = [
      this.customer.web, 
      [
      ]
    ];
    this.errors['web'] = [];
    this.messages['web'] = {
    };

    controlsConfig['depot_id'] = [
      this.customer.depot_id, 
      [
        Validators.required
      ]
    ];
    this.errors['depot_id'] = [];
    this.messages['depot_id'] = {
      'required': 'TASMAC Depot is required.'
    };
  
    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }
  
  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }

  show() {
    this.reset();
    this.lgModal.show();
  }

  reset() {
    this.submitted = false;
    this.fg.reset({
      type: this.customer.type,
      star_gradation_id: this.customer.star_gradation_id,
      name: this.customer.name, 
      add1: this.customer.add1,
      add2: this.customer.add2,
      add3: this.customer.add3,
      pin: this.customer.pin,
      web: this.customer.web,
      depot_id: this.customer.depot_id,
      gstin: this.customer.gstin
    });
    return true;
  }

  save() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      let sValue = this.fg.value;
      sValue.id = this.customer.id;
      
      this.loading = true;
      this.dataService.saveCustomer(sValue).subscribe((data:any) => {
        if (data.message == "Customer saved successfully.")
        {
          this.customer.type = sValue.type;
          if (sValue.star_gradation_id) {
            this.customer.star_gradation_id = +sValue.star_gradation_id;
            this.customer.star_gradation_name = this.star_gradations.filter(sg => sg.id === +sValue.star_gradation_id)[0].name;
          } else {
            this.customer.star_gradation_id = null;
            this.customer.star_gradation_name = null;
          }
          this.customer.name = sValue.name;
          this.customer.add1 = sValue.add1;
          this.customer.add2 = sValue.add2;
          this.customer.add3 = sValue.add3;
          this.customer.pin = sValue.pin;
          this.customer.web = sValue.web;
          this.customer.depot_id = sValue.depot_id;
          this.customer.gstin = sValue.gstin;
          this.lgModal.hide();
          this.saved.emit(this.customer);
        }
        this.loading = false;
      });
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }
}
