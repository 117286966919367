import { Component, Input } from '@angular/core';

import { PermitFee, PermitItem } from '../../../config';

@Component({
  selector: 'coel',
  templateUrl: './coel.component.html'
})
export class PermitCoELComponent {
  @Input('permititems')
  permititems:PermitItem[] = [];
  
  @Input()
  coel:PermitFee;

  constructor() {
  }

  getQtyBtlTotal() {
    let total = 0;
    this.permititems.forEach(i => {
      total += i.qty_btl;
    });
    return total;
  }
}
