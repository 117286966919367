import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import { AccordionModule, AlertModule, BsDatepickerModule, ModalModule, PaginationModule, TabsModule, TypeaheadModule } from 'ngx-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { CapitalizePipe } from './shared/capitalize.pipe';

import { AlertComponent } from './shared/alert/alert.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ImportFeedbackComponent } from './components/feedback/new.component';
import { TablePaginationComponent } from './shared/table-pagination/table-pagination.component';
import { LicenseeEditComponent } from './components/customers/edit.component';
import { LicenseComponent } from './components/license/license.component';
import { ViewLicComponent } from './components/license/view.component';
import { LicenseListComponent } from './components/license/license-list.component/license-list.component';
import { ViewDocComponent } from './shared/view-doc/view-doc.component';
import { VerifyComponent } from './shared/verify/verify.component';
import { VerfDetComponent } from './shared/verify/verf-det.component';
import { VerfStateComponent } from './shared/verify/verf-state.component';
import { VerfAddComponent } from './shared/verify/verf-add.component';
import { VerfMsgComponent } from './shared/verify/verf-msg.component';
import { SupplierEditComponent } from './components/supplier/new.component';
import { SupplierViewComponent } from './components/supplier/view.component';
import { SupplierStarListComponent } from './components/supplier/star-list.component';
import { WarehouseEditComponent } from './components/warehouse/new.component';
import { WarehouseViewComponent } from './components/warehouse/view.component';
import { WarehouseStarListComponent } from './components/warehouse/star-list.component';
import { PermitSpaceLeaseCertificateEditComponent } from './components/permit/space-cert/edit.component';
import { PermitSpaceLeaseCertificateViewComponent } from './components/permit/space-cert/view.component';
import { PermitRouteEditComponent } from './components/permit/route/edit.component';
import { PermitRouteViewComponent } from './components/permit/route/view.component';
import { PermitItemEditComponent } from './components/permit/item/edit.component';
import { PermitItemListComponent } from './components/permit/item/list.component';
import { PermitProformaInvoiceEditComponent } from './components/permit/proforma-invoice/edit.component';
import { PermitProformaInvoiceViewComponent } from './components/permit/proforma-invoice/view.component';
import { PermitSPFComponent } from './components/permit/worksheet/spf.component';
import { PermitAPFComponent } from './components/permit/worksheet/apf.component';
import { PermitSCComponent } from './components/permit/worksheet/sc.component';
import { PermitSTComponent } from './components/permit/worksheet/st.component';
import { PermitCoELComponent } from './components/permit/worksheet/coel.component';
import { PermitPayAddComponent } from './components/permit/pay/add.component';
import { PermitPayViewComponent } from './components/permit/pay/view.component';
import { PermitVStateComponent } from './components/permit/v-state.component';
import { PermitRemarkAddComponent } from './components/permit/remark/add.component';
import { PermitRemarkViewComponent } from './components/permit/remark/view.component';
import { PermitRemarkListComponent } from './components/permit/remark/remark-list/remark-list.component';
import { CustomerViewComponent } from './components/customers/view.component';
// TASMAC (Partials)
import { ReqItemViewComponent } from './tasmac/req-item-view/req-item-view.component';
import { ReqItemActionViewComponent } from './tasmac/req-item-action-view/req-item-action-view.component';

import { LoginComponent } from './general/login/login.component';
import { ImportPermitViewComponent } from './components/login/ipview.component';
import { PublicViewPermitComponent } from './components/public-view-permit/public-view-permit.component';
import { ForgotPasswordComponent } from './components/login/forgot_pwd.component';
import { ResetPasswordComponent } from './general/reset-pwd/reset-pwd.component';
import { LicenseeSignupComponent } from './licensee/signup/signup.component';
import { LicenseeSignupSuccessComponent } from './licensee/signup-success/signup-success.component';
import { HomeComponent } from './components/home/home.component';
import { ChangePasswordComponent } from './general/change-pwd/change-pwd.component';
import { FailurePaymentComponent } from './components/payments/failed-payment.component';

// CUS
import { NewPermitComponent } from './components/permit/new.component';
import { PaymentComponent } from './components/payments/add.component';
import { PermitRequestedComponent } from './components/permit/req.component';
import { PermitListComponent } from './components/permit/list.component';
import { PermitViewComponent } from './components/permit/view.component';
import { PermitRevalComponent } from './components/permit/reval.component';
import { PermitCancelComponent } from './components/permit/cancel.component';

import { CusFLLicenseComponent } from './components/license/cus-fl-lic.component';
import { CusFSLicenseComponent } from './components/license/cus-fs-lic.component';

import { CusMyselfComponent } from './components/license/myself.component';

// To ALL ('TSEC', 'TACC', 'TMD', 'PSEC', 'PCOM')
import { PermitsComponent } from './components/permit/permits.component';
import { PermitVerifyComponent } from './components/permit/verify.component';
import { PermitApprovedComponent } from './components/permit/approved.component';
// import { PendingDigitalSignaturesComponent } from './components/pending-digital-signatures/pending-digital-signatures.component';
import { DigitalSignatureComponent } from './components/digital-signature/digital-signature.component';
import { PermitsApprovedComponent } from './components/permit/permits_approved.component';
import { PermitsToBePrintedComponent } from './components/permit/permits_to_be_printed.component';
import { PermitsExtRequestedComponent } from './components/permit/permits_ext_requested.component';
import { PermitsToBeLabelledComponent } from './components/permit/permits_to_be_labelled.component';
import { PermitsRequestExtComponent } from './components/permit/permits_request_ext.component';
import { PermitsDespatchedComponent } from './components/permit/permits_despatched.component';
import { PermitsCandRejComponent } from './components/permit/permits_cand_rej.component';
import { PermitLabelComponent } from './components/permit/label.component';
import { PermitExtComponent } from './components/permit/ext.component';
import { PermitPrintComponent } from './components/permit/print.component';
import { PermitEvalPrintComponent } from './components/permit-eval-print/permit-eval-print.component';
import { RequestCreationBlockedComponent } from './components/permit/request-creation-blocked/request-creation-blocked.component';

import { PeSectionCustomersComponent } from './components/customers/customers.component';

import { CustomerComponent } from './components/customers/customer.component';
import { UsersComponent } from './components/user/view.component';
import { UserComponent } from './components/user/new.component';
import { CountriesComponent } from './components/country/view.component';
import { CountryComponent } from './components/country/new.component';
import { StatesComponent } from './components/state/view.component';
import { StateComponent } from './components/state/new.component';
import { DepotsComponent } from './components/depot/view.component';
import { DepotComponent } from './components/depot/new.component';
import { BrandOwnersComponent } from './components/brand_owners/view.component';
import { BrandOwnerComponent } from './components/brand_owners/new.component';
import { BrandRegistrationsComponent } from './components/brand_registration/list.component';
import { BrandRegistrationComponent } from './components/brand_registration/new.component';
import { BrandRegistrationSelectListComponent } from './components/brand_registration/select-list.component';
import { ItemCatsComponent } from './components/item_cat/view.component';
import { ItemCatComponent } from './components/item_cat/new.component';
import { ItemsComponent } from './components/item/items.component';
import { ItemComponent } from './components/item/item.component';
import { SuppliersComponent } from './components/supplier/suppliers.component';
import { SupplierComponent } from './components/supplier/create.component';
import { WarehousesComponent } from './components/warehouse/warehouses.component';
import { WarehouseComponent } from './components/warehouse/create.component';
import { SettingsEditComponent } from './components/settings/edit.component';

// Supplier Module
import { SupplierUsersComponent } from './supplier/users/users.component';
import { SupplierUserComponent } from './supplier/user/user.component';
import { SupplierReqItemsComponent } from './supplier/req-items/req-items.component';
import { SupplierReqItemComponent } from './supplier/req-item/req-item.component';
import { SupplierReqItemViewComponent } from './supplier/req-item-view/req-item-view.component';
import { SupplierLicenseIECComponent } from './supplier/lic-iec/lic-iec.component';
import { SupplierLicenseFSComponent } from './supplier/lic-fs/lic-fs.component';
import { SupplierWarehousesComponent } from './supplier/warehouses/warehouses.component';
import { SupplierWarehouseEditComponent } from './supplier/warehouse-edit/warehouse-edit.component';
import { SupplierPermitListComponent } from './supplier/permit/list/list.component';
import { SupplierPermitTasmacRequestComponent } from './supplier/permit/tasmac/request/request.component';
import { SupplierIndentComponent } from './supplier/indent/indent.component';
import { SupplierIndentItemEditComponent } from './supplier/indent-item-edit/indent-item-edit.component'; // Parital
import { SupplierIndentRemarkEditComponent } from './supplier/indent-remark-edit/indent-remark-edit.component'; // Partial
// import { SupplierIndentRemarkViewComponent } from './supplier/indent-remark-view/indent-remark-view.component'; // Partial
import { SupplierIndentSubmittedComponent } from './supplier/indent-submitted/indent-submitted.component';
import { SupplierIndentListComponent } from './supplier/indent-list/indent-list.component';
import { SupplierIndentViewComponent } from './supplier/indent-view/indent-view.component';
import { IndentsComponent } from './components/indents/indents.component';
import { ApprovedIndentsComponent } from './components/approved-indents/approved-indents.component';
import { SupplierIndentToRequestListComponent } from './supplier/permit/indent-to-request-list/indent-to-request-list.component';
import { SupplierIndentVerfRowComponent } from './supplier/indent-verf-row/indent-verf-row.component'; // Partial
import { SupplierIndentVerfMsgComponent } from './supplier/indent-verf-msg/indent-verf-msg.component'; // Partial

// TASMAC Module
import { ReqItemsComponent } from './tasmac/req-items/req-items.component';
import { ReqItemAppComponent } from './tasmac/req-item-app/req-item-app.component';
import { ReqItemFullViewComponent } from './tasmac/req-item-full-view/req-item-full-view.component';
import { TasmacBrandsComponent } from './tasmac/tasmac-brands/tasmac-brands.component';
import { TasmacBrandAuthorizationComponent } from './tasmac/tasmac-brand-authorization/tasmac-brand-authorization.component';
import { TasmacPermitsComponent } from './tasmac/permits/permits.component';
import { TasmacPermitCancelComponent } from './tasmac/permit-cancel/permit-cancel.component';

// Advanced Settings
import { MySQLBackupListComponent } from './shared/mysql-bkup/list.component';

// Guard & Provider
import { AuthGuard } from './shared/authguard/authguard';
import { requestOptionsProvider } from './shared/custom.request.header';
import { responseOptionsProvider } from './shared/custom.response.header';

// Resolver
// import { CusPaySumResolver, PaySumResolver } from './components/payments/resolver.service';
import { ImportPermitViewResolver } from './components/login/resolver.service';
import { UserResolver } from './components/user/resolver.service';
import { CountryResolver } from './components/country/resolver.service';
import { DepotResolver } from './components/depot/resolver.service';
import { ItemCatResolver } from './components/item_cat/resolver.service';
import { ItemResolver } from './components/item/resolver.service';
import { CustomerResolver } from './components/customers/resolver.service';
import { NewPermitResolver, PermitRequestedResolver, PermitsResolver, PermitListResolver, PermitRevalResolver, PermitCancelResolver } from './components/permit/resolver.service';

// Service
import { AlertService } from './shared/alert/alert.service';
import { AuthenticationService } from './login.service';
import { DataService } from './data.service';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

// Reports
// import { CustomerPaymentSummaryComponent } from './components/reports/customer/payment-summary/payment-summary.component';
import { PaymentSummaryComponent } from './components/reports/payment-summary/payment-summary.component';
import { TaxInvoicesComponent } from './components/reports/tax-invoices/tax-invoices.component';
import { RequestStatusSummaryComponent } from './components/reports/request-status-summary/request-status-summary.component';
import { PaymentSummaryByMonthComponent } from './components/reports/payment-summary-by-month/payment-summary-by-month.component';
import { DailyPaymentsComponent } from './components/reports/daily-payments/daily-payments.component';
import { TMBrandSummaryComponent } from './components/reports/tm-brand-summary/tm-brand-summary.component';
import { TMApprovedPermitsComponent } from './components/reports/tm-approved-permits/tm-approved-permits.component';

// New System Stock Monitoring

import { RateRevisedComponent } from './components/rate-master/rate-revised.component';
import { RateMastersComponent } from './components/rate-master/rate-masters.component';
import { StockComponent } from './components/stock/stock.component';
import { StockNineComponent } from './components/stock/stock-nine.component';
import { TasmacStockComponent } from './components/tasmac-stock/tasmac-stock.component';
import { OrderComponent } from './components/tasmac-stock/order.component';
import { PaymentDetailsComponent } from './components/tasmac-stock/payment-details.component';
import { ShopStockNineComponent } from './components/shop/stock-nine.component';
import { ShopStockComponent } from './components/shop/stock.component';
import { ShopOrderComponent } from './components/shop/order.component';
import { SupStockNineComponent } from './components/supplier/stock-nine.component';
import { SupStockComponent } from './components/supplier/stock.component';
import { TransferComponent } from './components/supplier/transfer.component';
import { TransferSupComponent } from './components/supplier/tasmac-stock.component';
import { TransferSupShopComponent } from './components/supplier/tasmac-stock-shop.component';
import { InvoiceComponent } from './components/supplier/invoice.component';
import { TasmacStockShopComponent } from './components/shop/tasmac-stock.component';
import { TransferInComponent } from './components/shop/transferin.component';
import { TransferOutComponent } from './components/shop/transferout.component';
import { TransferInDepotComponent } from './components/depot/transferin.component';
import { TransferOutDepotComponent } from './components/depot/transferout.component';
import { TransferInSupplierComponent } from './components/depot/transferinsupplier.component';
import { TasmacSaleShopComponent } from './components/shop/tasmac-sale.component';
import { InvoiceStatusComponent } from './components/supplier/order.component';
import { SupFlStockComponent } from './components/supplier/flstock.component';
import { InvoicePaymentDetailsComponent } from './components/supplier/payment-details.component';
import { SedimentComponent } from './components/depot/tasmac-sale.component';
import { ShopRequestComponent } from './components/depot/shop.component';
import { FlComponent } from './components/depot/fl.component';
import { DepotStockComponent } from './components/depot/stock.component';
import { DepotStockNineComponent } from './components/depot/stock-nine.component';
import { SupplierInvoiceComponent } from './components/tasmac-stock/invoice.component';
// import { SedimentComponent } from './components/depot/tasmac-sale.component';
import { InvoiceApprovingComponent } from './components/tasmac-stock/permits.component';
import { InvoiceVerfyComponent } from './components/tasmac-stock/verfy.component';
import { InvoiceViewComponent } from './components/tasmac-stock/view.component';
import { InvoiceApproving2Component } from './components/tasmac-stock/permits2.component';
import { InvoiceApprovingGMComponent } from './components/tasmac-stock/permitsgm.component';
import { InvoiceApprovingmdComponent } from './components/tasmac-stock/permitsmd.component';
import { SedimentDComponent } from './components/depot/sediment.component';
import { PermitsToBeGRAComponent } from './components/depot/permits_to_be_labelled.component';
import { InvoicePaymentComponent } from './components/tasmac-stock/payment-invoice.component';

@NgModule({
  declarations: [
    AppComponent,
    CapitalizePipe,
    
    // Partials
    AlertComponent,
    HeaderComponent,
    FooterComponent,
    ImportFeedbackComponent,
    TablePaginationComponent,
    LicenseeEditComponent,
    LicenseComponent,
    ViewLicComponent,
    LicenseListComponent,
    ViewDocComponent,
    VerifyComponent,
    VerfDetComponent,
    VerfStateComponent,
    VerfAddComponent,
    VerfMsgComponent,
    SupplierEditComponent,
    SupplierStarListComponent,
    SupplierViewComponent,
    WarehouseEditComponent,
    WarehouseStarListComponent,
    WarehouseViewComponent,
    PermitSpaceLeaseCertificateEditComponent,
    PermitSpaceLeaseCertificateViewComponent,
    PermitRouteEditComponent,
    PermitRouteViewComponent,
    PermitItemEditComponent,
    PermitItemListComponent,
    PermitProformaInvoiceEditComponent,
    PermitProformaInvoiceViewComponent,
    PermitSPFComponent,
    PermitAPFComponent,
    PermitSCComponent,
    PermitSTComponent,
    PermitCoELComponent,
    PermitPayAddComponent,
    PermitPayViewComponent,
    PermitVStateComponent,
    PermitRequestedComponent,
    PermitRemarkAddComponent,
    PermitRemarkViewComponent,
    PermitRemarkListComponent,
    CustomerViewComponent,
    ReqItemViewComponent,
    ReqItemActionViewComponent,
  
    // Navigatables
    LoginComponent,
    ImportPermitViewComponent,
    PublicViewPermitComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LicenseeSignupComponent,
    LicenseeSignupSuccessComponent,
    HomeComponent,
    ChangePasswordComponent,
    FailurePaymentComponent,
    // CUS
    NewPermitComponent,
    PaymentComponent,
    PermitListComponent,
    PermitViewComponent,
    PermitRevalComponent,
    PermitCancelComponent,
    
    CusFLLicenseComponent,
    CusFSLicenseComponent,
    
    CusMyselfComponent,
    
    PermitsComponent,
    PermitVerifyComponent,
    PermitApprovedComponent,
    // PendingDigitalSignaturesComponent,
    DigitalSignatureComponent,
    PermitsApprovedComponent,
    PermitsToBePrintedComponent,
    PermitsExtRequestedComponent,
    PermitsToBeLabelledComponent,
    PermitsRequestExtComponent,
    PermitsDespatchedComponent,
    PermitsCandRejComponent,
    PermitLabelComponent,
    PermitExtComponent,
    PermitPrintComponent,
    PermitEvalPrintComponent,
    RequestCreationBlockedComponent,
    
    PeSectionCustomersComponent,
    CustomerComponent,
    UsersComponent,
    UserComponent,
    CountriesComponent,
    CountryComponent,
    StatesComponent,
    StateComponent,
    DepotsComponent,
    DepotComponent,
    BrandOwnersComponent,
    BrandOwnerComponent,
    BrandRegistrationsComponent,
    BrandRegistrationComponent,
    BrandRegistrationSelectListComponent,
    ItemCatsComponent,
    ItemCatComponent,
    ItemsComponent,
    ItemComponent,
    SuppliersComponent,
    SupplierComponent,
    WarehousesComponent,
    WarehouseComponent,
    SettingsEditComponent,

    // Supplier module
    SupplierUsersComponent,
    SupplierUserComponent,
    
    SupplierReqItemsComponent,
    SupplierReqItemComponent,
    SupplierReqItemViewComponent,
    SupplierLicenseIECComponent,
    SupplierLicenseFSComponent,
    SupplierWarehousesComponent,
    SupplierWarehouseEditComponent,
    SupplierPermitListComponent,
    SupplierPermitTasmacRequestComponent,
    SupplierIndentComponent,
    SupplierIndentItemEditComponent, // Parital
    SupplierIndentRemarkEditComponent, // Partial
    // SupplierIndentRemarkViewComponent, // Partial
    SupplierIndentSubmittedComponent,
    SupplierIndentListComponent,
    SupplierIndentViewComponent,
    IndentsComponent,
    ApprovedIndentsComponent,
    SupplierIndentToRequestListComponent,
    SupplierIndentVerfRowComponent, // Partial
    SupplierIndentVerfMsgComponent, // Partial

    // TASMAC modules
    ReqItemsComponent,
    ReqItemAppComponent,
    ReqItemFullViewComponent,
    TasmacBrandsComponent,
    TasmacBrandAuthorizationComponent,
    TasmacPermitsComponent,
    TasmacPermitCancelComponent,

    // Advanced Settings
    MySQLBackupListComponent,
  
    // Reports
    // CustomerPaymentSummaryComponent,
    PaymentSummaryComponent,
    TaxInvoicesComponent,
    RequestStatusSummaryComponent,
    PaymentSummaryByMonthComponent,
    DailyPaymentsComponent,
    TMBrandSummaryComponent,
    TMApprovedPermitsComponent,

    //New Stock Monitoring system
    RateRevisedComponent,
    RateMastersComponent,
    StockComponent,
    StockNineComponent,
    TasmacStockComponent,
    OrderComponent,
    PaymentDetailsComponent,
    ShopStockComponent,
    ShopStockNineComponent,
    ShopOrderComponent,
    SupStockComponent,
    SupStockNineComponent,
    TransferComponent,
    TransferSupComponent,
    TransferSupShopComponent,
    InvoiceComponent,
    TransferInComponent,
    TransferOutComponent,
    TransferInDepotComponent,
    TransferOutDepotComponent,
    TransferInSupplierComponent,
    TasmacStockShopComponent,
    TasmacSaleShopComponent,
    InvoiceStatusComponent,
    InvoicePaymentDetailsComponent,
    SedimentComponent,
    FlComponent,
    ShopRequestComponent,
    DepotStockComponent,
    DepotStockNineComponent,
    SupplierInvoiceComponent,
    InvoiceApprovingComponent,
    InvoiceVerfyComponent,
    InvoiceViewComponent,
    InvoiceApproving2Component,InvoiceApprovingmdComponent,InvoiceApprovingGMComponent,
    SedimentDComponent,
    InvoicePaymentComponent,
    PermitsToBeGRAComponent,
    SupFlStockComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),

    AppRoutingModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthGuard,
    requestOptionsProvider,
    responseOptionsProvider,
  
    // Resolvers
    // NewRegTokenResolver,
    ImportPermitViewResolver,
    UserResolver,
    CountryResolver,
    DepotResolver,
    ItemCatResolver,
    ItemResolver,
    CustomerResolver,
    NewPermitResolver,
    PermitRequestedResolver,
    PermitsResolver,
    PermitListResolver,
    PermitRevalResolver,
    PermitCancelResolver,
  
    // Services
    AlertService,
    AuthenticationService,
    DataService,

    //New Stock Monitering
    // ItemRate
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
