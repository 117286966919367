import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';

import { DataService } from '../../data.service';
import { ItemCat } from '../../config';

@Injectable()
export class ItemCatResolver implements Resolve<ItemCat> {
  constructor(private dataService: DataService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ItemCat> {
    return Observable.create((ob:any) => {
      let id = +route.params['id'];
      if (id == 0)
      {
        ob.next(new ItemCat());
        ob.complete();
        return;
      }

      this.dataService.getItemCat({ id: id }).subscribe((data:any) => {
        if (data.id == id)
        {
          ob.next(data as ItemCat);
          ob.complete();
        }
        else
        {
          ob.next(new ItemCat());
          ob.complete();
          this.router.navigate(['/item_cats']);
        }
      });
    });
  }
}
