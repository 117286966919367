import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';
import { Location } from '@angular/common';

import { NumberValidator } from '../../../shared/validators/number.directive';
import { PermitFee, Payment } from '../../../config';
import { DataService } from '../../../data.service';

@Component({
  selector: 'permit-pay-add',
  templateUrl: './add.component.html'
})
export class PermitPayAddComponent implements OnChanges {
  @Input()
  permit_id:number;

  @Input()
  customer_id:number;

  @Input()
  pay_to:string;

  @Input()
  pay:any;

  @Input()
  fees:PermitFee[];

  @Input()
  fee_stage:string;

  @Output()
  balance_changed:EventEmitter<number> = new EventEmitter<number>();

  @Output()
  payment_verified:EventEmitter<void> = new EventEmitter<void>();

  pre_bal_p:any;
  pre_bal_s:any;
  cur:any;
  adj:any;
  paid:any;
  payInitated:any;
  balance:number = 0;
  disablePayNow = false;

  submitted = false;
  loading = false;
  fg: FormGroup;
  errors:any = { };
  messages:any = [];

  trustedUrl:SafeResourceUrl;
  @ViewChild('lgModal0', { static: false }) public lgModal0:ModalDirective;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private dataService: DataService
  ) { }

  ngOnChanges(changes:SimpleChanges|any) {
    if (changes.pay && changes.pay.currentValue)
    {
      this.disablePayNow = false;
      this.dist_pay();
      this.buildForm();
    }
  }

  private buildForm(): void {
    let controlsConfig:any = {};

    //  value="{{get_adj_amt()}}"
    const myPattern:RegExp = new RegExp(/^[0-9]{1,10}$/);
    controlsConfig['trnx_value'] = [
      this.get_adj_amt(),
      [
        Validators.required,
        NumberValidator.min(0),
        NumberValidator.max(this.get_max_amt()),
        Validators.pattern(myPattern)
      ]
    ];
    this.errors['trnx_value'] = [];
    this.messages['trnx_value'] = {
      'required': 'Adjustment amount is required.',
      'min': 'Adjustment amount should be greater than 0.',
      'max': 'Adjustment amount should not be greater than ₹ ' + this.get_max_amt().toLocaleString('hi-IN', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      'pattern': 'Adjustment amount should be a rounded value.'
    };
  
    this.fg = this.fb.group(controlsConfig);
    this.fg.valueChanges.subscribe(data => this.onValueChanged(data));
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
  }
  
  onValueChanged(data?: any) {
    if (!this.fg) { return; }
    
    const form = this.fg;
    for (const field in this.errors) {
      this.errors[field] = [];
      const control = form.get(field);
      if (control && control.invalid) { //  control.dirty &&
        const messages = this.messages[field];
        for (const key in control.errors) {
          this.errors[field].push(((messages[key])?messages[key]:(key + " error")));
        }
      }
    }
  }
  
  public isControlHasError(fieldName:string):boolean {
    let formGroup: FormGroup = this.fg;
    return formGroup.controls[fieldName].invalid && (this.submitted);
    // this.regForm.controls[fieldName].touched || 
  }

  dist_pay() {
    this.pre_bal_p = null;
    this.pre_bal_s = null;
    this.cur = null;
    this.adj = null;
    this.paid = null;
    this.payInitated = null;

    if (this.pay)
    {
      this.pay.forEach((p:any) => {
        if (p.pay_to == this.pay_to && p.ptype == "Previous Balance" && p.pay_amt >= 0)
          this.pre_bal_p = p;
        if (p.pay_to == this.pay_to && p.ptype == "Previous Balance" && p.pay_amt < 0)
          this.pre_bal_s = p;
        if (p.pay_to == this.pay_to && p.ptype == "Current Payable Amount")
          this.cur = p;
        if (p.pay_to == this.pay_to && p.ptype == "Current Paid Amount" && p.is_adjusted)
          this.adj = p;
        if (p.pay_to == this.pay_to && p.ptype == "Current Paid Amount" && !p.is_adjusted)
        {
          this.paid = p;
          if (this.paid.pay_det)
            this.paid.pay_det = this.paid.pay_det.map((pd:any) => {
              if (pd.trnx_date) pd.trnx_date = new Date(pd.trnx_date);
              if (pd.ifhrms_challan_date) pd.ifhrms_challan_date = new Date(pd.ifhrms_challan_date);
              if (pd.cancel_date) pd.cancel_date = new Date(pd.cancel_date);
              return pd;
            });
        }
        if (p.pay_to == this.pay_to && p.ptype == "Current Payment Initiated / Failed" && !p.is_adjusted)
        {
          this.payInitated = p;
          if (this.payInitated.pay_det)
          {
            this.payInitated.pay_det = this.payInitated.pay_det.map((pd:any) => {
              if (pd.trnx_date) pd.trnx_date = new Date(pd.trnx_date);
              if (pd.ifhrms_challan_date) pd.ifhrms_challan_date = new Date(pd.ifhrms_challan_date);
              if (pd.status == 'INITIATED' && this.disablePayNow == false)
                this.disablePayNow = true;
              return pd;
            });
          }
        }
      });
    }
    this.calc_balance();
  }

  calc_balance() {
      // - (this.pre_bal_s?this.pre_bal_s.pay_amt:0)
    this.balance = 
      (this.pre_bal_p?this.pre_bal_p.pay_amt:0)
      + (this.cur?Math.ceil(this.cur.pay_amt):0)
      - (this.adj?this.adj.pay_amt:0)
      - (this.paid?this.paid.pay_amt:0);
    this.balance_changed.emit(this.get_rounded(this.balance));
  }

  get_pay_to_fee() {
    return this.fees.filter(f => {
      return f.pay_to == this.pay_to && f.fee_stage == this.fee_stage;
    });
  }

  get_abs(val:number) {
    return Math.abs(val);
  }

  get_rounded(val:number) {
    return Math.ceil(val);
  }

  get_max_amt() {
    let ret_val = 0;
    if (this.pre_bal_s && this.pre_bal_s.pay_amt)
    {
      ret_val = Math.abs(this.pre_bal_s.pay_amt);
      if (this.cur && this.cur.pay_amt && this.cur.pay_amt < ret_val)
      ret_val = Math.ceil(this.cur.pay_amt);
    }
    else if (this.adj && this.adj.pay_amt)
      ret_val = this.adj.pay_amt;
    return ret_val;
  }

  get_adj_amt() {
    let ret_val = 0;
    if (this.adj && this.adj.pay_amt)
      ret_val = this.adj.pay_amt;
    return ret_val;
  }

  get_total_paid_adj_amt() {
    let tot = 0;
    if (this.paid && this.paid.pay_amt)
      tot += this.paid.pay_amt;
    if (this.adj && this.adj.pay_amt)
      tot += this.adj.pay_amt;
    return tot;
  }
  
  make_payment() {
    // if (!confirm("Are you sure to proceed for payment?")) return;
    const trnx_value:number = this.get_rounded(this.balance);
    // 'add_payment/:permit_id/:customer_id/:trnx_value/:trnx_to'
    const payment = new Payment(this.customer_id, this.fee_stage, this.pay_to, trnx_value);
    payment.permit_id = this.permit_id;
    this.dataService.filters.add_payment = payment;
    // { permit_id: this.permit_id, customer_id: this.customer_id, trnx_value: trnx_value, pay_to: this.pay_to, fee_stage: this.fee_stage };
    this.router.navigate(["/add_payment"]);
  }

  make_adjustment() {
    this.submitted = true;
    this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true});
    if (this.fg.valid) {
      let sValue = this.fg.value;
      if (!this.adj)
        this.adj = { 
          fee_stage: this.fee_stage,
          is_adjusted: 1
        }
      this.adj.permit_id = this.permit_id;
      this.adj.trnx_to = this.pay_to;
      this.adj.trnx_date = (new Date()).toLocaleDateString('hi-IN', {'day': '2-digit', 'month': '2-digit', 'year': 'numeric' });
      this.adj.trnx_value = sValue.trnx_value;
      this.loading = true;
      this.dataService.savePaymentAdjustment(this.adj).subscribe((data:any) => {
        if (data.message == "Payment Adjustment authorized successfully.")
        {
          if (data.id)
            this.adj.id = data.id;
          this.adj.pay_amt = this.adj.trnx_value;
          this.calc_balance();
        }
        this.loading = false;
      });
    }
  }

  verify_payment(payData: any) {
    
    console.log(payData);
    if (payData.is_ifhrms_payment) {

      const href = Location.joinWithSlash(location.href.split('#', 1)[0], '/svr/payment_status.php');
      const return_url = this.sanitizer.sanitize(SecurityContext.URL, href);
      const reqData = { return_url: return_url, trnx_no_own: payData.trnx_no_own as string };

      this.loading = true;
      this.dataService.doubleVerifyIFHRMSPayment(reqData).subscribe((data: any) => {
        this.loading = false;
        if (data.message === 'Proceed to IFHRMS reverify.') {
          if (data.is_ifhrms_gw) {
            const frmIFHRMS = document.createElement('form');
            frmIFHRMS.name = data.ifhrms_form_name;
            frmIFHRMS.action = this.sanitizer.sanitize(SecurityContext.URL, data.ifhrms_url);
            frmIFHRMS.method = "get";
  
            Object.keys(data.ifhrms_data).forEach(element => {
              
              const el = document.createElement("input");
              el.name = element;
              el.value = data.ifhrms_data[element];
              frmIFHRMS.appendChild(el);

            });
            document.body.appendChild(frmIFHRMS);
            frmIFHRMS.submit();
          }
        }
      });
    } else {
      this.loading = true;
      this.dataService.doubleVerifyPayment(payData).subscribe((data: any) => {
        this.loading = false;
        if (data.message == "Verified with Payment Gateway") {
          this.payment_verified.emit();
        }
      });
    }
  }
  
  cancelPayment(payData: Payment) {
    if (!confirm('This option will cancel your pending status and enable you to proceed with payment once again, in case you have attempted to make payment and the amount has not been debited from your account. However, if you wish to proceed with invoking this option even if the amount has been debited from your account, you may do so at your own discreetion. Otherwise, you can click on verify Payment status button to check your status.\n\nAre you sure you want to proceed?')) return;

    this.loading = true;
    this.dataService.cancelPayment(payData).subscribe((data: any) => {
      if (data.message == "Payment Transaction Cancelled Successfully.") {
        this.payment_verified.emit();
      }
      this.loading = false;
    });
  }

  printCInvoice(pd: any) {
    this.loading = true;
    const data = { 'id': this.permit_id, permit_payment_id: pd.id };
    this.dataService.printCInvoice(data).subscribe((blob:Blob) => {
      // let blob = new Blob([response.blob()], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }

  printAcknowledgement(pd: any) {
    this.loading = true;
    const data = { permit_payment_id: pd.id };
    this.dataService.printAcknowledgement(data).subscribe((blob:Blob) => {
      var fileURL = URL.createObjectURL(blob);
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      this.lgModal0.show();
      this.loading = false;
    });
  }
}
