import { Component, OnInit } from '@angular/core';

import { DataService } from '../../data.service';
import { Country } from '../../config';

@Component({
  templateUrl: './view.component.html'
})
export class CountriesComponent implements OnInit {
  filters:any = {};
  countries:Array<Country> = [];

  constructor(
    private dataService:DataService
  ) { }

  ngOnInit() {
    this.dataService.getCountries(this.filters).subscribe((data:Country[]) => {
      this.countries = data;
    });
  }
}
